import React, {createRef, useEffect, useRef, useState} from 'react';
//import styles from '../../courseManagement/CourseWizard/CourseWizard.module.scss';
import styles from './SearchList.module.scss';
const SearchListComponent = ({
  list = [],
  varient,
  handlePillList,
  pillsLimit = null,
  defaultPillList,
  placeHolderText,
  defaultInputData,
  reference,
  disabledInput = false,
  setBtn2SaveAndExitDisable,
  isDisabled = false,
}) => {
  const [searchList, setSearchList] = useState(list);
  const [search, setSearch] = useState('');
  const [val, setVal] = useState(null);
  const [open, setOpen] = useState(false);
  const [pillList, setPillList] = useState([]);
  const [pillListID, setPillListID] = useState([]);

  const ref = createRef();
  const handleOnChange = (data) => {
    setSearch(data);
    if (data !== '') {
      const results = list?.filter((ele) => {
        return ele.title.toLowerCase().includes(data.toLowerCase());
      });
      setSearchList(results);
      setOpen(true);
    } else {
      setSearchList(list);
      setOpen(true);
    }
  };

  useEffect(() => {
    let tempArr = [];
    defaultPillList && setPillList(defaultPillList);
    defaultPillList &&
      defaultPillList.map((data) => {
        tempArr.push(data?.id);
      });
    defaultPillList && setPillListID(tempArr);
  }, [defaultPillList]);

  useEffect(() => {
    list && setSearchList(list);
  }, [list]);

  useEffect(() => {
    if (varient === 'dropdown' && defaultInputData !== '') {
      setSearch(defaultInputData);
    }
  }, [defaultInputData]);

  const handleClickAdd = (data) => {
    if (
      !pillList.some((ele) => {
        return ele.title === data.title || ele.id === data.id;
      })
    ) {
      const tmpObj = {
        id: data.id,
        title: data.title,
      };
      if (pillsLimit && pillList?.length < pillsLimit) {
        setPillListID([...pillListID, data.id]);
        setSearch('');
        setVal(null);
        setPillList(
          [...pillList, tmpObj],
          handlePillList([...pillList, tmpObj], [...pillListID, data.id]),
        );
      } else {
        if (pillsLimit === null) {
          setPillListID([...pillListID, data.id]);
          setPillList(
            [...pillList, tmpObj],
            handlePillList([...pillList, tmpObj], [...pillListID, data.id]),
          );
          setSearch('');
          setVal(null);
        }
      }
    }
    setBtn2SaveAndExitDisable ? setBtn2SaveAndExitDisable(false) : null;
  };

  const handleClickOutside = (event) => {
    if (
      ref &&
      !ref.current?.contains(event.target) &&
      reference &&
      !reference.current?.contains(event.target) &&
      !event.target.className.includes('CourseWizard_dropdown_container') &&
      !event.target.className.includes('CourseWizard_list') &&
      !event.target.className.includes('CourseWizard_list_wrapper') &&
      !event.target.className.includes('CourseWizard_input_wrapper') &&
      !event.target.className.includes('CourseWizard_input') &&
      !event.target.className.includes('CourseWizard_dropdown_otr') &&
      !event.target.className.includes('CourseWizard_search_list_wrapper') &&
      !event.target.className.includes('input_arrow_dropdown_wrapper') &&
      !event.target.className.includes('input_wrapper_and_arrow') &&
      !event.target.className.includes('down_arw_icon_add') &&
      !event.target.className.includes('down_arw_icon_open_add') &&
      !event.target.className.includes('input_add') &&
      !event.target.className.includes('dropdown_container_add')
      //&&
      //!(event.target.className.indexOf("CourseWizard_dropdown_container") !== -1)
    ) {
      setOpen(false);
    }
  };

  //   useEffect(() => {
  // 	if(varient === "dropdown")
  // 	{
  // 	document?.getElementById("searchList")?.focus();
  // 	}
  // 	// else if(varient === "textInput")
  // 	// {
  // 	// document?.getElementById("inputList")?.focus();
  // 	// }
  // 	else if(varient === "dropdownwithadd")
  // 	{
  // 	document?.getElementById("audienceList")?.focus();
  // 	}
  //   }, [open]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    if (search == '') {
      setVal(null);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [search]);

  return (
    <div className={styles.search_list_wrapper}>
      {varient === 'dropdown' ? (
        <>
        <div
          className={[styles.input_wrapper, disabledInput && styles.disabled].join(' ')}
          tabIndex={-1}
          onClick={() => {
            !disabledInput && setOpen(!open);
          }}
          onKeyPress={(e) => {
            if (e.charCode == '13') {
              !disabledInput && setOpen(!open);
            }
          }}
        >
          <div className={!isDisabled ? styles.input : [styles.input, styles.disabled].join(' ')}>
            {search && search.length > 0
              ? search
              : placeHolderText
              ? placeHolderText
              : 'Select Category'}
          </div>
          <div
            className={
              open && !isDisabled
                ? styles.down_arw_icon_open
                : !open && isDisabled
                ? styles.down_arw_icon_disabled
                : styles.down_arw_icon
            }
          ></div>
        </div>
        {open && (
            <div className={[styles.dropdown_container, ''].join(' ')} ref={ref}>
              <div className={styles.list_wrapper}>
                {searchList &&
                  searchList.length > 0 &&
                  searchList?.map((data, index) => {
                    return (
                      <div
                        className={styles.list}
                        id={'searchList'}
                        onClick={() => {
                          setSearch(data.title);
                          setVal(data);
                          setOpen(false);
                          handlePillList(data.title, data.id);
                        }}
                        key={index}
                        tabIndex={index}
                        onKeyPress={(e) => {
                          if (e.charCode == '13') {
                            setSearch(data.title);
                            setVal(data);
                            setOpen(false);
                            handlePillList(data.title, data.id);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'ArrowDown') {
                            e.currentTarget.nextSibling && e.currentTarget.nextSibling.focus();
                          }
                          if (e.code === 'ArrowUp') {
                            e.currentTarget.previousSibling &&
                              e.currentTarget.previousSibling.focus();
                          }
                        }}
                      >
                        {/* <div className={styles.img_item}></div> */}
                        <div className={styles.text_item}>{data.title}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      ) : null}
      {varient === 'textInput' ? (
        <div className={styles.input_wrapper}>
          <div className={styles.dropdown_otr}>
            <input
              type={'text'}
              name="keywords"
              placeholder={placeHolderText ? placeHolderText : ''}
              className={[styles.input, disabledInput ? styles.disabled : ''].join(' ')}
              value={search}
              onChange={(e) => handleOnChange(e.target.value)}
              tabIndex={-1}
            />
            {search.length > 0 && open && (
              <div className={[styles.dropdown_container, ''].join(' ')}>
                <div className={styles.list_wrapper}>
                  {searchList && searchList.length > 0 ? (
                    searchList?.map((data, index) => {
                      return (
                        <div
                          className={styles.list}
                          key={index}
                          tabIndex={index}
                          id={'inputList'}
                          onClick={() => {
                            setSearch(data.title);
                            setVal(data);
                            setOpen(false);
                          }}
                          onKeyPress={(e) => {
                            if (e.charCode == '13') {
                              setSearch(data.title);
                              setVal(data);
                              setOpen(false);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.code === 'ArrowDown') {
                              e.currentTarget.nextSibling && e.currentTarget.nextSibling.focus();
                            }
                            if (e.code === 'ArrowUp') {
                              e.currentTarget.previousSibling &&
                                e.currentTarget.previousSibling.focus();
                            }
                          }}
                        >
                          {/* <div className={styles.img_item}></div> */}
                          <div className={styles.text_item}>{data.title}</div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles.list}>
                      <div className={''}></div>
                      <div className={styles.text_item}>No item found</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* <div className={styles.add_btn} onClick={() => val !== null && handleClickAdd(val)}> */}
          <div
            className={
              search.length >= 2 &&
              searchList?.find((ele) =>
                ele?.title?.toLowerCase()?.includes(search?.toLowerCase()),
              ) !== undefined &&
              pillList?.find((ele) => ele?.title === search) === undefined &&
              pillsLimit &&
              val !== null &&
              pillList?.length < pillsLimit
                ? styles.add_btn_enable
                : styles.add_btn
            }
            onClick={() => val !== null && handleClickAdd(val)}
          >
            <span className={styles.plus_sign}></span> Add
          </div>
        </div>
      ) : null}
      {varient === 'dropdownwithadd' ? (
        <div className={styles.input_wrapper}>
          <div
            className={styles.input_arrow_dropdown_wrapper}
            tabIndex={-1}
            onClick={() => {
              setOpen(!open);
            }}
            onKeyPress={(e) => {
              if (e.charCode == '13') {
                setOpen(!open);
              }
            }}
          >
            {/* input arrow */}
            <div className={styles.input_wrapper_and_arrow}>
              <div
                className={[
                  styles.input_add,
                  styles.input,
                  disabledInput ? styles.disabled : '',
                ].join(' ')}
              >
                {search && search.length > 0 ? search : 'Select Audience Group'}
              </div>
              <div
                className={
                  open && !disabledInput
                    ? [styles.down_arw_icon_open_add].join('')
                    : [styles.down_arw_icon_add].join('')
                }
              ></div>
            </div>
            {/* dropdown */}
            <div>
              {open && !disabledInput && (
                <div className={[styles.dropdown_container_add, ''].join(' ')} ref={ref}>
                  <div className={styles.list_wrapper}>
                    {searchList &&
                      searchList.length > 0 &&
                      searchList?.map((data, index) => {
                        return (
                          <div
                            className={styles.list}
                            id={'audienceList'}
                            onClick={() => {
                              setSearch(data.title);
                              setVal(data);
                              setOpen(false);
                              // handlePillList(data.title, data.id);
                            }}
                            key={index}
                            tabIndex={index}
                            onKeyPress={(e) => {
                              if (e.charCode == '13') {
                                setSearch(data.title);
                                setVal(data);
                                setOpen(false);
                                //  handlePillList(data.title, data.id);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.code === 'ArrowDown') {
                                e.currentTarget.nextSibling && e.currentTarget.nextSibling.focus();
                              }
                              if (e.code === 'ArrowUp') {
                                e.currentTarget.previousSibling &&
                                  e.currentTarget.previousSibling.focus();
                              }
                            }}
                          >
                            {/* <div className={styles.img_item}></div> */}
                            <div className={styles.text_item}>{data.title}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* add button */}
          <div>
            <div
              className={
                search.length >= 2 &&
                searchList?.find((ele) =>
                  ele?.title?.toLowerCase()?.includes(search?.toLowerCase()),
                ) !== undefined &&
                pillList?.find((ele) => ele?.title === search) === undefined
                  ? //&&
                    // pillsLimit &&
                    // pillList?.length < pillsLimit
                    styles.add_btn_enable
                  : styles.add_btn
              }
              onClick={() => val !== null && handleClickAdd(val)}
            >
              <span className={styles.plus_sign}></span> Add
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
SearchListComponent.propTypes = {};

export default SearchListComponent;
