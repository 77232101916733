import {
  SET_CROPPED_IMAGE_DATA,
  SET_RATIO,
  SET_MAIN_IMAGE_BACKUP,
  SET_CROPPED_IMAGE_1_1,
  SET_CROPPED_IMAGE_16_9,
  SET_BACKUP_IMAGE_16_9,
  SET_BACKUP_IMAGE_1_1,
  SET_REPLACED_IMAGE,
  SET_SELECTED_IMAGE_16_9_BACKUP,
  SET_SELECTED_IMAGE_1_1_BACKUP,
  SET_IMAGE_LIST_ALL,
  SET_BUTTON_169_STATUS,
  SET_BUTTON_11_STATUS,
  SET_REPLACED_IMAGE_UPLOADED,
  SET_CROPPED_IMAGE_DATA_11,
  SET_CROPPED_IMAGE_DATA_169,
  SET_IMAGE_DATA,
  SET_LOCAL_MAIN_IMAGE,
  SET_NEW_IMAGE_ID,
  SET_DELETE_CHECKBOX,
  SET_THUMBNAIL_IMAGE_16_9,
} from '../../actions/imageCrop/imageCropAction';

const initialState = {
  isImageUploaded: false,
  image_data: null,
  local_main_image_data: null,
  newImageID: null,
  croppedImage_16_9: null,
  croppedImage_1_1: null,
  deleteImageCheckbox: false,
  thumbnailImage169: null,
};

export const imageCropReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CROPPED_IMAGE_DATA: {
      return {...state, croppedImageData: action.payload};
    }
    case SET_CROPPED_IMAGE_DATA_11: {
      return {...state, croppedImageData11: action.payload};
    }
    case SET_CROPPED_IMAGE_DATA_169: {
      return {...state, croppedImageData169: action.payload};
    }

    case SET_RATIO: {
      return {...state, ratio: action.payload};
    }

    case SET_MAIN_IMAGE_BACKUP: {
      return {...state, mainImageBackup: action.payload};
    }

    case SET_SELECTED_IMAGE_16_9_BACKUP: {
      return {...state, selectedImage_16_9_Backup: action.payload};
    }

    case SET_SELECTED_IMAGE_1_1_BACKUP: {
      return {...state, selectedImage_1_1_Backup: action.payload};
    }

    case SET_BACKUP_IMAGE_16_9: {
      return {...state, backupImage_16_9: action.payload};
    }

    case SET_BACKUP_IMAGE_1_1: {
      return {...state, backupImage_1_1: action.payload};
    }

    case SET_CROPPED_IMAGE_1_1: {
      return {...state, croppedImage_1_1: action.payload};
    }

    case SET_CROPPED_IMAGE_16_9: {
      return {...state, croppedImage_16_9: action.payload};
    }

    case SET_REPLACED_IMAGE: {
      return {...state, replacedImage: action.payload};
    }

    case SET_IMAGE_LIST_ALL: {
      return {...state, image_list_all: action.payload};
    }

    case SET_BUTTON_169_STATUS: {
      return {...state, button_169_status: action.payload};
    }

    case SET_BUTTON_11_STATUS: {
      return {...state, button_11_status: action.payload};
    }

    case SET_REPLACED_IMAGE_UPLOADED: {
      return {...state, isImageUploaded: action.payload};
    }

    case SET_IMAGE_DATA: {
      return {...state, image_data: action.payload};
    }

    case SET_LOCAL_MAIN_IMAGE: {
      return {...state, local_main_image_data: action.payload};
    }
    case SET_NEW_IMAGE_ID: {
      return {...state, newImageID: action.payload};
    }
    case SET_DELETE_CHECKBOX: {
      return {...state, deleteImageCheckbox: action.payload};
    }
    case SET_THUMBNAIL_IMAGE_16_9: {
      return {...state, thumbnailImage169: action.payload};
    }
    default: {
      return state;
    }
  }
};
