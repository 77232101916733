import React, {useState} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import Pills from '../../shared/pills';
import CheckboxComponent from '../../shared/checkbox';
import styles from './TagPreset.module.scss';

const TagPresetModalComponent = ({closeTagPresetModal}) => {
  const [search, setSearch] = useState('');
  const [isEditable, setEditable] = useState(false);
  const [isCreateNew, setCreateNew] = useState(false);
  const [isTagRowID, setTagRowID] = useState(false);
  const [isCollapse, setCollapsible] = useState(false);
  const pillData1 = [
    {id: 1, name: 'Social service'},
    {id: 2, name: 'Taiwan ABOs'},
  ];
  const pillData2 = [
    {id: 1, name: 'Nutrilite'},
    {id: 2, name: 'Customer service'},
  ];

  const handleCollapsible = () => {
    setCollapsible(!isCollapse);
  };

  const handleEditTag = (value) => {
    setCreateNew(false);
    setTagRowID(value);
    if (value === isTagRowID) {
      setEditable(!isEditable);
    }
  };

  const handleCreateNew = (value) => {
    setEditable(false);
    setTagRowID(value);
    if (value === isTagRowID) {
      setCreateNew(!isCreateNew);
    }
  };

  return (
    <React.Fragment>
      <div className={[styles.modal_wrapper, ''].join(' ')}>
        <div className={styles.tag_preset_modal}>
          <div className={styles.close_wrapper} onClick={() => closeTagPresetModal()}>
            <div className={styles.close_icon} />
          </div>
          <div className={styles.header_wrapper}>
            <div className={styles.heading}>
              Tag - Preset
              <div className={styles.sub_txt}>
                Add presets made by you and other admins to this audience group
              </div>
            </div>
            <div className={styles.search_main_wrapper}>
              <div className={styles.search_wrapper}>
                <input
                  type="text"
                  placeholder="Search Tag-Presets"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={styles.search_input}
                />
                <div className={styles.icon_wrapper}>
                  <div className={styles.search} />
                </div>
              </div>
              {search.length > 3 ? (
                <div className={styles.dropdown_container}>
                  <div className={styles.list_wrapper}>
                    <div className={styles.list}>Nutrilite customers</div>
                    <div className={styles.list}>Selling Nutrilite products</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.action_wrapper}>
              <div className={styles.audience_grp}>
                <div className={styles.txt}>Create a new Tag-Preset</div>
              </div>
            </div>
          </div>
          <div className={styles.content_area}>
            <div className={styles.collapsible_wrapper}>
              <div
                className={[styles.collapsible, isCollapse ? styles.isOpenCollapse : ''].join(' ')}
              >
                <div className={styles.txt}>Create a new Tag-Preset</div>
                <div
                  className={[styles.down_arrow, isCollapse ? styles.isOpen : ''].join(' ')}
                  onClick={() => handleCollapsible()}
                />
              </div>

              {isCollapse ? (
                <form
                  className={[
                    styles.form_wrapper,
                    styles.pos_relative,
                    styles.create_new_preset,
                    'bootstrap-wrapper',
                  ].join(' ')}
                >
                  <div className={'row'}>
                    <div className={['col-xl-6 col-lg-6 pull-left', styles.mb_10].join(' ')}>
                      <div className={styles.form_field}>
                        <div className={styles.input_wrapper}>
                          <input
                            type={'text'}
                            placeholder={'Name of the preset goes here'}
                            className={styles.input}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={['col-xl-12 col-lg-12 pull-left'].join(' ')}>
                      <div className={styles.checkmark_container}>
                        <CheckboxComponent checked={false} />
                        <div className={styles.user_icon} />
                        <div className={styles.sub_txt}>Share this preset with other Admins</div>
                      </div>
                    </div>

                    <div className={[styles.action_wrapper, styles.action_position].join(' ')}>
                      <div className={styles.close_icon} />
                      <div className={styles.divider} />
                      <div className={styles.tick_icon} onClick={() => handleCreateNew(1)} />
                    </div>

                    <div
                      className={[
                        styles.mb_10,
                        styles.flex_end,
                        'col-xl-12 col-lg-12 pull-left',
                      ].join(' ')}
                    >
                      <div className={[styles.form_field, styles.w_50].join(' ')}>
                        <div className={styles.label}>Add tags</div>
                        <div className={[styles.input_wrapper].join(' ')}>
                          <input
                            type={'text'}
                            placeholder={'Add Keywords and Enter'}
                            className={styles.input}
                          />
                        </div>
                      </div>
                      <div className={[styles.btn_wrapper, styles.justify_left].join(' ')}>
                        <button className={[styles.btn, styles.disabled].join(' ')}>
                          <div className={styles.add_icon} />
                          <div className={[styles.txt, styles.ml_10].join(' ')}>Add</div>
                        </button>
                      </div>
                    </div>

                    <div className={['col-xl-12 col-lg-12 pull-left'].join(' ')}>
                      <Pills pillData={pillData2} isClose={true} isBold={false} />
                    </div>
                  </div>
                </form>
              ) : null}
            </div>

            {/* List Startes */}
            <div
              className={[styles.tag_list_wrapper, isCollapse ? styles.tag_collapisble : ''].join(
                ' ',
              )}
            >
              <div className={styles.tag_list}>
                <div className={styles.add_wrapper}>
                  <div className={styles.add_icon} />
                </div>
                <div className={styles.middle_content}>
                  <div className={styles.heading_area}>
                    <div className={styles.user} />
                    <div className={styles.title}>Nutrilite customers only</div>
                  </div>
                  <Pills pillData={pillData1} isClose={false} isBold={false} />
                </div>
                <div className={styles.action_wrapper}>
                  <div className={styles.delete_icon} />
                  <div className={styles.divider} />
                  <div className={styles.edit_icon} />
                </div>
              </div>
              <div
                className={[
                  styles.tag_list,
                  isEditable && isTagRowID == 2 ? styles.editable_wrapper : '',
                ].join(' ')}
              >
                {isEditable && isTagRowID == 2 ? (
                  <form className={[styles.form_wrapper, 'bootstrap-wrapper'].join(' ')}>
                    <div className={'row'}>
                      <div className={styles.label_xs}>Edit Tag Preset</div>
                      <div
                        className={['col-xl-6 col-lg-6 col-md-6 pull-left', styles.mb_10].join(' ')}
                      >
                        <div className={styles.form_field}>
                          <div className={styles.input_wrapper}>
                            <input
                              type={'text'}
                              placeholder={'Name of the preset goes here'}
                              className={styles.input}
                              value={'Taiwan market preset'}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={['col-xl-12 col-lg-12 pull-left'].join(' ')}>
                        <div className={styles.checkmark_container}>
                          <CheckboxComponent checked={false} />
                          <div className={styles.user_icon} />
                          <div className={styles.sub_txt}>Share this preset with other Admins</div>
                        </div>
                      </div>

                      <div className={[styles.action_wrapper, styles.action_position].join(' ')}>
                        <div className={styles.undo_icon} />
                        <div className={styles.divider} />
                        <div className={styles.tick_icon} onClick={() => handleEditTag(2)} />
                      </div>

                      <div
                        className={[
                          styles.mb_10,
                          styles.flex_end,
                          'col-xl-12 col-lg-12 pull-left',
                        ].join(' ')}
                      >
                        <div className={[styles.form_field, styles.w_50].join(' ')}>
                          <div className={styles.label}>Add tags</div>
                          <div className={[styles.input_wrapper].join(' ')}>
                            <input
                              type={'text'}
                              placeholder={'Add Keywords and Enter'}
                              className={styles.input}
                            />
                          </div>
                        </div>
                        <div className={[styles.btn_wrapper, styles.justify_left].join(' ')}>
                          <button className={[styles.btn, styles.disabled].join(' ')}>
                            <div className={styles.add_icon} />
                            <div className={[styles.txt, styles.ml_10].join(' ')}>Add</div>
                          </button>
                        </div>
                      </div>

                      <div className={['col-xl-12 col-lg-12 pull-left'].join(' ')}>
                        <Pills pillData={pillData1} isClose={true} isBold={false} />
                      </div>

                      <div className={[styles.btn_wrapper, styles.save_wrapper].join(' ')}>
                        <button className={[styles.btn_save, styles.disabled_save].join(' ')}>
                          <div className={[styles.txt].join(' ')}>Save</div>
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <>
                    <div className={styles.add_wrapper}>
                      <div className={styles.add_icon} />
                    </div>
                    <div className={styles.middle_content}>
                      <div className={styles.heading_area}>
                        <div className={styles.users_icon} />
                        <div className={styles.title}>Taiwan market preset</div>
                      </div>
                      <Pills pillData={pillData1} isClose={false} isBold={false} />
                    </div>
                    <div className={styles.action_wrapper}>
                      <div className={styles.delete_icon} />
                      <div className={styles.divider} />
                      <div className={styles.edit_icon} onClick={() => handleEditTag(2)} />
                    </div>
                  </>
                )}
              </div>
              <div className={styles.tag_list}>
                <div className={styles.add_wrapper}>
                  <div className={styles.add_icon} />
                </div>
                <div className={styles.middle_content}>
                  <div className={styles.heading_area}>
                    <div className={styles.global} />
                    <div className={styles.title}>India market preset</div>
                  </div>
                  <Pills pillData={pillData2} isClose={false} isBold={false} />
                </div>
              </div>

              <div className={styles.tag_list}>
                <div className={styles.add_wrapper}>
                  <div className={styles.add_icon} />
                </div>
                <div className={styles.middle_content}>
                  <div className={styles.heading_area}>
                    <div className={styles.global} />
                    <div className={styles.title}>Upline level only</div>
                  </div>
                  <Pills pillData={pillData2} isClose={false} isBold={false} />
                </div>
              </div>

              <div className={styles.tag_list}>
                <div className={styles.add_wrapper}>
                  <div className={styles.add_icon} />
                </div>
                <div className={styles.middle_content}>
                  <div className={styles.heading_area}>
                    <div className={styles.global} />
                    <div className={styles.title}>New ABOs</div>
                  </div>
                  <Pills pillData={pillData2} isClose={false} isBold={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

TagPresetModalComponent.propTypes = {};

export default TagPresetModalComponent;
