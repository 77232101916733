import React from 'react';
import styles from './CSTcard.module.scss';
import skillsIcon from '../../../../../styles/images/type_skill_cst.svg';
import tagsIcon from '../../../../../styles/images/type_tag_cst.svg';
import categoryIcon from '../../../../../styles/images/type_category_cst.svg';

export default function CSTcard({localeMap, clickHandler, key, drawerOpen, ...props}) {
  const {type, englishTranslation, learningPathsCount, coursesCount, allLanguage} = props;
  return (
    <div
      className={`${styles.card_container} ${drawerOpen ? styles.drawer_open : ''}`}
      onClick={clickHandler}
    >
      <div className={styles.heading}>
        <div className={styles.type_container}>
          <img src={type === 'Tag' ? tagsIcon : type === 'Skill' ? skillsIcon : categoryIcon} alt='type icon' />
          <div className={styles.type_name}>
            {type}
          </div>
        </div>
        <div className={styles.course_count}>
          <div>
            <div className={styles.courses_icon}></div>
            {coursesCount}
          </div>
          <div>
            <div className={styles.learning_path_icon}></div>
            {learningPathsCount}
          </div>
        </div>
      </div>
      <div className={styles.cstdetails}>
        {allLanguage &&
          allLanguage.map((item, index) => {
            return (
              <div className={styles.cstTitle} key={index}><span>
                {item?.displayTag + '*:'}
              </span>
                {localeMap?.[item?.locale]}
              </div>
            );
          })}

        {/* <div><span>
          EN*:
        </span>
          {englishTranslation}
        </div> */}

        {/* <div><span>
          KOR:
        </span>
          {localeMap?.['ko-kr']}
        </div> */}
      </div>
    </div>
  );
}
