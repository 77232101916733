import React from 'react';
import styles from '../ManageCourses.module.scss';

const CardViewUnCheckedBox = (props) => {
  const {
    courseId,
    handleCourseAddRemove,
    setAaa,
    setBbb,
    setClikedData,
    coursesUIDataCopy,
    clikedData,
    totalNumberRemovedCourses,
    setTotalNumberRemovedCourses,
    totalNumberAddedCourses,
    setTotalNumberAddedCourses,
    setClikedDataCopy,
    clikedDataCopy,
    coursesAPIResponse,
    course,
    setSelectedId,
    setRadioButtonData,
    mapCoursesToAudience,
  } = props;

  return (
    <div
      id={courseId}
      className={`${styles.uncheck_card_wrapper}`}
      onClick={(e) => {
        handleCourseAddRemove({
          e,
          setAaa,
          setBbb,
          setClikedData,
          coursesUIDataCopy,
          clikedData,
          totalNumberRemovedCourses,
          setTotalNumberRemovedCourses,
          totalNumberAddedCourses,
          setTotalNumberAddedCourses,
          setClikedDataCopy,
          clikedDataCopy,
          coursesAPIResponse,
        }),
          mapCoursesToAudience({
            course,
            setSelectedId,
            setRadioButtonData,
          });
      }}
    >
      <div className={`${styles.unchecked_box}`}></div>
    </div>
  );
};

export default CardViewUnCheckedBox;
