import React, {useEffect, useState, useRef} from 'react';
import FilterComponent from '../../shared/filter';
import SearchBarComponent from '../../shared/searchBar';
import styles from './TopBar.module.scss';
import GridListSwitch from '../../shared/gridListSwitch';
import RoundToggle from '../../shared/toggleSwitchRound';
import {dummyFunction} from '../../utils/commonUtil';
import {useHistory} from 'react-router-dom';

import {findIndex, find} from 'lodash';
import DropDown from '../../shared/dropDown';
// import DropDown from '../../shared/DropDown';
const TopBarComponenent = ({
  handleFilterApply,
  language = [],
  showGridListSwitch = false,
  showRoundToggler = false,
  fetchList,
  searchListFunction,
  filterPillAlign = '',
  list,
  setIsCardView = true,
  setIsListView = false,
  isLearningPath = false,
  isCardView,
  isListView,
  showAddedLP = false,
  showAddedCourses,
  setShowAddedCourses,
  clikedData = [],
  coursesUIDataCopy = [],
  placeholderName,
  handleToggleSwitch = dummyFunction,
  setPredictedSearch,
  predictedSearch = '',
  handleSearchInput,
  search = '',
  searchFontXS = '',
  filterData,
  isOpenSubCategories = true,
  setFilterDropdownopy = dummyFunction,
  searchPlaceholder = '',
  addNewButton = false,
  addNewButtonText = '',
  isDrawer = false,
  componentCST = false,
  marginLeft = '',
  setDrawer,
  searchBarFrom = '',
  newButtonHandle,
  isTilesBlue = false,
  subheader = '',
  isManageCourseScreen = false,
  countryList,
  setCountrySelected,
  toggleBtnLbl = '',
  remoteConfig = {},
}) => {
  // console.log('>>>> >>>> TopBarComponenent >>>> props', )
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [width, setWidth] = useState(0);
  const router = useHistory();

  const handleClickOutside = (e) => {
    const el = document.getElementById('divParentOfFilter');

    if (el !== null) {
      const outside = !el?.contains(e.target);
      if (outside) {
        if (
          !e.target.className.includes('Course_filter') &&
          !e.target.className.includes('Filter') &&
          !e.target.className.includes('RadioButtons') &&
          !e.target.className.includes('Checkbox') &&
          !e.target.className.includes('RadioButtons_container')
        ) {
          setFilterDropdown(false);
          //setFilterDropdownopy(!filterDropdown)(false);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
  }, []);

  const sortAndFilterFlyout = useRef(null);
  const closeSortAndFilterFlyout = (e) => {
    if (
      sortAndFilterFlyout.current &&
      !filterDropdown &&
      !sortAndFilterFlyout.current.contains(e.target) &&
      e.target.className &&
      e.target.className.indexOf('Content_') !== -1
    ) {
      setFilterDropdown(false);
      setFilterDropdownopy(false);
    }
  };
  const handleFilters = () => {
    setFilterDropdown(!filterDropdown);
    setFilterDropdownopy(!filterDropdown);
  };

  return (
    <React.Fragment>
      {/* Added isDrawerOpen class for click event to openclose drawer */}
      {/* Please add "isManageCourse" class conditionally below for screen manage course */}
      <div
        className={[
          isLearningPath ? styles.lp_top_area_with_drawer : '',
          componentCST ? styles.cst_top_area_with_drawer : '',
          isDrawer
            ? [styles.top_area_wrapper, styles.is_drawer_open].join(' ')
            : styles.top_area_wrapper,
        ].join(' ')}
      >
        <div id="divParentOfFilter" className={styles.sort_by_wrapper}>
          <div className={styles.txt}>Filter Table</div>
          <div className={[styles.icon_wrapper].join(' ')}>
            <div
              className={styles.filter}
              onClick={() => handleFilters()}
              ref={sortAndFilterFlyout}
            />
            {filterDropdown ? (
              <FilterComponent
                isPills={false}
                filterPillAlign={filterPillAlign}
                filterData={filterData}
                col6={true}
                handleFilterApply={(e) => {
                  handleFilterApply(e), handleFilters();
                }}
                handleFilterCancle={() => {
                  setFilterDropdown(false);
                  setFilterDropdownopy(false);
                }}
                isOpenSubCategories={isOpenSubCategories}
                isTilesBlue={isTilesBlue}
              />
            ) : null}
          </div>
        </div>
        <SearchBarComponent
          subheader={subheader}
          isDrawerOpen={isDrawer}
          searchFontXS={searchFontXS}
          isToggleSwitch={false}
          placeholderName={placeholderName || searchPlaceholder}
          handleSearchInput={(e) => {
            if (e != '') {
              searchListFunction(e), handleSearchInput(e);
            } else if (e == '') {
              searchListFunction(e);
            }
          }}
          predictedSearch={predictedSearch}
          searchBarFrom={searchBarFrom}
        />
        {isManageCourseScreen && remoteConfig?.user_group_country_targeting && (
          <DropDown list={countryList} setCountrySelected={setCountrySelected} />
        )}

        {showRoundToggler && (
          <div className={[styles.round_toggle_main_wrapper, ''].join(' ')}>
            <RoundToggle
              showAddedCourses={showAddedCourses}
              setShowAddedCourses={setShowAddedCourses}
              handleToggleSwitch={handleToggleSwitch}
              clikedData={clikedData}
              coursesUIDataCopy={coursesUIDataCopy}
              search={search}
            />

            {/* <div className={`${styles.text} ${styles.active} ${styles.disabled}`}> */}
            <div
              className={
                clikedData?.length > 0 && !showAddedCourses
                  ? `${styles.text} ${styles.active} `
                  : clikedData?.length > 0
                  ? `${styles.text}`
                  : `${styles.text} ${styles.disabled}`
              }
            >
              {toggleBtnLbl}
            </div>
          </div>
        )}

        <div className={styles.right_section_wrapper}>
          {showGridListSwitch && (
            <GridListSwitch
              setIsCardView={setIsCardView}
              setIsListView={setIsListView}
              isCardView={isCardView}
              isListView={isListView}
            />
          )}
          {/* buttons */}

          {addNewButton && (
            <div
              className={[
                styles.btn_wrapper,
                styles.btn_wrapper_xs,
                marginLeft == 'auto' ? styles.margin_left : '',
              ].join(' ')}
            >
              <div className={[styles.btn].join(' ')} onClick={newButtonHandle}>
                <div className={styles.add} />
                <div className={styles.txt}>{addNewButtonText}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

TopBarComponenent.propTypes = {};

export default TopBarComponenent;
