//Handling Collapsible state for filter
export const handleCollapsible = (value, setLearning, setCourses, isLearning, isCourses) => {
  if (value === 'learning_path') {
    setLearning(!isLearning);
    setCourses(false);
  } else {
    setCourses(!isCourses);
    setLearning(false);
  }
};

//Setting Filter State
export const handleFilter = (value, setFilterCourses, filterCourses) => {
  if (value === 'courses') {
    setFilterCourses(!filterCourses);
  }
};
