import React, {useState, useEffect} from 'react';
import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import styles from './NewImageCropper/NewImageCropper.module.scss';

const useEffectFirstIfCondition = (aspect_ratio, setCropConfig) => {
  if (aspect_ratio) {
    let _cropConfig = {};
    if (aspect_ratio === 1) {
      // 1:1
      _cropConfig = {
        unit: '%',
        height: 30,
        aspect: aspect_ratio,
        x: 44,
        y: 34,
      };
    } else {
      // 16:9
      _cropConfig = {
        unit: '%',
        width: 30,
        aspect: aspect_ratio,
        x: 35,
        y: 28,
      };
    }
    setCropConfig(_cropConfig);
  }
};

const useEffectSecondIfCondition = (sourceImage, aspect_ratio, setFlagDimensionsClasses) => {
  if (sourceImage && sourceImage.indexOf('https://images.contentstack.io/') !== -1) {
    //check
  } else {
    let _image = new Image();
    _image.src = sourceImage;
    _image.onload = function () {
      if (_image.height > 700 || _image.width > 1350) {
        setFlagDimensionsClasses(true);
      }
    };
  }
};

const dataURLtoFile = (dataurl, filename) => {
  const array = dataurl.split(',');
  const match = array[0].match(/:(.*?);/);
  if (!match || match.length < 1) {
    throw new Error('Invalid dataUrl');
  }
  const mime = match[1];
  const bstr = window.atob(array[1]);
  let n = bstr.length;
  const u8arry = new Uint8Array(n);
  while (n--) {
    u8arry[n] = bstr.codePointAt(n);
  }
  return new File([u8arry], filename, {type: mime});
};

function ImageCropper(props) {
  const {imageToCrop, onImageCropped, aspect_ratio, sourceImage} = props;
  const [cropConfig, setCropConfig] = useState();
  const [flagDimensionsClasses, setFlagDimensionsClasses] = useState(false);
  const [imageRef, setImageRef] = useState();

  useEffect(() => {
    useEffectFirstIfCondition(aspect_ratio, setCropConfig);
    useEffectSecondIfCondition(sourceImage, aspect_ratio, setFlagDimensionsClasses);
  }, []);

  useEffect(() => {
    if (flagDimensionsClasses) {
      useEffectFirstIfCondition(aspect_ratio, setCropConfig);
    }
  }, [flagDimensionsClasses]);

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        crop,
        'croppedImage.jpeg', // destination filename
      );
      //var file = new File([croppedImage], 'filename');
      var file = dataURLtoFile(croppedImage, 'croppedImage.jpeg');
      onImageCropped && onImageCropped(croppedImage, file);
    }
  }
  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        blob.name = fileName;
        // creating a Object URL representing the Blob object given
        //const croppedImageUrl = window.URL.createObjectURL(blob);
        const croppedImgBase64 = canvas?.toDataURL();
        resolve(
          //croppedImageUrl,
          croppedImgBase64,
        );
      }, 'image/jpeg');
    });
  }

  return (
    <div className={`${styles.App} ${styles.App_Image_Boundary_Dimensions}`}>
      <ReactCrop
        src={imageToCrop}
        crop={cropConfig}
        ruleOfThirds
        onImageLoaded={(imageRef) => setImageRef(imageRef)}
        onComplete={(cropConfig) => {
          cropImage(cropConfig);
        }}
        onChange={(cropConfig) => {
          setCropConfig(cropConfig);
        }}
        crossorigin="anonymous" // to avoid CORS-related problems
      />
    </div>
  );
}

export default ImageCropper;
