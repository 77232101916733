import React from 'react';
import styles from '../Content.module.scss';

const DeleteConfirmationModal = (props) => {
  const {
    isDeleteRecord,
    handleCloseDeleteModal,
    imageinput,
    handleDeleteInput,
    handleDeleteImage,
    input,
    handleDeleteRecord,
    notificationData,
    ImageDetail,
    ModalWrapper,
    Background,
    Notification,
  } = props;
  return (
    <>
      {isDeleteRecord ? (
        <div className={[styles.modal_wrapper, ''].join(' ')}>
          <Background backgroundFlag="small">
            <ModalWrapper modalFlag="small">
              {/* <div className={styles.delete_modal}> */}
              <div className={styles.close_wrapper} onClick={() => handleCloseDeleteModal()}>
                <div className={styles.close_icon} />
              </div>
              <div className={styles.header_wrapper}>
                <div className={styles.heading}>Confirm Delete</div>
              </div>
              <>
                <div className={styles.warning_section}>
                  <div className={styles.warning_icon} />
                  <div className={styles.message_section}>
                    <div className={[styles.heading, styles.mb_5].join(' ')}>
                      You are about to delete {imageinput}
                    </div>
                    <div className={styles.sub_heading}>This action cannot be undone.</div>
                  </div>
                </div>

                <div className={styles.confirm_wrapper}>
                  <div className={styles.txt}>To permanently delete this Image, type DELETE.</div>
                  <div className={styles.input_wrapper}>
                    <input
                      type={'text'}
                      placeholder="Type “DELETE” here."
                      // value={input}
                      onChange={(e) => handleDeleteInput(e.target.value)}
                      onBlur={(e) => handleDeleteImage(e.target.value)}
                      className={[
                        styles.input,
                        input.length > 1 && input.length !== 0 && input !== 'DELETE'
                          ? styles.error_input
                          : '',
                      ].join(' ')}
                    />
                    {input.length > 1 && input.length !== 0 && input !== 'DELETE' ? (
                      <>
                        <div className={styles.error_icon} />
                        <div className={styles.error_msg}>Error in character input</div>
                      </>
                    ) : null}
                  </div>
                </div>
              </>

              <div className={styles.btn_wrapper}>
                <button className={styles.btn} onClick={() => handleCloseDeleteModal()}>
                  Cancel
                </button>
                <button
                  className={[
                    styles.btn,
                    input.length > 0 && input === 'DELETE' ? styles.active : styles.disabled,
                  ].join(' ')}
                  onClick={() => handleDeleteRecord(ImageDetail?.imageId)}
                >
                  Delete
                </button>
              </div>
              {/* </div> */}
            </ModalWrapper>
          </Background>
        </div>
      ) : null}
      {/* Delete Modal */}
      {notificationData && notificationData.show && <Notification />}
    </>
  );
};

export default DeleteConfirmationModal;
