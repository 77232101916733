import React, {useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import CourseWizardComponent from '../components/courseManagement/CourseWizard/index';

const CreateCourseWizard = () => {
  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          {/* <SideNavigationComponent pageName="CONTENT-MANAGEMENT" {...props} /> */}
          <CourseWizardComponent />
        </main>
      </div>
    </div>
  );
};

CreateCourseWizard.propTypes = {};

export default CreateCourseWizard;
