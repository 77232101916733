import React from 'react';
import styles from '../ManageCourses.module.scss';

const CardViewCheckedBox = (props) => {
  const {
    clikedData,
    courseId,
    handleCourseAddRemove,
    setAaa,
    setBbb,
    setClikedData,
    coursesUIDataCopy,
    totalNumberRemovedCourses,
    setTotalNumberRemovedCourses,
    totalNumberAddedCourses,
    setTotalNumberAddedCourses,
    setClikedDataCopy,
    clikedDataCopy,
    coursesAPIResponse,
  } = props;
  return (
    <React.Fragment>
      {clikedData?.some((e) => e?.courseId === courseId) ? (
        <div
          id={courseId}
          className={`${styles.checked_card_wrapper}`}
          onClick={(e) => {
            handleCourseAddRemove({
              e,
              setAaa,
              setBbb,
              setClikedData,
              coursesUIDataCopy,
              clikedData,
              totalNumberRemovedCourses,
              setTotalNumberRemovedCourses,
              totalNumberAddedCourses,
              setTotalNumberAddedCourses,
              setClikedDataCopy,
              clikedDataCopy,
              coursesAPIResponse,
            });
          }}
        >
          <div className={`${styles.checked_box}`}></div>
          <div className={`${styles.text}`}>Added</div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default CardViewCheckedBox;
