import React, {useEffect} from 'react';
import styles from './notification.module.scss';
import {connect} from 'react-redux';
import {setNotificationData} from '../../../redux/actions/uiActions';

const baseClass = (type) => {
  return type === 'SUCCESS'
    ? 'success'
    : type === 'FAILURE'
    ? 'failure'
    : type === 'WARNING'
    ? 'warning'
    : type === 'UPLOADING'
    ? 'inprogress'
    : '';
};

const Notification = (props) => {
  const {notificationData, setNotificationData} = props;
  const {
    show = false,
    type = '',
    title = '',
    description = '',
    singleLine = false,
    twoLineDescription = false,
    lineTwoDescription = '',
  } = notificationData;
  const baseClassForCloseIcon =
    type === 'SUCCESS'
      ? 'green'
      : type === 'FAILURE'
      ? 'yellow'
      : type === 'WARNING'
      ? 'red'
      : type === 'UPLOADING'
      ? 'blue'
      : '';

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setNotificationData({
          show: false,
          title: '',
          description: '',
          type: '',
        });
      }, 10000);
    }
  }, [show]);

  return (
    <div className={`${styles.notification_wrapper} ${styles.notify_width}`}>
      <div className={`${styles.noti_bar} ${styles[baseClass(type) + '_bar']}`}>
        {!singleLine ? (
          <div className={`${styles.left_icon} ${styles[baseClass(type) + '_tick_icon']}`} />
        ) : null}
        <div className={`${styles.text_wrapper} ${styles[baseClass(type) + '_txt']}`}>
          <div className={styles.tagline}>{title}</div>
          {!singleLine ? <div className={styles.description}>{description}</div> : null}
          {!singleLine && twoLineDescription ? (
            <div className={styles.description}>{lineTwoDescription}</div>
          ) : null}
        </div>
        <div
          className={`${styles.right_icon} ${styles[baseClassForCloseIcon + '_close_icon']}`}
          onClick={() => {
            setNotificationData({
              show: false,
              title: '',
              description: '',
              type: '',
            });
          }}
        />
      </div>
    </div>
  );
};

Notification.propTypes = {};

const mapStateToProps = (state) => ({
  notificationData: state && state.uiState && state.uiState.notificationData,
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationData: (options) => dispatch(setNotificationData(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
