import ContentManagementComponent from '../components/contentManagement';
import React, {useEffect, useState} from 'react';
import { setBlockNavigation } from '../redux/actions/newCourseActions';
import { useDispatch } from 'react-redux';
import { setNewLPWizardState } from '../redux/actions/learningPathActions';

function contentManagement() {
  const [editImageExtends, seteditImageExtends] = useState('hide');
  const [croppedImg, setCroppedImg] = useState(undefined);
  const [croppedImg1, setCroppedImg1] = useState(undefined);

  const [imageLibraryOpen, setImageLibraryOpen] = useState(true);
  const [couserWizard, setCourseWizard] = useState(true);
  const [newCourse, setNewCourseWizard] = useState(false);
  const [thumbnailId, setThumbnailId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // This function will be called when the user attempts to leave the page, either by closing the tab/window, refreshing the page, or navigating to a different URL.
    const handleBeforeUnload = (event) => {
      // Dispatch an action to update the application state, specifically to unblock any navigation that might have been blocked earlier.
      dispatch(setBlockNavigation(false));
      dispatch(setNewLPWizardState({WizardOpen: false}));
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);

  return (
    <div className="page_content">
      {/* <SideNavigation pageName="CONTENT-MANAGEMENT" /> */}
      <ContentManagementComponent
        editImageExtends={editImageExtends}
        seteditImageExtends={seteditImageExtends}
        croppedImg={croppedImg}
        setCroppedImg={setCroppedImg}
        // mainImg={mainImg}
        //setMainImg={setMainImg}
        croppedImg1={croppedImg1}
        setCroppedImg1={setCroppedImg1}
        showImageLibrary={imageLibraryOpen}
        addCourseThubnailImage={(value) => {
          setThumbnailId(value);
        }}
        setOpenImageLib={() => {
          setImageLibraryOpen(true);
          setCourseWizard(false);
        }}
        setCloseImageLib={() => {
          setImageLibraryOpen(false);
          setCourseWizard(true);
          setNewCourseWizard(true);
        }}
      />
    </div>
  );
}
export default contentManagement;
