import React from 'react';
import PropTypes from 'prop-types';
import styles from './FieldTitle.module.scss';

const FieldTitle = ({title, infoText}) => {
  return (
    <div className={styles.label_wrapper}>
      <div>{title}</div>
      <div className={styles.tag_text}>{infoText}</div>
    </div>
  );
};

FieldTitle.defaultProps = {
  title: '',
  infoText: '',
};

FieldTitle.propTypes = {
  title: PropTypes.string.isRequired,
  infoText: PropTypes.string,
};

export default FieldTitle;
