import React from 'react';
import styles from './ModalWrapper.module.scss';

const ModalWrapper = ({children, modalFlag}) => {
  return (
    <div
      className={
        modalFlag === 'mini'
          ? styles['modal-container-mini']
          : modalFlag === 'small'
          ? styles['modal-container-small']
          : modalFlag === 'crop'
          ? styles['cropping-modal-container']
          : styles['modal-container']
      }
    >
      {children}
    </div>
  );
};

ModalWrapper.propTypes = {};

export default ModalWrapper;
