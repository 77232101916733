import React, {useEffect, useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import IncorrectUrlComponent from '../components/shared/pageNotFoundComponent/index';
import {  useHistory } from 'react-router-dom';

const PageNotFound = (props) => {
  const history = useHistory()
  const {isUserLoggedIn,customAuthHandler}=props
  useEffect(()=>{
    if(!isUserLoggedIn)  history.push('/login');
  },[isUserLoggedIn])
  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          {/* <SideNavigationComponent pageName="CONTENT-MANAGEMENT" {...props} /> */}
          <IncorrectUrlComponent />
        </main>
      </div>
    </div>
  );
};

PageNotFound.propTypes = {};

export default PageNotFound;
