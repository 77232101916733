import React from 'react';
import styles from '../Content.module.scss';

const DeleteImageModal = (props) => {
  const {handleDeleteModal, courseDetails} = props;
  return (
    <>
      {/* Bottom section With Button Start*/}
      <div
        className={[
          styles.btn_wrapper,
          courseDetails?.length > 0 ? styles.disabled : '',
          styles.btn_xs,
          styles.ml_auto,
        ].join(' ')}
        onClick={() => handleDeleteModal()}
      >
        <div className={[styles.btn].join(' ')}>
          <div>Delete Image</div>
          <div className={styles.delete_icon}></div>
        </div>
      </div>
      {/* Bottom section With Buttons for mob View End */}
      {courseDetails?.length > 0 ? (
        <div className={styles.suggestion_text}>
          Image may not be deleted while in use by courses. Replace Image On All Courses to
          continue.
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default DeleteImageModal;
