import React, {useEffect, useState} from 'react';

const CountrySelectionHandleEvents = () => {
  const handleSelectAllCountry = (countryList) => {
    const newCountryList = countryList.map((ele) => ({
      ...ele,
      checked: true,
    }));
    return newCountryList;
  };

  const handleClearAllCountry = (countryList) => {
    const newCountryList = countryList.map((ele) => ({
      ...ele,
      checked: false,
    }));
    return newCountryList;
  };

  const handleCountrySelect = (country, countryList) => {
    const newCountryList = countryList.map((ele) => {
      if (country.countryCode === ele.countryCode) {
        return {
          ...ele,
          checked: !ele.checked,
        };
      }
      return {...ele};
    });
    return newCountryList;
  };

  return {handleSelectAllCountry, handleClearAllCountry, handleCountrySelect};
};

export default CountrySelectionHandleEvents;
