import React, {useEffect, useState, useRef} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import styles from './StatusModal.module.scss';
import {connect} from 'react-redux';
import {findIndex, find} from 'lodash';
import {setSelectedNavigationOption, setIsUserLoggedIn} from '../../../../redux/actions/uiActions';
import {setErrorStateTemplate} from '../../../../redux/actions/uiActions';

const StatusModalComponent = () => {
  return (
    <React.Fragment>
      {/* Create New Category Modal */}
      <div className={[styles.modal_wrapper_main, ''].join(' ')}>
        <div className={styles.modal_wrapper}></div>
        <div className={styles.delete_modal}>
          <div className={styles.mob_bar}>
            <div className={styles.line_bar}></div>
          </div>

          <div className={styles.status_main_wrapper}>
            <div className={styles.header_wrapper_xs}>
              <div className={styles.heading}>Course Status</div>
            </div>

            {/* Status */}
            <div className={[styles.status_wrapper].join(' ')}>
              <div className={styles.status_list}>
                <div className={[styles.status_icon, styles.live].join(' ')}></div>
                <div className={styles.txt}>Live</div>
              </div>

              <div className={styles.status_list}>
                <div className={[styles.status_icon, styles.draft].join(' ')}></div>
                <div className={styles.txt}>Draft</div>
              </div>

              <div className={styles.status_list}>
                <div className={[styles.status_icon, styles.offline].join(' ')}></div>
                <div className={styles.txt}>Offline</div>
              </div>
            </div>
            {/* Status */}
          </div>
        </div>
      </div>
      {/* Create New Category Modal */}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isUserLoggedIn: state && state.uiState && state.uiState.isUserLoggedIn,
  notificationData: state && state.uiState && state.uiState.notificationData,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(StatusModalComponent));
