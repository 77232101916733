import React from 'react';
import LoadingShimmerCustom from '../../../shared/LoadingShimmerCustom';
import CardViewCheckedBox from '../CardViewCheckedBox';
import CardViewUnCheckedBox from '../CardViewUnCheckedBox';
import styles from '../ManageCourses.module.scss';
const CardViewManageCourses = (props) => {
  const {
    selectedCourse,
    coursesUIData,
    filterDropdowncopy,
    aaa,
    clikedData,
    closeOpenPopUpButton,
    isCardView,
    setAaa,
    isPopUpClicked,
    setPopUpClicked,
    setBbb,
    isListView,
    setSelectedId,
    setRadioButtonData,
    mapCoursesToAudience,
    apiCourseType,
    apiCourseTypeCopy,
    setCourseTypeChanged,
    radioButtonData,
    NewRadioButtonsComponent,
    selectedId,
    handleRadioButtomOnClick,
    setClikedData,
    coursesUIDataCopy,
    totalNumberRemovedCourses,
    setTotalNumberRemovedCourses,
    totalNumberAddedCourses,
    setTotalNumberAddedCourses,
    setClikedDataCopy,
    clikedDataCopy,
    coursesAPIResponse,
    handleCourseAddRemove,
    EmptyScreenComponent,
    isSearchFilterEmptyState,
    dateIsoToNormalDate,
    CourseTypePopUp,
    isLoading,
  } = props;
  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(
        <div className={[styles.card_holder, ''].join(' ')}>
          <LoadingShimmerCustom className={[styles.card].join(' ')} />
        </div>,
      );
    }
    return content;
  };
  return (
    <React.Fragment>
      <div
        className={[
          selectedCourse && selectedCourse?.courseId ? styles.card_wrapper : styles.card_wrapper,
          'bootstrap-wrapper',
        ].join(' ')}
      >
        {!isLoading && (
          <div className={styles.row_wrapper}>
            {coursesUIData?.length > 0 ? (
              coursesUIData.map((course, index) => {
                const {
                  courseId,
                  courseState,
                  description,
                  timeToShow,
                  dateToShowForCardView,
                  thumbnail,
                  title,
                  className,
                } = course;
                return (
                  <div
                    key={index}
                    id={courseId}
                    className={[styles.card_holder, ''].join(' ')}
                    style={!filterDropdowncopy && courseId === aaa ? {zIndex: '2'} : null}
                  >
                    {/* Selected class should be coditionally added for darker shadow to the card */}
                    <div id={courseId} className={[styles.card, styles.selected].join(' ')}>
                      {/* Course type popup btn */}
                      {clikedData?.some((e) => e?.courseId === courseId) && (
                        <div className={styles.popup_btn_wrapper}>
                          <div
                            id={courseId}
                            className={[
                              styles.course_type_icon,
                              course?.courseType == 'Quick Start'
                                ? [styles.qstart_icon].join(' ')
                                : course?.courseType == 'Required'
                                ? [styles.req_icon].join(' ')
                                : course.courseType == 'General visibility'
                                ? [styles.gen_visiblity_icon].join(' ')
                                : course.courseType == 'Recommended'
                                ? [styles.recom_icon].join(' ')
                                : [styles.gen_visiblity_icon].join(' '),
                            ].join(' ')}
                            onClick={(e) => {
                              closeOpenPopUpButton({
                                e,
                                isCardView,
                                setAaa,
                                isPopUpClicked,
                                setPopUpClicked,
                                setBbb,
                                isListView,
                              });
                              mapCoursesToAudience({
                                course,
                                setSelectedId,
                                setRadioButtonData,
                              });
                            }}
                          ></div>
                          {isPopUpClicked && courseId === aaa ? (
                            <div className={styles.course_type_popup} id={'divPopUp'}>
                              <div className={styles.title}>Course Type</div>
                              <div className={styles.course_types}>
                                {/* Add selected state for Every course type */}
                                {radioButtonData?.map((item, index) => {
                                  return (
                                    <CourseTypePopUp
                                      NewRadioButtonsComponent={NewRadioButtonsComponent}
                                      selectedId={selectedId}
                                      index={index}
                                      handleRadioButtomOnClick={handleRadioButtomOnClick}
                                      item={item}
                                      course={course}
                                      apiCourseTypeCopy={apiCourseTypeCopy}
                                      apiCourseType={apiCourseType}
                                      setSelectedId={setSelectedId}
                                      setCourseTypeChanged={setCourseTypeChanged}
                                      key={index}
                                      setPopUpClicked={setPopUpClicked}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                      {/* for unchecked card  */}
                      <CardViewUnCheckedBox
                        courseId={courseId}
                        handleCourseAddRemove={handleCourseAddRemove}
                        setAaa={setAaa}
                        setBbb={setBbb}
                        setClikedData={setClikedData}
                        coursesUIDataCopy={coursesUIDataCopy}
                        clikedData={clikedData}
                        totalNumberRemovedCourses={totalNumberRemovedCourses}
                        setTotalNumberRemovedCourses={setTotalNumberRemovedCourses}
                        totalNumberAddedCourses={totalNumberAddedCourses}
                        setTotalNumberAddedCourses={setTotalNumberAddedCourses}
                        setClikedDataCopy={setClikedDataCopy}
                        clikedDataCopy={clikedDataCopy}
                        coursesAPIResponse={coursesAPIResponse}
                        course={course}
                        setSelectedId={setSelectedId}
                        setRadioButtonData={setRadioButtonData}
                        mapCoursesToAudience={mapCoursesToAudience}
                      />
                      {/* or add above an below code conditionally post selection */}
                      {/* for checked card */}
                      <CardViewCheckedBox
                        clikedData={clikedData}
                        courseId={courseId}
                        handleCourseAddRemove={handleCourseAddRemove}
                        setAaa={setAaa}
                        setBbb={setBbb}
                        setClikedData={setClikedData}
                        coursesUIDataCopy={coursesUIDataCopy}
                        totalNumberRemovedCourses={totalNumberRemovedCourses}
                        setTotalNumberRemovedCourses={setTotalNumberRemovedCourses}
                        totalNumberAddedCourses={totalNumberAddedCourses}
                        setTotalNumberAddedCourses={setTotalNumberAddedCourses}
                        setClikedDataCopy={setClikedDataCopy}
                        clikedDataCopy={clikedDataCopy}
                        coursesAPIResponse={coursesAPIResponse}
                      />
                      <div className={styles.card_top}>
                        <div className={styles.checkbox_container}>
                          <div className={styles.txt}></div>
                        </div>
                        <div className={[styles.img_wrapper, styles.empty_state].join(' ')}>
                          {thumbnail ? (
                            <img className={styles.img} src={thumbnail} />
                          ) : (
                            <div className={[styles.img, styles.empty_state_img].join(' ')}></div>
                          )}
                        </div>
                      </div>
                      <div className={styles.card_bottom}>
                        <div className={[styles.status_wrapper, styles.archive_cd].join(' ')}>
                          <div
                            className={`${styles.status} ${
                              styles[course?.courseState?.toLowerCase()]
                            }`}
                          >
                            <div className={styles.status_txt}>{courseState || 'NA'}</div>
                          </div>
                          {courseState !== 'Draft' && (
                            <div className={styles.text_wrapper}>
                              {/* Please add other different calendar icon for different status */}
                              <div
                                className={
                                  courseState === 'Live'
                                    ? styles.calendar_icon
                                    : courseState === 'Offline'
                                    ? styles.offline_cal_icon
                                    : styles.archive_cal_icon
                                }
                              ></div>
                              <div className={styles.date_text}>
                                {course?.launchDate
                                  ? dateIsoToNormalDate(course?.launchDate)
                                  : 'NA'}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={styles.description}>{title || 'NA'}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : isSearchFilterEmptyState ? (
              <EmptyScreenComponent />
            ) : null}
          </div>
        )}
        {isLoading && <div className={styles.row_wrapper}> {getShimmerList(20)}</div>}
      </div>
    </React.Fragment>
  );
};

export default CardViewManageCourses;
