import React from 'react';
import styles from './RadioButtons.module.scss';

const RadioButtonsComponent = (props) => {
  const {id, checked, label, onChangeHandler, item} = props;
  return (
    <>
      <label className={styles.container}>
        <input
          type="radio"
          // checked={selectedFilterItem && selectedFilterItem.id && item && item.id && selectedFilterItem.id === item.id ? true : checked}
          checked={checked}
          key={id}
          onChange={() => {
            onChangeHandler(item);
          }}
          value={label}
        />
        <span className={styles.checkmark}></span>
        {label}
      </label>
    </>
  );
};

RadioButtonsComponent.propTypes = {};

export default RadioButtonsComponent;
