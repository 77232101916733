import React from 'react';
import styles from '../Portal.module.scss';

const MobileLearningHeader = (props) => {
  const {
    mobileTabSelected,
    searchLearningPath,
    predictedSearchLP,
    Hint,
    setSearchLearningPath,
    learningPathAPIResponse,
    setPredictedSearchLP,
    handleSearchInputLP,
    searchUserGroup,
    setSearch,
    setCourses,
    courses,
    setLearning,
    setAudience,
    learning,
    audience,
    filterDataLP,
    setFilterDataLP,
    setFilterDataTempLP,
    makeRequest,
    setLearningPathAPIResponse,
    setLearningPathAPIResponseCopy,
    setSearchFilterEmptyStateLP,
    setNotificationData,
    setFilterDropdownLP,
    handleSearch,
    filterDropdownLP,
    handleFilterApplyNewLP,
    FilterComponent,
    headingName,
    sortData,
    setIsLoading,
  } = props;

  return (
    <React.Fragment>
      {mobileTabSelected === 'LEARNING PATHS' ? (
        <div className={['col-sm-12 col-md-12 col-xl-5 col-lg-6 pull-left'].join(' ')}>
          <div className={[styles.card_wrapper, styles.learning_section].join(' ')}>
            <div className={styles.header_wrapper}>
              <div className={styles.action_wrapper}>
                <div
                  className={[
                    styles.search_wrapper,
                    searchLearningPath.length > 0 ? styles.outline : null,
                  ].join(' ')}
                >
                  <Hint options={predictedSearchLP} allowTabFill={true}>
                    <input
                      type="text"
                      placeholder="Search Learning Path"
                      value={searchLearningPath}
                      onChange={(event) => {
                        handleSearchInputLP({
                          event,
                          setSearchLearningPath,
                          learningPathAPIResponse,
                          setPredictedSearchLP,
                        });
                      }}
                      onKeyPress={(event) =>
                        handleSearchInputLP({
                          event,
                          setSearchLearningPath,
                          learningPathAPIResponse,
                          setPredictedSearchLP,
                        })
                      }
                      className={styles.search_input}
                      onKeyDown={(e) => {
                        if (
                          e.keyCode == 13 &&
                          searchUserGroup?.length > 0 &&
                          searchUserGroup?.length < 3
                        ) {
                          setNotificationData({
                            show: false,
                            type: 'WARNING',
                            title: 'Insufficient Search Input',
                            description:
                              'To search for any User group, enter 3 or more characters.',
                          });
                        }
                      }}
                    />
                  </Hint>
                  <div className={styles.icon_wrapper}>
                    <div
                      className={[
                        styles.search,
                        searchLearningPath.length > 0 ? styles.cross_icon : null,
                      ].join(' ')}
                      onClick={() => {
                        handleSearch({
                          value: 'learning',
                          setSearch,
                          setCourses,
                          courses,
                          setLearning,
                          setAudience,
                          learning,
                          audience,
                        });
                        setSearchLearningPath('');
                        setPredictedSearchLP('');
                      }}
                    />
                  </div>
                </div>
                <div className={styles.filter_main_wrapper}>
                  <div className={[styles.icon_wrapper].join(' ')}>
                    <div
                      className={styles.filter}
                      onClick={() => setFilterDropdownLP(!filterDropdownLP)}
                    />
                  </div>
                  {filterDropdownLP ? (
                    <FilterComponent
                      isPills={true}
                      headingName={headingName}
                      isLandingPage={true}
                      filterData={filterDataLP}
                      sortData={sortData}
                      handleFilterApply={(params) => {
                        handleFilterApplyNewLP({
                          params,
                          filterDataLP,
                          setFilterDataLP,
                          setFilterDataTempLP,
                          makeRequest,
                          setLearningPathAPIResponse,
                          setLearningPathAPIResponseCopy,
                          setSearchFilterEmptyStateLP,
                          setIsLoading,
                        });
                      }}
                      handleFilterCancle={() => setFilterDropdownLP(false)}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            {/* Empty error container */}

            <div className={[styles.learning_image_container, 'hide'].join(' ')}>
              <div className={styles.learning_middle_section}>
                <div className={styles.learning_image_area}>
                  <div
                    className={[styles.empty_screen_icon, styles.learning_path_icon].join(' ')}
                  ></div>
                  <div className={styles.empty_screen_path_detail}>
                    <div className={styles.error_label}>Learning Path Management</div>
                    <div className={styles.error_content}>
                      The ability to group courses into Learning Paths coming later in 2022.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

MobileLearningHeader.propTypes = {};

export default MobileLearningHeader;
