import React, {useState, useEffect} from 'react';
import styles from './Filter.module.scss';
import RadioButtonsComponent from '../../shared/radioButtons';
import CheckboxNew from '../checkboxNew';
import skillsIcon from '../../../styles/images/type_skill_cst.svg';
import tagsIcon from '../../../styles/images/type_tag_cst.svg';
import categoryIcon from '../../../styles/images/type_category_cst.svg';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

const callingRadioButtonsComponent = (
  item,
  selectedFilterItems,
  setIsDisabled,
  setSelectedFilterItems,
) => {
  return (
    <RadioButtonsComponent
      key={item.id}
      id={item.id}
      item={item}
      // checked={selectedFilter && item && item.id === selectedFilter.id}
      checked={
        selectedFilterItems?.find((ele) => ele?.typeOfFilter === 'RADIO') !== undefined &&
        selectedFilterItems?.find((ele) => ele?.typeOfFilter === 'RADIO')?.id === item?.id
      }
      // checked={item.checked}
      label={item.name}
      onChangeHandler={(filterItem) => {
        setIsDisabled(false);
        // handleChange();
        if (selectedFilterItems && selectedFilterItems.length > 0) {
          if (selectedFilterItems?.find((ele) => ele?.typeOfFilter === 'RADIO') !== undefined) {
            const index = selectedFilterItems.indexOf(
              selectedFilterItems?.find((ele) => ele?.typeOfFilter === 'RADIO'),
            );
            if (index > -1) {
              selectedFilterItems.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
          setSelectedFilterItems([...selectedFilterItems, filterItem]);
        } else {
          setSelectedFilterItems([filterItem]);
        }
      }}
    />
  );
};

const callingCheckBoxNewComponent = (
  item,
  setClearAllCheck,
  setIsDisabled,
  selectedFilterItems,
  setSelectedFilterItems,
  showClearAllButton,
  clearAllCheck,
  selectedFilterItemsTemp,
) => {
  return (
    <CheckboxNew
      label={item?.label}
      handleCheckClick={() => {
        setClearAllCheck(false);
        setIsDisabled(false);
        item.checked = !item.checked;
        if (selectedFilterItems && selectedFilterItems.length > 0) {
          const canInsert = selectedFilterItems.find((ele) => ele.id === item.id) === undefined;
          if (canInsert) {
            setSelectedFilterItems([...selectedFilterItems, item]);
          } else {
            const index = selectedFilterItems.indexOf(
              selectedFilterItems?.find((ele) => ele?.id === item?.id),
            );

            if (index > -1) {
              selectedFilterItemsTemp(selectedFilterItems);
              selectedFilterItems.splice(index, 1); // 2nd parameter means remove one item only
              setSelectedFilterItems(selectedFilterItems);
            }
          }
        } else {
          setSelectedFilterItems([item]);
        }
        showClearAllButton();
      }}
      isChecked={clearAllCheck ? false : item?.checked}
    />
  );
};
const filterRadioButtonArray = (ele, tempArr, tempRadio) => {
  if (ele?.checked) {
    tempArr.push(ele);
    if (ele?.checked) {
      tempRadio.push(ele);
    }
  }
};

const filterCheckBoxArray = (ele, tempArr, tempCheck) => {
  if (ele?.checked) {
    tempArr.push(ele);
    if (ele?.checked) {
      tempCheck.push(ele?.title);
    }
  }
};
const firstEmptyUseEffectFunction = (
  showClearAllButton,
  tiles,
  filtersList,
  setTilesCheckArray,
  setRadioCheckArray,
  setCheckBoxArray,
  setSelectedFilterItems,
  mobileDrawerOutsideClick,
  setSelectedFilterItemsTemp,
) => {
  showClearAllButton();
  let tempArr = [];
  let tempTiles = [];
  let tempRadio = [];
  let tempCheck = [];
  if (tiles && tiles.length > 0) {
    for (const ele of tiles) {
      tempArr.push(ele);
      if (ele?.checked) {
        tempTiles.push(ele?.id);
      }
    }
  }

  if (filtersList[0]) {
    for (const ele of filtersList[0].filterTypeData.sectionData) {
      filterRadioButtonArray(ele, tempArr, tempRadio);
    }
  }
  if (filtersList[1]) {
    for (const ele of filtersList[1].filterTypeData.sectionData) {
      filterCheckBoxArray(ele, tempArr, tempCheck);
    }
  }
  setTilesCheckArray(tempTiles);
  setRadioCheckArray(tempRadio);
  setCheckBoxArray(tempCheck);
  setSelectedFilterItems(tempArr);
  setSelectedFilterItemsTemp(new Array(...tempArr));
  document.addEventListener('mousedown', mobileDrawerOutsideClick, true);
  return () => {
    document.removeEventListener('click', mobileDrawerOutsideClick, true);

    document.removeEventListener('mousedown', mobileDrawerOutsideClick, true);
  };
};

const radioCheckArrayUseEffectFunction = (radioCheckArray, mobileDrawerOutsideClick) => {
  if (radioCheckArray[0]) {
    document.addEventListener('mousedown', mobileDrawerOutsideClick, true);
  }
};

const getCollapsibleState = (buttonType, checkboxCollapsible, radioCollapsible) => {
  if (
    (buttonType === 'checkbox' && checkboxCollapsible) ||
    (buttonType === 'radio' && radioCollapsible)
  ) {
    return true;
  }
  return false;
};

const clearAllFunction = (
  filtersList,
  selectedFilterItems,
  setSelectedFilterItems,
  setClearAllCheck,
  setIsDisabled,
  setShowClearAll,
) => {
  filtersList[1]?.filterTypeData?.sectionData?.map((ele) => {
    if (ele?.typeOfFilter === 'CHECKBOX') {
      ele.checked = false;
    }
  });
  let tempFilter = selectedFilterItems.filter((ele) => ele?.typeOfFilter !== 'CHECKBOX');

  setSelectedFilterItems(tempFilter);
  setClearAllCheck(true);
  setIsDisabled(false);
  setShowClearAll(false);
};

const cancelClick = (
  selectedFilterItems,
  tilesCheckArray,
  checkBoxArray,
  radioCheckArray,
  setSelectedFilterItems,
  handleFilterApply,
  handleFilterCancle,
  selectedFilterItemsTemp,
  filterData,
) => {
  const tempArr = selectedFilterItems;

  for (const ele of tempArr) {
    if (ele?.typeOfFilter === 'tiles' && tilesCheckArray.includes(ele?.id)) {
      ele.checked = true;
    } else if (ele?.typeOfFilter === 'tiles' && !tilesCheckArray.includes(ele?.id)) {
      ele.checked = false;
    } else if (ele?.typeOfFilter === 'CHECKBOX' && checkBoxArray.includes(ele?.title)) {
      ele.checked = true;
    } else if (ele?.typeOfFilter === 'CHECKBOX' && !checkBoxArray.includes(ele?.title)) {
      ele.checked = false;
    } else if (ele?.typeOfFilter === 'RADIO') {
      const index = selectedFilterItems.indexOf(
        selectedFilterItems?.find((item) => item?.typeOfFilter === 'RADIO'),
      );
      if (index > -1) {
        tempArr.splice(index, 1, radioCheckArray[0]); // 2nd parameter means remove one item only
      }
    }
  }
  setSelectedFilterItems(tempArr);
  //handleFilterApply(tempArr);
  handleFilterCancle(filterData);
};

const getFiltersList = (
  buttonType,
  setRadioCollapsible,
  radioCollapsible,
  setCheckboxCollapsible,
  checkboxCollapsible,
  filtersList,
  showClearAll,
  clearAllFunction,
  selectedFilterItems,
  setSelectedFilterItems,
  setClearAllCheck,
  setIsDisabled,
  setShowClearAll,
  getCollapsibleState,
  clearAllCheck,
  showClearAllButton,
  isOpenSubCategories,
  selectedFilterItemsTemp,
) => {
  return (
    <div className={styles.collapsible_wrapper}>
      <div className={styles.collapse_button}>
        {/* onClick={() => handleCollapse()} */}

        {/* Please add here  "collapse_wrapper" class to collapse/non_collapse icon  to increase coverage area of "-" sign*/}
        {!isOpenSubCategories ? (
          <div
            className={styles.collapse_wrapper}
            onClick={() => {
              if (!isOpenSubCategories && buttonType === 'radio') {
                setRadioCollapsible(!radioCollapsible);
              } else if (!isOpenSubCategories && buttonType === 'checkbox') {
                setCheckboxCollapsible(!checkboxCollapsible);
              }
            }}
          >
            {buttonType === 'radio' && !isOpenSubCategories && (
              <div className={radioCollapsible ? styles.non_collapse : styles.collapse_icon} />
            )}
            {buttonType === 'checkbox' && !isOpenSubCategories && (
              <div className={checkboxCollapsible ? styles.non_collapse : styles.collapse_icon} />
            )}
          </div>
        ) : null}
        <div className={[styles.txt, isOpenSubCategories ? styles.padd_left : ''].join(' ')}>
          {filtersList[buttonType === 'radio' ? 0 : 1]?.filterTypeData?.sectionTitle}
        </div>
        {/* Added clear all button, it should be only for categories section */}

        {/* Add "disable" class for this (css added)*/}
        {buttonType === 'checkbox' && showClearAll && !isOpenSubCategories && (
          <div
            className={[styles.clear_btn].join(' ')}
            onClick={() =>
              clearAllFunction(
                filtersList,
                selectedFilterItems,
                setSelectedFilterItems,
                setClearAllCheck,
                setIsDisabled,
                setShowClearAll,
                setIsDisabled,
              )
            }
          >
            Clear All
          </div>
        )}
      </div>

      <div
        className={[
          styles.filter_list,
          getCollapsibleState(buttonType, checkboxCollapsible, radioCollapsible) ? 'hide' : '',
        ].join(' ')}
      >
        <div className={styles.filter_data}>
          {filtersList[buttonType === 'radio' ? 0 : 1]?.filterTypeData?.sectionData?.map(
            (item, index) => {
              return (
                <div className={styles.list} key={index}>
                  {buttonType === 'radio' &&
                    callingRadioButtonsComponent(
                      item,
                      selectedFilterItems,
                      setIsDisabled,
                      setSelectedFilterItems,
                    )}
                  {buttonType === 'checkbox' &&
                    callingCheckBoxNewComponent(
                      item,
                      setClearAllCheck,
                      setIsDisabled,
                      selectedFilterItems,
                      setSelectedFilterItems,
                      showClearAllButton,
                      clearAllCheck,
                      selectedFilterItemsTemp,
                    )}
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

const FilterComponent = (props) => {
  const history = useHistory();

  const {
    handleFilterCancle,
    filterData,
    handleFilterApply,
    filterPillAlign,
    col6,
    isOpenSubCategories = false,
    isTilesBlue = false,
    lpFilter = false,
  } = props;
  const {tiles, filtersList = []} = filterData;
  // const [isCollpasible, setCollapsible] = useState(true);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [selectedFilterItemsTemp, setSelectedFilterItemsTemp] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [radioCollapsible, setRadioCollapsible] = useState(false);
  const [checkboxCollapsible, setCheckboxCollapsible] = useState(true);

  const [tilesCheckArray, setTilesCheckArray] = useState([]);
  const [radioCheckArray, setRadioCheckArray] = useState([]);
  const [checkBoxArray, setCheckBoxArray] = useState([]);

  const [clearAllCheck, setClearAllCheck] = useState(false);
  const [showClearAll, setShowClearAll] = useState(false);

  useEffect(() => {
    firstEmptyUseEffectFunction(
      showClearAllButton,
      tiles,
      filtersList,
      setTilesCheckArray,
      setRadioCheckArray,
      setCheckBoxArray,
      setSelectedFilterItems,
      mobileDrawerOutsideClick,
      setSelectedFilterItemsTemp,
    );
  }, []);

  useEffect(() => {
    radioCheckArrayUseEffectFunction(radioCheckArray, mobileDrawerOutsideClick);
  }, [radioCheckArray]);

  const mobileDrawerOutsideClick = (event) => {
    if (
      event.target.className.includes('Filter_custom_overlay') ||
      event.target.className.includes('Filter_mob_bar') ||
      event.target.className.includes('Filter_line_bar')
    ) {
      cancelClick(
        selectedFilterItems,
        tilesCheckArray,
        checkBoxArray,
        radioCheckArray,
        setSelectedFilterItems,
        handleFilterApply,
        handleFilterCancle,
      );
    }
  };

  const filterCourseStatusTiles = (tileItem) => {
    if (selectedFilterItems && selectedFilterItems.length > 0) {
      tileItem.checked = !tileItem.checked;
      const tempArray = [...selectedFilterItems];

      const index = tempArray.indexOf(selectedFilterItems?.find((ele) => ele?.id === tileItem?.id));

      if (index > -1) {
        tempArray.splice(index, 1, tileItem); // 2nd parameter means remove one item only
      }
      setSelectedFilterItems([...tempArray]);
    }
  };

  // const handleCollapse = () => {
  //   setCollapsible(!isCollpasible);
  // };
  // const handleChange = selectedItem => {
  //   filterData?.filtersList.map(item => {
  //     if (item?.filterType == "RADIO") {
  //       item?.filterTypeData?.sectionData.map(radioItem => {
  //         if (radioItem.id === selectedItem.id) {
  //           radioItem.checked = !radioItem.checked;
  //           setSelectedFilterItems(selectedItem);
  //           return radioItem;
  //         } else {
  //           return (radioItem.checked = false);
  //         }
  //       });
  //     }
  //   });
  // };

  const showClearAllButton = () => {
    setShowClearAll(filtersList[1]?.filterTypeData?.sectionData?.find((ele) => ele?.checked));
  };

  const applyFilter = () => {
    handleFilterApply(selectedFilterItems);
    setSelectedFilterItemsTemp(selectedFilterItems);
    handleFilterCancle(filterData);
  };

  return (
    <React.Fragment>
      <div className={[styles.filter_container, lpFilter ? styles.align_right : ''].join(' ')}>
        {' '}
        {/* Use filter_ugm class name */}
        <div className={styles.custom_overlay}></div>
        <div className={styles.filter_main_wrapper}>
          <div className={styles.filter_wrapper}>
            <div className={styles.mob_bar}>
              <div className={styles.line_bar}></div>
            </div>
            <div className={styles.filter_content}>
              <div
                className={[
                  styles.pills_wrapper,
                  filterPillAlign === 'center' ? styles.center_align : '',
                ].join(' ')}
              >
                {tiles &&
                  tiles.length &&
                  tiles.length > 0 &&
                  tiles.map((tileItem, index) => {
                    return (
                      <div
                        className={[
                          styles.pill,
                          isTilesBlue ? styles.normal_button : styles[tileItem.className],
                          tileItem?.checked
                            ? [styles.selected, isTilesBlue ? styles.blue : ''].join(' ')
                            : '',
                        ].join(' ')}
                        style={{width: col6 ? '45%' : 'auto'}}
                        onClick={() => {
                          setIsDisabled(false);
                          filterCourseStatusTiles(tileItem);
                        }}
                        key={index}
                      >
                        {history?.location?.pathname === '/CategorySkillsTagsmanagement' && (
                          <>
                            <img
                              src={
                                tileItem?.title === 'Tags'
                                  ? tagsIcon
                                  : tileItem.title === 'Skills'
                                  ? skillsIcon
                                  : categoryIcon
                              }
                              alt="type icon"
                              className={styles.type_icons}
                            />
                          </>
                        )}
                        <div className={styles.txt}>{tileItem?.title}</div>
                      </div>
                    );
                  })}
              </div>
              {getFiltersList(
                'radio',
                setRadioCollapsible,
                radioCollapsible,
                setCheckboxCollapsible,
                checkboxCollapsible,
                filtersList,
                showClearAll,
                clearAllFunction,
                selectedFilterItems,
                setSelectedFilterItems,
                setClearAllCheck,
                setIsDisabled,
                setShowClearAll,
                getCollapsibleState,
                clearAllCheck,
                showClearAllButton,
                isOpenSubCategories,
                selectedFilterItemsTemp,
              )}
              {getFiltersList(
                'checkbox',
                setRadioCollapsible,
                radioCollapsible,
                setCheckboxCollapsible,
                checkboxCollapsible,
                filtersList,
                showClearAll,
                clearAllFunction,
                selectedFilterItems,
                setSelectedFilterItems,
                setClearAllCheck,
                setIsDisabled,
                setShowClearAll,
                getCollapsibleState,
                clearAllCheck,
                showClearAllButton,
                isOpenSubCategories,
                selectedFilterItemsTemp,
              )}
            </div>
          </div>
          <div className={styles.filter_button_wrapper}>
            <button
              type="button"
              className={[styles.btn, styles.m_lr_6].join(' ')}
              onClick={() =>
                cancelClick(
                  selectedFilterItems,
                  tilesCheckArray,
                  checkBoxArray,
                  radioCheckArray,
                  setSelectedFilterItems,
                  handleFilterApply,
                  handleFilterCancle,
                  selectedFilterItemsTemp,
                  filterData,
                )
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className={[
                isDisabled ? styles.btn_disable : styles.btn,
                styles.dark,
                styles.m_lr_6,
              ].join(' ')}
              onClick={(e) => {
                if (!isDisabled) {
                  e && e.preventDefault();
                  applyFilter();
                  //cancelClick(selectedFilterItems,tilesCheckArray,checkBoxArray,radioCheckArray,setSelectedFilterItems,handleFilterApply,handleFilterCancle)
                }
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

FilterComponent.propTypes = {};

export default FilterComponent;
