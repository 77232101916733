import {INCREMENT_VALUE, DECREMENT_VALUE} from './exampleActionTypes';

/* export action creators here */
export const incrementValue = (incrementBy = 1) => {
  return {
    type: INCREMENT_VALUE,
    payload: incrementBy,
  };
};

export const decrementValue = (decrementBy = 1) => {
  return {
    type: DECREMENT_VALUE,
    payload: decrementBy,
  };
};
