import React, {useState} from 'react';
import CheckboxNew from '../checkboxNew';
import styles from './CountrySelection.module.scss';
import {check} from 'prettier';

const CountrySelection = (props) => {
  const {
    countryList,
    handleClearAll,
    handleSelectAll,
    handleCountrySelect,
    title = 'Country Availability*',
    isLive = false,
  } = props;

  return (
    <div
      className={
        !isLive ? styles.country_section : [styles.country_section, styles.disabled].join(' ')
      }
    >
      <div className={styles.country_section_title}>{title}</div>
      <div className={styles.country_action_btns}>
        <div className={styles.clear_all} onClick={handleClearAll}>
          Clear All
        </div>
        <div className={styles.select_all} onClick={handleSelectAll}>
          Select All
        </div>
      </div>
      <div className={styles.check_list}>
        {Array.isArray(countryList) &&
          countryList.map((ele) => (
            <CheckboxNew
              key={ele.countryCode}
              isChecked={ele.checked}
              label={ele.countryName}
              className={styles.checkBox}
              country={ele}
              handleCountrySelect={handleCountrySelect}
            ></CheckboxNew>
          ))}
      </div>
    </div>
  );
};

export default CountrySelection;
