import React, {useState} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import styles from './Delete.module.scss';

const ModalTypeArchive = (modalType, input) => {
  return (
    <>
      {modalType === 'ARCHIVE' ? (
        input.length > 0 && input !== 'ARCHIVE' ? (
          <div className={[styles.error_message].join(' ')}>Error in character input</div>
        ) : null
      ) : input.length > 0 && input !== 'DELETE' ? (
        <div className={[styles.error_message].join(' ')}>Error in character input</div>
      ) : null}
    </>
  );
};

const DeleteRecordInfoBanner = (props) => {
  const {cstDelete, modalType, boldTxt, name} = props;
  return (
    <>
      <div className={styles.warning_section}>
        <div className={[styles.warning_icon, cstDelete ? styles.cst_warning : ''].join(' ')} />
        <div className={[styles.message_section, cstDelete ? styles.cst_delete : ''].join(' ')}>
          <div className={[styles.heading, styles.mb_5].join(' ')}>
            {modalType === 'ARCHIVE'
              ? ` You are about to archive ${name}`
              : ` You are about to delete ${name} `}
            {cstDelete ? <span className={styles.bold_txt}>{boldTxt ? boldTxt : ''}</span> : ''}
          </div>
          <div className={styles.sub_heading}>This action cannot be undone.</div>
        </div>
      </div>
    </>
  );
};

const DeleteRecordTextField = (props) => {
  const {modalType, type, input, setInput} = props;

  return (
    <>
      <div className={styles.confirm_wrapper}>
        <div className={styles.txt}>
          {modalType === 'ARCHIVE'
            ? `To permanently archive this ${type}, type ARCHIVE.`
            : `To permanently delete this ${type}, type DELETE.`}
        </div>
        <div className={[styles.input_wrapper].join(' ')}>
          <input
            type={'text'}
            placeholder={modalType === 'ARCHIVE' ? 'Type ARCHIVE here.' : 'Type “DELETE” here.'}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={[
              styles.input,
              // If Needed Add cstDelete on place of false
              false ? styles.border_bold : '',
              input.length > 0 && input !== 'ARCHIVE' && input.length > 0 && input !== 'DELETE'
                ? styles.error_wrapper
                : null,
            ].join(' ')}
          />
          {input.length > 0 && input !== 'ARCHIVE' && input.length > 0 && input !== 'DELETE' ? (
            <div className={styles.error_icon_red}></div>
          ) : null}
        </div>
        {ModalTypeArchive(modalType, input)}
      </div>
    </>
  );
};

const DeleteRecord = (
  isDeleteRecord,
  name,
  boldTxt,
  cstDelete,
  modalType,
  input,
  setInput,
  type,
) => {
  if (!isDeleteRecord) {
    return (
      <>
        <DeleteRecordInfoBanner
          cstDelete={cstDelete}
          modalType={modalType}
          boldTxt={boldTxt}
          name={name}
        />

        <DeleteRecordTextField
          modalType={modalType}
          type={type}
          input={input}
          setInput={setInput}
        />
      </>
    );
  }
};
const DeleteModalComponent = (props) => {
  const {
    closeDeleteModal,
    deleteCall,
    modalType = 'DELETE',
    name = '',
    boldTxt = '',
    type = 'course',
    cstDelete = false,
  } = props;
  const [input, setInput] = useState('');
  const [isDeleteRecord, setDeleteRecord] = useState(false);

  const handleDeleteRecord = async () => {
    await deleteCall();
    setDeleteRecord(!isDeleteRecord);
    closeDeleteModal();
  };

  return (
    <React.Fragment>
      <div className={[styles.modal_wrapper, ''].join(' ')}>
        <div className={styles.delete_modal}>
          <div
            className={styles.close_wrapper}
            onClick={() => {
              closeDeleteModal();
            }}
          >
            <div className={styles.close_icon} />
          </div>
          {/* If Needed Add cstDelete on place of false */}
          <div
            className={[styles.header_wrapper, false ? styles.cst_header_wrapper : ''].join(' ')}
          >
            {modalType === 'ARCHIVE' ? (
              <div className={styles.heading}>Confirm Archive</div>
            ) : (
              <div className={styles.heading}>
                {!isDeleteRecord ? 'Confirm Delete' : 'Delete user group'}
              </div>
            )}
          </div>
          {
            DeleteRecord(isDeleteRecord, name, boldTxt, cstDelete, modalType, input, setInput, type)
            //   !isDeleteRecord && (
            //     <>
            //       <div className={styles.warning_section}>
            //         <div className={styles.warning_icon} />
            //         <div className={styles.message_section}>
            //           <div className={[styles.heading, styles.mb_5].join(' ')}>
            //             {modalType === 'ARCHIVE'
            //               ? ` You are about to archive ${name}`
            //               : ` You are about to delete ${name}`}
            //           </div>
            //           <div className={styles.sub_heading}>This action cannot be undone.</div>
            //         </div>
            //       </div>

            //       <div className={styles.confirm_wrapper}>
            //         <div className={styles.txt}>
            //           {modalType === 'ARCHIVE'
            //             ? 'To permanently archive this course, type ARCHIVE.'
            //             : 'To permanently delete this course, type DELETE.'}
            //         </div>
            //         <div className={[styles.input_wrapper].join(' ')}>
            //           <input
            //             type={'text'}
            //             placeholder={
            //               modalType === 'ARCHIVE' ? 'Type ARCHIVE here.' : 'Type “DELETE” here.'
            //             }
            //             value={input}
            //             onChange={(e) => setInput(e.target.value)}
            //             className={[styles.input,(input.length > 0 && input !== 'ARCHIVE') && (input.length > 0 && input !== 'DELETE') ?  styles.error_wrapper : null].join(' ')}
            //           />
            //           {(input.length > 0 && input !== 'ARCHIVE') && (input.length > 0 && input !== 'DELETE') ? (<div className={styles.error_icon_red}></div>) : null}
            //         </div>
            //         {modalType === 'ARCHIVE' ? (
            //         input.length > 0 && input !== 'ARCHIVE' ? <div className={[styles.error_message].join(' ')}>Error in character input</div>: null
            //       ) : (
            //        (input.length > 0 && input !== 'DELETE' ? <div className={[styles.error_message].join(' ')}>Error in character input</div>: null)
            //       )}
            //       </div>
            //     </>
            //   )
            //   // : (
            //   //   <div className={styles.confirm_msg}>
            //   //     <div className={styles.txt}>The user group</div>{' '}
            //   //     <div className={[styles.txt, styles.bold].join(' ')}>Lorem ipsum dolor sit amet</div>
            //   //     <div className={styles.txt}>has been deleted.</div>
            //   //   </div>
            //   // )
          }
          {
            !isDeleteRecord && (
              //  If Needed Add cstDelete on place of false
              <div className={[styles.btn_wrapper, false ? styles.border_bold : ''].join(' ')}>
                <button
                  className={[styles.btn].join(' ')}
                  onClick={() => {
                    closeDeleteModal();
                  }}
                >
                  Cancel
                </button>
                {modalType === 'ARCHIVE' ? (
                  <button
                    className={[
                      styles.btn,
                      input.length > 0 && input === 'ARCHIVE' ? styles.active : styles.disabled,
                    ].join(' ')}
                    onClick={() => handleDeleteRecord()}
                  >
                    Archive
                  </button>
                ) : (
                  <button
                    className={[
                      styles.btn,
                      input.length > 0 && input === 'DELETE' ? styles.active : styles.disabled,
                    ].join(' ')}
                    onClick={() => handleDeleteRecord()}
                  >
                    Delete
                  </button>
                )}
              </div>
            )
            // : (
            //   <div className={styles.btn_wrapper}>
            //     <button
            //       className={styles.btn}
            //       onClick={() => {
            //         closeDeleteModal();
            //       }}
            //     >
            //       Undo
            //     </button>
            //     <button
            //       className={[styles.btn, styles.save_changes].join(' ')}
            //       onClick={() => saveDeleteChanges()}
            //     >
            //       Save changes
            //     </button>
            //   </div>
            // )
          }
        </div>
      </div>
    </React.Fragment>
  );
};

DeleteModalComponent.propTypes = {};

export default DeleteModalComponent;
