import React from 'react';
import styles from './translationDelete.module.scss';
// import CheckboxComponent from '';
// import EditImageModal from '../addImagePage/EditImageModal/index';

const TranslationDeleteModalComponent = (props) => {
  const {
    heading,
    subHeading,
    isDeleteRecord,
    handleCloseDeleteModal,
    handleSaveDeleteChanges,
    handleDeleteRecord,
    notificationData,
    ModalWrapper,
    Background,
    Notification,
  } = props;

  return (
    <React.Fragment>
      <>
        {isDeleteRecord ? (
          <div className={[styles.modal_wrapper, ''].join(' ')}>
            <Background backgroundFlag="small">
              <div className={styles.delete_modal}>
                <div className={styles.close_wrapper} onClick={() => handleCloseDeleteModal(false)}>
                  <div className={styles.close_icon} />
                </div>
                <div className={styles.header_wrapper}>
                  <div className={styles.heading}>{heading ? heading : ''}</div>
                </div>
                <div className={styles.sub_header}>{subHeading ? subHeading : ''}</div>

                <div className={styles.btn_wrapper}>
                  <button className={styles.btn} onClick={() => handleCloseDeleteModal(false)}>
                    Cancel
                  </button>
                  <button
                    className={[styles.btn, styles.active].join(' ')}
                    onClick={() => handleSaveDeleteChanges(false)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </Background>
          </div>
        ) : null}
        {/* Delete Modal */}
        {notificationData && notificationData.show && <Notification />}
      </>
    </React.Fragment>
  );
};

TranslationDeleteModalComponent.propTypes = {};

export default TranslationDeleteModalComponent;
