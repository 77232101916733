export const SET_NEW_IMAGE_API_DATA = 'SET_NEW_IMAGE_API_DATA';
export const SET_CROP_DIALOGUE = 'SET_CROP_DIALOGUE';

export const setNewImageApiData = (options) => {
  return {
    type: SET_NEW_IMAGE_API_DATA,
    payload: options,
  };
};

export const setCropDialogue = (options) => {
  return {
    type: SET_CROP_DIALOGUE,
    payload: options,
  };
};
