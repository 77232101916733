/* eslint-disable  no-undef */
import {marketEnvConfig} from './marketEnvConfig';

const marketConfig = marketEnvConfig();
const market = marketConfig?.code?.toUpperCase();
const environment = marketConfig?.environment?.toUpperCase();
const localhost_BaseURL = `REACT_APP_${market}_${environment}_BASE_URL`;
const localhost_CLIENT_ID = `REACT_APP_${market}_OIDC_${environment}_CLIENT_ID`;
const localhost_OIDC_ISSUER = `REACT_APP_${market}_OIDC_ISSUER`;
const localhost_REDIRECT_URI = `REACT_APP_${market}_OIDC_${environment}_REDIRECT_URI`;

export const configAll = {
  [market]: {
    apiConfig: {
      dev: {
        baseURL: marketConfig?.BASE_URL?.dev ? marketConfig?.BASE_URL?.dev : process.env[localhost_BaseURL],
      },
      qa: {
        baseURL: marketConfig?.BASE_URL?.qa ? marketConfig?.BASE_URL?.qa : process.env[localhost_BaseURL],
      },
      prod: {
        baseURL: marketConfig?.BASE_URL?.prod ? marketConfig?.BASE_URL?.prod : process.env[localhost_BaseURL],
      },
    },
    oidc: {
      dev: {
        clientId: marketConfig?.CLIENT_ID?.dev ? marketConfig?.CLIENT_ID?.dev : process.env[localhost_CLIENT_ID],
        issuer: marketConfig?.OIDC_ISSUER ? marketConfig?.OIDC_ISSUER : process.env[localhost_OIDC_ISSUER],
        redirectUri: marketConfig?.REDIRECT_URI?.dev ? marketConfig?.REDIRECT_URI?.dev : process.env[localhost_REDIRECT_URI],
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        useInteractionCode: false,
      },
      qa: {
        clientId: marketConfig?.CLIENT_ID?.qa ? marketConfig?.CLIENT_ID?.qa : process.env[localhost_CLIENT_ID],
        issuer: marketConfig?.OIDC_ISSUER ? marketConfig?.OIDC_ISSUER : process.env[localhost_OIDC_ISSUER],
        redirectUri: marketConfig?.REDIRECT_URI?.qa ? marketConfig?.REDIRECT_URI?.qa : process.env[localhost_REDIRECT_URI],
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        useInteractionCode: false,
      },
      prod: {
        clientId: marketConfig?.CLIENT_ID?.prod ? marketConfig?.CLIENT_ID?.prod : process.env[localhost_CLIENT_ID],
        issuer: marketConfig?.OIDC_ISSUER ? marketConfig?.OIDC_ISSUER : process.env[localhost_OIDC_ISSUER],
        redirectUri: marketConfig?.REDIRECT_URI?.prod ? marketConfig?.REDIRECT_URI?.prod : process.env[localhost_REDIRECT_URI],
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        useInteractionCode: false,
      },
    },
    functionalityFlags: {
      showChinese: false,
    },
  },
};