import React from 'react';
import styles from '../Portal.module.scss';

const MobileSelectPageSection = (props) => {
  const {mobileTabSelected, setMobileTabSelected} = props;

  return (
    <React.Fragment>
      <div className={styles.header_list}>
        <div
          className={[styles.txt, mobileTabSelected === 'COURSES' ? styles.selected : ''].join(' ')}
          onClick={() => setMobileTabSelected('COURSES')}
        >
          Courses
        </div>
        <div
          className={[
            styles.txt,
            mobileTabSelected === 'LEARNING PATHS' ? styles.selected : '',
          ].join(' ')}
          onClick={() => setMobileTabSelected('LEARNING PATHS')}
        >
          Learning Paths
        </div>
        <div
          className={[styles.txt, mobileTabSelected === 'AUDIENCE' ? styles.selected : ''].join(
            ' ',
          )}
          onClick={() => setMobileTabSelected('AUDIENCE')}
        >
          User Groups
        </div>
      </div>
    </React.Fragment>
  );
};

MobileSelectPageSection.propTypes = {};

export default MobileSelectPageSection;
