import React from 'react';
import styles from './NoteCard.module.scss';
const NoteCardComponent = ({textName}) => {
  // const {onClick, title, description} = props;
  return (
    <div className={`${styles.notification_wrapper}`}>
      <div className={`${styles.noti_bar} `}>
        <div className={`${styles.left_icon} ${styles.warning_filled_icon}`} />
        <div className={`${styles.text_wrapper}`}>
          <div className={styles.description}>
            No User will have access to this {textName ? textName : 'course'} as all User Groups
            have been removed from User Group Targeting list.
          </div>
        </div>
      </div>
    </div>
  );
};
NoteCardComponent.propTypes = {};
export default NoteCardComponent;
