import React, {useState, useEffect} from 'react';
import {makeRequest} from '../APIsUtils/httpsUtils';
import {makeAnagram} from './CSTLandingPage';
// import {useState} from 'react'

export const handleCSTCreatModelCloseOpen = (props) => {
  const {
    isDrawer,
    setDrawer,
    setIsEditDrawer,
    setCreateCSTOpen,
    isCreateCSTOpen,
    setSelectTypeText,
    setOpenSelectTypeDropdown,
    setMouseHoverState,
    setEnglishTranslationCreateCST,
    setSpanishTranslationCreateCST,
    setSecondaryTranslationCreateCST,
    setNameErrorStateEnglishCreate,
    setCreateMaxCharErrorMessageEnglish,
    setCreateEnglishTranslationDuplicetName,
    setCreateEnglishTranslationEmpty,
    setCreateSecondaryTranslationEmpty,
    language,
  } = props;

  setDrawer(!isDrawer);
  setIsEditDrawer(false);
  setCreateCSTOpen(!isCreateCSTOpen);
  //console.log('>>>> handleCSTCreatModelCloseOpen >>>> props', props);
  setSelectTypeText('Select type');
  setOpenSelectTypeDropdown(false);
  setMouseHoverState('Select type');
  setEnglishTranslationCreateCST('');
  // setSecondaryTranslationCreateCST([]);
  language?.otherLocales?.map((item, key) => {
    setSecondaryTranslationCreateCST((prevState) => ({
      ...prevState,
      [item?.title]: {
        text: '',
        name: item?.title,
      },
    }));
  });
  setNameErrorStateEnglishCreate(false);
  setCreateMaxCharErrorMessageEnglish(false);
  setCreateEnglishTranslationDuplicetName(false);
  setCreateEnglishTranslationEmpty(false);
  language?.otherLocales?.map((item, key) => {
    setCreateSecondaryTranslationEmpty((prevState) => ({
      ...prevState,
      [item?.title]: false,
    }));
  });
};

export const handleCreateCSTSelectTypeDropdown = (props) => {
  const {setOpenSelectTypeDropdown, openSelectTypeDropdown} = props;
  setOpenSelectTypeDropdown(!openSelectTypeDropdown);
  //console.log('>>>> handleCreateCSTSelectTypeDropdown >>>> props', props);
};

export const handleEnglishTranslationCreateCST = (props) => {
  const {text = '', setEnglishTranslationCreateCST} = props;
  // console.log('>>>> >>>> handleEnglishTranslationCreateCST >>>> props', props);
  if (text[0] !== ' ') {
    setEnglishTranslationCreateCST(text);
  }
};

export const handleSecondaryTranslationCreateCST = (props) => {
  const {text = '', name = '', setSecondaryTranslationCreateCST} = props;
  if (text[0] !== ' ') {
    setSecondaryTranslationCreateCST((prevState) => ({
      ...prevState,
      [name]: {
        text: text?.replaceAll(/\s+/g, ' '),
        name: name,
      },
    }));
  }
};

export const handleCreateNewCSTButtonSaveChanges = async (props) => {
  const {
    setIsCreateButtonEnable,
    selectTypeText = '',
    englishTranslationCreateCST = '',
    setCreateBtnEnabled,
    secondaryTranslationCreateCST,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    setCreateCSTOpen,
    isCreateCSTOpen,
    setSelectTypeText,
    setOpenSelectTypeDropdown,
    setMouseHoverState,
    setEnglishTranslationCreateCST,
    setSpanishTranslationCreateCST,
    setSecondaryTranslationCreateCST,
    nameErrorStateEnglishCreate,
    setNameErrorStateEnglishCreate,
    setCreateEnglishTranslationDuplicetName,
    CreateEnglishTranslationDuplicetName,
    setNameErrorStateTamilCreate,
    setNameErrorStateBengaliCreate,
    setCreateMaxCharErrorMessageEnglish,
    setCreateMaxCharErrorMessageTamil,
    setCreateMaxCharErrorMessageBengali,
    setNotificationData,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    fetchCategorySkillsTags,
    setCreateEnglishTranslationEmpty,
    setCreateTamilTranslationEmpty,
    setCreateBengaliTranslationEmpty,
    filterData,
    setFilterData,
    setIsLoading,
    setDrawer,
    isDrawer,
    setIsEditDrawer,
    language,
    setCreateSecondaryTranslationEmpty,
  } = props;
  setIsCreateButtonEnable(true);
  let result = [];
  let tempSecondaryTranslationCreateCST = Object.values(secondaryTranslationCreateCST);

  language?.otherLocales.map((item) => {
    tempSecondaryTranslationCreateCST.map((val) => {
      if (item?.title === val?.name) {
        result.push({title: val?.text?.trim(), locale: item?.locale});
      }
    });
  });

  result.push({title: englishTranslationCreateCST?.trim(), locale: 'en-us'});

  const endpoint = `/admin/api/v1/csts?type=${selectTypeText}`;
  if (!CreateEnglishTranslationDuplicetName) {
    // let data = '';
    let data = await makeRequest({
      method: 'POST',
      endpoint,
      body: result,
    });
    setDrawer(false);
    if (data != 'ERR_NETWORK' && data != 'ERR_BAD_REQUEST' && data) {
      handleCSTCreatModelCloseOpen({
        isDrawer,
        setDrawer,
        setIsEditDrawer,
        setCreateCSTOpen,
        isCreateCSTOpen,
        setSelectTypeText,
        setOpenSelectTypeDropdown,
        setMouseHoverState,
        setEnglishTranslationCreateCST,
        setSpanishTranslationCreateCST,
        setSecondaryTranslationCreateCST,
        setNameErrorStateEnglishCreate,
        setNameErrorStateTamilCreate,
        setNameErrorStateBengaliCreate,
        setCreateMaxCharErrorMessageEnglish,
        setCreateMaxCharErrorMessageTamil,
        setCreateMaxCharErrorMessageBengali,
        setCreateEnglishTranslationDuplicetName,
        setCreateEnglishTranslationEmpty,
        setCreateTamilTranslationEmpty,
        setCreateBengaliTranslationEmpty,
        setCreateSecondaryTranslationEmpty,
      });
      setIsCreateButtonEnable(false);
      setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: `New ${selectTypeText} created`,
        description: `${selectTypeText} has been successfully created.`,
      });
      setDashboardNotificationCountApi();
      setNotificationListApi();
      await fetchCategorySkillsTags({
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
      });
      setFilterData(filterData);
    } else if (data != 'ERR_NETWORK' || data != 'ERR_BAD_REQUEST') {
      handleCSTCreatModelCloseOpen({
        isDrawer,
        setDrawer,
        setIsEditDrawer,
        setCreateCSTOpen,
        isCreateCSTOpen,
        setSelectTypeText,
        setOpenSelectTypeDropdown,
        setMouseHoverState,
        setEnglishTranslationCreateCST,
        setSpanishTranslationCreateCST,
        setSecondaryTranslationCreateCST,
        setNameErrorStateEnglishCreate,
        setNameErrorStateTamilCreate,
        setNameErrorStateBengaliCreate,
        setCreateMaxCharErrorMessageEnglish,
        setCreateMaxCharErrorMessageTamil,
        setCreateMaxCharErrorMessageBengali,
        setCreateEnglishTranslationDuplicetName,
        setCreateEnglishTranslationEmpty,
        setCreateTamilTranslationEmpty,
        setCreateBengaliTranslationEmpty,
        setCreateSecondaryTranslationEmpty,
      });
      setIsCreateButtonEnable(false);
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: `CST Creation Failed`,
        description: `Please Try Again`,
      });
    }
  }
};

export const handleCreateErrorStatesEnglish = (props) => {
  const {
    e,
    setNameErrorStateEnglishCreate,
    setCreateMaxCharErrorMessageEnglish,
    list,
    drawerDetails,
    setCreateEnglishTranslationDuplicetName,
    selectTypeText,
    setEnglishTranslationCreateCSTInvalidCharacter,
    setIsCreateButtonEnable,
  } = props;
  // console.log('>>>> >>>> handleCreateErrorStatesEnglish >>>> props', props);

  let errorString = makeAnagram({
    a: e,
    b: e?.toString(),
  });
  setEnglishTranslationCreateCSTInvalidCharacter(errorString);

  let resultCategory = [];
  let resultSill = [];
  let resultTag = [];

  // console.log('>>>> >>>> handleCreateErrorStatesEnglish >>>> list', list, '>>>> ', selectTypeText);

  list?.find((item) => {
    if (item?.type?.toLowerCase() === 'category' && selectTypeText?.toLowerCase() === 'category') {
      resultCategory?.push(item);
    } else if (item?.type?.toLowerCase() === 'skill' && selectTypeText?.toLowerCase() === 'skill') {
      resultSill?.push(item);
    } else if (item?.type?.toLowerCase() === 'tag' && selectTypeText?.toLowerCase() === 'tag') {
      resultTag?.push(item);
    }
  });

  if (e?.toString()) {
    if (e?.length > 50) {
      setIsCreateButtonEnable(true);
      setCreateMaxCharErrorMessageEnglish(true);
    } else {
      if (selectTypeText?.toLowerCase() === 'category') {
        if (
          resultCategory?.find((singel) => {
            return singel?.englishTranslation?.toLowerCase() == e?.toLowerCase()?.trim();
          })
        ) {
          setIsCreateButtonEnable(true);
          setCreateEnglishTranslationDuplicetName(true);
          return;
        } else {
          setIsCreateButtonEnable(false);
          setCreateEnglishTranslationDuplicetName(false);
        }
      } else if (selectTypeText?.toLowerCase() === 'skill') {
        if (
          resultSill?.find((singel) => {
            return singel?.englishTranslation?.toLowerCase() == e?.toLowerCase()?.trim();
          })
        ) {
          setIsCreateButtonEnable(true);
          setCreateEnglishTranslationDuplicetName(true);
          return;
        } else {
          setIsCreateButtonEnable(false);
          setCreateEnglishTranslationDuplicetName(false);
        }
      } else if (selectTypeText?.toLowerCase() === 'tag') {
        if (
          resultTag?.find((singel) => {
            return singel?.englishTranslation?.toLowerCase() == e?.toLowerCase()?.trim();
          })
        ) {
          setIsCreateButtonEnable(true);
          setCreateEnglishTranslationDuplicetName(true);
          return;
        } else {
          setIsCreateButtonEnable(false);
          setCreateEnglishTranslationDuplicetName(false);
        }
      }
      setIsCreateButtonEnable(false);
      setCreateMaxCharErrorMessageEnglish(false);
    }
  } else {
    setIsCreateButtonEnable(false);
    setCreateMaxCharErrorMessageEnglish(false);
    setNameErrorStateEnglishCreate(false);
  }
};

export const handleCreateErrorStatesSecondary = (props) => {
  // console.log("handleCreateErrorStatesSecondary ---- ", props)
  const {
    e,
    setCreateMaxCharErrorMessageSecondary,
    setNameErrorStateSecondaryCreate,
    setSecondaryTranslationCreateCSTInvalidCharacter,
    name,
  } = props;
  if (e?.length > 0) {
    let errorString = makeAnagram({
      a: e,
      b: e?.toString(),
    });
    setSecondaryTranslationCreateCSTInvalidCharacter((prevState) => ({
      ...prevState,
      [name]: errorString,
    }));
  }
    if (e?.length > 50) {
      setCreateMaxCharErrorMessageSecondary((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    } else {
      setCreateMaxCharErrorMessageSecondary((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }
    if (e?.toString()) {
      setNameErrorStateSecondaryCreate((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    } else {
      setNameErrorStateSecondaryCreate((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }
};

export const handleCreateButtonState = (props) => {
  const {
    englishTranslationCreateCST,
    isCreateEnglishTranslationEmpty,
    CreateEnglishTranslationDuplicetName,
    nameErrorStateEnglishCreate,
    secondaryTranslationCreateCST,
    isAllSecondaryInputText,
    CreateMaxCharErrorMessageSecondary,
    isCreateButtonEnable,
  } = props;
  // console.log('>>>> >>>> handleCreateButtonState >>>> props', props);
  if (
    (englishTranslationCreateCST?.length > 0 && englishTranslationCreateCST?.length <= 50) &&
    isAllSecondaryInputText &&
    CreateMaxCharErrorMessageSecondary &&
    CreateMaxCharErrorMessageSecondary !== undefined &&
    Object.keys(CreateMaxCharErrorMessageSecondary).every(
      (k) => !CreateMaxCharErrorMessageSecondary[k],
    )
  ) {
    return true;
  } else {
    return false;
  }
};
