import React from 'react';
import styles from '../ManageCourses.module.scss';

const CourseTypePopUp = (props) => {
  const {
    NewRadioButtonsComponent,
    selectedId,
    index,
    handleRadioButtomOnClick,
    item,
    course,
    apiCourseTypeCopy,
    apiCourseType,
    setSelectedId,
    setCourseTypeChanged,
    setPopUpClicked,
  } = props;
  return (
    <div className={[styles.item_wrapper].join(' ')} key={index}>
      <div className={styles.radio_btn_wrapper}>
        <NewRadioButtonsComponent
          selectedId={selectedId}
          onChangeHandler={() => {
            handleRadioButtomOnClick({
              course,
              item,
              apiCourseType,
              apiCourseTypeCopy,
              setCourseTypeChanged,
              setSelectedId,
              setPopUpClicked,
            });
          }}
          item={item}
        />
      </div>
      <div className={styles.item}>{item.title}</div>
      <div
        className={[
          styles.course_type_icon,
          item?.title == course?.courseType ? styles.selected : '',
          item?.title == 'Quick Start'
            ? styles.qstart_icon
            : item?.title == 'Required'
            ? styles.req_icon
            : item?.title == 'General visibility'
            ? styles.gen_visiblity_icon
            : item?.title == 'Recommended'
            ? styles.recom_icon
            : '',
          styles[item.className],
        ].join(' ')}
      ></div>
    </div>
  );
};

export default CourseTypePopUp;
