export const updateImageDetails = async (
  formData,
  makeRequest,
  setNotificationData,
  replacedImage,
  setReplacedImage,
  router,
  newCourseWizardState,
  setNewImageId,
  setDashboardNotificationCountApi,
  setNotificationListApi,
  currentUserId,
  setNewThumbnailId,
  setImageInput,
  newLPData,
  newLPWizardState,
  setMainImageBackup,
  newThumbnailImage,
  newCourseData,
) => {
  const _endpoint = '/admin/api/v1/images';
  let dataUpdateImageDetails = await makeRequest({
    method: 'POST',
    endpoint: _endpoint,
    body: formData,
  });
  if (dataUpdateImageDetails) {
    if (dataUpdateImageDetails === 'ERR_BAD_REQUEST' || dataUpdateImageDetails === 'ERR_NETWORK') {
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Image Creation Failed',
        description: 'Unable to add an image. Please try again later',
      });
      setImageInput('');
      if (newCourseWizardState?.courseWizardOpen) {
        setMainImageBackup(newThumbnailImage);
      } else if (newLPWizardState?.WizardOpen) {
        setMainImageBackup(newLPData.thumbnail);
      }
    } else {
      if (dataUpdateImageDetails.status === 'Success') {
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'New Image Added',
          description: `${dataUpdateImageDetails?.data?.title} has been added to the image library.`,
        });
      }
    }
    if (replacedImage && !newLPWizardState?.WizardOpen && !newCourseWizardState?.courseWizardOpen) {
      setReplacedImage({
        ...replacedImage,
        respNewlyCreatedImageDetail: dataUpdateImageDetails,
      });
      router.push({
        pathname: '/Contentmanagement',
        query: {actionName: 'IMAGE_REPLACEMENT'},
      });
    } else if (newCourseWizardState?.courseWizardOpen) {
      setNewThumbnailId(dataUpdateImageDetails?.data?.id);

      let subheader =
        newCourseData?.courseState == 'Draft'
          ? 'course_description'
          : newCourseData?.courseState == 'Live' || newCourseData?.courseState == 'Offline'
          ? 'edit'
          : '';
      router.push({
        pathname: '/create-course-wizard',
        search: `?id=${newCourseData.courseId}&sub_header=${subheader}`,
        //query: {actionName: ""},
      });
    } else if (newLPWizardState?.WizardOpen) {
      setNewThumbnailId(dataUpdateImageDetails?.data?.id);
      router.push({
        pathname: '/newLearningPath',
        search: `?id=${newLPData.learningPathId}&sub_header=learning_path_description`,
      });
    } else {
      setNewImageId(dataUpdateImageDetails?.data?.id);
      router.push({
        pathname: '/Contentmanagement',
      });
    }
  }
  setDashboardNotificationCountApi(currentUserId);
  setNotificationListApi();
};

export const dragAndDropandImageUploadNotification = ({
  setIsUserLoggedIn,
  setSelectedNavigationOption,
  setImageList,
  //   imageChange,
  fetchKeywords,
  refImageDrop,
  handleDragEnter,
  handleDrop,
  handleDragLeave,
  image_list_all,
  fetchKeywordsProps,
  handleImageInputProps,
  handleDropProps,
  imageChangeProps,
}) => {
  setIsUserLoggedIn(true);
  setSelectedNavigationOption('CONTENT-MANAGEMENT');
  const image_list_data =
    image_list_all !== undefined && image_list_all?.length > 0
      ? image_list_all
      : JSON.parse(sessionStorage.getItem('imagelistStorage'));
  setImageList(image_list_data);
  fetchKeywords(fetchKeywordsProps);

  if (refImageDrop && refImageDrop.current && refImageDrop.current.addEventListener) {
    refImageDrop.current.addEventListener('dragover', handleDragEnter);
    refImageDrop.current.addEventListener('drop', (e) =>
      handleDrop({e, image_list_data, handleDropProps, handleImageInputProps, imageChangeProps}),
    );
    refImageDrop.current.addEventListener('dragenter', handleDragEnter);
    refImageDrop.current.addEventListener('dragleave', handleDragLeave);
  }

  return () => {
    if (refImageDrop && refImageDrop.current && refImageDrop.current.removeEventListener) {
      refImageDrop.current.removeEventListener('dragover', handleDragEnter);
      refImageDrop.current.removeEventListener('drop', handleDrop);
      refImageDrop.current.removeEventListener('dragenter', handleDragEnter);
      refImageDrop.current.removeEventListener('dragleave', handleDragLeave);
    }
  };
};

export const fetchKeywords = async (fetchKeywordsProps) => {
  const {makeRequest, setKeywordSuggestion, setKeywordSuggestionCopy, setErrorStateTemplate} =
    fetchKeywordsProps;
  const _endpoint = '/api/v1/keywords';
  let dataFetchKeywords = await makeRequest({
    method: 'GET',
    endpoint: _endpoint,
  });
  if (dataFetchKeywords != 'ERR_NETWORK' && dataFetchKeywords) {
    setKeywordSuggestion(dataFetchKeywords?.data);
    setKeywordSuggestionCopy(dataFetchKeywords?.data);
  } else if (dataFetchKeywords == 'ERR_NETWORK') {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const handlePillsDataOnSaveChanges = ({pillsData}) => {
  let _tags = '';
  pillsData &&
    pillsData.length &&
    pillsData.length > 0 &&
    pillsData.map((pill) => {
      if (pill && pill.title) {
        if (_tags === '') {
          _tags = pill.title;
        } else {
          _tags = _tags + ', ' + pill.title;
        }
      }
    });
  return _tags;
};

export const handleKeywordInput = (props) => {
  const {value = '', handleKeywordInputprops = {}} = props;
  const {
    setSelectedKeyword,
    setUpdaedKeywordSuggestionCopy,
    keywordSuggestion,
    setKeywordDropdown,
    setKeywordSuggestion,
    KeywordSuggestionCopy,
  } = handleKeywordInputprops;
  setSelectedKeyword({title: value});
  if (value.length > 0) {
    setUpdaedKeywordSuggestionCopy(
      keywordSuggestion?.filter((itemss) =>
        itemss?.title?.toLowerCase().match(new RegExp(value?.toLowerCase())),
      ),
    );
    setKeywordDropdown(true);
  } else {
    setKeywordDropdown(false);
    setKeywordSuggestion(KeywordSuggestionCopy);
  }
};

export const handleDeletePill = (props) => {
  const {item, handleDeletePillProps} = props;
  const {pillsData, setPillsData, setImageData, image_data} = handleDeletePillProps;
  let tempPills = [...pillsData];
  const index = tempPills.indexOf(item);
  if (index > -1) {
    tempPills.splice(index, 1); // 2nd parameter means remove one item only
    // const updatedPills = keywordSuggestion.filter((item) => {
    //   return item.title.toLowerCase().search(value.toLowerCase()) !== -1;
    // });
    setPillsData(tempPills);
    setImageData({
      ...image_data,
      pillsData: tempPills,
    });
  }
};
const checkFileNameErrors = (props) => {
  const {value, setShortFileName, setFileNameGreaterThanThree} = props;
  if (value.length >= 3) {
    setShortFileName(false);
  } else {
    setShortFileName(true);
  }
  if (value.length >= 3) {
    setFileNameGreaterThanThree(true);
  } else {
    setFileNameGreaterThanThree(false);
  }
};
export const handleImageInput = (props) => {
  const {inputValue, image_list_data, handleImageInputProps} = props;
  const {
    regexNameChinese,
    imageList,
    setShortFileName,
    setFileNameGreaterThanThree,
    setImageData,
    image_data,
    setFileError,
    setImageInput,
    selectedImage,
  } = handleImageInputProps;
  // let value = inputValue?.toString()?.replaceAll(regexNameChinese, ''); // Comment coz don't want - in between image name
  let value = inputValue?.toString();
  const imageList2 =
    imageList?.length > 0 ? imageList : image_list_data?.length > 0 ? image_list_data : [];
  checkFileNameErrors({value, setShortFileName, setFileNameGreaterThanThree});

  setImageData({
    ...image_data,
    imageName: value,
  });
  let usedFileName =
    imageList2 &&
    imageList2.filter((item) => {
      if (item.fileName.toLowerCase() == value.toLowerCase()) {
        return item;
      }
    });
  if (usedFileName?.length > 0) {
    setFileError(true);
  } else {
    setFileError(false);
  }
  if (value.length <= 30) {
    setImageInput(value ? value : selectedImage?.name);
  } else if (value.length > 30) {
    setImageInput(value ? value : selectedImage?.name);

    setShortFileName(true);
    setFileNameGreaterThanThree(false);
  }
};

export function cropImageModal_16_9(props) {
  const {cropImageModal_16_9Props} = props;
  const {
    setRatio,
    router,
    croppedImageData,
    setLocalMainImage,
    replacedImage,
    selectedImage_16_9_Backup,
  } = cropImageModal_16_9Props ? cropImageModal_16_9Props : props;
  setRatio('169');
  router.push({
    pathname: '/edit-modal',
    query: {},
  });
  localStorage.setItem('ratio', '169');

  if (
    croppedImageData &&
    croppedImageData.croppedImage &&
    croppedImageData.croppedImage.croppedImage_16_9
  ) {
    // localStorage.setItem("mainImg", croppedImageData.croppedImage.croppedImage_16_9);
    setLocalMainImage(croppedImageData.croppedImage.croppedImage_16_9);
  } else {
    if (replacedImage?.image) {
      // localStorage.setItem("mainImg", replacedImage.image);
      setLocalMainImage(replacedImage.image);
    } else {
      // localStorage.setItem("mainImg", selectedImage_16_9_Backup.image);
      setLocalMainImage(selectedImage_16_9_Backup.image);
    }
  }
}

export function cropImageModal_1_1(props) {
  const {cropImageModal_1_1Props} = props;
  const {
    setRatio,
    router,
    croppedImageData,
    setLocalMainImage,
    replacedImage,
    selectedImage_16_9_Backup,
  } = cropImageModal_1_1Props ? cropImageModal_1_1Props : props;
  setRatio('11');
  router.push({
    pathname: '/edit-modal',
    query: {},
  });
  localStorage.setItem('ratio', '11');

  if (
    croppedImageData &&
    croppedImageData.croppedImage &&
    croppedImageData.croppedImage.croppedImage_1_1
  ) {
    // localStorage.setItem("mainImg", croppedImageData.croppedImage.croppedImage_1_1);
    setLocalMainImage(croppedImageData.croppedImage.croppedImage_1_1);
  } else {
    if (replacedImage?.image) {
      // localStorage.setItem("mainImg", replacedImage.image);
      setLocalMainImage(replacedImage.image);
    } else {
      // localStorage.setItem("mainImg", selectedImage_16_9_Backup.image);
      setLocalMainImage(selectedImage_16_9_Backup.image);
    }
  }
  //localStorage.setItem("mainImg", selectedImage);
}

export const handleDescriptionInput = (props) => {
  const {value, handleDescriptionInputProps} = props;
  const {setDescriptionInput, image_data, setImageData} = handleDescriptionInputProps;
  if (value.length <= 500) {
    setDescriptionInput(value);
    setImageData({
      ...image_data,
      imageDescription: value,
    });
  }
};

export const handleDrop = (props) => {
  const {e, image_list_data, handleDropProps, handleImageInputProps, imageChangeProps} = props;
  const {setIsDragOver, imageChange} = handleDropProps;

  e.preventDefault();
  e.stopPropagation();
  const {files} = e.dataTransfer;

  if (files?.length) {
    setIsDragOver(false);
    imageChange({
      event: {target: {files}},
      image_list_data: image_list_data,
      handleImageInputProps: handleImageInputProps,
      imageChangeProps: imageChangeProps,
    });
  }
};

export const handleCloseButton = (props) => {
  const {handleCloseButtonProps} = props;
  // e && e.preventDefault && e.preventDefault();
  const {
    newCourseWizardState,
    router,
    setMainImageBackup,
    setSelectedImage,
    setCroppedImageData,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setSelectedImage_16_9_Backup,
    setSelectedImage_1_1_Backup,
    setImageData,
    image_data,
    newThumbnailImage,
    setCroppedImageData11,
    setCroppedImageData169,
    newLPData,
    newLPWizardState,
    newCourseData,
    setNewThumbnailId,
    setBlockNavigation
  } = handleCloseButtonProps;

  if (newCourseWizardState?.courseWizardOpen) {
    const url = '/create-course-wizard';
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');
    let subheader =
      newCourseData?.courseState == 'Draft'
        ? 'course_description'
        : newCourseData?.courseState == 'Live' || newCourseData?.courseState == 'Offline'
        ? 'edit'
        : 'course_description';
    router.push({
      pathname: url,
      search: `?id=${newCourseData?.courseId ? newCourseData.courseId : 0}&sub_header=${subheader}`,
      //query: {actionName: ""},
    });
    // router.push({
    //   pathname: '/Coursesmanagement',
    // });
    setMainImageBackup(newThumbnailImage);
    setImageData({
      imageName: null,
      imageDescription: null,
      pillsData: null,
    });
    setNewThumbnailId(null);
    setCroppedImageData11(null);
    setCroppedImageData169(null);
    setCroppedImage_16_9(null);
    setCroppedImage_1_1(null);
    setSelectedImage(null);
    setSelectedImage_16_9_Backup(null);
    setSelectedImage_1_1_Backup(null);
  } else if (newLPWizardState?.WizardOpen) {
    const url = '/create-course-wizard';
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');
    let subheader = 'learning_path_description';
    // router.push({
    //   pathname: url,
    //   search: `?id=${
    //     newLPData?.learningPathId ? newLPData.learningPathId : 0
    //   }&sub_header=${subheader}`,
    //   //query: {actionName: ""},
    // });
    router.push({
      pathname: '/newLearningPath',
      search: `?id=${
        newLPData?.learningPathId ? newLPData.learningPathId : 0
      }&sub_header=${subheader}`,
    });
    // setMainImageBackup(newThumbnailImage);
    // setImageData({
    //   imageName: null,
    //   imageDescription: null,
    //   pillsData: null,
    // });
    setNewThumbnailId(null)
    setCroppedImageData11(null);
    setCroppedImageData169(null);
    setCroppedImage_16_9(null);
    setCroppedImage_1_1(null);
    setSelectedImage(null);
    setSelectedImage_16_9_Backup(null);
    setSelectedImage_1_1_Backup(null);
  } else {
    router.push({
      pathname: '/Contentmanagement',
      query: {},
    });
    setMainImageBackup(null);
    setSelectedImage(null);
    setCroppedImageData(null);
    setCroppedImage_16_9(null);
    setCroppedImage_1_1(null);
    setSelectedImage_16_9_Backup(null);
    setSelectedImage_1_1_Backup(null);
  }

  setImageData({
    imageName: image_data?.imageName ? image_data?.imageName : null,
    imageDescription: image_data?.imageDescription ? image_data?.imageDescription : null,
    pillsData: image_data?.pillsData ? image_data?.pillsData : null,
  });
};

const initialStateOfCropButton16_9 = (croppedImageData, setButton_169_status) => {
  if (croppedImageData.croppedImage.croppedImage_16_9 === null) {
    setButton_169_status(1);
  }
};

const initialStateOfCropButton1_1 = (croppedImageData, setButton_11_status) => {
  if (croppedImageData.croppedImage.croppedImage_1_1 === null) {
    setButton_11_status(1);
  }
};
export const imageChange = (props) => {
  const {event = {}, image_list_data, handleImageInputProps = {}, imageChangeProps = ''} = props;
  const {
    allowedFileSize,
    allowedFileType,
    setSelectedImage,
    setMainImageBackup,
    setSelectedImage_16_9_Backup,
    croppedImageData,
    setButton_169_status,
    setSelectedImage_1_1_Backup,
    setButton_11_status,
    imageinput,
    setImageData,
    image_data,
    handleImageInput,
    setNotificationData = () => {},
  } = imageChangeProps;
  if (
    event.target &&
    event.target.files &&
    event.target.files.length > 0 &&
    event.target.files[0].size <= allowedFileSize &&
    allowedFileType.includes(event.target.files[0].type)
  ) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const image = reader.result;
      setSelectedImage({
        image,
        imgFile: event.target.files[0],
      });
      setMainImageBackup(image);
      setSelectedImage_16_9_Backup({
        image,
        imgFile: event.target.files[0],
      });
      initialStateOfCropButton16_9(croppedImageData, setButton_169_status);
      setSelectedImage_1_1_Backup({
        image,
        imgFile: event.target.files[0],
      });
      initialStateOfCropButton1_1(croppedImageData, setButton_11_status);
      if (imageinput === '' || imageinput === null || imageinput === undefined) {
        setImageData({
          ...image_data,
          imageName: event.target.files[0].name,
          // imageFile: uploadedImage
        });
        handleImageInput({
          inputValue: event.target.files[0].name.split('.').slice(0, -1).join('.'),
          image_list_data: image_list_data,
          handleImageInputProps: handleImageInputProps,
        });
      }
    });
    reader.readAsDataURL(event.target.files[0]);
  }
  if (
    (event.target &&
      event.target.files &&
      event?.target?.files?.length > 0 &&
      event?.target?.files[0]?.size >= allowedFileSize) ||
    (event?.target?.files?.length > 0 && !allowedFileType?.includes(event?.target?.files[0]?.type))
  ) {
    setNotificationData({
      show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
      title: 'Image Uploading Failed',
      description: 'Please upload only .jpg/ .jpeg files of size 3MB max.',
      type: 'FAILURE',
    });
  }
};
