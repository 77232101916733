import React, {useState, useEffect, useRef} from 'react';
import styles from './AddImage.module.scss';
import Header from '../../shared/header';
import FieldTitle from '../../shared/FieldTitle';
import Pills from '../../shared/pills';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {setReplacedImage} from '../../../redux/actions/imageCrop/imageCropAction';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import Notification from '../../shared/notification';

import {setRatio, setMainImageBackup} from '../../../redux/actions/imageCrop/imageCropAction';
import {
  setDashboardNotificationCountApi,
  setNotificationListApi,
  setNotificationData,
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setErrorStateTemplate,
} from '../../../redux/actions/uiActions';

import {
  setSelectedImage_1_1_Backup,
  setSelectedImage_16_9_Backup,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setButton_169_status,
  setButton_11_status,
  setImageData,
  setLocalMainImage,
  setNewImageId,
  setCroppedImageData,
  setCroppedImageData11,
  setCroppedImageData169,
  setImageListAll,
} from '../../../redux/actions/imageCrop/imageCropAction';
import {
  setNewThumbnailId,
  setNewThumbnailImageData,
  setNewImageData,
  setBlockNavigation,
} from '../../../redux/actions/newCourseActions';
import {
  updateImageDetails,
  dragAndDropandImageUploadNotification,
  fetchKeywords,
  handlePillsDataOnSaveChanges,
  handleKeywordInput,
  handleDeletePill,
  handleImageInput,
  cropImageModal_16_9,
  cropImageModal_1_1,
  handleDescriptionInput,
  handleDrop,
  handleCloseButton,
  imageChange,
} from '../../utils/ContentManagementUtils/AddImageComponentUtils';
import Strings from '../../utils/Localization/Strings';

const ImagesSectionInAddImage = (props) => {
  const {ImagesSectionInAddImageProps} = props;
  const {
    replacedImage = null,
    croppedImageData = null,
    button_169_status = null,
    setCroppedImage_16_9 = null,
    setButton_169_status = null,
    cropImageModal_16_9,
    button_11_status = null,
    setCroppedImage_1_1 = null,
    setButton_11_status = null,
    cropImageModal_1_1,
    setCroppedImageData11 = null,
    setCroppedImageData169 = null,
    cropImageModal_16_9Props,
    cropImageModal_1_1Props,
  } = ImagesSectionInAddImageProps;

  return (
    <React.Fragment>
      <div className={styles.middle_container}>
        <div className={[styles.middle_wrapper, styles.full_width_class].join(' ')}>
          <div className={styles.title}>{replacedImage?.image ? 'Crop Image' : 'Upload Image'}</div>

          <div className={[styles.browse_wrapper, 'styles.loading_mask_wrapper', ''].join(' ')}>
            <div className={['', styles.scale_wrapper, styles.left_wrapper].join(' ')}>
              <div className={styles.label}>Ratio 16:9</div>
              <div className={styles.image_wrapper}>
                <div className={styles.loading_image}>
                  {croppedImageData.croppedImage.croppedImage_16_9 && (
                    <img
                      className={[styles.borderRadius12, styles.img_prop].join(' ')}
                      src={croppedImageData.croppedImage.croppedImage_16_9}
                      alt="Thumb"
                    />
                  )}
                  {!croppedImageData.croppedImage.croppedImage_16_9 && (
                    <img
                      className={[styles.borderRadius12, styles.img_prop].join(' ')}
                      src={replacedImage?.image}
                      alt="Thumb"
                    />
                  )}
                </div>
              </div>
              <div className={[styles.loading_btn_wrapper, styles.btn_wrapper].join(' ')}>
                <div className={[styles.inner_btn_wrapper, ''].join(' ')}>
                  <div
                    className={[styles.fill_btn, button_169_status === 1 ? styles.active : ''].join(
                      ' ',
                    )}
                    onClick={() => {
                      setCroppedImage_16_9(null);
                      setCroppedImageData169(null);
                      setButton_169_status(1);
                    }}
                  >
                    <div
                      className={
                        button_169_status === 1 ? styles.active_fill_image : styles.fill_image
                      }
                    ></div>
                  </div>
                  <div
                    className={[styles.crop_btn, button_169_status === 2 ? styles.active : ''].join(
                      ' ',
                    )}
                    onClick={() => {
                      cropImageModal_16_9(cropImageModal_16_9Props);
                      setButton_169_status(2);
                    }}
                  >
                    <div
                      className={
                        button_169_status === 2 ? styles.active_crop_image : styles.crop_image
                      }
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={[' ', styles.scale_wrapper, styles.right_wrapper].join(' ')}>
              <div className={styles.label}>Ratio 1:1</div>
              <div className={styles.image_wrapper}>
                <div className={styles.loading_image}>
                  {croppedImageData.croppedImage.croppedImage_1_1 && (
                    <img
                      className={[styles.borderRadius12, styles.img_prop].join(' ')}
                      src={croppedImageData.croppedImage.croppedImage_1_1}
                      alt="Thumb"
                    />
                  )}
                  {!croppedImageData.croppedImage.croppedImage_1_1 && (
                    <img
                      className={[styles.borderRadius12, styles.img_prop].join(' ')}
                      src={replacedImage?.image}
                      alt="Thumb"
                    />
                  )}
                </div>
              </div>
              <div className={[styles.loading_btn_wrapper, styles.btn_wrapper].join(' ')}>
                <div className={[styles.inner_btn_wrapper, ''].join(' ')}>
                  <div
                    className={[styles.fill_btn, button_11_status === 1 ? styles.active : ''].join(
                      ' ',
                    )}
                    onClick={() => {
                      setCroppedImage_1_1(null);
                      setCroppedImageData11(null);
                      setButton_11_status(1);
                    }}
                  >
                    <div
                      className={
                        button_11_status === 1 ? styles.active_fill_image : styles.fill_image
                      }
                    ></div>
                  </div>
                  <div
                    className={[styles.crop_btn, button_11_status === 2 ? styles.active : ''].join(
                      ' ',
                    )}
                    onClick={() => {
                      cropImageModal_1_1(cropImageModal_1_1Props);
                      setButton_11_status(2);
                    }}
                  >
                    <div
                      className={
                        button_11_status === 2 ? styles.active_crop_image : styles.crop_image
                      }
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const MobileButtonSectionSaveCancel = ({
  imageinput,
  descriptionInput,
  pillsData,
  selectedImage,
  isfileError,
  handleSaveChanges,
  handleCloseButton,
  saveChangesProps,
  handleCloseButtonProps,
}) => {
  return (
    <React.Fragment>
      <div className={[styles.btn_wrapper, styles.btn_xs, styles.ml_auto].join(' ')}>
        <button
          className={[
            styles.btn,
            imageinput?.length > 2 &&
            descriptionInput?.length > 0 &&
            pillsData?.length > 0 &&
            selectedImage &&
            isfileError === false
              ? styles.active
              : styles.disabled,
          ].join(' ')}
          onClick={() => {
            handleSaveChanges(saveChangesProps);
          }}
        >
          Save changes
        </button>
        <button className={styles.btn} onClick={() => handleCloseButton({handleCloseButtonProps})}>
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

const MiddleContainerOfUploadImage = (props) => {
  const {MiddleContainerOfUploadImageProps} = props;
  const {
    selectedImage,
    cropImageModal_16_9,
    cropImageModal_1_1,
    isDragOver,
    refImageUpload,
    refImageDrop,
    cropImageModal_16_9Props,
    cropImageModal_1_1Props,
    image_list_all,
    imageChange,
    setImageList,
    onclickImageInput,
    handleImageInputProps,
    imageChangeProps = '',
  } = MiddleContainerOfUploadImageProps;
  return (
    <React.Fragment>
      <div className={styles.middle_container}>
        <div className={[styles.middle_wrapper].join(' ')}>
          <div className={styles.title}>Upload Image</div>
          {
            selectedImage ? (
              /* Loading mask starts */
              <div className={[styles.browse_wrapper, 'styles.loading_mask_wrapper', ''].join(' ')}>
                <div className={['', styles.scale_wrapper, styles.left_wrapper].join(' ')}>
                  <div className={styles.label}>Ratio 16:9</div>
                  <div className={styles.image_wrapper}>
                    <div className={styles.loading_image}>
                      <img src={selectedImage} alt="Thumb" />
                    </div>
                  </div>
                  <div className={[styles.loading_btn_wrapper, styles.btn_wrapper].join(' ')}>
                    <div className={[styles.inner_btn_wrapper, ''].join(' ')}>
                      <div className={[styles.fill_btn, styles.active].join(' ')}>
                        <div className={styles.fill_image}></div>
                      </div>
                      <div
                        className={styles.crop_btn}
                        onClick={() => {
                          cropImageModal_16_9(cropImageModal_16_9Props);
                        }}
                      >
                        <div className={styles.crop_image}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={[' ', styles.scale_wrapper, styles.right_wrapper].join(' ')}>
                  <div className={styles.label}>Ratio 1:1</div>
                  <div className={styles.image_wrapper}>
                    <div className={styles.loading_image}>
                      <img src={selectedImage} alt="Thumb" />
                    </div>
                  </div>
                  <div className={[styles.loading_btn_wrapper, styles.btn_wrapper].join(' ')}>
                    <div className={[styles.inner_btn_wrapper, ''].join(' ')}>
                      <div className={[styles.fill_btn, styles.active].join(' ')}>
                        <div className={styles.fill_image}></div>
                      </div>
                      <div
                        className={styles.crop_btn}
                        onClick={() => {
                          cropImageModal_1_1(cropImageModal_1_1Props);
                        }}
                      >
                        <div className={styles.crop_image}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={[
                  styles.pre_browse_wrapper,
                  isDragOver ? styles.uploading_state : '',
                  '',
                ].join(' ')}
                htmlFor="imageUpload"
                onClick={() => {
                  if (refImageUpload) {
                    refImageUpload.current.click();
                  }
                }}
                ref={refImageDrop}
              >
                <input
                  id="imageUpload"
                  type={'file'}
                  className={styles.file_input}
                  accept="image/jpg, image/jpeg"
                  ref={refImageUpload}
                  onChange={(e) => {
                    const image_list_data =
                      image_list_all !== undefined && image_list_all?.length > 0
                        ? image_list_all
                        : JSON.parse(sessionStorage.getItem('imagelistStorage'));
                    setImageList(image_list_data);
                    // imageChange(e, image_list_data);
                    imageChange({
                      event: e,
                      image_list_data: image_list_data,
                      handleImageInputProps: handleImageInputProps,
                      imageChangeProps: imageChangeProps && imageChangeProps,
                    });
                  }}
                  onClick={(e) => {
                    onclickImageInput({
                      event: e,
                      handleImageInputProps: handleImageInputProps,
                      imageChangeProps: imageChangeProps && imageChangeProps,
                    });
                  }}
                />
                <div className={styles.div_wrapper_browse_img_browse_text}>
                  <div className={styles.browse_img}></div>
                  <div className={styles.browse_text}>Choose Image from Local Device</div>
                </div>
                <div className={styles.text}>or</div>
                <br />
                <div className={`${styles.browse_text_secondary}`}>
                  Drag & Drop Image from Local Device
                </div>
              </div>
            )
            /* Browse File */
          }
          <div className={styles.tag_text}> 3MB max | .jpg, jpeg</div>
        </div>
      </div>
    </React.Fragment>
  );
};

const OneAsToOneImageAndCropButton = (props) => {
  const {OneAsToOneImageAndCropButtonProps} = props;
  const {
    croppedImageData,
    selectedImage_1_1_Backup,
    button_11_status,
    setCroppedImageData11,
    setCroppedImage_1_1,
    setButton_11_status,
    cropImageModal_1_1,
    cropImageModal_1_1Props,
  } = OneAsToOneImageAndCropButtonProps;

  return (
    <React.Fragment>
      <div className={[' ', styles.scale_wrapper, styles.right_wrapper].join(' ')}>
        <div className={styles.label}>Ratio 1:1</div>
        <div className={styles.image_wrapper}>
          <div className={styles.loading_image}>
            {croppedImageData?.croppedImage?.croppedImage_1_1 && (
              <img
                className={[styles.borderRadius12, styles.img_prop].join(' ')}
                src={croppedImageData.croppedImage.croppedImage_1_1}
                alt="Thumb"
              />
            )}
            {!croppedImageData?.croppedImage?.croppedImage_1_1 && (
              <img
                className={[styles.borderRadius12, styles.img_prop].join(' ')}
                src={selectedImage_1_1_Backup.image}
                alt="Thumb"
              />
            )}
          </div>
        </div>
        <div className={[styles.loading_btn_wrapper, styles.btn_wrapper].join(' ')}>
          <div className={[styles.inner_btn_wrapper, ''].join(' ')}>
            <div
              className={[styles.fill_btn, button_11_status === 1 ? styles.active : ''].join(' ')}
              onClick={() => {
                setCroppedImageData11(null);
                setCroppedImage_1_1(null);
                setButton_11_status(1);
              }}
            >
              <div
                className={button_11_status === 1 ? styles.active_fill_image : styles.fill_image}
              ></div>
            </div>
            <div
              className={[styles.crop_btn, button_11_status === 2 ? styles.active : ''].join(' ')}
              onClick={() => {
                cropImageModal_1_1(cropImageModal_1_1Props);
                setButton_11_status(2);
              }}
            >
              <div
                className={button_11_status === 2 ? styles.active_crop_image : styles.crop_image}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const SixteenAsToNineImageAndCropButton = (props) => {
  const {SixteenAsToNineImageAndCropButtonProps} = props;
  const {
    croppedImageData,
    selectedImage_16_9_Backup,
    button_169_status,
    setCroppedImageData169,
    setCroppedImage_16_9,
    setButton_169_status,
    cropImageModal_16_9,
    cropImageModal_16_9Props,
  } = SixteenAsToNineImageAndCropButtonProps;
  return (
    <React.Fragment>
      <div className={['', styles.scale_wrapper, styles.left_wrapper].join(' ')}>
        <div className={styles.label}>Ratio 16:9</div>
        <div className={styles.image_wrapper}>
          <div className={styles.loading_image}>
            {croppedImageData?.croppedImage?.croppedImage_16_9 && (
              <img
                className={[styles.borderRadius12, styles.cropped_img_prop].join(' ')}
                src={croppedImageData.croppedImage.croppedImage_16_9}
                alt="Thumb"
              />
            )}
            {!croppedImageData?.croppedImage?.croppedImage_16_9 && (
              <img
                className={[styles.borderRadius12, styles.img_prop].join(' ')}
                src={selectedImage_16_9_Backup.image}
                alt="Thumb"
              />
            )}
          </div>
        </div>
        <div className={[styles.loading_btn_wrapper, styles.btn_wrapper].join(' ')}>
          <div className={[styles.inner_btn_wrapper, ''].join(' ')}>
            <div
              className={[styles.fill_btn, button_169_status === 1 ? styles.active : ''].join(' ')}
              onClick={() => {
                setCroppedImageData169(null);
                setCroppedImage_16_9(null);
                setButton_169_status(1);
              }}
            >
              <div
                className={button_169_status === 1 ? styles.active_fill_image : styles.fill_image}
              ></div>
            </div>
            <div
              className={[styles.crop_btn, button_169_status === 2 ? styles.active : ''].join(' ')}
              onClick={() => {
                cropImageModal_16_9(cropImageModal_16_9Props);
                setButton_169_status(2);
              }}
            >
              <div
                className={button_169_status === 2 ? styles.active_crop_image : styles.crop_image}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const FileNameErrorComponent = (props) => {
  const {isfileError, shortFileName, imageinput} = props;
  return (
    <React.Fragment>
      {isfileError ? (
        <div className={styles.error_message}>
          Image Name already exists in the Library. Enter a different name to save changes.
        </div>
      ) : null}
      {shortFileName ? (
        <div className={styles.error_message}>
          {imageinput?.length > 30
            ? 'Image file name should be 30 characters or less.'
            : 'Need 3 or more characters. Valid characters include A-z, 0-9, ., :, ?, !, -, “ ”, ‘’ '}
        </div>
      ) : null}
    </React.Fragment>
  );
};

const TopSectionOfAddImagePage = (props) => {
  const {TopSectionOfAddImagePageProps} = props;
  const {
    isfileError,
    shortFileName,
    regexNameChinese,
    handleImageInput,
    imageinput,
    selectedImage,
    handleDescriptionInput,
    descriptionInput,
    selectedKeyword,
    handleKeywordInput,
    isKeywordDropdown,
    updatedKeywordSuggestionCopy,
    handleSelectedKeyword,
    enabledAddTag,
    pillsData,
    handleAddTags,
    handleDeletePill,
    Pills,
    handleAddTagsProps,
    handleKeywordInputprops,
    handleDeletePillProps,
    handleImageInputProps,
    handleDescriptionInputProps,
  } = TopSectionOfAddImagePageProps;
  return (
    <React.Fragment>
      <div className={[styles.top_section, ''].join(' ')}>
        <div className={styles.left_container}>
          <div
            className={[
              styles.info_container,
              isfileError || shortFileName ? styles.error_wrapper : '',
              ' ',
            ].join(' ')}
          >
            <FieldTitle title={Strings.FILE_NAME} infoText={Strings.MAX_30_CHAR} />
            <div className={styles.form_field}>
              <div className={styles.input_wrapper}>
                <input
                  type={'text'}
                  placeholder={'Enter Image Name'}
                  className={styles.input}
                  pattern={regexNameChinese}
                  onChange={
                    (e) => {
                      e.target.value.length === 0 &&
                        handleImageInput({
                          inputValue: '',
                          handleImageInputProps: handleImageInputProps,
                        });
                      handleImageInput({
                        inputValue: e.target.value?.toString().trimStart(),
                        // ?.replaceAll(regexNameChinese, '') // Comment coz don't want - in between image name
                        handleImageInputProps: handleImageInputProps,
                      });
                    }
                    // handleImageInput(e.target.value.trimStart())
                  }
                  value={
                    imageinput?.length > 0
                      ? // imageinput?.split('.')[0]?.toString()?.replaceAll(regexNameChinese, '') // Comment coz don't want - in between image name
                        imageinput?.split('.')[0]?.toString()
                      : selectedImage?.name
                      ? // selectedImage.name?.toString()?.replaceAll(regexNameChinese, '') // Comment coz don't want - in between image name
                        selectedImage.name?.toString()
                      : ''
                  }
                />
                {isfileError || shortFileName ? (
                  <div className={styles.error_icon_red}></div>
                ) : null}
              </div>
            </div>
            <FileNameErrorComponent
              isfileError={isfileError}
              shortFileName={shortFileName}
              imageinput={imageinput}
            />
          </div>
          <div className={[styles.info_container, ' '].join(' ')}>
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>Description</div>
              <div className={styles.tag_text}>Max 500 characters</div>
            </div>
            <div className={styles.form_field}>
              <div className={styles.input_wrapper}>
                <textarea
                  type={'text'}
                  placeholder={'Enter Image Description'}
                  className={styles.textarea}
                  onChange={(e) =>
                    handleDescriptionInput({
                      value: e.target.value,
                      handleDescriptionInputProps: handleDescriptionInputProps,
                    })
                  }
                  value={descriptionInput}
                  contentEditable
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right_container}>
          <div className={[styles.info_container, ' '].join(' ')}>
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>Add Tags</div>
            </div>
            <div className={styles.form_field}>
              <div className={styles.input_wrapper}>
                <div className={[styles.input_area, styles.active].join(' ')}>
                  <input
                    type={'text'}
                    placeholder={'Add Keywords and Enter'}
                    className={[
                      styles.input,
                      selectedKeyword?.length > 0 ? styles.active : '',
                      styles.ip2,
                    ].join(' ')}
                    onChange={(e) => {
                      handleKeywordInput({
                        value: e.target.value,
                        handleKeywordInputprops: handleKeywordInputprops,
                      });
                    }}
                    value={selectedKeyword.title}
                  />
                  {isKeywordDropdown ? (
                    <div className={styles.dropdown_container}>
                      {updatedKeywordSuggestionCopy?.length > 0 ? (
                        <div className={styles.list_wrapper}>
                          {updatedKeywordSuggestionCopy?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={styles.list}
                                onClick={() => handleSelectedKeyword(item)}
                              >
                                {item.title}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className={styles.list_wrapper}>
                          <div className={styles.list}>No results found</div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>

                <div
                  className={[
                    styles.add_btn,
                    enabledAddTag &&
                    selectedKeyword.title &&
                    !pillsData.some((pillsData) => pillsData.title === selectedKeyword?.title)
                      ? styles.selected
                      : '',
                  ].join(' ')}
                  onClick={() => handleAddTags(handleAddTagsProps)}
                >
                  <span className={styles.plus_sign}></span> Add
                </div>
              </div>
            </div>
          </div>
          <div className={[styles.pills_container, 'col-xl-12 col-lg-12 pull-left'].join(' ')}>
            <Pills
              pillData={pillsData ? pillsData : []}
              isClose={true}
              isBold={false}
              onClosePill={(item) => {
                handleDeletePill({item: item, handleDeletePillProps: handleDeletePillProps});
              }}
            />
          </div>
          <div className={styles.suggestion_text}>
            Image cropping is unavailable on mobile. Please use your phone camera’s image cropping
            feature before uploading.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const handleSaveChanges = async (props) => {
  const {saveChangesProps} = props;
  const {
    setIsSave,
    counter,
    setCounter,
    isFileNameGreaterThanThree,
    replacedImage,
    setImageData,
    imageinput,
    descriptionInput,
    pillsData,
    router,
    selectedImage_16_9_Backup,
    setImageInput,
    croppedImageData169,
    croppedImageData11,
    makeRequest,
    setNotificationData,
    setReplacedImage,
    newCourseWizardState,
    setNewImageId,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    currentUserId,
    setNewThumbnailId,
    setCroppedImageData11,
    setCroppedImageData169,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setSelectedImage,
    setShortFileName,
    setSelectedImage_16_9_Backup,
    setSelectedImage_1_1_Backup,
    getDefaultDimensionImg,
    newLPData,
    newLPWizardState,
    setMainImageBackup,
    newThumbnailImage,
    newCourseData,
  } = saveChangesProps ? saveChangesProps : props;

  if (isFileNameGreaterThanThree) {
    setIsSave(true);
    const _tags = handlePillsDataOnSaveChanges({pillsData});
    if (replacedImage?.image) {
      setImageData({
        imageName: imageinput,
        imageDescription: descriptionInput,
        pillsData: pillsData,
      });
      router.push({
        pathname: '/Contentmanagement',
        query: {actionName: 'IMAGE_REPLACEMENT'},
      });
    } else {
      const promiseArray = [];
      if (croppedImageData169 === null || croppedImageData169 === undefined) {
        promiseArray.push(getDefaultDimensionImg('16:9', selectedImage_16_9_Backup));
      } else {
        promiseArray.push(getDefaultDimensionImg('16:9', croppedImageData169));
      }
      if (croppedImageData11 === null || croppedImageData11 === undefined) {
        promiseArray.push(getDefaultDimensionImg('1:1', selectedImage_16_9_Backup));
      } else {
        promiseArray.push(getDefaultDimensionImg('1:1', croppedImageData11));
      }
      if (counter == 0) {
        Promise.all(promiseArray).then(async (responses) => {
          // let uploadedImage = selectedImage_16_9_Backup?.imgFile
          // const regexImageNameForAPI = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFFa-zA-Z0-9]/g;
          // let _fileName = ""
          // _fileName = imageinput
          // let _fileType = selectedImage_16_9_Backup.imgFile.name.substring(selectedImage_16_9_Backup.imgFile.name.lastIndexOf("."), selectedImage_16_9_Backup.imgFile.name.length)
          // _fileName = _fileName.replaceAll(regexImageNameForAPI, "")
          // _fileName = _fileName + _fileType
          // setImageInput(_fileName)
          // let blob = selectedImage_16_9_Backup.imgFile.slice(0, selectedImage_16_9_Backup.imgFile.size, uploadedImage.type);
          // uploadedImage = new File([blob], _fileName, {type: uploadedImage.type});
          const formData = new FormData();
          formData.append('IMAGE_16_9', responses[0]?.imgFile);
          formData.append('IMAGE_1_1', responses[1]?.imgFile);
          formData.append('fileName', imageinput);
          formData.append('description', descriptionInput);
          formData.append('tags', _tags);
          await updateImageDetails(
            formData,
            makeRequest,
            setNotificationData,
            replacedImage,
            setReplacedImage,
            router,
            newCourseWizardState,
            setNewImageId,
            setDashboardNotificationCountApi,
            setNotificationListApi,
            currentUserId,
            setNewThumbnailId,
            setImageInput,
            newLPData,
            newLPWizardState,
            setMainImageBackup,
            newThumbnailImage,
            newCourseData,
          );
          //uploadedImage = null
          setImageData({
            imageName: null,
            imageDescription: null,
            pillsData: null,
          });
          setCroppedImageData11(null);
          setCroppedImageData169(null);
          setCroppedImage_16_9(null);
          setCroppedImage_1_1(null);
          setSelectedImage(null);
          setSelectedImage_16_9_Backup(null);
          setSelectedImage_1_1_Backup(null);
        });
        setCounter((prev) => prev + 1);
      }
    }
  } else {
    setShortFileName(true);
  }
  setSelectedImage(null);
  // setCroppedImageData(null);
  // setSelectedImage_16_9_Backup(null);
  // setSelectedImage_1_1_Backup(null);
  //setNewCourseWizardState(true);
};

const handleAddTags = (handleAddTagsProps) => {
  const {
    setEnabledAddTag,
    selectedKeyword,
    pillsData,
    setImageData,
    image_data,
    setSelectedKeyword,
  } = handleAddTagsProps;
  setEnabledAddTag(false);
  if (selectedKeyword?.title?.length > 0 && selectedKeyword?.id?.length > 0) {
    let tempList = [];
    let tempObj = {
      //id: selectedKeyword?.id,
      title: selectedKeyword?.title,
    };
    tempList.push(selectedKeyword?.title);
    //setPillsData((preState) => [...preState, tempList]);
    if (
      selectedKeyword &&
      selectedKeyword?.title &&
      selectedKeyword?.title?.length > 0 &&
      !pillsData.some((pillsData) => pillsData.title === tempObj?.title)
    ) {
      // setPillsData([...pillsData, tempObj]);
      setImageData({
        ...image_data,
        pillsData: [...pillsData, tempObj],
      });
    }
    setSelectedKeyword({title: ''});
  }
};

const alreadyExistImageData = (props) => {
  // const { image_data, setPillsData} = props
  const {imageinput, setFileNameGreaterThanThree, image_data, setPillsData} = props;
  if (imageinput?.length >= 3) {
    setFileNameGreaterThanThree(true);
  } else {
    setFileNameGreaterThanThree(false);
  }
  if (image_data && image_data?.pillsData) {
    let tempArray = [];
    image_data?.pillsData?.forEach((data) => {
      if (typeof data === 'string') {
        tempArray.push({
          title: data,
        });
      } else if (typeof data === 'object') {
        tempArray.push({
          title: data?.title,
        });
      }
    });
    setPillsData(tempArray);
  }
};

const AddImageComponent = ({
  replacedImage,
  setReplacedImage,
  setRatio,
  croppedImageData,
  croppedImageData11,
  croppedImageData169,
  setMainImageBackup,
  newThumbnailImage,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  selectedImage_16_9_Backup,
  selectedImage_1_1_Backup,
  image_list_all,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setButton_169_status,
  setButton_11_status,
  button_169_status,
  button_11_status,
  setImageData,
  image_data,
  newImageData,
  setLocalMainImage,
  setNewImageId,
  newCourseWizardState,
  setCroppedImageData,
  setNewThumbnailId,
  setDashboardNotificationCountApi,
  setNotificationListApi,
  setNotificationData,
  currentUserId,
  setCroppedImageData11,
  setCroppedImageData169,
  setSelectedNavigationOption,
  notificationData,
  setIsUserLoggedIn,
  setImageListAll,
  //setNewThumbnailImageData,
  //setNewImageData
  setErrorStateTemplate,
  newLPData,
  newLPWizardState,
  newCourseData,
  courseMode,
  learningPathMode,
  setBlockNavigation,
}) => {
  const allowedFileSize = 3 * 1000 * 1000; //Allowed File Size is 6 MB
  const allowedFileType = ['image/jpeg', 'image/jpg'];
  const [keywordSuggestion, setKeywordSuggestion] = useState([]);
  const [KeywordSuggestionCopy, setKeywordSuggestionCopy] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState({title: ''});
  const [isKeywordDropdown, setKeywordDropdown] = useState(false);
  const [pillsData, setPillsData] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [imageinput, setImageInput] = useState(image_data?.imageName ? image_data?.imageName : '');
  const [descriptionInput, setDescriptionInput] = useState(
    image_data?.imageDescription ? image_data?.imageDescription : '',
  );
  const [isFileNameGreaterThanThree, setFileNameGreaterThanThree] = useState(false);
  const [counter, setCounter] = useState(0);
  const [shortFileName, setShortFileName] = useState(false);
  const [isfileError, setFileError] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const refImageUpload = useRef(null);
  const refImageDrop = React.useRef(null);
  const router = useHistory();
  const {query = {}} = router.location;
  const {actionName} = query;
  const [updatedKeywordSuggestionCopy, setUpdaedKeywordSuggestionCopy] = useState([]);
  const [enabledAddTag, setEnabledAddTag] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const handlePopState = (event) => {
    setMainImageBackup(null);
    setNewThumbnailId(null);
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const fetchImages = async () => {
    let dataSearchAndSort = await makeRequest({
      method: 'GET',
      endpoint: '/admin/api/v1/images?searchString=&usedInCourse=&sortBy=Recent_Activity',
    });
    if (dataSearchAndSort) {
      setImageListAll(dataSearchAndSort?.data);
    }
  };

  useEffect(() => {
    setImageList(image_list_all);
    dragAndDropandImageUploadNotification({
      setIsUserLoggedIn,
      setSelectedNavigationOption,
      setImageList,
      imageChange,
      fetchKeywords,
      refImageDrop,
      handleDragEnter,
      handleDrop,
      handleDragLeave,
      image_list_all,
      fetchKeywordsProps: {
        makeRequest,
        setKeywordSuggestion,
        setKeywordSuggestionCopy,
        setErrorStateTemplate,
      },
      handleImageInputProps: {
        regexNameChinese,
        imageList,
        setShortFileName,
        setFileNameGreaterThanThree,
        setImageData,
        image_data,
        setFileError,
        setImageInput,
        selectedImage,
      },
      handleDropProps: {setIsDragOver, imageChange},
      imageChangeProps: {
        allowedFileSize,
        allowedFileType,
        setSelectedImage,
        setMainImageBackup,
        setSelectedImage_16_9_Backup,
        croppedImageData,
        setButton_169_status,
        setSelectedImage_1_1_Backup,
        setButton_11_status,
        imageinput,
        setImageData,
        image_data,
        handleImageInput,
        setNotificationData,
      },
    });
    fetchImages();
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  useEffect(() => {
    if (imageinput?.length > 0) {
      let usedFileName =
        image_list_all &&
        image_list_all.filter((item) => {
          if (item.fileName.toLowerCase() == imageinput.toLowerCase()) {
            return item;
          }
        });
      if (usedFileName?.length > 0) {
        setFileError(true);
      } else {
        setFileError(false);
      }
      if (imageinput.length > 30) {
        setImageInput(imageinput ? imageinput : selectedImage?.name);
        setShortFileName(true);
      }
      if (imageinput.length < 3) {
        setImageInput(imageinput ? imageinput : selectedImage?.name);
        setShortFileName(true);
        setFileNameGreaterThanThree(false);
      }
    }
    alreadyExistImageData({imageinput, setFileNameGreaterThanThree, image_data, setPillsData});
  }, [image_data]);

  const regexNameChinese =
    /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z.。?？:：!！\"”“'‘’ -]+/g;

  const handleSelectedKeyword = (item) => {
    setEnabledAddTag(true);
    setSelectedKeyword(item);
    setKeywordDropdown(false);
  };

  const onclickImageInput = (event, handleImageInputProps, imageChangeProps) => {
    const image_list_data =
      image_list_all !== undefined && image_list_all?.length > 0
        ? image_list_all
        : JSON.parse(sessionStorage.getItem('imagelistStorage'));
    setImageList(image_list_data);
    // imageChange(e,image_list_data)
    imageChange({
      event: event,
      image_list_data: image_list_data,
      handleImageInputProps: handleImageInputProps,
      imageChangeProps: imageChangeProps && imageChangeProps,
    });
  };

  useEffect(() => {
    newImageData &&
      imageChange({
        event: newImageData,
        image_list_data: imageList,
        handleImageInputProps: {
          regexNameChinese,
          imageList,
          setShortFileName,
          setFileNameGreaterThanThree,
          setImageData,
          image_data,
          setFileError,
          setImageInput,
          selectedImage,
        },
        imageChangeProps: {
          allowedFileSize,
          allowedFileType,
          setSelectedImage,
          setMainImageBackup,
          setSelectedImage_16_9_Backup,
          croppedImageData,
          setButton_169_status,
          setSelectedImage_1_1_Backup,
          setButton_11_status,
          imageinput,
          setImageData,
          image_data,
          handleImageInput,
          setNotificationData,
        },
      });
  }, [newImageData]);

  const dataURLtoFile = (dataurl, filename) => {
    const array = dataurl.split(',');
    const match = array[0].match(/:(.*?);/);
    if (!match || match.length < 1) {
      throw new Error('Invalid dataUrl');
    }
    const mime = match[1];
    const bstr = window.atob(array[1]);
    let n = bstr.length;
    const u8arry = new Uint8Array(n);
    while (n--) {
      u8arry[n] = bstr.codePointAt(n);
    }
    return new File([u8arry], filename, {type: mime});
  };
  // Utility method to resize image to 16:9 OR 1:1 aspect ratio if it is uploaded without being cropped
  const getDefaultDimensionImg = (aspectRatio, selectedImage_16_9_Backup) => {
    return new Promise((resolve) => {
      const imageFile = selectedImage_16_9_Backup?.imgFile;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(imageFile);
      fileReader.onload = (fileReaderEvent) => {
        const imageAsBase64 = fileReaderEvent.target.result;
        const image = document.createElement('img');
        image.src = imageAsBase64;
        const imageHeight = image.height;
        const imageWidth = image.width;
        const minDimension =
          imageHeight >= imageWidth ? parseInt(imageWidth) : parseInt(imageHeight);

        // Specify Resize Image Height-Width based on aspect ratio.
        const resizeImageHeight = minDimension;
        const resizeImageWidth =
          aspectRatio == '16:9' ? parseInt((minDimension / 9) * 16) : minDimension;

        const canvas = document.createElement('canvas');
        canvas.width = resizeImageWidth;
        canvas.height = resizeImageHeight;

        const context = canvas.getContext('2d', {alpha: false});
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        const resizedImageAsBase64 = canvas.toDataURL('image/jpeg');

        //...............................................................................................
        let uploadedImage = selectedImage_16_9_Backup?.imgFile;
        const regexImageNameForAPI = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFFa-zA-Z0-9]/g;
        let _fileName = '';
        _fileName = imageinput;
        let _fileType = selectedImage_16_9_Backup.imgFile.name.substring(
          selectedImage_16_9_Backup.imgFile.name.lastIndexOf('.'),
          selectedImage_16_9_Backup.imgFile.name.length,
        );
        // _fileName = _fileName.replaceAll(regexImageNameForAPI, ''); // Comment coz don't want - in between image name
        _fileName = _fileName;
        _fileName = _fileName + _fileType;
        setImageInput(_fileName);
        let blob = selectedImage_16_9_Backup.imgFile.slice(
          0,
          selectedImage_16_9_Backup.imgFile.size,
          uploadedImage.type,
        );
        uploadedImage = new File([blob], _fileName, {type: uploadedImage.type});
        //...............................................................................................

        const resizedImageFile = dataURLtoFile(resizedImageAsBase64, uploadedImage?.name);
        const responseObject = {
          image: resizedImageAsBase64,
          imgFile: resizedImageFile,
        };
        resolve(responseObject);
      };
    });
  };

  return (
    <React.Fragment>
      <div
        className={[
          styles.add_image_container,
          learningPathMode === 'FROM_LEARNING_PATH' || courseMode == 'FROM_COURSE_WIZARD'
            ? styles.content_mgt_wrapper_disable_side_nav
            : '',
          '',
        ].join(' ')}
      >
        {/* Header Component */}
        <div className={styles.header_container}>
          <Header
            headerName={replacedImage ? 'Add Image Details' : 'Add New Image'}
            actionName={actionName ? actionName : null}
            headerXSName=" "
            btnName1="Cancel"
            btnName2="Save changes"
            isHeaderXS={false}
            isBtn1={true}
            isBtn2={true}
            handleButton={(handleCloseButtonProps) => {
              handleCloseButton({handleCloseButtonProps});
            }}
            handleSaveChanges={(saveChangesProps) => {
              handleSaveChanges(saveChangesProps);
            }}
            saveChangesProps={{
              setIsSave,
              counter,
              setCounter,
              isFileNameGreaterThanThree,
              replacedImage,
              setImageData,
              imageinput,
              descriptionInput,
              pillsData,
              router,
              selectedImage_16_9_Backup,
              setImageInput,
              croppedImageData169,
              croppedImageData11,
              makeRequest,
              setNotificationData,
              setReplacedImage,
              newCourseWizardState,
              setNewImageId,
              setDashboardNotificationCountApi,
              setNotificationListApi,
              currentUserId,
              setNewThumbnailId,
              setCroppedImageData11,
              setCroppedImageData169,
              setCroppedImage_16_9,
              setCroppedImage_1_1,
              setSelectedImage,
              setShortFileName,
              setSelectedImage_16_9_Backup,
              setSelectedImage_1_1_Backup,
              getDefaultDimensionImg,
              newLPData,
              newLPWizardState,
              setMainImageBackup,
              newThumbnailImage,
              newCourseData,
            }}
            handleCloseButtonProps={{
              newCourseWizardState,
              router,
              setMainImageBackup,
              setSelectedImage,
              setCroppedImageData,
              setCroppedImage_16_9,
              setCroppedImage_1_1,
              setSelectedImage_16_9_Backup,
              setSelectedImage_1_1_Backup,
              setImageData,
              image_data,
              newThumbnailImage,
              setCroppedImageData11,
              setCroppedImageData169,
              newLPData,
              newLPWizardState,
              newCourseData,
              setNewThumbnailId,
            }}
            isBtn2Disable={
              // isSaveChangesDisabled ||
              !(
                imageinput?.length > 2 &&
                imageinput?.length <= 30 &&
                descriptionInput?.length > 0 &&
                pillsData?.length > 0 &&
                (selectedImage || replacedImage?.image || selectedImage_16_9_Backup?.image) &&
                isfileError === false &&
                counter == 0
              )
            }
            isSideNavigationPresent={
              learningPathMode === 'FROM_LEARNING_PATH' || courseMode == 'FROM_COURSE_WIZARD'
                ? false
                : true
            }
          />
        </div>
        {/* Header Component */}

        {/* Add Image inner Wrapper Start */}
        <div
          className={[
            styles.add_inner_wrapper,
            learningPathMode === 'FROM_LEARNING_PATH' || courseMode == 'FROM_COURSE_WIZARD'
              ? styles.audience_wrapper
              : '',
            '',
          ].join(' ')}
        >
          {/* Top Section Of Page Start*/}
          <TopSectionOfAddImagePage
            TopSectionOfAddImagePageProps={{
              isfileError: isfileError,
              shortFileName: shortFileName,
              regexNameChinese: regexNameChinese,
              handleImageInput: handleImageInput,
              imageinput: imageinput,
              selectedImage: selectedImage,
              handleDescriptionInput: handleDescriptionInput,
              descriptionInput: descriptionInput,
              selectedKeyword: selectedKeyword,
              handleKeywordInput: handleKeywordInput,
              isKeywordDropdown: isKeywordDropdown,
              updatedKeywordSuggestionCopy: updatedKeywordSuggestionCopy,
              handleSelectedKeyword: handleSelectedKeyword,
              enabledAddTag: enabledAddTag,
              pillsData: pillsData,
              handleAddTags: handleAddTags,
              handleDeletePill: handleDeletePill,
              Pills: Pills,
              handleAddTagsProps: {
                setEnabledAddTag,
                selectedKeyword,
                pillsData,
                setImageData,
                image_data,
                setSelectedKeyword,
              },
              handleKeywordInputprops: {
                setSelectedKeyword,
                setUpdaedKeywordSuggestionCopy,
                keywordSuggestion,
                setKeywordDropdown,
                setKeywordSuggestion,
                KeywordSuggestionCopy,
              },
              handleDeletePillProps: {pillsData, setPillsData, setImageData, image_data},
              handleImageInputProps: {
                regexNameChinese,
                imageList,
                setShortFileName,
                setFileNameGreaterThanThree,
                setImageData,
                image_data,
                setFileError,
                setImageInput,
                selectedImage,
              },
              handleDescriptionInputProps: {setDescriptionInput, image_data, setImageData},
            }}
          />
          {/* Top Section Of Page End*/}

          {selectedImage_16_9_Backup && selectedImage_1_1_Backup && (
            <>
              <div className={styles.middle_container}>
                {/* Please add here class "full_width_class" conditionally after uploading an image */}
                <div className={[styles.middle_wrapper, styles.full_width_class].join(' ')}>
                  <div className={styles.title}>
                    {selectedImage_16_9_Backup && selectedImage_1_1_Backup
                      ? 'Crop Image'
                      : 'Upload Image'}
                  </div>

                  <div
                    className={[styles.browse_wrapper, 'styles.loading_mask_wrapper', ''].join(' ')}
                  >
                    <SixteenAsToNineImageAndCropButton
                      SixteenAsToNineImageAndCropButtonProps={{
                        croppedImageData: croppedImageData,
                        selectedImage_16_9_Backup: selectedImage_16_9_Backup,
                        button_169_status: button_169_status,
                        setCroppedImageData169: setCroppedImageData169,
                        setCroppedImage_16_9: setCroppedImage_16_9,
                        setButton_169_status: setButton_169_status,
                        cropImageModal_16_9: (cropImageModal_16_9Props) => {
                          cropImageModal_16_9(cropImageModal_16_9Props);
                        },
                        cropImageModal_16_9Props: {
                          setRatio,
                          router,
                          croppedImageData,
                          setLocalMainImage,
                          replacedImage,
                          selectedImage_16_9_Backup,
                        },
                      }}
                    />
                    <OneAsToOneImageAndCropButton
                      OneAsToOneImageAndCropButtonProps={{
                        croppedImageData: croppedImageData,
                        selectedImage_1_1_Backup: selectedImage_1_1_Backup,
                        button_11_status: button_11_status,
                        setCroppedImageData11: setCroppedImageData11,
                        setCroppedImage_1_1: setCroppedImage_1_1,
                        setButton_11_status: setButton_11_status,
                        cropImageModal_1_1: (cropImageModal_1_1Props) => {
                          cropImageModal_1_1(cropImageModal_1_1Props);
                        },
                        cropImageModal_1_1Props: {
                          setRatio,
                          router,
                          croppedImageData,
                          setLocalMainImage,
                          replacedImage,
                          selectedImage_16_9_Backup,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Middle Container Of Page Start */}
          {!(replacedImage && replacedImage) && !selectedImage_16_9_Backup && (
            <MiddleContainerOfUploadImage
              MiddleContainerOfUploadImageProps={{
                selectedImage: selectedImage,
                cropImageModal_16_9: (cropImageModal_16_9Props) => {
                  cropImageModal_16_9(cropImageModal_16_9Props);
                },
                cropImageModal_1_1: (cropImageModal_1_1Props) => {
                  cropImageModal_1_1(cropImageModal_1_1Props);
                },
                isDragOver: isDragOver,
                refImageUpload: refImageUpload,
                refImageDrop: refImageDrop,
                cropImageModal_16_9Props: {
                  setRatio,
                  router,
                  croppedImageData,
                  setLocalMainImage,
                  replacedImage,
                  selectedImage_16_9_Backup,
                },
                cropImageModal_1_1Props: {
                  setRatio,
                  router,
                  croppedImageData,
                  setLocalMainImage,
                  replacedImage,
                  selectedImage_16_9_Backup,
                },
                image_list_all: image_list_all,
                imageChange: imageChange,
                setImageList: setImageList,
                onclickImageInput: onclickImageInput,
                handleImageInputProps: {
                  regexNameChinese,
                  imageList,
                  setShortFileName,
                  setFileNameGreaterThanThree,
                  setImageData,
                  image_data,
                  setFileError,
                  setImageInput,
                  selectedImage,
                },
                imageChangeProps: {
                  allowedFileSize,
                  allowedFileType,
                  setSelectedImage,
                  setMainImageBackup,
                  setSelectedImage_16_9_Backup,
                  croppedImageData,
                  setButton_169_status,
                  setSelectedImage_1_1_Backup,
                  setButton_11_status,
                  imageinput,
                  setImageData,
                  image_data,
                  handleImageInput,
                  setNotificationData,
                },
              }}
            />
          )}

          {replacedImage && replacedImage.image && (
            <div>
              <ImagesSectionInAddImage
                ImagesSectionInAddImageProps={{
                  replacedImage: replacedImage,
                  croppedImageData: croppedImageData,
                  button_169_status: button_169_status,
                  setCroppedImage_16_9: setCroppedImage_16_9,
                  setButton_169_status: setButton_169_status,
                  cropImageModal_16_9: (cropImageModal_16_9Props) => {
                    cropImageModal_16_9(cropImageModal_16_9Props);
                  },
                  button_11_status: button_11_status,
                  setCroppedImage_1_1: setCroppedImage_1_1,
                  setButton_11_status: setButton_11_status,
                  cropImageModal_1_1: (cropImageModal_1_1Props) => {
                    cropImageModal_1_1(cropImageModal_1_1Props);
                  },
                  setCroppedImageData11: setCroppedImageData11,
                  setCroppedImageData169: setCroppedImageData169,
                  cropImageModal_16_9Props: {
                    setRatio,
                    router,
                    croppedImageData,
                    setLocalMainImage,
                    replacedImage,
                    selectedImage_16_9_Backup,
                  },
                  cropImageModal_1_1Props: {
                    setRatio,
                    router,
                    croppedImageData,
                    setLocalMainImage,
                    replacedImage,
                    selectedImage_16_9_Backup,
                  },
                }}
              />
            </div>
          )}
          {/* Middle Container Of Page End */}

          {/* Bottom section With Buttons for mob View Start*/}
          <MobileButtonSectionSaveCancel
            imageinput={imageinput}
            descriptionInput={descriptionInput}
            pillsData={pillsData}
            selectedImage={selectedImage}
            isfileError={isfileError}
            handleSaveChanges={(saveChangesProps) => {
              handleSaveChanges(saveChangesProps);
            }}
            handleCloseButton={(handleCloseButtonProps) => {
              handleCloseButton({handleCloseButtonProps});
            }}
            saveChangesProps={{
              counter,
              setCounter,
              isFileNameGreaterThanThree,
              replacedImage,
              setImageData,
              imageinput,
              descriptionInput,
              pillsData,
              router,
              selectedImage_16_9_Backup,
              setImageInput,
              croppedImageData169,
              croppedImageData11,
              makeRequest,
              setNotificationData,
              setReplacedImage,
              newCourseWizardState,
              setNewImageId,
              setDashboardNotificationCountApi,
              setNotificationListApi,
              currentUserId,
              setNewThumbnailId,
              setCroppedImageData11,
              setCroppedImageData169,
              setCroppedImage_16_9,
              setCroppedImage_1_1,
              setSelectedImage,
              setShortFileName,
              setSelectedImage_16_9_Backup,
              setSelectedImage_1_1_Backup,
              getDefaultDimensionImg,
              newLPWizardState,
              setMainImageBackup,
              newThumbnailImage,
              newCourseData,
            }}
            handleCloseButtonProps={{
              newCourseWizardState,
              router,
              setMainImageBackup,
              setSelectedImage,
              setCroppedImageData,
              setCroppedImage_16_9,
              setCroppedImage_1_1,
              setSelectedImage_16_9_Backup,
              setSelectedImage_1_1_Backup,
              setImageData,
              image_data,
              newThumbnailImage,
              setCroppedImageData11,
              setCroppedImageData169,
              setNewThumbnailId,
            }}
          />
          {notificationData?.show && <Notification />}
          {/* Bottom section With Buttons for mob View End */}
        </div>
      </div>
    </React.Fragment>
  );
};

AddImageComponent.propTypes = {};

const mapStateToProps = (state) => ({
  croppedImageData: state,
  croppedImageData169: state?.croppedImage?.croppedImageData169,
  croppedImageData11: state?.croppedImage?.croppedImageData11,
  replacedImage: state && state.croppedImage && state.croppedImage.replacedImage,
  croppedImage_1_1: state && state.croppedImage && state.croppedImage.cropped_image_1_1,
  croppedImage_16_9: state && state.croppedImage && state.croppedImage.cropped_image_16_9,
  selectedImage_16_9_Backup:
    state && state.croppedImage && state.croppedImage.selectedImage_16_9_Backup,
  selectedImage_1_1_Backup:
    state && state.croppedImage && state.croppedImage.selectedImage_1_1_Backup,
  image_list_all: state && state.croppedImage && state.croppedImage.image_list_all,
  button_169_status: state?.croppedImage?.button_169_status,
  button_11_status: state?.croppedImage?.button_11_status,
  image_data: state?.croppedImage?.image_data,
  newImageData: state && state.courseState && state.courseState.newImageData,
  newCourseData:
    state &&
    state.courseState &&
    state.courseState.newCourseData &&
    state.courseState.newCourseData.data,
  newCourseWizardState: state && state.courseState && state.courseState.newCourseWizardState,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
  newThumbnailImage: state && state.courseState && state.courseState.newThumbnailImage,
  notificationData: state && state.uiState && state.uiState.notificationData,
  newLPData: state && state.LPState && state.LPState.newLPData,
  newLPWizardState: state && state.LPState && state.LPState.newLPWizardState,
  courseMode: state && state.uiState && state.uiState.courseMode,
  learningPathMode: state && state.uiState && state.uiState.learningPathMode,
});

const mapDispatchToProps = (dispatch) => ({
  setReplacedImage: (options) => dispatch(setReplacedImage(options)),
  setRatio: (options) => dispatch(setRatio(options)),
  setMainImageBackup: (options) => dispatch(setMainImageBackup(options)),
  setSelectedImage_16_9_Backup: (options) => dispatch(setSelectedImage_16_9_Backup(options)),
  setSelectedImage_1_1_Backup: (options) => dispatch(setSelectedImage_1_1_Backup(options)),
  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),
  setButton_169_status: (options) => dispatch(setButton_169_status(options)),
  setButton_11_status: (options) => dispatch(setButton_11_status(options)),
  setImageData: (options) => dispatch(setImageData(options)),
  setLocalMainImage: (options) => dispatch(setLocalMainImage(options)),
  setNewImageId: (options) => dispatch(setNewImageId(options)),
  setCroppedImageData: (options) => dispatch(setCroppedImageData(options)),
  setNewThumbnailId: (options) => dispatch(setNewThumbnailId(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setCroppedImageData11: (options) => dispatch(setCroppedImageData11(options)),
  setCroppedImageData169: (options) => dispatch(setCroppedImageData169(options)),
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),

  setNewThumbnailImageData: (options) => dispatch(setNewThumbnailImageData(options)),
  setNewImageData: (options) => dispatch(setNewImageData(options)),
  setImageListAll: (options) => dispatch(setImageListAll(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setBlockNavigation: (options) => dispatch(setBlockNavigation(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AddImageComponent));
