import React, {useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import EditImageModal from '../components/contentManagement/addImagePage/EditImageModal';

const EditImage = () => {
  const [editImageExtends, seteditImageExtends] = useState('hide');
  const [croppedImg, setCroppedImg] = useState(undefined);
  const [croppedImg1, setCroppedImg1] = useState(undefined);
  const [mainImg, setMainImg] = useState(undefined);
  const [ratio1, setRatio1] = useState(false);
  return (
    <div className="page_content">
      {/* <SideNavigation pageName="CONTENT-MANAGEMENT" /> */}
      <div className={styles.container}>
        <main className={styles.main}>
          {/* <SideNavigationComponent {...props} /> */}
          <EditImageModal
            editImageExtends={editImageExtends}
            seteditImageExtends={seteditImageExtends}
            croppedImg={croppedImg}
            setCroppedImg={setCroppedImg}
            mainImg={mainImg}
            setMainImg={setMainImg}
            croppedImg1={croppedImg1}
            setCroppedImg1={setCroppedImg1}
            ratio1={ratio1}
            setRatio1={setRatio1}
          />
        </main>
      </div>
    </div>
  );
};

EditImage.propTypes = {};

export default EditImage;
