import React from 'react';
import styles from './newRadioButtonsComponent.module.scss';

const NewRadioButtonsComponent = (props) => {
  const {selectedId, label, onChangeHandler, item} = props;
  return (
    <>
      <label className={styles.container}>
        <input
          type="radio"
          // checked={selectedFilterItem && selectedFilterItem.id && item && item.id && selectedFilterItem.id === item.id ? true : checked}
          checked={item?.id === selectedId}
          key={item?.id}
          onChange={() => {
            onChangeHandler(item);
          }}
          value={label}
        />
        <span className={styles.checkmark}></span>
        {label}
      </label>
    </>
  );
};

NewRadioButtonsComponent.propTypes = {};

export default NewRadioButtonsComponent;
