export const LPSsubheaderArray = [
  {
    title: 'Learning Path Information',
    className: 'home_icon',
    subHeaderClick: true,
  },
  {
    title: 'Translation',
    className: 'translation_icon',
    subHeaderClick: true,
  },
  {
    title: 'Course Addition',
    className: 'course_addition_icon',
    subHeaderClick: false,
  },
  {
    title: 'Course Sequencing',
    className: 'course_sequencing_icon',
    subHeaderClick: false,
  },
  {
    title: 'User Group Targeting',
    className: 'user_grp_icon',
    subHeaderClick: false,
  },
  {
    title: 'Categories, Skills, Tags',
    className: 'tag_icon',
    subHeaderClick: false,
  },
  {
    title: 'Review & Publish',
    className: 'publish_icon',
    subHeaderClick: false,
  },
];
