import React from 'react';
import styles from '../Portal.module.scss';

const MobileAudienceHeader = (props) => {
  const {
    mobileTabSelected,
    searchUserGroup,
    setSearchUserGroup,
    setPredictedSearchUGM,
    audienceGroupList,
    handleSearchInputUGM,
    setNotificationData,
    setSearch,
    setCourses,
    courses,
    setLearning,
    setAudience,
    learning,
    audience,
    handleSearch,
    filterDropdownUGM,
    filterCourses,
    filterDataUGM,
    setFilterDataUGM,
    setFilterDataTempUGM,
    makeRequest,
    setSearchFilterEmptyState,
    setAudienceGroupListCopy,
    setAudienceGroupList,
    setErrorStateTemplate,
    isOnline,
    handleFilter,
    setFilterDropdownUGM,
    setFilterCourses,
    handleFilterApplyUGM,
    FilterComponent,
    sortData,
    setIsLoading,
  } = props;

  return (
    <React.Fragment>
      {mobileTabSelected === 'AUDIENCE' ? (
        <div className={styles.action_wrapper}>
          <div
            className={[
              styles.search_wrapper,
              searchUserGroup?.length > 0 ? styles.outline : null,
            ].join(' ')}
          >
            <input
              type="text"
              placeholder="Search User Group"
              className={styles.search_input}
              onChange={(event) =>
                handleSearchInputUGM({
                  event,
                  setSearchUserGroup,
                  setPredictedSearchUGM,
                  audienceGroupList,
                })
              }
              value={searchUserGroup}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  searchUserGroup?.length < 3 &&
                  searchUserGroup?.length > 0
                ) {
                  setNotificationData({
                    show: false,
                    type: 'WARNING',
                    title: 'Insufficient Search Input',
                    description: 'To search for any User group, enter 3 or more characters.',
                  });
                }
              }}
            />
            <div className={styles.icon_wrapper}>
              <div
                className={[
                  styles.search,
                  searchUserGroup?.length > 0 ? styles.cross_icon : null,
                ].join(' ')}
                onClick={() => {
                  handleSearch({
                    value: 'audience',
                    setSearch,
                    setCourses,
                    courses,
                    setLearning,
                    setAudience,
                    learning,
                    audience,
                  });
                  setSearchUserGroup('');
                  setPredictedSearchUGM('');
                }}
              />
            </div>
          </div>
          <div className={styles.filter_main_wrapper}>
            <div className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(' ')}>
              <div
                className={styles.filter}
                onClick={() => {
                  handleFilter({
                    value: 'audience',
                    setFilterCourses,
                  });
                  setFilterDropdownUGM(!filterDropdownUGM);
                }}
              />
            </div>
            {filterDropdownUGM ? (
              <FilterComponent
                //isPills={true}
                isPills={false}
                //headingName={headingNameUGM}
                //isLandingPage={true}
                col6={true}
                filterData={filterDataUGM}
                sortData={sortData}
                handleFilterApply={(params) => {
                  handleFilterApplyUGM({
                    params,
                    filterDataUGM,
                    setFilterDataUGM,
                    setFilterDataTempUGM,
                    makeRequest,
                    setSearchFilterEmptyState,
                    searchUserGroup,
                    setAudienceGroupListCopy,
                    setAudienceGroupList,
                    setErrorStateTemplate,
                    isOnline,
                    setIsLoading,
                  });
                }}
                handleFilterCancle={() => setFilterDropdownUGM(false)}
                isOpenSubCategories={true}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

MobileAudienceHeader.propTypes = {};

export default MobileAudienceHeader;
