import React from 'react';
import styles from '../Content.module.scss';

const ToggleSwitchHandle = (props) => {
  const {
    newCourseWizardState,
    isListView,
    handleToggleSwitch,
    isCardView,
    setIsCardView,
    setIsListView,
    learningPathMode,
  } = props;
  return (
    <React.Fragment>
      <div className={styles.toggle_section_wapper}>
        {!newCourseWizardState?.courseWizardOpen && learningPathMode !== 'FROM_LEARNING_PATH' && (
          <div className={styles.toggle_wrapper}>
            <div
              className={[
                styles.toggle_section,
                styles.card_radius,
                isCardView ? styles.card_selected : '',
              ].join(' ')}
              onClick={() => handleToggleSwitch('cardView', setIsCardView, setIsListView)}
            >
              <div className={styles.card_view} />
            </div>
            <div
              className={[
                styles.toggle_section,
                styles.view_radius,
                isListView ? styles.view_selected : '',
              ].join(' ')}
              onClick={() => handleToggleSwitch('listView', setIsCardView, setIsListView)}
            >
              <div className={styles.list_view} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ToggleSwitchHandle;
