import React from 'react';
import styles from '../Portal.module.scss';
import {setSelectedNavigationOption} from '../../../redux/actions/uiActions';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

const ViewAllCompnentMobile = (props) => {
  const {
    isLoading = false,
    showEmptyTemplateFor = '',
    list,
    SelectedNavigationOptionText = '',
    pageName = '',
    viewType = 'card',
  } = props;

  let pathOFNavigation = '';
  switch (pageName) {
    case 'Learning Paths':
      pathOFNavigation = '/LearningPathModal';
      break;
    case 'Courses':
      pathOFNavigation = '/coursesManagement';
      break;
    case 'User Groups':
      pathOFNavigation = '/UserManagement';
      break;
  }

  const router = useHistory();
  return (
    !isLoading &&
    showEmptyTemplateFor !== 'COURSES' &&
    list.length > 0 && (
      <div
        className={[styles.course_bottom_link, 'col-lg-12'].join(' ')}
        onClick={() => {
          setSelectedNavigationOption(SelectedNavigationOptionText);
          router.push({
            pathname: pathOFNavigation,
            query: {viewType: viewType},
          });
        }}
      >
        View All {pageName}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ViewAllCompnentMobile));

// export default ViewAllCompnentMobile;
