import React, {useEffect} from 'react';
import {connect} from 'react-redux';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import styles from './LearningPath.module.scss';
import Header from '../../shared/header';
import {setSelectedNavigationOption, setIsUserLoggedIn} from '../../../redux/actions/uiActions';

const LearningPathModalComponent = ({setSelectedNavigationOption, setIsUserLoggedIn}) => {
  useEffect(() => {
    setIsUserLoggedIn(true);
    setSelectedNavigationOption('LEARNING-PATH');
  }, []);

  return (
    <React.Fragment>
      <Header headerName="Learning Path Management" />

      {/* Learning Empty error container */}
      <div className={[styles.lp_empty_screen_wrapper].join(' ')}>
        <div className={styles.inner_wrapper_empty}>
          <div className={styles.empty_screen}>
            {/* <div className={styles.image_wrapper}> */}
            <div className={[styles.image_wrapper, styles.lp_image].join(' ')}></div>
            {/* </div> */}
            <div className={styles.content_wrapper}>
              <div className={styles.label}>Learning Path Management</div>
              <div className={styles.content}>
                The ability to group courses into Learning Paths coming later in 2022.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of empty screen */}

      {/* DO NOT REMOVE COMMENTED CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}

      {/* <div className={[styles.modal_wrapper, 'hide'].join(' ')}>
        <div className={[styles.learning_modal,'hide'].join(' ')}>
          <div className={styles.close_wrapper} onClick={() => closeLearningModal()}>
            <div className={styles.close_icon} />
          </div>
          <div className={styles.header_wrapper}>
            <div className={styles.heading}>Taiwan New ABOs</div>
          </div>
          <div className={styles.top_area_wrapper}>
            <div
              className={[styles.search_main_wrapper, search.length > 3 ? styles.active : ''].join(
                ' ',
              )}
            >
              <div className={styles.search_wrapper}>
                <input
                  type="text"
                  placeholder="Search Learning paths to add to Audience group"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={styles.search_input}
                />
                <div className={styles.icon_wrapper}>
                  <div className={styles.search} />
                </div>
              </div>
              {search.length > 3 ? (
                <div className={styles.dropdown_container}>
                  <div className={styles.list_wrapper}>
                    <div className={styles.list}>Nutrilite customers</div>
                    <div className={styles.list}>Selling Nutrilite products</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.sort_by_wrapper}>
              <div className={styles.txt}>Filter Table</div>
              <div
                className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(' ')}
              >
                <div className={styles.filter} />
              </div>
            </div>
            <div className={styles.action_wrapper}>
              <div className={[styles.audience_grp, styles.disabled, 'hide'].join(' ')}>
                <div className={styles.add} />
                <div className={styles.txt}>Add Learning path to Audience</div>
              </div>
              <div className={[styles.audience_grp].join(' ')}>
                <div className={styles.remove} />
                <div className={styles.txt}>Remove All Selected</div>
              </div>
            </div>
          </div>
          <div className={styles.tbl_wrapper}>
            <div className={styles.tbl_header}>
              <div className={styles.tbl_head}>
                <div className={styles.filter_main_wrapper}>
                  <div
                    className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(
                      ' ',
                    )}
                  >
                    <div className={styles.filter} onClick={() => handleFilter('courses')} />
                  </div>
                  {filterCourses ? (
                    <FilterComponent
                      left="-5px"
                      right="auto"
                      top="42px"
                      isPills={true}
                      headingName="Sort By"
                      filterData={filterData}
                      handleFilterCancle={() => handleFilterCancle()}
                    />
                  ) : null}
                </div>

                <CheckboxComponent checked={false} />

                <div className={styles.txt}>Learning paths</div>
              </div>
              <div className={styles.tbl_head}>
                <div className={styles.txt}>No of courses</div>
              </div>
              <div className={styles.tbl_head}>
                <div className={styles.txt}>Status</div>
                <div className={styles.question_icon} />
              </div>
              <div className={styles.tbl_head}>
                <div className={styles.txt}>Remove</div>
              </div>
            </div>
            <div className={styles.tbl_body}>
              <div className={[styles.tbl_row, styles.selected].join(' ')}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={true} onClick={() => handleRowSelected()} />
                  <div className={styles.txt}>The Science Behind The 6 Step Selling Framework</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_15].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.live].join(' ')}>
                    <div className={styles.status_txt}>Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={[styles.tbl_row, styles.selected].join(' ')}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={true} />
                  <div className={styles.txt}>Selling Matters For New ABOs</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_15].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.draft].join(' ')}>
                    <div className={styles.status_txt}>Draft</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>Goal Setting and Action Planning For Leaders</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_15].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_offline].join(' ')}>
                    <div className={styles.status_txt}>Go Offline</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>How To Use Social Selling Assets</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_15].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>
                    Too Much or Too Little? Providing Effective Strategy
                  </div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_15].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>Successful Selling Conversations</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_15].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.btn_wrapper}>
            <button className={styles.btn}>Cancel</button>
            <button className={[styles.btn, styles.disabled].join(' ')}>Save Changes</button>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

LearningPathModalComponent.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
});

export default connect(null, mapDispatchToProps)(LearningPathModalComponent);
