export const _apiHeaderConfigs = () => {
  const _localStorageOktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
  if (_localStorageOktaTokenStorage) {
    const {idToken = {}, accessToken = {}} = _localStorageOktaTokenStorage;
    const _accessToken = accessToken && accessToken.accessToken;
    const _idToken = idToken && idToken.idToken;

    return {
      _accessToken: _accessToken,
      _idToken: _idToken,
    };
  } else {
    return {
      _accessToken: false,
      _idToken: false,
    };
  }
};
