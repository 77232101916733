import React, {useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import ManageCourseModal from '../components/learningPathManagement/ManageCourseModal/ManageCourseModal';

const LearningPathManageCourse = () => {
  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          <ManageCourseModal />
        </main>
      </div>
    </div>
  );
};

LearningPathManageCourse.propTypes = {};

export default LearningPathManageCourse;
