import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import {rootReducer} from './reducers/rootReducer';
import storage from 'redux-persist/lib/storage';

const initialState = {};

const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['setNotificationErrorState'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, initialState, applyMiddleware(...middleware));
export const persistor = persistStore(store);
export default {store, persistor};
