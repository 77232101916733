import React, {useState} from 'react';
import FilterComponent from '../../portal/filter';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import CheckboxComponent from '../../shared/checkbox';
import styles from './Courses.module.scss';

const CoursesModalComponent = ({closeCoursesModal}) => {
  const filterData = [
    {name: 'Recent Activity', id: 5, checked: true},
    {name: 'A - Z', id: 1, checked: false},
    {name: 'Z - A', id: 2, checked: false},
    {name: 'Newest To Oldest', id: 3, checked: false},
    {name: 'Oldest To Newest', id: 4, checked: false},
  ];
  const [search, setSearch] = useState('');
  const [filterCourses, setFilterCourses] = useState(false);

  const handleFilter = (value) => {
    if (value === 'courses') {
      setFilterCourses(!filterCourses);
    }
  };

  const handleFilterCancle = () => {
    setFilterCourses(!filterCourses);
  };

  const handleRowSelected = () => {
    // error
  };
  return (
    <React.Fragment>
      <div className={[styles.modal_wrapper, ''].join(' ')}>
        <div className={styles.courses_modal}>
          <div className={styles.close_wrapper} onClick={() => closeCoursesModal()}>
            <div className={styles.close_icon} />
          </div>
          <div className={styles.header_wrapper}>
            <div className={styles.heading}>Taiwan New ABOs</div>
          </div>
          <div className={styles.top_area_wrapper}>
            <div
              className={[styles.search_main_wrapper, search.length > 3 ? styles.active : ''].join(
                ' ',
              )}
            >
              <div className={styles.search_wrapper}>
                <input
                  type="text"
                  placeholder="Search Courses to add to Audience group"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={styles.search_input}
                />
                <div className={styles.icon_wrapper}>
                  <div className={styles.search} />
                </div>
              </div>
              {search.length > 3 ? (
                <div className={[styles.dropdown_container].join(' ')}>
                  <div className={styles.list_wrapper}>
                    <div className={styles.list}>Nutrilite customers</div>
                    <div className={styles.list}>Selling Nutrilite products</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.sort_by_wrapper}>
              <div className={styles.txt}>Filter Table</div>
              <div
                className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(' ')}
              >
                <div className={styles.filter} />
              </div>
            </div>
            <div className={styles.action_wrapper}>
              <div className={[styles.audience_grp, styles.disabled, 'hide'].join(' ')}>
                <div className={styles.add} />
                <div className={styles.txt}>Add Course to Audience</div>
              </div>
              <div className={[styles.audience_grp].join(' ')}>
                <div className={styles.remove} />
                <div className={styles.txt}>Remove All Selected</div>
              </div>
            </div>
          </div>
          <div className={styles.tbl_wrapper}>
            <div className={styles.tbl_header}>
              <div className={styles.tbl_head}>
                <div className={styles.filter_main_wrapper}>
                  <div
                    className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(
                      ' ',
                    )}
                  >
                    <div className={styles.filter} onClick={() => handleFilter('courses')} />
                  </div>
                  {filterCourses ? (
                    <FilterComponent
                      left="-5px"
                      right="auto"
                      top="42px"
                      isPills={true}
                      headingName="Sort By"
                      filterData={filterData}
                      handleFilterCancle={() => handleFilterCancle()}
                    />
                  ) : null}
                </div>

                <CheckboxComponent checked={false} />

                <div className={styles.txt}>Course name</div>
              </div>
              <div className={styles.tbl_head}>
                <div className={styles.txt}>Status</div>
                <div className={styles.question_icon} />
              </div>
              <div className={styles.tbl_head}>
                <div className={styles.txt}>Remove</div>
              </div>
            </div>
            <div className={styles.tbl_body}>
              <div className={[styles.tbl_row, styles.selected].join(' ')}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={true} onClick={() => handleRowSelected()} />
                  <div className={styles.txt}>The Science Behind The 6 Step Selling Framework</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.live].join(' ')}>
                    <div className={styles.status_txt}>Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={[styles.tbl_row, styles.selected].join(' ')}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={true} />
                  <div className={styles.txt}>Selling Matters For New ABOs</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.draft].join(' ')}>
                    <div className={styles.status_txt}>Draft</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>Goal Setting and Action Planning For Leaders</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_offline].join(' ')}>
                    <div className={styles.status_txt}>Go Offline</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>How To Use Social Selling Assets</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>
                    Too Much or Too Little? Providing Effective Strategy
                  </div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>Successful Selling Conversations</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.btn_wrapper}>
            <button className={styles.btn}>Cancel</button>
            <button className={[styles.btn, styles.disabled].join(' ')}>Save Changes</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CoursesModalComponent.propTypes = {};

export default CoursesModalComponent;
