import React, {useEffect, useState, useRef} from 'react';
import styles from './ManageCourses.module.scss';
import Header from '../../shared/header';
import TopBarComponenent from '../TopBar';
import {dateIsoToNormalDate} from '../../utils/commonUtil';
import {ReactComponent as ImageNew} from '../../../styles/images/sample_img6.svg';
import CheckBoxBlue from '../../shared/checkboxBlue';
import EmptyScreenComponent from '../../shared/emptyScreen';
import RadioButtonsComponent from '../../shared/radioButtons';
import NewRadioButtonsComponent from '../../shared/newRadioButtonsComponent';
import {useHistory} from 'react-router-dom';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import {
  setNotificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
  setCountryList,
} from '../../../redux/actions/uiActions';
import {connect} from 'react-redux';
import findIndex from 'lodash/findIndex';
import Notification from '../../shared/notification';
import {
  fetchAudienceDetailsWithAllCourses,
  fetchAudienceDetails,
  handleClickOutside,
  handleFilterApply,
  getCoursesUIData,
  closeOpenPopUpButton,
  handleCloseButton,
  handleCourseAddRemove,
  handleToggleSwitch,
  handleSaveChanges,
  handleRadioButtomOnClick,
  handleSearchInput,
  fetchCategories,
  mapCoursesToAudience,
  handleCheckClick,
  updateWindowDimensions,
} from '../../utils/AudienceManagementUtils/ManagesCoursesUtils';
import {dateIsoToDate, dateIsoToTime} from '../../utils/commonUtil';
import CardViewManageCourses from './CardViewManageCourses';
import ListViewManageCourses from './ListViewManageCourses';
import CourseTypePopUp from './CourseTypePopUp';

const ManageCoursesModal = ({
  setNotificationData,
  notificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
  currentUserId,
  countryList,
  setCountryList,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const [coursesAPIResponse, setCoursesAPIResponse] = useState([]);
  const [isCardView, setIsCardView] = useState(true);
  const [isListView, setIsListView] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [isPopUpClicked, setPopUpClicked] = useState(false);
  const [showAddedCourses, setShowAddedCourses] = useState(true);
  // API state
  const [coursesUIData, setCoursesUiData] = useState([]);
  const [clikedData, setClikedData] = useState([]);
  const [clikedDataCopy, setClikedDataCopy] = useState([]);
  const [audienceDetails, setAudienceDetails] = useState([]);
  const [courseListDefaultFilter, setCourseListDefaultFilter] = useState([]);

  const [coursesUIDataCopy, setCoursesUiDataCopy] = useState([]);
  const [isRadioChecked, setRadioChecked] = useState(false);

  const [aaa, setAaa] = useState('');
  const [bbb, setBbb] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [totalNumberRemovedCourses, setTotalNumberRemovedCourses] = useState([]);
  const [totalNumberAddedCourses, setTotalNumberAddedCourses] = useState([]);

  const [apiCourseTypeCopy, setApiCourseTypeCopy] = useState([]);
  const [apiCourseType, setApiCourseType] = useState([]);
  const [isCourseTypeChanged, setCourseTypeChanged] = useState(true);

  const [search, setSearch] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [allCourseSearch, setAllCourseSearch] = useState([]);
  const [addedCourseSearch, setAddedCourseSearch] = useState([]);
  const [predictedSearch, setPredictedSearch] = useState([]);
  const [filterAllCoursesData, setFilterAllCoursesData] = useState([]);
  const [filterDropdowncopy, setFilterDropdownopy] = useState(false);
  const [selectedId, setSelectedId] = useState(4);

  const [isChecked, setChecked] = useState(true);
  const [radioButtonData, setRadioButtonData] = useState([]);
  const [countryDDList, setCountryDDList] = useState(
    countryList.map((item) => {
      return {
        ...item,
        name: item.countryName,
        id: item.countryCode,
      };
    }),
  );
  const [countrySelected, setCountrySelected] = useState(countryDDList[0]);

  useEffect(() => {
    let fetchAudId = query?.countryCodeAllIds?.find(
      (country) => country.countryCode === countrySelected.countryCode,
    );
    fetchAudienceDetailsWithAllCourses({
      // query,
      fetchAudId,
      setCoursesUiDataCopy,
      setTotalNumberRemovedCourses,
      setApiCourseTypeCopy,
      setApiCourseType,
      setCoursesAPIResponse,
      setCourseListDefaultFilter,
      setFilterAllCoursesData,
      setSearchFilterEmptyState,
      setSearchList,
      setIsLoading,
    });
    setShowAddedCourses(true);
  }, [countrySelected]);

  useEffect(() => {
    fetchCategories({
      filterData,
      findIndex,
      setFilterData,
    });
    updateWindowDimensions({setWidth});
    window.addEventListener('resize', () => {
      updateWindowDimensions({setWidth});
    });
  }, []);

  useEffect(() => {
    if (coursesAPIResponse && coursesAPIResponse.length && coursesAPIResponse.length > 0) {
      let _coursesUIData = [];
      coursesAPIResponse.map((course) => {
        _coursesUIData.push(getCoursesUIData({course}));
      });
      setCoursesUiData(_coursesUIData);
    }
    setClikedData(
      coursesAPIResponse.filter((el) => {
        return coursesUIDataCopy.some((element) => {
          return element.courseId === el.courseId;
        });
      }),
    );
    setClikedDataCopy(
      coursesAPIResponse.filter((el) => {
        return coursesUIDataCopy.some((element) => {
          return element.courseId === el.courseId;
        });
      }),
    );
  }, [coursesAPIResponse]);

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      (e) => {
        handleClickOutside({e, setPopUpClicked});
      },
      true,
    );

    if (showAddedCourses) {
      // All Courses
      if (search?.length > 0) {
        setCoursesUiData(allCourseSearch);
        setSearchList(allCourseSearch);
      } else {
        if (filterAllCoursesData.length >= 0) {
          setSearchList(filterAllCoursesData);
          setCoursesUiData(filterAllCoursesData);
        } else {
          setSearchList(coursesAPIResponse);
          setCoursesUiData(coursesAPIResponse);
        }
      }
    } else if (!showAddedCourses) {
      // Added courses Only
      if (search?.length > 0) {
        setSearchList(filterAllCoursesData);
        setCoursesUiData(filterAllCoursesData);
      } else {
        setSearchList(clikedData);
        setCoursesUiData(clikedData);
      }
    }
  }, [showAddedCourses, clikedData, search]);

  useEffect(() => {
    setSearchList(filterAllCoursesData);
    setCoursesUiData(filterAllCoursesData);
  }, [filterAllCoursesData]);

  useEffect(() => {
    if (search && search.length && search.length >= 1) {
      let allCourseResult;

      allCourseResult = filterAllCoursesData.filter((user) =>
        user.title.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      allCourseResult?.sort(function (one, other) {
        return one?.title?.length - other?.title?.length;
      });
      //for checking the serach char index in audi group name
      allCourseResult?.sort(function (one, other) {
        return (
          one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.title?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });
      let addedCourseResult;
      addedCourseResult = clikedData?.filter((user) =>
        user.title.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      addedCourseResult?.sort(function (one, other) {
        return one?.title?.length - other?.title?.length;
      });
      //for checking the serach char index in audi group name
      addedCourseResult?.sort(function (one, other) {
        return (
          one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.title?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });

      setAddedCourseSearch(addedCourseResult);
      setAllCourseSearch(allCourseResult);
      showAddedCourses ? setCoursesUiData(allCourseResult) : setCoursesUiData(addedCourseResult);
    }
  }, [search, showAddedCourses, clikedData]);

  useEffect(() => {
    if (audienceDetails?.courses?.length > 0 && clikedDataCopy?.length === 0) {
      setShowAddedCourses(true);
    }
    if (isCardView) {
      setPopUpClicked(true);
    } else if (isListView) {
      setPopUpClicked(true);
    }
  }, [clikedData]);

  const _initialFilterData = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Draft',
        className: 'draft',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Live',
        className: 'goLive',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Offline',
        className: 'goOffline',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 3,
        id: 'ARCHIVE',
        title: 'Archive',
        className: 'archive',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: [],
        },
      },
    ],
  };

  const [filterData, setFilterData] = useState(_initialFilterData);

  const [width, setWidth] = useState(0);

  return (
    <React.Fragment>
      {query?.isManageCourseOpen && (
        <div className={[styles.modal_wrapper].join(' ')}>
          <div className={[styles.course_mgt_wrapper, ''].join(' ')}>
            {/* Header Component */}
            <Header
              headerName={` ${query?.AudienceGrpName}`}
              headerXSName=" "
              btnName1="Cancel"
              btnName2="Save changes"
              isHeaderXS={false}
              isBtn1={true}
              isBtn2={true}
              isProgressBar={false}
              isSideNavigationPresent={false}
              handleButton={() => {
                handleCloseButton({
                  router,
                  totalNumberRemovedCourses,
                  totalNumberAddedCourses,
                  coursesUIDataCopy,
                  query,
                  setNotificationData,
                  audienceDetails,
                  width,
                });
              }}
              handleSaveChanges={() => {
                let fetchAudId = query?.countryCodeAllIds?.find(
                  (country) => country.countryCode === countrySelected.countryCode,
                );
                let countryCodeAllIds = query?.countryCodeAllIds;
                handleSaveChanges({
                  clikedDataCopy,
                  query,
                  fetchAudId,
                  router,
                  setDashboardNotificationCountApi,
                  currentUserId,
                  setNotificationListApi,
                  totalNumberAddedCourses,
                  totalNumberRemovedCourses,
                  coursesUIDataCopy,
                  setNotificationData,
                  audienceDetails,
                  countryCodeAllIds,
                });
              }}
              isBtn2Disable={
                coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 0 &&
                totalNumberAddedCourses?.length === 0 &&
                isCourseTypeChanged === true
              }
              plainTitleWithBold={true}
              planiText={query?.CourseCount != 0 ? 'Manage Courses for' : 'Add Courses to'}
            />
            {/* Header Component */}

            {/* <div className={[styles.audience_wrapper, styles.is_drawer_open].join(" ")}> */}
            <div className={[styles.audience_wrapper, styles.course_inner_wrapper, ''].join(' ')}>
              {/* <SeverErrorComponent /> */}
              <div className={[styles.main_wrapper, ''].join(' ')}>
                <TopBarComponenent
                  showGridListSwitch={true}
                  showRoundToggler={true}
                  isCardView={isCardView}
                  isListView={isListView}
                  showAddedCourses={showAddedCourses}
                  setShowAddedCourses={setShowAddedCourses}
                  setIsCardView={setIsCardView}
                  setIsListView={setIsListView}
                  handleToggleSwitch={() => {
                    handleToggleSwitch({
                      setShowAddedCourses,
                      showAddedCourses,
                      setNotificationData,
                      width,
                    });
                  }}
                  clikedData={clikedData}
                  coursesUIDataCopy={coursesUIDataCopy}
                  placeholderName={'Search Course Name'}
                  list={searchList}
                  searchListFunction={(e) => {
                    if (e != '') {
                      setSearch(e?.target?.value?.trim());
                    } else if (e == '') {
                      setSearch('');
                    }
                  }}
                  setPredictedSearch={setPredictedSearch}
                  predictedSearch={predictedSearch}
                  handleSearchInput={(event) => {
                    handleSearchInput({
                      event,
                      setSearch,
                      setPredictedSearch,
                      coursesUIData,
                    });
                  }}
                  search={search}
                  filterData={filterData}
                  handleFilterApply={(params) => {
                    handleFilterApply({
                      params,
                      setCoursesUiData,
                      courseListDefaultFilter,
                      clikedDataCopy,
                      coursesAPIResponse,
                      filterData,
                      setFilterData,
                      setFilterAllCoursesData,
                      setClikedData,
                    });
                  }}
                  isOpenSubCategories={false}
                  setFilterDropdownopy={setFilterDropdownopy}
                  isManageCourseScreen={true}
                  countryList={countryDDList}
                  setCountrySelected={setCountrySelected}
                  toggleBtnLbl={'Show Only Added Courses'}
                  remoteConfig={query?.remoteConfig}
                />
                {/* Notification bar */}
                {/* <div className={}></div> */}
                {/* END of notification bar */}
                <div className={styles.inner_wrapper}>
                  {/* Card View Started  */}
                  {width >= 767 && isCardView ? (
                    <CardViewManageCourses
                      isLoading={isLoading}
                      selectedCourse={selectedCourse}
                      coursesUIData={coursesUIData}
                      filterDropdowncopy={filterDropdowncopy}
                      aaa={aaa}
                      clikedData={clikedData}
                      closeOpenPopUpButton={closeOpenPopUpButton}
                      isCardView={isCardView}
                      setAaa={setAaa}
                      isPopUpClicked={isPopUpClicked}
                      setPopUpClicked={setPopUpClicked}
                      setBbb={setBbb}
                      isListView={isListView}
                      setSelectedId={setSelectedId}
                      setRadioButtonData={setRadioButtonData}
                      mapCoursesToAudience={mapCoursesToAudience}
                      apiCourseType={apiCourseType}
                      apiCourseTypeCopy={apiCourseTypeCopy}
                      setCourseTypeChanged={setCourseTypeChanged}
                      radioButtonData={radioButtonData}
                      NewRadioButtonsComponent={NewRadioButtonsComponent}
                      selectedId={selectedId}
                      handleRadioButtomOnClick={handleRadioButtomOnClick}
                      setClikedData={setClikedData}
                      coursesUIDataCopy={coursesUIDataCopy}
                      totalNumberRemovedCourses={totalNumberRemovedCourses}
                      setTotalNumberRemovedCourses={setTotalNumberRemovedCourses}
                      totalNumberAddedCourses={totalNumberAddedCourses}
                      setTotalNumberAddedCourses={setTotalNumberAddedCourses}
                      setClikedDataCopy={setClikedDataCopy}
                      clikedDataCopy={clikedDataCopy}
                      coursesAPIResponse={coursesAPIResponse}
                      handleCourseAddRemove={handleCourseAddRemove}
                      EmptyScreenComponent={EmptyScreenComponent}
                      isSearchFilterEmptyState={isSearchFilterEmptyState}
                      dateIsoToNormalDate={dateIsoToNormalDate}
                      CourseTypePopUp={CourseTypePopUp}
                    />
                  ) : (
                    <ListViewManageCourses
                      isLoading={isLoading}
                      coursesUIData={coursesUIData}
                      clikedData={clikedData}
                      totalNumberRemovedCourses={totalNumberRemovedCourses}
                      setTotalNumberRemovedCourses={setTotalNumberRemovedCourses}
                      totalNumberAddedCourses={totalNumberAddedCourses}
                      setTotalNumberAddedCourses={setTotalNumberAddedCourses}
                      setClikedDataCopy={setClikedDataCopy}
                      clikedDataCopy={clikedDataCopy}
                      coursesAPIResponse={coursesAPIResponse}
                      setAaa={setAaa}
                      setBbb={setBbb}
                      setClikedData={setClikedData}
                      coursesUIDataCopy={coursesUIDataCopy}
                      setSelectedId={setSelectedId}
                      setRadioButtonData={setRadioButtonData}
                      handleCourseAddRemove={handleCourseAddRemove}
                      mapCoursesToAudience={mapCoursesToAudience}
                      setChecked={setChecked}
                      isChecked={isChecked}
                      CheckBoxBlue={CheckBoxBlue}
                      isCardView={isCardView}
                      isPopUpClicked={isPopUpClicked}
                      setPopUpClicked={setPopUpClicked}
                      isListView={isListView}
                      apiCourseType={apiCourseType}
                      apiCourseTypeCopy={apiCourseTypeCopy}
                      setCourseTypeChanged={setCourseTypeChanged}
                      handleRadioButtomOnClick={handleRadioButtomOnClick}
                      dateIsoToTime={dateIsoToTime}
                      handleCheckClick={handleCheckClick}
                      dateIsoToDate={dateIsoToDate}
                      closeOpenPopUpButton={closeOpenPopUpButton}
                      bbb={bbb}
                      NewRadioButtonsComponent={NewRadioButtonsComponent}
                      radioButtonData={radioButtonData}
                      EmptyScreenComponent={EmptyScreenComponent}
                      isSearchFilterEmptyState={isSearchFilterEmptyState}
                      selectedId={selectedId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

ManageCoursesModal.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  notificationData: state && state.uiState && state.uiState.notificationData,
  countryList: state && state.uiState && state.uiState.countryList,
});
const mapDispatchToProps = (dispatch) => ({
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setCountryList: (options) => dispatch(setCountryList(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ManageCoursesModal));
