import {colors} from '@material-ui/core';
import React, { useEffect } from 'react';
import SideNavigation from '../components/sideNavigation';
import Dashboard from '../components/portal';
import { useDispatch } from 'react-redux';
import { setBlockNavigation } from '../redux/actions/newCourseActions';
const Dasboard = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBlockNavigation(false));
  }, []);

  return (
    <div className="page_content">
      {/* <SideNavigation pageName="DASHBOARD" /> */}
      <Dashboard />
    </div>
  );
};

// Dasboard.propTypes = {};

export default Dasboard;
