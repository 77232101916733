import React from 'react';
import LoadingShimmerCustom from '../../shared/LoadingShimmerCustom';
import styles from '../Portal.module.scss';
import ViewAllComponent from '../ViewAllComponent';

const MobileCoursesSection = (props) => {
  const {
    mobileTabSelected,
    courseList,
    redirectCourses,
    setSelectedNavigationOption,
    router,
    getMobileViewCourseState,
    dateIsoToNormalDate,
    EmptyScreenComponent,
    isSearchFilterEmptyStateCourses,
    isLoading,
  } = props;
  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(
        <div className={[styles.p_lr_10, 'col-lg-4 col-md-4 pull-left'].join(' ')}>
          <LoadingShimmerCustom className={[styles.mobile_view_course_shimmer_card].join(' ')} />
        </div>,
      );
    }
    return content;
  };
  return (
    <React.Fragment>
      {mobileTabSelected === 'COURSES' ? (
        <div className={[styles.card_view_wrapper, ''].join(' ')}>
          {!isLoading && courseList?.length > 0 ? (
            courseList?.map((course, index) => (
              <div
                key={index}
                className={[styles.p_lr_10, 'col-lg-4 col-md-4 pull-left'].join(' ')}
                onClick={() =>
                  redirectCourses({
                    id: course?.courseId,
                    viewType: 'card',
                    setSelectedNavigationOption,
                    router,
                  })
                }
              >
                <div
                  className={[
                    styles.card,
                    styles[getMobileViewCourseState({state: course?.course_state})],
                  ].join(' ')}
                >
                  <div className={[styles.image_wrapper, styles.empty_state].join(' ')}>
                    {course?.bannerImage ? (
                      <img src={course?.bannerImage} alt={'selected Mob-TAB course'} />
                    ) : (
                      <div className={[styles.img, styles.empty_state_img].join(' ')}></div>
                    )}
                  </div>
                  <div className={styles.details_wrapper}>
                    <div className={styles.bottom_wrapper}>
                      <div className={styles.highlight}>{course?.course_state}</div>
                      {course?.course_state?.toLowerCase() !== 'draft' && course?.launch_date && (
                        <div className={styles.block_wrapper}>
                          <div
                            className={[
                              course?.course_state?.toLowerCase() === 'live'
                                ? styles.calendar
                                : course?.course_state?.toLowerCase() === 'offline'
                                ? styles.offline_cal_icon
                                : styles.archive_cal_icon,
                            ]}
                          />
                          <div className={styles.date}>
                            {dateIsoToNormalDate(course?.launch_date)}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.description}>{course?.title}</div>
                  </div>
                </div>
              </div>
            ))
          ) : !isLoading && isSearchFilterEmptyStateCourses ? (
            <EmptyScreenComponent />
          ) : null}
          {/* Added for mobile view as discussed with Saurabh */}
          <ViewAllComponent
            isLoading={isLoading}
            showEmptyTemplateFor={''}
            list={courseList}
            SelectedNavigationOptionText={'COURSES-MANAGEMENT'}
            pageName="Courses"
          />
          {isLoading && getShimmerList(30)}
        </div>
      ) : null}
    </React.Fragment>
  );
};

MobileCoursesSection.propTypes = {};

export default MobileCoursesSection;
