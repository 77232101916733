import React from 'react';
import styles from '../Content.module.scss';

const HandleAddTagsComponent = (props) => {
  const {
    handleKeywordInput,
    selectedKeyword,
    isKeywordDropdown,
    updatedKeywordSuggestionCopy,
    handleSelectedKeyword,
    handleAddTags,
    keywordPillsData,
    handleDeletePill,
    ImageDetail,
    Pills,
  } = props;
  return (
    <div className={[styles.info_container, styles.mb_45, ' '].join(' ')}>
      <div className={styles.label_wrapper}>
        {/* <div className={styles.label_text}>Add Tags</div> */}
      </div>
      <div className={styles.form_field}>
        <div className={styles.input_wrapper}>
          <div className={[styles.input_area, styles.active].join(' ')}>
            <input
              type={'text'}
              placeholder={'Add keywords and enter'}
              className={[
                styles.input,
                selectedKeyword.title?.length > 0 ? styles.active : '',
                styles.ip2,
              ].join(' ')}
              onChange={(e) => handleKeywordInput(e.target.value)}
              value={selectedKeyword.title}
            />
            {isKeywordDropdown ? (
              <div className={styles.dropdown_container}>
                {updatedKeywordSuggestionCopy?.length > 0 ? (
                  <div className={styles.list_wrapper}>
                    {updatedKeywordSuggestionCopy &&
                      updatedKeywordSuggestionCopy.map((item) => (
                        <div
                          key={item.imageId}
                          className={styles.list}
                          onClick={() => handleSelectedKeyword(item)}
                        >
                          {item.title}
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className={styles.list_wrapper}>
                    <div className={styles.list}>No results found</div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div
            className={[
              styles.add_btn,
              !keywordPillsData?.some(
                (ele) => ele?.title?.toLowerCase() === selectedKeyword?.title?.toLowerCase(),
              ) &&
              updatedKeywordSuggestionCopy?.some(
                (ele) => ele?.title?.toLowerCase() === selectedKeyword?.title?.toLowerCase(),
              ) &&
              selectedKeyword !== '' &&
              selectedKeyword?.id != undefined
                ? styles.selected
                : '',
            ].join(' ')}
            onClick={() => handleAddTags()}
          >
            <span className={styles.plus_sign}></span> Add
          </div>
        </div>
      </div>
      {ImageDetail?.tags?.length > 0 ? (
        <div className={[styles.pills_container, 'col-xl-12 col-lg-12'].join(' ')}>
          <Pills
            // pillData={ImageDetail?.tags ? ImageDetail.tags : []}
            pillData={keywordPillsData}
            isClose={true}
            isBold={false}
            onClosePill={handleDeletePill}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HandleAddTagsComponent;
