import React, {useState, useEffect} from 'react';
import styles from './pageNotFound.module.scss';
import {useHistory} from 'react-router-dom';

const IncorrectUrlComponent = () => {
  const [width, setWidth] = useState(0);
  const router = useHistory();
  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return window.removeEventListener('resize', updateWindowDimensions);
  }, []);
  return (
    // add here class "url_error_wrapper" which should reflect only for url error
    <div className={[styles.empty_container, styles.url_error_wrapper, ''].join(' ')}>
      <div className={[styles.empty_screen_wrapper].join(' ')}>
        <div className={styles.inner_wrapper_empty}>
          <div className={styles.empty_screen}>
            <div
              className={
                width < 768
                  ? [styles.image_wrapper, styles.url_error_img].join(' ')
                  : width > 768 && width < 1024
                  ? [styles.image_wrapper, styles.url_error_img].join(' ')
                  : width >= 1024
                  ? [styles.image_wrapper, styles.empty_img_server_error_large].join(' ')
                  : [styles.image_wrapper, styles.empty_img_server_error].join(' ')
              }
            ></div>
            <div className={styles.content_wrapper}>
              <div className={styles.label}>Sorry, something went wrong</div>
              <div className={styles.content}>
                <div>Looks like there is an error in the URL.</div>
                <div>Please try again.</div>
              </div>
            </div>
            <div className={styles.btn_wrapper} onClick={() => router.push('/Dashboard')}>
              <div className={styles.btn}>Back to Dashboard</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IncorrectUrlComponent.propTypes = {};

export default IncorrectUrlComponent;
