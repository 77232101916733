import React, {useState, useEffect, useRef} from 'react';
import styles from './imageUpload.module.scss';
import {setNotificationData} from '../../../redux/actions/uiActions';
import {connect} from 'react-redux';

const onImageClick = (e) => {
  if (e?.target?.value) {
    e.target.value = '';
  }
};

const referenceImageUpload = (refImageUpload) => {
  if (refImageUpload) {
    refImageUpload.current.click();
  }
};

const onImageChange = (
  event,
  allowedFileType,
  allowedFileSize,
  imageChange,
  setNotificationData,
) => {
  if (
    (event.target.files &&
      event.target.files.length > 0 &&
      event.target.files[0].size >= allowedFileSize) ||
    !allowedFileType?.includes(event.target.files[0].type)
  ) {
    setNotificationData({
      show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
      title: 'Image Uploading Failed',
      description: 'Please upload only .jpg/ .jpeg files of size 3MB max.',
      type: 'FAILURE',
    });
  } else {
    imageChange(event);
  }
};

const ImageUploadComponent = (props) => {
  const {imageChange, parentComponent, setNotificationData} = props;
  const [width, setWidth] = useState(0);
  const [isDragOver, setIsDragOver] = useState(false);
  const allowedFileSize = 3 * 1000 * 1000; //Allowed File Size is 6 MB
  const allowedFileType = ['image/jpeg', 'image/jpg'];
  const refImageDrop = React.useRef(null);
  const refImageUpload = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {files} = e.dataTransfer;
    let event = {};
    event.target = {
      files,
    };
    if (files && files.length) {
      setIsDragOver(false);
      onImageChange(event, allowedFileType, allowedFileSize, imageChange, setNotificationData);
    }
  };
  useEffect(() => {
    //setSelectedNavigationOption("CONTENT-MANAGEMENT");
    if (refImageDrop?.current?.addEventListener) {
      refImageDrop.current.addEventListener('dragover', handleDragEnter);
      refImageDrop.current.addEventListener('drop', handleDrop);
      refImageDrop.current.addEventListener('dragenter', handleDragEnter);
      refImageDrop.current.addEventListener('dragleave', handleDragLeave);
    }

    return () => {
      if (refImageDrop?.current?.removeEventListener) {
        refImageDrop.current.removeEventListener('dragover', handleDragEnter);
        refImageDrop.current.removeEventListener('drop', handleDrop);
        refImageDrop.current.removeEventListener('dragenter', handleDragEnter);
        refImageDrop.current.removeEventListener('dragleave', handleDragLeave);
      }
    };
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  useEffect(() => {
    updateWindowDimensions();

    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  return width > 600 ? (
    <div
      // className={
      //[
      //   styles.pre_browse_wrapper,
      // //  styles.uploading_state,
      // //  styles.div_wrapper_browse_img_browse_text,

      //   "",
      // ].join(" ")}

      className={[
        styles.pre_browse_wrapper,
        isDragOver ? styles.uploading_state : '',
        parentComponent === 'REPLACE_IMAGE_MODAL' && styles.custom_styling,
        '',
      ].join(' ')}
      htmlFor="imageUpload"
      onClick={() => {
        referenceImageUpload(refImageUpload);
      }}
      ref={refImageDrop}
    >
      <div className={styles.div_wrapper_browse_img_browse_text}>
        <input
          id="imageUpload"
          type={'file'}
          className={styles.file_input}
          accept="image/jpeg, image/jpg"
          ref={refImageUpload}
          onChange={(e) => {
            onImageChange(e, allowedFileType, allowedFileSize, imageChange, setNotificationData);
          }}
          onClick={onImageClick}
        />
        <div className={styles.browse_img}></div>

        <div className={styles.browse_text}>Choose Image from Local Device</div>
      </div>
      <div className={styles.text}>or</div>
      <div className={styles.browse_text_secondary}>Drag & Drop Image from Local Device</div>
    </div>
  ) : (
    <div
      // className={[
      //   styles.pre_browse_wrapper,
      //   styles.uploading_state,
      //   parentComponent === "REPLACE_IMAGE_MODAL" && styles.custom_styling,
      //   "",
      // ].join(" ")}
      className={[
        styles.pre_browse_wrapper,
        isDragOver ? styles.uploading_state : '',
        parentComponent === 'REPLACE_IMAGE_MODAL' && styles.custom_styling,
        '',
      ].join(' ')}
      onClick={() => {
        referenceImageUpload(refImageUpload);
      }}
      ref={refImageDrop}
    >
      <input
        id="imageUpload"
        type={'file'}
        className={styles.file_input}
        accept="image/png, image/gif, image/jpeg, image/svg+xml"
        ref={refImageUpload}
        onChange={(e) => {
          onImageChange(e, allowedFileType, allowedFileSize, imageChange, setNotificationData);
        }}
        onClick={onImageClick}
      />
      <div className={styles.browse_img}></div>

      <div className={styles.browse_text}>Upload New Image from Mobile Device</div>
    </div>
  );
};

ImageUploadComponent.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  setNotificationData: (options) => dispatch(setNotificationData(options)),
});

export default React.memo(connect(null, mapDispatchToProps)(ImageUploadComponent));
