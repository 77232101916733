export const ListArraySubheader = [
  {
    title: 'Course Information',
    className: 'home_icon',
    subHeaderClick: true,
  },
  {
    title: 'Translation',
    className: 'translation_icon',
    subHeaderClick: false,
  },
  {
    title: 'User Group Targeting',
    className: 'user_grp_icon',
    subHeaderClick: false,
  },
  {
    title: 'Categories, Skills, Tags',
    className: 'tag_icon',
    subHeaderClick: false,
  },
];
export const regexNameChinese =
/[^\u0900-\u097F\u00C0-\u00FF\u00C0-\u00FF\u3131-\uD79D\u4e00-\u9fa50-9a-zA-Z_àáãạảăắằẳẵặâấầẩẫàậấèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợọóửùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ.。:\-.,'"$%^&|*()!?;`~#+-_ ?？:：!！\"”“'‘’ -]+/g;

export const regexNameHindi = /[^\u0900-\u097F\u00C0-\u00FF\u00C0-\u00FF\u3131-\uD79D\u4e00-\u9fa50-9a-zA-Z_àáãạảăắằẳẵặâấầẩẫàậấèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợọóửùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ.。:\-.,'"$%^&|*()!?;`~#+-_ ?？:：!！\"”“'‘’ -]+/g;
