import React, {useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import ManageCoursesModal from '../components/audienceManagement/ManageCoursesModal';

const AudienceManageCourses = () => {
  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          <ManageCoursesModal />
        </main>
      </div>
    </div>
  );
};

AudienceManageCourses.propTypes = {};

export default AudienceManageCourses;
