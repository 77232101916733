import {
  SET_NEW_LP_WIZARD,
  SET_NEW_LP_DATA,
  SET_NEW_COURSE_ADDED,
  SET_NEW_UGM_ADDED_DELETE,
  SET_NEW_LP_DATA_CST,
} from '../actions/learningPathActions';

const initialState = {
  newLPData: null,
  addedCourseListState: [],
  newLPWizardState: {WizardOpen: false, WizardStateOpen: ''},
  newUGMListState: null,
  newLPDataCST: null,
};

export const newLPReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_LP_DATA: {
      return {...state, newLPData: action.payload};
    }
    case SET_NEW_COURSE_ADDED: {
      return {...state, addedCourseListState: action.payload};
    }
    case SET_NEW_LP_WIZARD: {
      return {...state, newLPWizardState: action.payload};
    }
    case SET_NEW_UGM_ADDED_DELETE: {
      return {...state, newUGMListState: action.payload};
    }
    case SET_NEW_LP_DATA_CST: {
      return {...state, newLPDataCST: action.payload};
    }

    default: {
      return state;
    }
  }
};
