import React, {useEffect, useRef, useState} from 'react';
import styles from './DropDown.module.scss';

const DropDown = (props) => {
  const {list, selectedItem = '', setCountrySelected} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selectedItem || list[0]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleDD = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    setCountrySelected(value);
  };

  return (
    <div className={styles.drop_down} ref={dropdownRef}>
      <div className={styles.selected_arrow_item} onClick={handleToggleDD}>
        <div className={styles.selected_item}>{selectedValue?.name}</div>
        <div className={styles.arrow_icon}></div>
      </div>
      {isOpen && (
        <div className={styles.list}>
          {list.map((element, index) => (
            <div
              key={index}
              className={
                selectedValue.name === element.name ? styles.list_item_selected : styles.list_item
              }
              onClick={() => handleOptionClick(element)}
            >
              {element.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
