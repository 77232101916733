export const SET_NEW_LP_WIZARD = 'SET_NEW_LP_WIZARD';
export const SET_NEW_LP_DATA = 'SET_NEW_LP_DATA';
export const SET_NEW_LP_DATA_CST = 'SET_NEW_LP_DATA_CST';
export const SET_NEW_COURSE_ADDED = 'SET_NEW_COURSE_ADDED';
export const SET_NEW_UGM_ADDED_DELETE = 'SET_NEW_UGM_ADDED_DELETE';

export const setNewLPWizardState = (options) => {
  return {
    type: SET_NEW_LP_WIZARD,
    payload: options,
  };
};
export const setNewLPData = (options) => {
  return {
    type: SET_NEW_LP_DATA,
    payload: options,
  };
};
export const setNewLPDataCST = (options) => {
  return {
    type: SET_NEW_LP_DATA_CST,
    payload: options,
  };
};
export const setNewCourseAddedList = (options) => {
  return {
    type: SET_NEW_COURSE_ADDED,
    payload: options,
  };
};
export const setNewAddDeleteUGMList = (options) => {
  return {
    type: SET_NEW_UGM_ADDED_DELETE,
    payload: options,
  };
};
