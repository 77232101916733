import {countryMapping} from './countryMapping';

const countryConfiguration = countryMapping();

export const marketEnvConfig = () => {
  const origin = window.location.origin;
  const hostname = window.location.hostname;
  // const origin = 'https://admin.academy.amway.com.in';
  // const hostname = 'admin.academy.amway.com';
  let configData = '';
  let environment = '';
  let locale = '';
  let BaseData = {};

  if (hostname !== 'localhost') {
    configData = extractCountryCode(origin);

    locale = configData?.locale;

    BaseData = {
      name: configData?.name,
      code: configData?.code,
      primaryLanguage: configData?.primaryLanguage,
      locale: configData?.locale,
    };
    localStorage.setItem('configData', JSON.stringify(BaseData));
  } else {
    let localhostURL = `REACT_APP_${process.env.REACT_APP_CURRENT_REGION}_OIDC_DEV_REDIRECT_URI`;
    configData = extractCountryCode(process.env[localhostURL].slice(0, -10));
    locale = configData?.locale;

    BaseData = {
      name: configData?.name,
      code: configData?.code,
      primaryLanguage: configData?.primaryLanguage,
      locale: configData?.locale,
    };
    localStorage.setItem('configData', JSON.stringify(BaseData));
  }

  if (hostname !== 'localhost') {
    environment = extractEnvironment(origin, configData);
  } else {
    environment = process.env.REACT_APP_CURRENT_ENV;
  }

  const config = {
    ...configData,
    locale: locale,
    environment: environment,
  };
  return config;
};

/**
 * This function is used to return current country code
 */
function extractCountryCode(url) {
  // const configData = countryConfiguration.find((country) => url.includes(country?.code));
  const configData = countryConfiguration.find((country) => country?.urls.includes(url));
  if (configData) {
    return configData;
  }
}

/**
 * This function is used to return current Environment
 */
function extractEnvironment(url, configData) {
  const updatedUrl = url + '/dashboard';
  const redirect_uris = configData?.REDIRECT_URI;

  for (url in redirect_uris) {
    if (redirect_uris[url] === updatedUrl) {
      return url;
    }
  }
  console.warn('RedirectURI not found');
  return `dev`;
  // const regex = /(dev|qa|admin.academy.amway.com)/;
  // const matches = url.match(regex);

  // if (matches && matches.length > 1) {
  //   return matches[1] !== 'dev' && matches[1] !== 'qa' ? 'prod' : matches[1];
  // }
}
