import React from 'react';
import styles from '../Content.module.scss';
import {handleDate} from '../../utils/ContentManagementUtils/ContentManagementLanding';
import LoadingShimmerList from '../../shared/LoadingShimmerList';

const ImageListView = (props) => {
  const {
    ImageList,
    imageId,
    handleImageClick,
    //handleDate,
    isSearchFilterEmptyState,
    EmptyScreenComponent,
    isDrawer,
    newCourseWizardState,
    learningPathMode,
    setImageThumbnailButtonClick,
    setImageId,
    setMainImg,
    setSelectedKeyword,
    setMainImageBackup,
    setFileError,
    setKeywordDropdown,
    isLoading,
  } = props;

  return (
    <div className={styles.table_main_wrapper}>
      <div className={styles.table_container}>
        <div className={styles.tbl_header}>
          <div className={styles.tbl_head}>Image</div>
          <div className={styles.tbl_head_wrapper}>
            <div className={styles.tbl_head}>File Name</div>
            <div className={styles.tbl_head}>Uploaded By</div>
            <div className={styles.tbl_head}>Upload date</div>
            <div className={styles.tbl_head}>Instances</div>
          </div>
        </div>
        <div className={styles.tbl_body}>
          <div className={[styles.tbl_row_wrapper].join(' ')}>
            {ImageList && ImageList.length > 0 && !isLoading ? (
              ImageList.map((item) => (
                <div
                  key={item.imageId}
                  className={[
                    styles.tbl_row,
                    item.imageId === imageId &&
                    (learningPathMode === 'FROM_LEARNING_PATH' || isDrawer) &&
                    !newCourseWizardState?.courseWizardOpen
                      ? styles.selected
                      : '',
                  ].join(' ')}
                  onClick={() => {
                    handleImageClick({
                      item,
                      setImageThumbnailButtonClick,
                      setImageId,
                      setMainImg,
                      setSelectedKeyword,
                      setMainImageBackup,
                      setFileError,
                      setKeywordDropdown,
                    });
                  }}
                >
                  <div className={styles.tbl_data}>
                    <div className={styles.inner_data}>
                      <img src={item?.bannerImage ? item?.bannerImage : ''} alt="list view" />
                    </div>
                  </div>

                  <div className={styles.tbl_data_wrapper}>
                    <div className={styles.tbl_data}>
                      <div className={styles.inner_data}>
                        <div className={[styles.txt].join(' ')}>{item?.fileName}</div>
                      </div>
                    </div>

                    <div className={styles.tbl_data}>
                      <div className={styles.inner_data}>
                        <div className={[styles.txt, styles.mob_font].join(' ')}>
                          {item.uploadedBy}
                        </div>
                      </div>
                    </div>

                    <div className={styles.tbl_data}>
                      <div className={styles.inner_data}>
                        <div className={[styles.txt, styles.mob_font].join(' ')}>
                          {handleDate({date: item?.uploadDate})}
                        </div>
                      </div>
                    </div>

                    <div className={styles.tbl_data}>
                      <div className={styles.inner_data}>
                        <div className={styles.courses_icon} />

                        <div className={[styles.txt].join(' ')}>
                          {item?.instancesCount > 0 ? item?.instancesCount : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : isSearchFilterEmptyState && !isLoading ? (
              <EmptyScreenComponent />
            ) : isLoading ? (
              <div>
                <LoadingShimmerList count={20} className={[styles.image_lib_shimmer].join(' ')} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageListView;
