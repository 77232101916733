import {useState} from 'react';
import {makeRequest} from '../APIsUtils/httpsUtils';
export const fetchCategorySkillsTags = async (props) => {
  const configData = JSON.parse(localStorage.getItem('configData'));

  const {
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
  } = props;

  const data = await makeRequest({
    method: 'GET',
    endpoint: `/admin/api/v1/csts?locale=${configData?.locale}`,
  });
  if (data != 'ERR_NETWORK' && data) {
    setCategorgyList(data?.data);
    setCategorgyListCopy(data?.data);
    setSearchFilterEmptyState(true);
  } else if (data == 'ERR_NETWORK' && isOnline) {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const fetchCategorySkillsTagsListWithParams = async (props) => {
  const {
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setCategorgyList,
    setCategorgyListCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
  } = props;

  let endpoint = '/admin/api/v1/csts';
  if (sortBy) {
    endpoint += `?sortBy=${sortBy?.toLowerCase()}`;
  }
  const dataCourses = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (dataCourses != 'ERR_NETWORK' && dataCourses) {
    let newDataCourses = [];
    newDataCourses = dataCourses?.data;
    let tilesLables = [];

    if (tilesFilterArray !== []) {
      for (const ele of tilesFilterArray) {
        if (ele?.checked) {
          tilesLables.push(
            ele?.title != 'Categories' ? ele?.title.slice(0, -1)?.toLowerCase() : 'category',
          );
        }
      }

      if (tilesLables?.length > 0) {
        newDataCourses = dataCourses?.data.filter((ele) => {
          return tilesLables?.includes(ele?.type?.toLowerCase());
        });
      }
    }
    setCategorgyList(newDataCourses);
    setCategorgyListCopy(newDataCourses);
    setSearchFilterEmptyState(true);
  } else if (dataCourses == 'ERR_NETWORK') {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const getTitleByLocale = (multiLingualDetails) => {
  // console.log("multiLingualDetails --- ", multiLingualDetails)
  //Returns a object where each locale is mapped with it equivalent title
  if (!Array.isArray(multiLingualDetails)) return {};
  return multiLingualDetails.reduce((acc, item) => {
    acc[item.locale] = item.title;
    return acc;
  }, {});
};

export const fetchCategorySkillsTagsDetails = async (props) => {
  const {
    CSTId,
    makeRequest,
    setCategorySkillsTagsDetails,
    setDrawer,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    type,
    setTranslationNames,
    fromRefreshList = false,
    variant = '',
    setCoursesDrawerList,
    setLearningPathsDrawerList,
    setTranslationNamesIntialCopy,
    learningPathsDrawerList,
    coursesDrawerList,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    language
  } = props;

  
  const endpoint = `/admin/api/v1/csts/${CSTId}?type=${type}`;
  let audienceDetailsData = await makeRequest({
    method: 'GET',
    endpoint,
    });

  let result = [];
  
  if (audienceDetailsData != 'ERR_NETWORK' && audienceDetailsData && fromRefreshList === false) {
    setCategorySkillsTagsDetails(audienceDetailsData?.data);
    setDrawerAPIFailed(false);
    setDrawer(true);

    language?.otherLocales?.map((item) => {
      audienceDetailsData?.data?.multilingualDetails?.map((val) => {
        if (item?.locale === val?.locale) {
          result.push({title: val?.title?.trim(), locale: item?.locale, language: item?.title?.toLowerCase()?.replace(/ .*/, '')});
        }
      });
    });

    result.push({
      title: audienceDetailsData?.data?.englishTranslation,
      locale: language?.marketDefaultLocale?.locale,
      language: language?.marketDefaultLocale?.title?.toLowerCase()?.replace(/ .*/, ''),
    })

    result?.map((item, key) => {
      setTranslationNames((prevState) => ({
        ...prevState,
        [`${item?.language}_Translation`]: item?.title
      }))
    })
    
    result?.map((item, key) => {
      setTranslationNamesIntialCopy((prevState) => ({
        ...prevState,
        [`${item?.language}_Translation`]: item?.title
      }))
    })

    setCoursesDrawerList(audienceDetailsData?.data?.courses);
    setLearningPathsDrawerList(audienceDetailsData?.data?.learningPaths);

  } else if (
    audienceDetailsData != 'ERR_NETWORK' &&
    audienceDetailsData &&
    fromRefreshList === true
  ) {
    if (variant === 'courses') {
      if (audienceDetailsData?.data?.courses?.length !== coursesDrawerList?.length) {
        fetchCategorySkillsTags({
          setCategorgyList,
          setSearchFilterEmptyState,
          setCategorgyListCopy,
          setErrorStateTemplate,
          isOnline,
        });
      }
      setCoursesDrawerList(audienceDetailsData?.data?.courses);
    } else {
      if (audienceDetailsData?.data?.learningPaths?.length !== learningPathsDrawerList?.length) {
        fetchCategorySkillsTags({
          setCategorgyList,
          setSearchFilterEmptyState,
          setCategorgyListCopy,
          setErrorStateTemplate,
          isOnline,
        });
      }
      setLearningPathsDrawerList(audienceDetailsData?.data?.learningPaths);
    }
  } else if (audienceDetailsData == 'ERR_NETWORK') {
    if (CSTId != undefined && CSTId !== drawerIdForBrokenLink) {
      setDrawerIdForBrokenLink(CSTId);
    }
    setDrawer(true);
    setDrawerAPIFailed(true);
  }
};

export const handleSearchInput = (props) => {
  const {event, setSearch, setPredictedSearch, categorgyList} = props;
  setSearch(event?.target?.value);
  setPredictedSearch([]);

  let tempPredition = categorgyList?.map((item) => ({
    id: item?.id && item?.id !== null && item.id !== undefined ? item?.id : '',
    label:
      item?.englishTranslation &&
      item?.englishTranslation !== null &&
      item.englishTranslation !== undefined
        ? item?.englishTranslation
        : '',
  }));
  // let tempPredition2 = categorgyList?.map((item) => ({
  //   id: item?.id && item?.id !== null && item.id !== undefined ? item?.id : '',
  //   label:
  //     item?.englishTranslation &&
  //     item?.englishTranslation !== null &&
  //     item.englishTranslation !== undefined
  //       ? item?.englishTranslation
  //       : '',
  // }));

  // let a = tempPredition.concat(tempPredition2);

  if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
    setPredictedSearch(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearch([]);
  }
};

export const handleFilterApplyNew = async (props) => {
  const {
    params,
    filterData,
    makeRequest,
    setFilterData,
    setFilterDataTemp,
    setCategorgyList,
    setCategorgyListCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    setIsLoading,
  } = props;
  let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
  const categoryId = [];
  let categoriesArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');

  for (const ele of categoriesArray) {
    ele.checked && categoryId.push(ele.id);
  }
  let bodyVar = {
    categoryId,
  };
  let sortBy = '';
  let sortByName = 'Recent Activity';
  let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');
  radioFilterArray.forEach((ele) => {
    switch (ele?.name) {
      case 'Recent Activity':
        (sortBy = 'Recent_Activity'), (sortByName = 'Recent Activity');
        break;
      case 'A - Z':
        (sortBy = 'A_Z'), (sortByName = 'A - Z');
        break;
      case 'Z - A':
        (sortBy = 'Z_A'), (sortByName = 'Z - A');
        break;
      case 'Newest To Oldest':
        (sortBy = 'Newest_To_Oldest'), (sortByName = 'Newest To Oldest');
        break;
      case 'Oldest To Newest':
        (sortBy = 'Oldest_To_Newest'), (sortByName = 'Oldest To Newest');
        break;
      default:
        sortBy = 'Recent_Activity';
        break;
    }
  });
  let sortByData = filterData.filtersList[0].filterTypeData.sectionData;
  sortByData.forEach((data) => {
    if (data?.name == sortByName) {
      data.checked = true;
    } else {
      data.checked = false;
    }
  });
  const _filterData = {
    tiles: tilesFilterArray,
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: sortByData,
        },
      },
    ],
  };
  setFilterData(_filterData);
  setFilterDataTemp(new Object(_filterData));
  await fetchCategorySkillsTagsListWithParams({
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setCategorgyList,
    setCategorgyListCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
};

export const closeDrawerModal = async (props) => {
  const {
    englishTranslationLength,
    chineseTranslationLength,
    drawerClose,
    drawerOpen,
    drawerDetails,
    setEnglishTranslationEmpty,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    handleCSTTranslationChange,
    translationNamesIntialCopy,
    translationNames,
    setEnglishTranslationDuplicetName,
    englishTranslationDuplicetName,
    setNotificationData,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    filterData,
    nameErrorStateEnglish,
    isSave,
    allLanguage,
  } = props;
  let sortBy = '';
  filterData?.filtersList[0]?.filterTypeData?.sectionData.find((item) => {
    if (item?.checked === true) {
      sortBy = item?.name;
    }
  });
  switch (sortBy) {
    case 'Recent Activity':
      sortBy = 'Recent_Activity';
      break;
    case 'A - Z':
      sortBy = 'A_Z';
      break;
    case 'Z - A':
      sortBy = 'Z_A';
      break;
    case 'Newest To Oldest':
      sortBy = 'Newest_To_Oldest';
      break;
    case 'Oldest To Newest':
      sortBy = 'Oldest_To_Newest';
      break;
    default:
      sortBy = 'Recent_Activity';
      break;
  }

  drawerClose(!drawerOpen);
  if (
    translationNames &&
    translationNames !== undefined &&
    Object.keys(translationNames).every((k) =>
      translationNames[k].length > 0 || translationNames[k].length <= 50 ? true : false,
    )
  ) {
    if (
      JSON.stringify(translationNames) !== JSON.stringify(translationNamesIntialCopy)
    ) {
      if (isSave) {
        await handleCSTTranslationChange({
          type: drawerDetails?.type,
          CSTId: drawerDetails?.id,
          translationNames,
          setNotificationData,
          translationNamesIntialCopy,
          setDashboardNotificationCountApi,
          setNotificationListApi,
          allLanguage,
        });
        fetchCategorySkillsTagsListWithParams({
          sortBy,
          tilesFilterArray: filterData?.tiles,
          makeRequest,
          setCategorgyList,
          setCategorgyListCopy,
          setSearchFilterEmptyState,
          setErrorStateTemplate,
        });
      }

      // await fetchCategorySkillsTags({
      //   setCategorgyList,
      //   setSearchFilterEmptyState,
      //   setCategorgyListCopy,
      //   setErrorStateTemplate,
      //   isOnline,
      // });
    }
  }
  if (englishTranslationLength === 0) {
    setEnglishTranslationEmpty(true);
  }
};

export const handleErrorStatesEnglish = (props) => {
  const {
    e,
    setNameErrorStateEnglish,
    setMaxCharErrorMessageEnglish,
    list,
    drawerDetails,
    setEnglishTranslationDuplicetName,
    setEnglishTranslationInvalidCharacter,
    setIsSaveBtnDisable,
  } = props;

  let errorString = makeAnagram({
    a: e,
    b: e?.toString(),
  });
  setEnglishTranslationInvalidCharacter(errorString);

  let resultCategory = [];
  let resultSill = [];
  let resultTag = [];
  if (e?.length > 50) {
    setIsSaveBtnDisable(true);
    setMaxCharErrorMessageEnglish(true);
  } else {
    setMaxCharErrorMessageEnglish(false);
  }

  list?.find((item) => {
    if (
      item?.type?.toLowerCase() === 'category' &&
      drawerDetails?.type?.toLowerCase() === 'category'
    ) {
      resultCategory?.push(item);
    } else if (
      item?.type?.toLowerCase() === 'skill' &&
      drawerDetails?.type?.toLowerCase() === 'skill'
    ) {
      resultSill?.push(item);
    } else if (
      item?.type?.toLowerCase() === 'tag' &&
      drawerDetails?.type?.toLowerCase() === 'tag'
    ) {
      resultTag?.push(item);
    }
  });
  if (drawerDetails?.type?.toLowerCase() === 'category') {
    if (
      resultCategory?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase()?.trim() == e?.toLowerCase()?.trim();
      }) &&
      drawerDetails?.englishTranslation?.toLowerCase()?.trim() != e?.toLowerCase()?.trim()
    ) {
      setIsSaveBtnDisable(true);
      setEnglishTranslationDuplicetName(true);
    } else {
      setEnglishTranslationDuplicetName(false);
    }
  } else if (drawerDetails?.type?.toLowerCase() === 'skill') {
    if (
      resultSill?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase()?.trim() == e?.toLowerCase()?.trim();
      }) &&
      drawerDetails?.englishTranslation?.toLowerCase()?.trim() != e?.toLowerCase()?.trim()
    ) {
      setIsSaveBtnDisable(true);
      setEnglishTranslationDuplicetName(true);
    } else {
      setEnglishTranslationDuplicetName(false);
    }
  } else if (drawerDetails?.type?.toLowerCase() === 'tag') {
    if (
      resultTag?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase()?.trim() == e?.toLowerCase()?.trim();
      }) &&
      drawerDetails?.englishTranslation?.toLowerCase()?.trim() != e?.toLowerCase()?.trim()
    ) {
      setIsSaveBtnDisable(true);
      setEnglishTranslationDuplicetName(true);
    } else {
      setEnglishTranslationDuplicetName(false);
    }
  }
};

export const handleErrorStatesSpanish = (props) => {
  const {
    e,
    setMaxCharErrorMessageSpanish,
    setNameErrorStateSpanish,
    setSpanishTranslationInvalidCharacter,
  } = props;

  let errorString = makeAnagram({
    a: e?.target?.value,
    b: e?.target?.value?.toString(),
  });
  setSpanishTranslationInvalidCharacter(errorString);
  if (e?.target?.value?.length > 50) {
    setMaxCharErrorMessageSpanish(true);
  } else {
    setMaxCharErrorMessageSpanish(false);
  }
  if (e?.target?.value?.toString()) {
    setNameErrorStateSpanish(true);
  } else {
    setNameErrorStateSpanish(false);
  }
};

// handleErrorStatesSpanish

export const handleOnClickEditCourse = (props) => {
  const {id = 1, state = ''} = props;
  const url = '/create-course-wizard';

  let subheader =
    state == 'Draft' ? 'course_description' : state == 'Live' || state == 'Offline' ? 'edit' : '';
  window.open(url + '?id=' + id + '&' + 'sub_header=' + subheader, '_blank', 'noopener,noreferrer');
};

export const handleOnClickEditLearningPath = (props) => {
  const {id = '', state = ''} = props;

  const url = '/newLearningPath';
  let subheader =
    state == 'Draft'
      ? 'learning_path_description'
      : state == 'Live' || state == 'Offline'
      ? 'categories_skills_tags'
      : '';
  window.open(url + '?id=' + id + '&' + 'sub_header=' + subheader, '_blank', 'noopener,noreferrer');
};

export const handleCloseCST = (props) => {
  const {setDelete, isDelete} = props;

  setDelete(!isDelete);
};

export const handleCSTTranslationChange = async (props) => {
  const {
    type = '',
    CSTId = '',
    translationNames,
    setNotificationData,
    translationNamesIntialCopy,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    allLanguage,
  } = props;

  let payload = [];
  payload = allLanguage?.map((item, key) => {
    if ((item?.title?.toLowerCase()?.replace(/ .*/, '') + '_Translation') === Object.keys(translationNames)[key]) {
      return {
        title: Object.values(translationNames)[key],
        locale: item?.locale
      }
    }
  })

  // let change = Object.entries(translationNames).map(([key, title]) => ({
  //   title,
  //   locale: key.toLocaleLowerCase() === 'english_translation' ? 'en-us' : 'en-vn', //since vietnam is the primary language and is stored inside english Translation
  // }));
  // console.log('Changhe>>>', change);

  const endpoint = `/admin/api/v1/csts/${CSTId}?type=${type}`;
  let data = await makeRequest({
    method: 'PUT',
    endpoint,
    body: payload,
  });
  if (data != 'ERR_NETWORK' && data != 'ERR_BAD_REQUEST' && data) {
    setNotificationData({
      show: true,
      type: 'SUCCESS',
      title: `${type} Edited Successfully`,
      description: `Translation edited.`,
    });
    setNotificationListApi();
    setDashboardNotificationCountApi();
  }
};

export const refreshList = async (props) => {
  const {
    variant = '',
    drawerId = '',
    type = '',
    setLoadingCoursesList,
    setLoadingLearningPathList,
    setCategorySkillsTagsDetails,
    setDrawer,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setTranslationNames,
    setCoursesDrawerList,
    setLearningPathsDrawerList,
    setTranslationNamesIntialCopy,
    learningPathsDrawerList,
    coursesDrawerList,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    language,
  } = props;

  if (variant === 'courses') {
    setLoadingCoursesList(true);
  } else if (variant === 'learningPaths') {
    setLoadingLearningPathList(true);
  }
  await fetchCategorySkillsTagsDetails({
    CSTId: drawerId,
    makeRequest,
    setCategorySkillsTagsDetails,
    setDrawer,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setTranslationNames,
    type,
    fromRefreshList: true,
    variant: variant,
    setCoursesDrawerList,
    setLearningPathsDrawerList,
    setTranslationNamesIntialCopy,
    learningPathsDrawerList,
    coursesDrawerList,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    language,
  });
  if (variant === 'courses') {
    setLoadingCoursesList(false);
  } else if (variant === 'learningPaths') {
    setLoadingLearningPathList(false);
  }
};

export const handleDeleteCST = async (props) => {
  const {
    categorySkillsTagsDetails,
    setDrawer,
    setNotificationData,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    setNotificationListApi,
    setDashboardNotificationCountApi,
    filterData,
  } = props;
  let sortBy = '';
  filterData?.filtersList[0]?.filterTypeData?.sectionData.find((item) => {
    if (item?.checked === true) {
      sortBy = item?.name;
    }
  });
  switch (sortBy) {
    case 'Recent Activity':
      sortBy = 'Recent_Activity';
      break;
    case 'A - Z':
      sortBy = 'A_Z';
      break;
    case 'Z - A':
      sortBy = 'Z_A';
      break;
    case 'Newest To Oldest':
      sortBy = 'Newest_To_Oldest';
      break;
    case 'Oldest To Newest':
      sortBy = 'Oldest_To_Newest';
      break;
    default:
      sortBy = 'Recent_Activity';
      break;
  }
  let endpoint = `/admin/api/v1/csts/${categorySkillsTagsDetails?.id}?type=${categorySkillsTagsDetails?.type}`;
  let dataDeleteLearningPath = await makeRequest({
    method: 'DELETE',
    endpoint: endpoint,
  });
  if (
    dataDeleteLearningPath != 'ERR_NETWORK' &&
    dataDeleteLearningPath != 'ERR_BAD_REQUEST' &&
    dataDeleteLearningPath
  ) {
    setDrawer(false);
    setNotificationData({
      show: true,
      type: 'SUCCESS',
      title: `${categorySkillsTagsDetails?.type} deleted Successfully`,
      description: `${categorySkillsTagsDetails?.englishTranslation} deleted.`,
    });
    // fetchCategorySkillsTags({
    //   setCategorgyList,
    //   setSearchFilterEmptyState,
    //   setCategorgyListCopy,
    //   setErrorStateTemplate,
    //   isOnline,
    // });
    fetchCategorySkillsTagsListWithParams({
      sortBy,
      tilesFilterArray: filterData?.tiles,
      makeRequest,
      setCategorgyList,
      setCategorgyListCopy,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
    });
    setNotificationListApi();
    setDashboardNotificationCountApi();
  }
};

export const selectedCategorySkillsTags = async (props) => {
  const {
    e,
    item,
    isDrawer,
    categorySkillsTagsDetails,
    setDrawer,
    setCategorySkillsTagsDetails,
    makeRequest,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    type,
    setTranslationNames,
    setCoursesDrawerList,
    setLearningPathsDrawerList,
    englishTranslationDuplicetName,
    setSelectedCST,
    translationNames,
    translationNamesIntialCopy,
    setEnglishTranslationEmpty,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    setEnglishTranslationDuplicetName,
    setNotificationData,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    regexNameHindi,
    setTranslationNamesIntialCopy,
    learningPathsDrawerList,
    coursesDrawerList,
    filterData,
    nameErrorStateChinese,
    nameErrorStateEnglish,
    isEditDrawer,
    setIsEditDrawer,
    language,
    setCreateMaxCharErrorMessageSecondary,
  } = props;
  setIsEditDrawer(true);
  setSelectedCST(type);
  setCreateMaxCharErrorMessageSecondary([]);
  let translationLength = false;
  translationLength = language?.otherLocales?.map((item, key) => {
    return Object.keys(translationNames)[key]?.length !== 0 ? true : false
  })

  if (
    isDrawer &&
    e.currentTarget.id === categorySkillsTagsDetails?.id &&
    translationLength &&
    englishTranslationDuplicetName === false &&
    nameErrorStateEnglish === false &&
    nameErrorStateChinese === false
  ) {
    setDrawer(false);
    setCategorySkillsTagsDetails(null);
    if (
      JSON.stringify(translationNames) !== JSON.stringify(translationNamesIntialCopy)
    ) {
      closeDrawerModal({
        englishTranslationLength: translationNames?.english_Translation?.length,
        chineseTranslationLength: translationNames?.chinese_Translation?.length,
        drawerClose: setDrawer,
        drawerOpen: isDrawer,
        drawerDetails: categorySkillsTagsDetails,
        setEnglishTranslationEmpty: setEnglishTranslationEmpty,
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
        handleCSTTranslationChange,
        translationNamesIntialCopy,
        translationNames,
        setEnglishTranslationDuplicetName,
        englishTranslationDuplicetName,
        setNotificationData,
        setDashboardNotificationCountApi,
        setNotificationListApi,
        filterData,
        nameErrorStateChinese,
        nameErrorStateEnglish,
        language
      });
    }
  } else if (
    (isDrawer && englishTranslationDuplicetName === true) ||
    nameErrorStateChinese === true ||
    nameErrorStateEnglish === true
  ) {
    closeDrawerModal({
      englishTranslationLength: translationNames?.english_Translation
        ?.toString()
        ?.length,
      chineseTranslationLength: translationNames?.chinese_Translation
        ?.toString()
        ?.length,
      drawerClose: setDrawer,
      drawerOpen: isDrawer,
      drawerDetails: categorySkillsTagsDetails,
      setEnglishTranslationEmpty: setEnglishTranslationEmpty,
      setCategorgyList,
      setSearchFilterEmptyState,
      setCategorgyListCopy,
      setErrorStateTemplate,
      isOnline,
      handleCSTTranslationChange,
      translationNamesIntialCopy,
      translationNames,
      setEnglishTranslationDuplicetName,
      englishTranslationDuplicetName,
      setNotificationData,
      setDashboardNotificationCountApi,
      setNotificationListApi,
      filterData,
      nameErrorStateChinese,
      nameErrorStateEnglish,
      language
    });
  } else if (isDrawer && e.currentTarget.id !== categorySkillsTagsDetails?.id) {
    if (
      JSON.stringify(translationNames) !== JSON.stringify(translationNamesIntialCopy)
    ) {
      closeDrawerModal({
        englishTranslationLength: translationNames?.english_Translation
          ?.toString()
          ?.length,
        chineseTranslationLength: translationNames?.chinese_Translation
          ?.toString()
          ?.length,
        drawerClose: setDrawer,
        drawerOpen: isDrawer,
        drawerDetails: categorySkillsTagsDetails,
        setEnglishTranslationEmpty: setEnglishTranslationEmpty,
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
        handleCSTTranslationChange,
        translationNamesIntialCopy,
        translationNames,
        setEnglishTranslationDuplicetName,
        englishTranslationDuplicetName,
        setNotificationData,
        setDashboardNotificationCountApi,
        setNotificationListApi,
        filterData,
        nameErrorStateChinese,
        nameErrorStateEnglish,
      });
      fetchCategorySkillsTagsDetails({
        CSTId: item,
        makeRequest,
        setCategorySkillsTagsDetails,
        setDrawer,
        setDrawerAPIFailed,
        drawerIdForBrokenLink,
        setDrawerIdForBrokenLink,
        type,
        setTranslationNames,
        setCoursesDrawerList,
        setLearningPathsDrawerList,
        setTranslationNamesIntialCopy,
        learningPathsDrawerList,
        coursesDrawerList,
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
        language,
      });
    } else {
      fetchCategorySkillsTagsDetails({
        CSTId: item,
        makeRequest,
        setCategorySkillsTagsDetails,
        setDrawer,
        setDrawerAPIFailed,
        drawerIdForBrokenLink,
        setDrawerIdForBrokenLink,
        type,
        setTranslationNames,
        setCoursesDrawerList,
        setLearningPathsDrawerList,
        setTranslationNamesIntialCopy,
        learningPathsDrawerList,
        coursesDrawerList,
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
        language
      });
    }
  } else {
    fetchCategorySkillsTagsDetails({
      CSTId: item,
      makeRequest,
      setCategorySkillsTagsDetails,
      setDrawer,
      setDrawerAPIFailed,
      drawerIdForBrokenLink,
      setDrawerIdForBrokenLink,
      type,
      setTranslationNames,
      setCoursesDrawerList,
      setLearningPathsDrawerList,
      setTranslationNamesIntialCopy,
      learningPathsDrawerList,
      coursesDrawerList,
      setCategorgyList,
      setSearchFilterEmptyState,
      setCategorgyListCopy,
      setErrorStateTemplate,
      isOnline,
      language
    });
  }
};

export const initialUseEffect = async (props) => {
  const {
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    setSelectedNavigationOption,
    setIsUserLoggedIn,
    setIsLoading,
  } = props;

  setIsUserLoggedIn(true);
  setSelectedNavigationOption('CATEGORY-SKILLS-MANAGEMENT');
  await fetchCategorySkillsTags({
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
  });
    setIsLoading(false);
};

export const handleEnglishTranslationChange = (props) => {
  const {e, variant, currentKey, setTranslationNames, translationNames, regexNameHindi} = props;
  if (e?.target?.value[0] !== ' ') {
    if (variant === 'courses') {
      setTranslationNames({
        ...translationNames,
        [currentKey?.toLowerCase()?.replace(/ .*/, '') + '_Translation']: e?.target?.value?.replaceAll(/\s+/g, ' '),
      });
    } else {
      setTranslationNames({
        ...translationNames,
      });
    }
  }
};

export const handleTranslationChange = (props) => {
  const {e, variant, currentKey, setTranslationNames, translationNames} = props;
  if (e?.target?.value[0] !== ' ') {
    if (variant === 'courses') {
      setTranslationNames({
        ...translationNames,
        [currentKey?.toLowerCase()?.replace(/ .*/, '') + '_Translation']:
          e?.target?.value?.replaceAll(/\s+/g, ' '),
      });
    } else {
      setTranslationNames({
        ...translationNames,
      });
    }
  }
};

export const searchVariableUseEffect = (props) => {
  const {search, categorgyListCopy, setCategorgyList} = props;

  if (search && search.length && search.length >= 1) {
    let result;
    result = categorgyListCopy.filter((user) =>
      user.englishTranslation.toLowerCase().includes(search.toLowerCase()),
    );
    //for checking length of CST name
    result?.sort(function (one, other) {
      return one?.englishTranslation?.length - other?.englishTranslation?.length;
    });
    //for checking the serach char index in CST name
    result?.sort(function (one, other) {
      return (
        one.englishTranslation?.toLowerCase().indexOf(search?.toLowerCase()) -
        other.englishTranslation?.toLowerCase().indexOf(search?.toLowerCase())
      );
    });
    setCategorgyList(result);
  } else {
    setCategorgyList(categorgyListCopy);
  }
};

export const makeAnagram = (props) => {
  const {a, b} = props;

  const arr1 = a?.split('');
  const arr2 = b?.split('');
  const diff1 = arr1?.filter((letter) => !arr2?.includes(letter));
  const diff2 = arr2?.filter((letter) => !arr1?.includes(letter));
  let str = '';
  str = diff1 + diff2;

  return str?.replaceAll(',', '');
};
