import React from 'react';
import styles from './loadingShimmer.module.scss';
const LoadingShimmer = ({
  count = 1,
  isLearningPath = false,
  flex30 = false,
  isLpLandingPage = false,
}) => {
  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(
        <div
          key={i}
          className={[
            isLearningPath
              ? styles.card_holder_learning_path
              : flex30
              ? styles.card_holder_30
              : isLpLandingPage
              ? styles.card_holder_landing_page
              : styles.card_holder,
          ]}
        >
          <div className={[styles.card]}>
            <div className={[styles.shimmerBG, styles.media].join(' ')}></div>
          </div>
        </div>,
      );
    }
    return content;
  };
  return <React.Fragment>{getShimmerList(count)}</React.Fragment>;
};

LoadingShimmer.propTypes = {};

export default LoadingShimmer;
