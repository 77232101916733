import React from 'react';
import LoadingShimmerCustom from '../../shared/LoadingShimmerCustom';
import StatusLabel from '../../shared/StatusLabel';
import styles from '../Portal.module.scss';
import ViewAllComponent from '../ViewAllComponent';

const MobileLearningSection = (props) => {
  const {
    mobileTabSelected,
    learningPathAPIResponse,
    redirectLearningPath,
    setSelectedNavigationOption,
    router,
    dateIsoToNormalDate,
    showEmptyTemplateFor,
    EmptyScreenComponent,
    isSearchFilterEmptyStateLP,
    isLoading,
  } = props;
  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(
        <div className={[styles.p_lr_10, 'col-lg-4 col-md-4 pull-left'].join(' ')}>
          <LoadingShimmerCustom className={[styles.mobile_view_course_shimmer_card].join(' ')} />
        </div>,
      );
    }
    return content;
  };
  return (
    <React.Fragment>
      {mobileTabSelected === 'LEARNING PATHS' ? (
        <div className={[styles.card_wrapper, styles.learning_section, ' '].join(' ')}>
          <div className={styles.learning_card_wrapper}>
            <div
              className={[
                styles.p_lr_10,
                styles.learning_card,
                'col-xl-12 col-md-6 pull-left',
              ].join(' ')}
            >
              {!isLoading && learningPathAPIResponse?.length > 0 ? (
                learningPathAPIResponse?.map((item, index) => {
                  return (
                    <div
                      className={styles.card}
                      key={index}
                      onClick={() => {
                        redirectLearningPath({
                          id: item?.learningPathId,
                          viewType: 'card',
                          setSelectedNavigationOption,
                          router,
                        });
                      }}
                    >
                      <div className={[styles.image_wrapper, styles.empty_state].join(' ')}>
                        {/* <img src={item?.thumbnail ? item?.thumbnail : ''} alt="card view" /> */}
                        {item?.thumbnail ? (
                          <img src={item?.thumbnail} alt={'selected Mob-TAB course'} />
                        ) : (
                          <div className={[styles.img, styles.empty_state_img].join(' ')}></div>
                        )}
                      </div>
                      <div className={styles.details_wrapper}>
                        <div className={styles.top_wrapper}>
                          <StatusLabel item={item} large={false} />
                          {item?.state?.toLowerCase() !== 'draft' && item?.launchDate && (
                            <div className={styles.block_wrapper}>
                              <div
                                className={[
                                  item?.state?.toLowerCase() === 'live'
                                    ? styles.calendar
                                    : item?.state?.toLowerCase() === 'offline'
                                    ? [styles.offline_cal_icon].join(' ')
                                    : [styles.archive_cal_icon].join(' '),
                                ]}
                              />
                              <div className={styles.date}>
                                {dateIsoToNormalDate(item?.launchDate)}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={styles.description}>{item?.title || 'NA'}</div>
                        <div className={styles.bottom_wrapper}>
                          <div className={styles.block_wrapper}>
                            <div className={styles.book} />
                            <div className={styles.date}>{item?.courseCount}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : !isLoading && isSearchFilterEmptyStateLP ? (
                <EmptyScreenComponent small={true} />
              ) : null}
              <ViewAllComponent
                isLoading={isLoading}
                showEmptyTemplateFor={showEmptyTemplateFor}
                list={learningPathAPIResponse}
                SelectedNavigationOptionText={'LEARNING-PATH'}
                pageName="Learning Paths"
              />
              {isLoading && getShimmerList(30)}
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

MobileLearningSection.propTypes = {};

export default MobileLearningSection;
