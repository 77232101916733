import {
  SET_NEW_COURSE_DATA,
  SET_NEW_IMAGE_DATA,
  SET_NEW_COURSE_WIZARD,
  SET_ADAPT_FILE_DATA,
  SET_THUMBNAIL_ID,
  SET_NEW_COURSE_BLOCK_NAVIGATION,
  SET_NEW_THUMBNAIL_DATA,
} from '../actions/newCourseActions';

const initialState = {
  newCourseData: null,
  newImageData: null,
  newThumbnailImage: null,
  newCourseWizardState: {courseWizardOpen: false, courseWizardStateOpen: ''},
  newThumbnailId: null,
  navigationDisable: false,
};

export const newCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_COURSE_DATA: {
      return {...state, newCourseData: action.payload};
    }
    case SET_ADAPT_FILE_DATA: {
      return {...state, newAdaptFile: action.payload};
    }
    case SET_NEW_IMAGE_DATA: {
      return {...state, newImageData: action.payload};
    }
    case SET_NEW_COURSE_WIZARD: {
      return {...state, newCourseWizardState: action.payload};
    }
    case SET_THUMBNAIL_ID: {
      return {...state, newThumbnailId: action.payload};
    }
    case SET_NEW_COURSE_BLOCK_NAVIGATION: {
      return {...state, navigationDisable: action.payload};
    }
    case SET_NEW_THUMBNAIL_DATA: {
      return {...state, newThumbnailImage: action.payload};
    }
    default: {
      return state;
    }
  }
};
