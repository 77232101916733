import {makeRequest} from '../APIsUtils/httpsUtils';

export const fetchAudienceDetailsWithAllCourses = async (props) => {
  const {
    // query,
    fetchAudId,
    setCoursesUiDataCopy,
    setTotalNumberRemovedCourses,
    setApiCourseTypeCopy,
    setApiCourseType,
    setCoursesAPIResponse,
    setCourseListDefaultFilter,
    setFilterAllCoursesData,
    setSearchFilterEmptyState,
    setSearchList,
    setIsLoading,
  } = props;
  const dataCourses = await makeRequest({
    method: 'GET',
    endpoint: `/admin/api/v1/audience-groups/${fetchAudId?.countryId}/courses`,
  });
  if (dataCourses != 'ERR_NETWORK' && dataCourses) {
    const result = dataCourses?.data?.filter((item) => {
      if (item?.isAdded === true) {
        return item;
      }
    });

    setCoursesUiDataCopy(result);
    setTotalNumberRemovedCourses(result);
    setApiCourseTypeCopy(result?.map(({courseId, courseType}) => ({courseId, courseType})));
    setApiCourseType(result?.map(({courseId, courseType}) => ({courseId, courseType})));

    setCoursesAPIResponse(dataCourses?.data);
    setCourseListDefaultFilter(dataCourses?.data);
    setSearchFilterEmptyState(true);
    setFilterAllCoursesData(dataCourses?.data);
    setSearchList(dataCourses?.data);
    setIsLoading(false);
  }
};

export const fetchAudienceDetails = async (props) => {
  const {setAudienceDetails, fetchAudId} = props;
  const endpoint = `/admin/api/v1/audience-groups/details?audGrpIds=${fetchAudId?.countryId}`;
  let audienceDetailsData = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (audienceDetailsData != 'ERR_NETWORK' && audienceDetailsData) {
    setAudienceDetails(audienceDetailsData?.data);
    // setCoursesUiDataCopy(audienceDetailsData?.data?.courses)
    // setTotalNumberRemovedCourses(audienceDetailsData?.data?.courses)
    // setApiCourseTypeCopy(audienceDetailsData?.data?.courses.map(({ courseId, courseType }) => ({courseId, courseType})))
    // setApiCourseType(audienceDetailsData?.data?.courses.map(({ courseId, courseType }) => ({courseId, courseType})))
  }
};

export const fetchCategories = async (props) => {
  const {filterData, findIndex, setFilterData} = props;

  const dataCategories = await makeRequest({
    method: 'GET',
    endpoint: '/api/v1/categories',
  });
  if (dataCategories) {
    let _filterData = filterData;
    let _filtersList = _filterData.filtersList;
    let _tempFiltersList = [];
    let index = findIndex(_filtersList, ['filterType', 'CHECKBOX']);
    if (index !== -1) {
      dataCategories?.data?.length &&
        dataCategories?.data?.length > 0 &&
        dataCategories?.data?.map((dataItem, _index) => {
          const {id = '', title = ''} = dataItem;
          _tempFiltersList.push({
            index: _index,
            id: id,
            label: title,
            title: title,
            filterParameter: 'category',
            checked: false,
            typeOfFilter: 'CHECKBOX',
          });
        });
      _filtersList[index].filterTypeData.sectionData = _tempFiltersList;
    }
    setFilterData(_filterData);
  }
};

export const handleClickOutside = (props) => {
  const {e, setPopUpClicked} = props;
  const el = document.getElementById('divPopUp');
  if (el !== null) {
    const outside = !el?.contains(e.target);
    if (outside) {
      if (
        !e.target.className.includes('ManageCourses_course_type_popup') &&
        !e.target.className.includes('ManageCourses_popup_btn_wrapper') &&
        !e.target.className.includes('_icon') &&
        !e.target.className.includes('checkbox_wrapper')
      ) {
        setPopUpClicked(false);
      }
    }
  }
};

export const handleFilterApply = (props) => {
  const {
    params,
    setCoursesUiData,
    courseListDefaultFilter,
    clikedDataCopy,
    coursesAPIResponse,
    filterData,
    setFilterData,
    setFilterAllCoursesData,
    setClikedData,
  } = props;
  setCoursesUiData([]);
  let arrayOfList = [courseListDefaultFilter, clikedDataCopy];
  arrayOfList.forEach((value, index) => {
    let _coursesUIData = [];

    let _coursesAPIResponse = [];
    if (params?.find((ele) => ele?.name === 'Recent Activity')) {
      _coursesAPIResponse = index === 0 ? [...courseListDefaultFilter] : [...clikedDataCopy];
    } else {
      _coursesAPIResponse = index === 0 ? [...coursesAPIResponse] : [...clikedDataCopy];
    }
    // if (params?.find((ele) => ele?.name === "Recent Activity")) {
    //   if(showAddedCourses){
    //     _coursesAPIResponse = [...courseListDefaultFilter];
    //   }
    //   else{
    //     _coursesAPIResponse = [...clikedDataCopy];
    //   }
    // } else {
    //   if(!showAddedCourses){
    //     _coursesAPIResponse = [...clikedDataCopy];
    //   }
    //   else{
    //     _coursesAPIResponse = [...coursesAPIResponse];
    //   }
    // }

    let checkBoxFilterArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');
    let checkBoxLables = [];
    let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
    let tilesLables = [];
    let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');

    if (checkBoxFilterArray !== []) {
      for (const ele of checkBoxFilterArray) {
        if (ele?.checked) {
          checkBoxLables.push(ele?.title);
        }
      }
      const tempSectionData = new Array(...filterData.filtersList[1].filterTypeData.sectionData);
      tempSectionData?.forEach((x) => {
        if (checkBoxLables?.includes(x?.title)) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
      filterData.filtersList[1].filterTypeData.sectionData = tempSectionData;
      setFilterData(filterData);
    }

    if (tilesFilterArray !== []) {
      for (const ele of tilesFilterArray) {
        if (ele?.checked) {
          tilesLables.push(ele?.title);
        }
      }

      const tempSectionData = new Array(...filterData.tiles);

      filterData.tiles = tempSectionData;
      setFilterData(filterData);
    }

    if (checkBoxLables?.length > 0) {
      _coursesUIData = _coursesAPIResponse.filter((ele) => {
        return checkBoxLables.includes(ele?.category);
      });
    } else {
      _coursesUIData = _coursesAPIResponse;
    }

    if (tilesLables?.length > 0) {
      _coursesUIData = _coursesUIData.filter((ele) => {
        return tilesLables.includes(ele?.courseState);
      });
    }

    if (radioFilterArray !== []) {
      for (const ele of radioFilterArray) {
        const tempSectionData = new Array(...filterData.filtersList[0].filterTypeData.sectionData);
        tempSectionData?.forEach((x) => {
          if (ele?.name === x?.name) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        filterData.filtersList[0].filterTypeData.sectionData = tempSectionData;
        setFilterData(filterData);

        switch (ele?.filterParameterType) {
          case 'ASC':
            if (_coursesUIData?.length > 0) {
              _coursesUIData = _coursesUIData.sort((a, b) => {
                if (a[ele?.filterParameter]?.toLowerCase() > b[ele?.filterParameter]?.toLowerCase())
                  return 1;
                else if (
                  a[ele?.filterParameter]?.toLowerCase() < b[ele?.filterParameter]?.toLowerCase()
                )
                  return -1;
                else return 0;
              });
            }
            break;

          case 'DESC':
            if (_coursesUIData?.length > 0) {
              _coursesUIData = _coursesUIData.sort((a, b) => {
                if (a[ele?.filterParameter]?.toLowerCase() < b[ele?.filterParameter]?.toLowerCase())
                  return 1;
                else if (
                  a[ele?.filterParameter]?.toLowerCase() > b[ele?.filterParameter]?.toLowerCase()
                )
                  return -1;
                else return 0;
              });
            }
            break;

          case 'TIME-ASC':
            if (_coursesUIData?.length > 0) {
              _coursesUIData = _coursesUIData.sort((a, b) => {
                if (
                  a[ele?.filterParameter] === undefined ||
                  a?.courseState?.toLowerCase() === 'draft'
                )
                  return 1;
                else if (
                  b[ele?.filterParameter] === undefined ||
                  b?.courseState?.toLowerCase() === 'draft'
                )
                  return -1;
                else if (a[ele?.filterParameter] < b[ele?.filterParameter]) return 1;
                else if (a[ele?.filterParameter] > b[ele?.filterParameter]) return -1;
                else return 0;
              });
            }
            break;

          case 'TIME-DESC':
            if (_coursesUIData?.length > 0) {
              _coursesUIData = _coursesUIData.sort((a, b) => {
                if (
                  a[ele?.filterParameter] === undefined ||
                  a?.courseState?.toLowerCase() === 'draft'
                )
                  return 1;
                else if (
                  b[ele?.filterParameter] === undefined ||
                  b?.courseState?.toLowerCase() === 'draft'
                )
                  return -1;
                else if (a[ele?.filterParameter] > b[ele?.filterParameter]) return 1;
                else if (a[ele?.filterParameter] < b[ele?.filterParameter]) return -1;
                else return 0;
              });
            }
            break;

          case 'default':
            _coursesUIData = _coursesUIData;
            break;
        }
      }
    }
    setCoursesUiData(_coursesUIData);
    //setAllCourseSearch(_coursesUIData)
    index === 1 ? setClikedData(_coursesUIData) : null;
    index === 0 ? setFilterAllCoursesData(_coursesUIData) : null;
  });
};

export const getCoursesUIData = (props) => {
  const {course} = props;

  const {courseId, courseState, description, launchDate, thumbnail, title} = course;
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let dateToShowForListView = ' ';
  let dateToShowForCardView = ' ';
  let timeToShow = ' ';
  if (launchDate) {
    const date = new Date(launchDate);
    const hours = date.getHours();
    const mins = date.getMinutes();
    timeToShow = (hours > 12 ? hours - 12 : hours) + ':' + mins + (hours > 12 ? ' pm' : ' am');
    dateToShowForListView =
      months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    dateToShowForCardView = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  } else {
    dateToShowForListView = ' ';
    dateToShowForCardView = ' ';
  }

  let className = '';
  switch (courseState) {
    case 'Live':
      className = 'live';
      break;
    case 'Offline':
      className = 'offline';
      break;
    case 'Draft':
      className = 'draft';
      break;
    case 'Archive':
      className = 'archive';
      break;
  }

  return {
    courseId,
    courseState,
    description,
    thumbnail,
    title,
    timeToShow,
    dateToShowForListView,
    dateToShowForCardView,
    className,
    launchDate,
  };
};

export const closeOpenPopUpButton = (props) => {
  const {e, isCardView, setAaa, isPopUpClicked, setPopUpClicked, setBbb, isListView} = props;

  if (isCardView) {
    setAaa(e.currentTarget.id);
    setPopUpClicked(!isPopUpClicked);
  } else if (isListView) {
    setBbb(e.currentTarget.id);
    setPopUpClicked(!isPopUpClicked);
  }
};

export const handleCloseButton = (props) => {
  const {
    router,
    totalNumberRemovedCourses,
    totalNumberAddedCourses,
    coursesUIDataCopy,
    query,
    setNotificationData,
    audienceDetails,
    width,
  } = props;
  router.push({
    pathname: '/UserManagement',
    query: {
      isManageCourseOpen: false,
      UserGroupId: query?.AudienceGroupId,
    },
  });
  if (
    !(
      coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 0 &&
      totalNumberAddedCourses?.length === 0
    )
  ) {
    if (width > 992) {
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'User Group Changes have not been saved',
        description: `Courses have not been updated in the ${audienceDetails?.audienceGrpName}.`,
      });
    } else {
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'User Group Edit Failed',
        description: `Courses have not been updated in ${audienceDetails?.audienceGrpName}.`,
      });
    }
  }
};

export const handleCourseAddRemove = (props) => {
  const {
    e,
    setAaa,
    setBbb,
    setClikedData,
    coursesUIDataCopy,
    clikedData,
    totalNumberRemovedCourses,
    setTotalNumberRemovedCourses,
    totalNumberAddedCourses,
    setTotalNumberAddedCourses,
    setClikedDataCopy,
    clikedDataCopy,
    coursesAPIResponse,
  } = props;
  //For removing course
  setAaa(e?.currentTarget?.id);
  setBbb(e?.currentTarget?.id);
  if (clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)) {
    setClikedData(
      clikedData?.filter((item) => {
        //For Count of Removed courses
        if (
          coursesUIDataCopy?.some((ele) => ele?.courseId === e.currentTarget.id) &&
          clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
        ) {
          setTotalNumberRemovedCourses(
            totalNumberRemovedCourses?.filter((item) => {
              return item?.courseId !== e.currentTarget.id;
            }),
          );
        }
        if (
          totalNumberAddedCourses?.some((ele) => ele?.courseId === e.currentTarget.id) &&
          clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
        ) {
          setTotalNumberAddedCourses(
            totalNumberAddedCourses?.filter((item) => {
              return item?.courseId !== e.currentTarget.id;
            }),
          );
        }
        return item?.courseId !== e.currentTarget.id;
      }),
    );
    setClikedDataCopy(
      clikedDataCopy?.filter((item) => {
        //For Count of Removed courses
        if (
          coursesUIDataCopy?.some((ele) => ele?.courseId === e.currentTarget.id) &&
          clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
        ) {
          setTotalNumberRemovedCourses(
            totalNumberRemovedCourses?.filter((item) => {
              return item?.courseId !== e.currentTarget.id;
            }),
          );
        }
        if (
          totalNumberAddedCourses?.some((ele) => ele?.courseId === e.currentTarget.id) &&
          clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
        ) {
          setTotalNumberAddedCourses(
            totalNumberAddedCourses?.filter((item) => {
              return item?.courseId !== e.currentTarget.id;
            }),
          );
        }
        return item?.courseId !== e.currentTarget.id;
      }),
    );
  }
  //For Adding Courses
  else if (!clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)) {
    //For Count of Removed courses
    if (
      coursesUIDataCopy?.some((ele) => ele?.courseId === e.currentTarget.id) &&
      !clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
    ) {
      let b = coursesUIDataCopy?.filter((item) => {
        return item?.courseId === e.currentTarget.id;
      });
      setTotalNumberRemovedCourses((prev) => [...prev, b[0]]);
    }
    if (
      !coursesUIDataCopy?.some((ele) => ele?.courseId === e.currentTarget.id) &&
      !totalNumberAddedCourses?.some((ele) => ele?.courseId === e.currentTarget.id)
    ) {
      let c = coursesAPIResponse?.filter((item) => {
        return item?.courseId === e.currentTarget.id;
      });
      setTotalNumberAddedCourses((prev) => [...prev, c[0]]);
    }
    let a = coursesAPIResponse?.filter((item) => {
      return item?.courseId === e.currentTarget.id;
    });
    setClikedData((prev) => [...prev, a[0]]);
    setClikedDataCopy((prev) => [...prev, a[0]]);
  }
};

export const handleToggleSwitch = (props) => {
  const {setShowAddedCourses, showAddedCourses, setNotificationData, width} = props;
  setShowAddedCourses(!showAddedCourses);
  if (width <= 767 && showAddedCourses) {
    setNotificationData({
      show: true,
      type: 'UPLOADING',
      title: 'Showing Only Added Courses',
      singleLine: true,
    });
  }
};

export const handleSaveChanges = async (props) => {
  const {
    clikedDataCopy,
    query,
    fetchAudId,
    router,
    setDashboardNotificationCountApi,
    currentUserId,
    setNotificationListApi,
    totalNumberAddedCourses,
    totalNumberRemovedCourses,
    coursesUIDataCopy,
    setNotificationData,
    audienceDetails,
    countryCodeAllIds = [],
  } = props;

  let courses = clikedDataCopy?.map(({courseId, courseType}) => ({courseId, courseType}));

  let addRemoveCourses = {courses};
  const _endpoint = `/admin/api/v1/audience-groups/${fetchAudId?.countryId}/courses`;
  let dataUpdateImageDetails = await makeRequest({
    method: 'PATCH',
    endpoint: _endpoint,
    body: addRemoveCourses,
  });
  if (dataUpdateImageDetails?.status === 'Success') {
    router.push({
      pathname: '/UserManagement',
      query: {
        isManageCourseOpen: false,
        // UserGroupId: query?.AudienceGroupId,
        UserGroupId: countryCodeAllIds,
      },
    });
    setDashboardNotificationCountApi(currentUserId);
    setNotificationListApi(currentUserId);
    totalNumberAddedCourses?.length > 0 &&
    coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 0
      ? setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'User Group Edited Successfully',
          description:
            totalNumberAddedCourses?.length === 1
              ? `${totalNumberAddedCourses?.length} course Added to the ${query?.AudienceGrpName}.`
              : `${totalNumberAddedCourses?.length} courses Added to the ${query?.AudienceGrpName}.`,
        })
      : totalNumberAddedCourses?.length === 0 &&
        coursesUIDataCopy?.length - totalNumberRemovedCourses?.length > 0
      ? setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'User Group Edited Successfully',
          description:
            coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 1
              ? `${
                  coursesUIDataCopy?.length - totalNumberRemovedCourses?.length
                } course Removed from the ${query?.AudienceGrpName}.`
              : `${
                  coursesUIDataCopy?.length - totalNumberRemovedCourses?.length
                } courses Removed from the ${query?.AudienceGrpName}.`,
        })
      : totalNumberAddedCourses?.length > 0 &&
        coursesUIDataCopy?.length - totalNumberRemovedCourses?.length > 0
      ? setNotificationData({
          show: true,
          twoLineDescription: true,
          type: 'SUCCESS',
          title: 'User Group Edited Successfully',
          description:
            totalNumberAddedCourses?.length === 1
              ? `${totalNumberAddedCourses?.length} course Added to the ${query?.AudienceGrpName}.`
              : `${totalNumberAddedCourses?.length} courses Added to the ${query?.AudienceGrpName}.`,
          lineTwoDescription:
            coursesUIDataCopy?.length - totalNumberRemovedCourses?.length === 1
              ? `${
                  coursesUIDataCopy?.length - totalNumberRemovedCourses?.length
                } course Removed from the ${query?.AudienceGrpName}.`
              : `${
                  coursesUIDataCopy?.length - totalNumberRemovedCourses?.length
                } courses Removed from the ${query?.AudienceGrpName}.`,
        })
      : null;
  }
};

export const handleRadioButtomOnClick = (props) => {
  const {
    course,
    item,
    apiCourseType,
    apiCourseTypeCopy,
    setCourseTypeChanged,
    setSelectedId,
    setPopUpClicked,
  } = props;
  const indexOf = apiCourseType?.findIndex((obj) => {
    return obj?.courseId === course?.courseId;
  });
  if (indexOf !== -1) {
    apiCourseTypeCopy[indexOf].courseType = item?.title;
  }
  setCourseTypeChanged(
    apiCourseTypeCopy.length === apiCourseType.length &&
      apiCourseTypeCopy.every(
        (o, i) =>
          Object.keys(o).length === Object.keys(apiCourseType[i]).length &&
          Object.keys(o).every((k) => o[k] === apiCourseType[i][k]),
      ),
  );
  switch (item?.title) {
    case 'Required':
      course.courseType = 'Required';
      setSelectedId(1);
      break;
    case 'Recommended':
      course.courseType = 'Recommended';
      setSelectedId(2);
      break;
    case 'Quick Start':
      course.courseType = 'Quick Start';
      setSelectedId(3);
      break;
    case 'General visibility':
      course.courseType = 'General visibility';
      setSelectedId(4);
      break;
    default:
      setSelectedId(4);
      break;
  }
  setPopUpClicked(false);
};

export const handleSearchInput = (props) => {
  const {event, setSearch, setPredictedSearch, coursesUIData} = props;
  setSearch(event?.target?.value);
  setPredictedSearch([]);

  let tempPredition = coursesUIData?.map((item) => ({
    id:
      item?.courseId && item?.courseId !== null && item.courseId !== undefined
        ? item?.courseId
        : '',
    label: item?.title && item?.title !== null && item.title !== undefined ? item?.title : '',
  }));
  if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
    setPredictedSearch(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearch([]);
  }
};

export const mapCoursesToAudience = (props) => {
  const {course, setSelectedId, setRadioButtonData} = props;
  if (course?.courseType === undefined || course?.courseType === '') {
    course.courseType = 'General visibility';
  }

  const radioButtonData = [
    {
      id: 1,
      title: 'Required',
      className: 'req_icon',
      checked: false,
      typeOfFilter: 'RADIO',
    },
    {
      id: 2,
      title: 'Recommended',
      className: 'recom_icon',
      checked: false,
      typeOfFilter: 'RADIO',
    },
    {
      id: 3,
      title: 'Quick Start',
      className: 'qstart_icon',
      checked: false,
      typeOfFilter: 'RADIO',
    },
    {
      id: 4,
      title: 'General visibility',
      className: 'gen_visiblity_icon',
      checked: false,
      typeOfFilter: 'RADIO',
    },
  ];
  switch (course?.courseType) {
    case 'Required':
      setSelectedId(1);
      break;
    case 'Recommended':
      setSelectedId(2);
      break;
    case 'Quick Start':
      setSelectedId(3);
      break;
    case 'General visibility':
      setSelectedId(4);
      break;
  }
  // return radioButtonData
  setRadioButtonData(radioButtonData);
};

export const handleCheckClick = (props) => {
  const {setChecked, isChecked} = props;
  setChecked(!isChecked);
};

export const updateWindowDimensions = (props) => {
  const {setWidth} = props;

  setWidth(window.innerWidth);
};
