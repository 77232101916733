import { SET_ALL_LEARNING_PATH_LIST } from "../actions/learningPathManagementActions";

const initialState = {
    allLearningPathList: []
};

export const learningPathManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_LEARNING_PATH_LIST: {
            return { ...state, allLearningPathList: action.payload };
        }

        default: {
            return state;
        }
    }
};