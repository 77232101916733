import React, {useState} from 'react';
import styles from './CropImage.module.scss';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  setRatio,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setCroppedImageData169,
  setCroppedImageData11,
  setButton_169_status,
  setButton_11_status,
  setCroppedImageData,
  setLocalMainImage,
} from '../../../redux/actions/imageCrop/imageCropAction';
//import { setCropDialogue } from "../../../redux/actions/actionImageLibrary";
import _config from '../../../config';
import {
  cropImageModal_16_9,
  cropImageModal_1_1,
  dataURLtoFile,
  getBase64FromUrl,
  saveChanges,
} from '../../utils/ContentManagementUtils/ImageCroppingModalUtils';
import Background from '../../shared/Modal/Background/Background';
import ModalWrapper from '../../shared/Modal/ModalWrapper/ModalWrapper';

const cropAndRestoreButton1_1 = (enableCrop_1_1, button_11_status) => {
  return enableCrop_1_1 ? '' : styles.disabled, button_11_status === 1 ? styles.active : '';
};
const cropAndRestoreButton16_9 = (enableCrop_16_9, button_169_status) => {
  return enableCrop_16_9 ? '' : styles.disabled, button_169_status === 1 ? styles.active : '';
};

const CropImageModalComponent = (props) => {
  const {
    imageId,
    saveDeleteChanges,
    setCropDialogue,
    editImageExtends,
    seteditImageExtends,
    croppedImg,
    setCroppedImg,
    mainImg,
    setMainImg,
    croppedImg1,
    setCroppedImg1,
    imageinput,
    descriptionInput,
    croppedImageData,
    setRatio,
    croppedImage_16_9,
    croppedImage_1_1,
    backupImage_16_9,
    backupImage_1_1,
    ImageDetail,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setCroppedImageData169,
    setCroppedImageData11,
    croppedImageData169,
    croppedImageData11,
    setButton_169_status,
    setButton_11_status,
    button_169_status,
    button_11_status,
    setCroppedImageData,
    setLocalMainImage,
    fetchImageList,
    selectedImageItem,
    fetchImageDetails,
    handleImageClick,
    makeRequest,
    isImageDetailDrawerUpdated,
    setNotificationData,
    setImageDetailsData,
    setImageDetailDrawerUpdated,
    setImageDetail,
    setImageInput,
    setDescriptionInput,
    setCourseIds,
    setMainImageBackup,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setCourseDetails,
  } = props;

  const router = useHistory();
  const {query = {}} = router.location;
  const {imgId = false} = query;
  const [hide, setHide] = useState('');
  const [enableSaveChanges, setEnableSaveChanges] = useState(imgId);
  const [enableCrop_16_9, setEnableCrop16_9] = useState(false);
  const [enableCrop_1_1, setEnableCrop1_1] = useState(false);
  const [isLoading,setLoading] = useState(false);

  function closeCropImageModal() {
    setCropDialogue(false);
  }

  const [file_1_1, setFile_1_1] = useState(undefined);

  return (
    <React.Fragment>
      <div className={[styles.modal_wrapper].join(' ')}>
        <Background backgroundFlag="crop">
          <ModalWrapper modalFlag="crop">
            {/* <div className={styles.crop_img_modal}> */}
            <div
              className={styles.close_wrapper}
              onClick={() => {
                setCroppedImage_16_9(null);
                setCroppedImage_1_1(null);
                setCroppedImageData(null);
                closeCropImageModal();
              }}
            >
              <div className={styles.close_icon} />
            </div>
            <div className={styles.header_wrapper}>
              <div className={styles.heading}>
                Crop Image
                {/* {!isDeleteRecord ? "Confirm Delete" : "Delete user group"} */}
              </div>
              <div className={styles.warning_msg_wrapper}>
                <div className={styles.msg_txt}>
                  WARNING: When cropping images, after save changes is clicked the original borders
                  of the image outside of the crop will be deleted. To restore the original image
                  you will need to replace it from your local machine.
                </div>
              </div>
            </div>
            <div className={styles.modal_middle_body}>
              <div className={styles.top_inner_wrapper}>
                <div className={styles.left_section_wrapper}>
                  <div className={styles.title_wrapper}>
                    <div className={styles.title}>Ratio 16:9</div>
                  </div>
                  {!croppedImageData?.croppedImage?.croppedImage_16_9 && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginBottom: "15px",
                      }}
                    >
                      <img
                        src={ImageDetail.bannerImage}
                        className={[styles.center, styles.img_prop].join(' ')}
                        onLoad={() => {
                          setTimeout(() => {
                            setEnableCrop16_9(true);
                          }, 1000);
                        }}
                        alt="16:9 cropper"
                      />
                    </div>
                  )}
                  {croppedImageData?.croppedImage?.croppedImage_16_9 && (
                    <div
                      className={[styles.crop_image_content, ''].join(' ')}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginBottom: "15px",
                      }}
                    >
                      <img
                        src={croppedImageData.croppedImage.croppedImage_16_9}
                        className={[styles.center, styles.img_prop].join(' ')}
                        onLoad={() => {
                          setTimeout(() => {
                            setEnableCrop16_9(true);
                          }, 1000);
                        }}
                        alt="16:9 cropper"
                      />
                    </div>
                  )}

                  <div className={[styles.btn_wrapper, ''].join(' ')}>
                    <div className={[styles.inner_btn_wrapper, ''].join(' ')}>
                      <div
                        className={[
                          styles.fill_btn,
                          cropAndRestoreButton16_9(enableCrop_16_9, button_169_status),
                        ].join(' ')}
                        onClick={() => {
                          setCroppedImageData169(null);
                          setCroppedImage_16_9(null);
                          setButton_169_status(1);
                        }}
                      >
                        <div
                          className={
                            button_169_status === 1 ? styles.active_fill_image : styles.fill_image
                          }
                        ></div>
                      </div>
                      <div
                        className={[
                          styles.crop_btn,
                          enableCrop_16_9 ? '' : styles.disabled,
                          button_169_status === 2 ? styles.active : '',
                        ].join(' ')}
                        onClick={() =>
                          cropImageModal_16_9(
                            setButton_169_status,
                            setCropDialogue,
                            seteditImageExtends,
                            setRatio,
                            router,
                            localStorage,
                            croppedImageData,
                            setLocalMainImage,
                            ImageDetail,
                            imageId,
                          )
                        }
                      >
                        <div
                          className={
                            button_169_status === 2 ? styles.active_crop_image : styles.crop_image
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.right_section_wrapper}>
                  <div className={styles.title_wrapper}>
                    <div className={styles.title}>Ratio 1:1</div>
                  </div>

                  {!croppedImageData?.croppedImage?.croppedImage_1_1 && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={ImageDetail.thumbnailImage}
                        className={[styles.center, styles.img_prop].join(' ')}
                        onLoad={() => {
                          setTimeout(() => {
                            setEnableCrop1_1(true);
                          }, 1000);
                        }}
                        alt="1:1 cropper"
                      />
                    </div>
                  )}
                  {croppedImageData?.croppedImage?.croppedImage_1_1 && (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img
                        src={croppedImageData.croppedImage.croppedImage_1_1}
                        className={[styles.center, styles.img_prop].join(' ')}
                        onLoad={() => {
                          setTimeout(() => {
                            setEnableCrop1_1(true);
                          }, 1000);
                        }}
                        alt="1:1 cropper"
                      />
                    </div>
                  )}
                  <div className={[styles.btn_wrapper, ''].join(' ')}>
                    <div className={[styles.inner_btn_wrapper, ''].join(' ')}>
                      <div
                        className={[
                          styles.fill_btn,
                          cropAndRestoreButton1_1(enableCrop_1_1, button_11_status),
                        ].join(' ')}
                        onClick={() => {
                          setCroppedImageData11(null);
                          setCroppedImage_1_1(null);
                          setButton_11_status(1);
                        }}
                      >
                        <div
                          className={
                            button_11_status === 1 ? styles.active_fill_image : styles.fill_image
                          }
                        ></div>
                      </div>
                      <div
                        className={[
                          styles.crop_btn,
                          enableCrop_1_1 ? '' : styles.disabled,
                          button_11_status === 2 ? styles.active : '',
                        ].join(' ')}
                        onClick={() =>
                          cropImageModal_1_1(
                            setButton_11_status,
                            setCropDialogue,
                            seteditImageExtends,
                            setRatio,
                            router,
                            localStorage,
                            croppedImageData,
                            setLocalMainImage,
                            ImageDetail,
                            imageId,
                          )
                        }
                      >
                        <div
                          className={
                            button_11_status === 2 ? styles.active_crop_image : styles.crop_image
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className={[styles.img_details_opt_wrapper, 'hide'].join(' ')}>
                    <div className={styles.info_text}>Add Image Details</div>
                    <div className={styles.left_arrow_icon}></div>
                  </div>
                </div>
              </div>

              <div className={styles.warning_section}>
                {/* <div className={styles.warning_icon} /> */}
                <div className={styles.message_section}>
                  <div className={[styles.heading, styles.mb_5].join(' ')}>
                    Image cropping is unavailable on mobile. Please use your phone camera’s image
                    cropping feature before uploading.
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.btn_wrapper}>
              <button
                className={[
                  styles.btn,
                  styles.btn1,
                  (croppedImageData?.croppedImage?.croppedImage_1_1 ||
                  croppedImageData?.croppedImage?.croppedImage_16_9 )&& !isLoading
                    ? styles.active
                    : styles.disabled,
                ].join(' ')}
                onClick={() => {
                  setLoading(true); 
                  saveChanges({
                    fetchImageList,
                    fetchImageDetails,
                    setCroppedImageData,
                    setCroppedImage_1_1,
                    setCroppedImage_16_9,
                    setCroppedImageData11,
                    setCroppedImageData169,
                    selectedImageItem,
                    handleImageClick,
                    setCropDialogue,
                    imageId,
                    ImageDetail,
                    imageinput,
                    descriptionInput,
                    croppedImageData169,
                    getBase64FromUrl,
                    dataURLtoFile,
                    croppedImageData11,
                    _config,
                    makeRequest,
                    isImageDetailDrawerUpdated,
                    setNotificationData,
                    setImageDetailsData,
                    setImageDetailDrawerUpdated,
                    setImageDetail,
                    setImageInput,
                    setDescriptionInput,
                    setCourseIds,
                    setMainImageBackup,
                    setDrawerAPIFailed,
                    drawerIdForBrokenLink,
                    setDrawerIdForBrokenLink,
                    setCourseDetails,
                    setLoading
                  });
                }}
              >
                Save changes
              </button>
              <button
                className={[styles.btn, styles.btn2].join(' ')}
                onClick={() => {
                  setCroppedImage_16_9(null);
                  setCroppedImage_1_1(null);
                  setCroppedImageData(null);
                  setCroppedImageData11(null);
                  setCroppedImageData169(null);
                  closeCropImageModal();
                }}
              >
                Cancel
              </button>
            </div>
            {/* </div> */}
          </ModalWrapper>
        </Background>
      </div>
    </React.Fragment>
  );
};

CropImageModalComponent.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  croppedImageData: state,
  croppedImageData169: state?.croppedImage?.croppedImageData169,
  croppedImageData11: state?.croppedImage?.croppedImageData11,
  croppedImage_1_1: state && state.croppedImage && state.croppedImage.cropped_image_1_1,
  croppedImage_16_9: state && state.croppedImage && state.croppedImage.cropped_image_16_9,
  backupImage_16_9: state && state.croppedImage && state.croppedImage.backupImage_16_9,
  backupImage_1_1: state && state.croppedImage && state.croppedImage.backupImage_1_1,
  button_169_status: state?.croppedImage?.button_169_status,
  button_11_status: state?.croppedImage?.button_11_status,
});

const mapDispatchToProps = (dispatch) => ({
  setRatio: (options) => dispatch(setRatio(options)),
  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),
  setButton_169_status: (options) => dispatch(setButton_169_status(options)),
  setButton_11_status: (options) => dispatch(setButton_11_status(options)),
  setCroppedImageData: (options) => dispatch(setCroppedImageData(options)),
  setCroppedImageData11: (options) => dispatch(setCroppedImageData11(options)),
  setCroppedImageData169: (options) => dispatch(setCroppedImageData169(options)),
  setLocalMainImage: (options) => dispatch(setLocalMainImage(options)),
  //setCropDialogue: (options) => dispatch(setCropDialogue(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CropImageModalComponent));
