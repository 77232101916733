import React from 'react';
import styles from './Background.module.scss';

const Background = ({children, backgroundFlag}) => {
  return (
    <div
      className={
        backgroundFlag === 'mini'
          ? styles['background-container-mini']
          : backgroundFlag === 'small'
          ? styles['background-container-small']
          : backgroundFlag === 'crop'
          ? styles['background-cropping-container']
          : styles['background-container']
      }
    >
      {children}
    </div>
  );
};

Background.propTypes = {};

export default Background;
