import React from 'react';
import {useEffect} from 'react';
import styles from './loadingShimmerList.module.scss';
const LoadingShimmerList = ({count = 1, customHeight = 56, className = ''}) => {
  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(
        <div
          key={i}
          className={[styles.comment, styles.br, styles.animate, className].join(' ')}
        ></div>,
      );
    }
    return content;
  };

  return (
    <React.Fragment>
      <div className={styles.wrapper}>{getShimmerList(count)}</div>
    </React.Fragment>
  );
};

LoadingShimmerList.propTypes = {};

export default LoadingShimmerList;
