import React from 'react';
import StatusLabel from '../../shared/StatusLabel';
import styles from '../Content.module.scss';

const CourseStatusComponent = (props) => {
  const {isReplaceAllInstance, courseDetails} = props;
  return (
    isReplaceAllInstance &&
    courseDetails &&
    courseDetails.map((item, index) => (
      <div className={styles.list} key={index}>
        <div className={styles.item_wrapper}>
          <div
            className={[
              styles.item_icon,
              !item?.isLearningPath ? styles.item_art_icon : styles.item_sci_icon,
            ].join(' ')}
          ></div>
          <div className={styles.item}>{item.title}</div>
        </div>
        <StatusLabel item={item} large={true} />
        {/* <div
          className={[
            styles.instance_status,

            item?.state == 'Live'
              ? styles.live_sts
              : item?.state == 'Draft'
              ? styles.draft_sts
              : item?.state == 'Archive'
              ? styles.archive_sts
              : item?.state == 'Offline'
              ? styles.offline_sts
              : '',
          ].join(' ')}
        >
          {item?.state}
        </div> */}
      </div>
    ))
  );
};

export default CourseStatusComponent;
