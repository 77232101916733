import React from 'react';
import styles from '../Content.module.scss';

const ImageCardView = (props) => {
  const {
    ImageList,
    handleImageClick,
    isSearchFilterEmptyState,
    imageId,
    isDrawer,
    learningPathMode,
    EmptyScreenComponent,
    setImageThumbnailButtonClick,
    setImageId,
    setMainImg,
    setSelectedKeyword,
    setMainImageBackup,
    setFileError,
    setKeywordDropdown,
  } = props;
  return (
    <div className={styles.content_wrapper}>
      <div className={[styles.image_container, ''].join(' ')}>
        {ImageList && ImageList.length > 0 ? (
          ImageList.map((item) => (
            <div
              key={item.imageId}
              className={[
                styles.flex_item,
                item.imageId === imageId && (isDrawer || learningPathMode === 'FROM_LEARNING_PATH')
                  ? styles.selected
                  : '',
              ].join(' ')}
            >
              <div
                className={styles.image_wrapper}
                onClick={() => {
                  handleImageClick({
                    item,
                    setImageThumbnailButtonClick,
                    setImageId,
                    setMainImg,
                    setSelectedKeyword,
                    setFileError,
                    setKeywordDropdown,
                  });
                }}
              >
                <div className={styles.pointer_icon_wrapper}>
                  <div className={styles.pointer_img}></div>
                </div>

                <img src={item.thumbnailImage ? item.thumbnailImage : ''} alt="card view" />
              </div>
            </div>
          ))
        ) : isSearchFilterEmptyState ? (
          <EmptyScreenComponent />
        ) : null}
      </div>
    </div>
  );
};

export default ImageCardView;
