export const makeMobilePillsArray = (mobilePillsArray) => {
  if (mobilePillsArray?.length > 0 && typeof mobilePillsArray[0] === 'string') {
    let tempArray = [];
    for (const item of mobilePillsArray) {
      tempArray.push({title: item});
    }
    return [...tempArray];
  }
  return [];
};

export const getImageTag = (mobilePillsArray) => {
  let _tags = '';
  if (mobilePillsArray?.length > 0) {
    mobilePillsArray.map((pill) => {
      if (pill && pill.title) {
        if (_tags === '') {
          _tags = pill.title;
        } else {
          _tags = _tags + ', ' + pill.title;
        }
      }
    });
  } else {
    _tags = '' + ', ' + '';
  }
  return _tags;
};
// export const imageAssetData = async (croppedImageData169,replacedImage,croppedImageData11) => {
//   const formData = new FormData();
//   formData.append(
//     "IMAGE_16_9",
//     croppedImageData169?.imgFile
//       ? croppedImageData169?.imgFile
//       : replacedImage?.imgFile
//   );
//   formData.append(
//     "IMAGE_1_1",
//     croppedImageData11?.imgFile
//       ? croppedImageData11?.imgFile
//       : replacedImage?.imgFile
//   );
//   let imageTags = [];
//   for (const item of image_data?.pillsData) {
//     imageTags?.push(item?.title);
//   }
//   formData.append("fileName", image_data?.imageName);
//   formData.append("description", image_data?.imageDescription);
//   formData.append("tags", imageTags);
//   return formData;
// };

export const updatedImageDetails = (
  dataUpdateImageDetails,
  setNotificationData,
  setNewCreatedImageData,
  replacedImage,
  setReplacedImage,
  newCourseWizardState,
) => {
  if (dataUpdateImageDetails) {
    if (dataUpdateImageDetails === 'ERR_BAD_REQUEST') {
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Image Creation Failed',
        description: 'Unable to add an image. Please try again later',
      });
    } else {
      if (dataUpdateImageDetails.status === 'Success') {
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'New Image Added',
          description: `${dataUpdateImageDetails?.data?.title} has been added to the image library.`,
        });
      }

      setNewCreatedImageData(dataUpdateImageDetails);

      if (replacedImage) {
        setReplacedImage({
          ...replacedImage,
          respNewlyCreatedImageDetail: dataUpdateImageDetails,
        });
      } else if (newCourseWizardState?.courseWizardOpen) {
        //setNewThumbnailId(dataUpdateImageDetails?.data?.id);
      } else {
        //setNewImageId(dataUpdateImageDetails?.data?.id);
      }
    }
  }
};

export const makeImageData = (image_data, setImageData, pillsData) => {
  if (!image_data?.pillsData || image_data?.pillsData?.length === 0) {
    setImageData({
      ...image_data,
      pillsData: pillsData,
    });
  }
};
export const setNewReplacedImage = (
  newCreatedImageData,
  mobileReplaceCall,
  setReplacedImage,
  replacedImage,
  replaceImageForMobile,
  handleOnClickReplaceImage,
) => {
  if (newCreatedImageData) {
    if (mobileReplaceCall) {
      setReplacedImage({
        ...replacedImage,
        imgID: newCreatedImageData?.data?.id,
      });
      replaceImageForMobile(newCreatedImageData?.data?.id);
    } else {
      setReplacedImage({
        ...replacedImage,
        imgID: newCreatedImageData?.data?.id,
      });
      handleOnClickReplaceImage(newCreatedImageData?.data?.id);
    }
  }
};

export const ImageSaveNotification = (
  dataImageDetail,
  handleImageClick,
  isImageDetailDrawerUpdated,
  setNotificationData,
  setImageDetailDrawerUpdated,
  setImageDetail,
  setImageInput,
  setDescriptionInput,
  setCourseIds,
  imageinput,
) => {
  if (dataImageDetail) {
    handleImageClick(dataImageDetail?.data);
    if (isImageDetailDrawerUpdated) {
      setNotificationData({
        show: true,
        type: 'SUCCES',
        title: 'Saved Changes',
        description: `Details for the ${imageinput} were saved.`,
      });
      setImageDetailDrawerUpdated(false);
    }
    setImageDetail(dataImageDetail?.data);
    setImageInput(dataImageDetail?.data?.fileName);
    setDescriptionInput(dataImageDetail?.data?.description);
    setCourseIds(dataImageDetail?.data?.courses);
  }
};

export const replaceImageNotification = (
  newImageName,
  isDeletePrevious,
  setNotificationData,
  ImageDetail,
  newId,
) => {
  if (isDeletePrevious === true) {
    //delete images notification
    setNotificationData({
      show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
      title: 'Image Replaced & Deleted',
      description: `${ImageDetail.fileName} has been replaced and deleted with ${newImageName}.`,
      type: 'SUCCESS',
    });
  } else {
    setNotificationData({
      show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
      title: 'Image Replaced',
      description: `${ImageDetail.fileName} has been replaced with ${newImageName}.`,
      type: 'SUCCESS',
    });
  }

  let payload = {
    previousImageId: ImageDetail?.imageId ? ImageDetail?.imageId : null,
    newImageId: newId ? newId : null,
    deletePrevious: isDeletePrevious,
  };
  return payload;
};

export const replaceMobileImage = (
  dataReplaceImageForMob,
  closeReplaceImageModal,
  setImageData,
  setReplacedImage,
  setReplacedImageUploaded,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  currentUserId,
  fetchImageList,
  router,
) => {
  if (dataReplaceImageForMob) {
    router?.push({
      pathname: '/Contentmanagement',
      query: {actionName: ''},
    });
    fetchImageList();
    setNotificationListApi();
    setDashboardNotificationCountApi(currentUserId);
    closeReplaceImageModal();
    setImageData({
      imageName: null,
      imageDescription: null,
      pillsData: null,
    });
    setReplacedImage(null);
    setReplacedImageUploaded(false);
  }
};

export const handleTags = (
  selectedKeyword,
  mobilePillsArray,
  setMobilePillsArray,
  setImageData,
  setSelectedKeyword,
  image_data,
) => {
  if (selectedKeyword?.title?.length > 0) {
    let tempList = '';
    let tempObj = {
      id: selectedKeyword?.id,
      title: selectedKeyword?.title,
    };
    tempList = selectedKeyword?.title;
    //setPillsData((preState) => [...preState, tempList]);
    if (mobilePillsArray?.length > 0) {
      setMobilePillsArray([...mobilePillsArray, tempObj]);

      setImageData({
        ...image_data,
        pillsData: [...mobilePillsArray, tempList],
      });
    } else {
      setMobilePillsArray([tempObj]);
      setImageData({
        ...image_data,
        pillsData: [tempList],
      });
    }

    setSelectedKeyword({title: ''});
  }
};

export const handleMobileImageName = (
  value,
  imageLocalList,
  setFileError,
  setImageData,
  setMobileImageName,
  image_data,
) => {
  let usedFileName =
    imageLocalList &&
    imageLocalList.filter((item) => {
      if (item.fileName.toLowerCase() == value.toLowerCase()) {
        return item;
      }
    });

  if (usedFileName?.length > 0) {
    setFileError(true);
  } else {
    setFileError(false);
  }

  setImageData({
    ...image_data,
    imageName: value,
  });

  if (value.length <= 30) {
    setMobileImageName(value);
  } else if (value.length > 30) {
    setMobileImageName(value);
  }
};
