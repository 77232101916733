import React, {createRef, useEffect, useRef, useState} from 'react';
import styles from '../createNewLearningPath/CreateNewLP.module.scss';
import CardNewComponent from '../../shared/cardNewComponent';
import NoteCardComponent from '../../shared/noteCard';
import EmptyScreen from './components/EmptyScreen/EmptyScreen';
import CountrySelection from '../../shared/countrySelection';
import {setCountryList} from '../../../redux/actions/uiActions';
import CountrySelectionHandleEvents from '../../utils/CountrySelection/countrySelectionHandleEvents';
import {connect} from 'react-redux';

const UserTargetingLP = ({
  deleteUserGroupList,
  addUserGroupList,
  disableStateUserManagement,
  handleDeleteUserTargetingGroups,
  handleDeleteUserGroups,
  disableClickBanner,
  handleAddUserTargetingGroups,
  isLoadingApi,
  countryList,
  setCountryList,
  remoteConfig = {},
  data,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {updateCountryList, handleSelectAllCountry, handleClearAllCountry, handleCountrySelect} =
    CountrySelectionHandleEvents();
  useEffect(() => {
    setIsLoading(isLoadingApi);
  }, [isLoadingApi]);

  const handleCountryClearAll = () => {
    const newCountryList = handleClearAllCountry(countryList);
    setCountryList(newCountryList);
  };
  const handleCountrySelectAll = () => {
    const newCountryList = handleSelectAllCountry(countryList);
    setCountryList(newCountryList);
  };

  const handleCountrySelectA = (country) => {
    const newCountryList = handleCountrySelect(country, countryList);
    setCountryList(newCountryList);
  };

  return (
    <div className={[styles.info_container, ''].join(' ')}>
      <div
        className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-12', ''].join(
          ' ',
        )}
      >
        <div className={styles.title_wrapper}>
          <div className={styles.title}>User Group Targeting</div>
          <div className={styles.txt}>
            Target this Learning Path to the User Groups mentioned below. If you do not want this Learning Path to be accessible to a particular User Group(s), 
            you can remove them from the accessibility list. You can specify the type by selecting from the options given below each User Group.
            {remoteConfig?.user_group_country_targeting ? (
              <div>
                <b>
                  Note: At least one country must be selected in Country Availability to move forward in the Create Learning Path wizard.{' '}
                </b>
              </div>
            ) : null}
          </div>
        </div>

        {remoteConfig?.user_group_country_targeting ? (
          <CountrySelection
            countryList={countryList}
            handleClearAll={handleCountryClearAll}
            handleSelectAll={handleCountrySelectAll}
            handleCountrySelect={handleCountrySelectA}
            isLive={data?.state === 'Live'}
          ></CountrySelection>
        ) : null}

        <div className={styles.user_grp_row_wrapper}>
          <div
            className={[styles.user_group_container, styles.info_wrapper, 'col-md-12'].join(' ')}
          >
            <>
              <div className={[styles.card_list_wrapper, styles.padding_right].join(' ')}>
                {addUserGroupList?.length == 0 && deleteUserGroupList?.length > 0 && (
                  <div className={styles.note_wrapper}>
                    <NoteCardComponent textName={'Learning Path'} />
                  </div>
                )}
                {addUserGroupList?.length > 0 && (
                  <div className={styles.title}>User Groups with Learning Path Access</div>
                )}
                <div className={styles.card_wrapper}>
                  {addUserGroupList?.map((element, index) => {
                    return (
                      <CardNewComponent
                        key={index}
                        ugCourseTitle={element?.title || element?.audGrpName}
                        id={element?.id || element?.audGrpName}
                        isDisabled={disableStateUserManagement}
                        audienceGrpDescription={element?.audienceGrpDescription}
                        onHandleClickRadio={(ele, id) => {
                          handleDeleteUserTargetingGroups(
                            {
                              learningPathType: ele,
                              id,
                              title: element?.title || element?.audGrpName,
                              audienceGrpDescription:
                                element?.audienceGrpDescription || element.description,
                            },
                            addUserGroupList,
                          );
                        }}
                        courseType={element?.type ? element?.type : element?.learningPathType}
                        onButtonClick={() => {
                          handleDeleteUserGroups({
                            learningPathType: element?.type
                              ? element?.type
                              : element?.learningPathType,
                            id: element?.title || element?.audGrpName,
                            title: element?.title || element?.audGrpName,
                            audienceGrpDescription:
                              element?.audienceGrpDescription || element.description,
                          });
                        }}
                        disableClickBanner={() => {
                          disableClickBanner();
                        }}
                        isQuickStart={false}
                        bonusIcon={true}
                      />
                    ); /* single_line_course_type => Use this className for single row radio item */
                  })}
                </div>
              </div>
            </>
            {
              <>
                <div className={[styles.card_list_wrapper, styles.padding_left].join(' ')}>
                  <div className={styles.title}>User Groups With No Learning Path Access</div>
                  {deleteUserGroupList?.length > 0 ? (
                    <>
                      <div className={styles.card_wrapper}>
                        {deleteUserGroupList?.map((element, index) => {
                          return (
                            <CardNewComponent
                              key={index}
                              ugCourseTitle={element?.title || element?.audGrpName}
                              withoutCourses={true}
                              onHandleClickRadio={(ele) => {
                                handleAddUserTargetingGroups({
                                  learningPathType: ele,
                                  id: element?.id,
                                });
                              }}
                              courseType={element?.type ? element?.type : element?.learningPathType}
                              id={element?.id || element?.audGrpName}
                              isDisabled={disableStateUserManagement}
                              onButtonClick={() => {
                                handleAddUserTargetingGroups({
                                  learningPathType: 'General visibility',
                                  id: element?.title || element?.audGrpName,
                                  title: element?.title || element?.audGrpName,
                                  audienceGrpDescription: element?.audienceGrpDescription,
                                });
                              }}
                              disableClickBanner={() => {
                                disableClickBanner();
                              }}
                              audienceGrpDescription={element?.audienceGrpDescription}
                              bonusIcon={true}
                            />
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className={styles.usergroup_withoutcourse_wrapper}>
                      <EmptyScreen />
                    </div>
                  )}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  countryList: state && state.uiState && state.uiState.countryList,
});

const mapDispatchToProps = (dispatch) => ({
  setCountryList: (options) => dispatch(setCountryList(options)),
});

UserTargetingLP.propTypes = {};
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(UserTargetingLP));
