import React from 'react';
import styles from './gridListSwitch.module.scss';

const GridListSwitch = (props) => {
  const {setIsCardView = true, setIsListView = false, isCardView, isListView} = props;

  const handleToggleSwitch = (value) => {
    if (value === 'listView') {
      setIsCardView(false);
      setIsListView(true);
    } else {
      setIsCardView(true);
      setIsListView(false);
    }
  };
  return (
    <React.Fragment>
      <div className={styles.toggle_section_wapper}>
        <div className={styles.toggle_wrapper}>
          <div
            className={[
              styles.toggle_section,
              styles.card_radius,
              isCardView ? styles.card_selected : '',
            ].join(' ')}
            onClick={() => handleToggleSwitch('cardView')}
          >
            <div className={styles.card_view} />
          </div>

          <div
            className={[
              styles.toggle_section,
              styles.view_radius,
              isListView ? styles.view_selected : '',
            ].join(' ')}
            onClick={() => handleToggleSwitch('listView')}
          >
            <div className={styles.list_view} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

GridListSwitch.propTypes = {};

export default GridListSwitch;
