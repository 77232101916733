import React, {useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import AddImageComponent from '../components/contentManagement/addImagePage';

const AddNewImage = () => {
  const [editImageExtends, seteditImageExtends] = useState('hide');
  const [croppedImg, setCroppedImg] = useState(undefined);
  const [croppedImg1, setCroppedImg1] = useState(undefined);
  const [mainImg, setMainImg] = useState(undefined);
  const [ratio1, setRatio1] = useState(false);
  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          {/* <SideNavigationComponent pageName="CONTENT-MANAGEMENT" {...props} /> */}
          <AddImageComponent
            editImageExtends={editImageExtends}
            seteditImageExtends={seteditImageExtends}
            croppedImg={croppedImg}
            setCroppedImg={setCroppedImg}
            mainImg={mainImg}
            setMainImg={setMainImg}
            croppedImg1={croppedImg1}
            setCroppedImg1={setCroppedImg1}
            ratio1={ratio1}
          />
        </main>
      </div>
    </div>
  );
};

AddNewImage.propTypes = {};

export default AddNewImage;
