import React from 'react';
import LoadingShimmerList from '../../../shared/LoadingShimmerList';
import CourseTypePopUp from '../CourseTypePopUp';
import ListViewColumnTitles from '../ListViewColumnTitles';
import styles from '../ManageCourses.module.scss';
const ListViewManageCourses = (props) => {
  const {
    coursesUIData,
    clikedData,
    totalNumberRemovedCourses,
    setTotalNumberRemovedCourses,
    totalNumberAddedCourses,
    setTotalNumberAddedCourses,
    setClikedDataCopy,
    clikedDataCopy,
    coursesAPIResponse,
    setAaa,
    setBbb,
    setClikedData,
    coursesUIDataCopy,
    setSelectedId,
    setRadioButtonData,
    handleCourseAddRemove,
    mapCoursesToAudience,
    setChecked,
    isChecked,
    CheckBoxBlue,
    isCardView,
    isPopUpClicked,
    setPopUpClicked,
    isListView,
    apiCourseType,
    apiCourseTypeCopy,
    setCourseTypeChanged,
    handleRadioButtomOnClick,
    dateIsoToTime,
    handleCheckClick,
    dateIsoToDate,
    closeOpenPopUpButton,
    bbb,
    NewRadioButtonsComponent,
    radioButtonData,
    EmptyScreenComponent,
    isSearchFilterEmptyState,
    selectedId,
    isLoading,
  } = props;
  return (
    <div className={[styles.table_main_wrapper, styles.table_reduced_width].join(' ')}>
      <div className={styles.table_container}>
        <ListViewColumnTitles />
        {!isLoading && coursesUIData?.length > 0 ? (
          <div className={styles.tbl_body}>
            <div className={[styles.tbl_row_wrapper].join(' ')}>
              {/* Please add selected state/class(selected) for row here */}
              {coursesUIData && coursesUIData.length > 0
                ? coursesUIData.map((course, index) => {
                    const {
                      courseId,
                      courseState,
                      description,
                      timeToShow,
                      dateToShowForCardView,
                      thumbnail,
                      title,
                      className,
                    } = course;
                    return (
                      <div
                        id={courseId}
                        className={[
                          styles.row_outer_wrapper,
                          clikedData?.some((e) => e?.courseId === courseId) ? styles.selected : '',
                        ].join(' ')}
                        key={index}
                      >
                        <div
                          className={[
                            styles.tbl_row,
                            // styles.selected,
                          ].join(' ')}
                          // onClick={() => fetchSelectedCourse(courseId)}
                        >
                          <div
                            id={courseId}
                            className={`${styles.checkbox_wrapper} ${
                              clikedData?.some((e) => e?.courseId === courseId)
                                ? styles.active
                                : null
                            }`}
                            onClick={(e) => {
                              handleCourseAddRemove({
                                e,
                                setAaa,
                                setBbb,
                                setClikedData,
                                coursesUIDataCopy,
                                clikedData,
                                totalNumberRemovedCourses,
                                setTotalNumberRemovedCourses,
                                totalNumberAddedCourses,
                                setTotalNumberAddedCourses,
                                setClikedDataCopy,
                                clikedDataCopy,
                                coursesAPIResponse,
                              }),
                                mapCoursesToAudience({
                                  course,
                                  setSelectedId,
                                  setRadioButtonData,
                                });
                            }}
                          >
                            <div className={`${styles.check_box}`}></div>
                          </div>

                          <div className={styles.tbl_data}>
                            <div className={styles.inner_data}>
                              {thumbnail ? (
                                <img className={styles.img} src={thumbnail} />
                              ) : (
                                <div
                                  className={[
                                    // styles.img,
                                    styles.empty_state_img,
                                  ].join(' ')}
                                ></div>
                              )}
                            </div>
                          </div>
                          <div className={styles.tbl_data_wrapper}>
                            <div
                              id={courseId}
                              className={styles.tbl_data}
                              onClick={(e) => {
                                handleCourseAddRemove({
                                  e,
                                  setAaa,
                                  setBbb,
                                  setClikedData,
                                  coursesUIDataCopy,
                                  clikedData,
                                  totalNumberRemovedCourses,
                                  setTotalNumberRemovedCourses,
                                  totalNumberAddedCourses,
                                  setTotalNumberAddedCourses,
                                  setClikedDataCopy,
                                  clikedDataCopy,
                                  coursesAPIResponse,
                                }),
                                  mapCoursesToAudience({
                                    course,
                                    setSelectedId,
                                    setRadioButtonData,
                                  });
                              }}
                            >
                              <CheckBoxBlue
                                isChecked={clikedDataCopy?.some((e) => e?.courseId === courseId)}
                                handleCheckClick={() => {
                                  handleCheckClick({
                                    setChecked,
                                    isChecked,
                                  });
                                }}
                              />
                            </div>
                            <div className={styles.tbl_data}>
                              <div className={styles.inner_data}>
                                <div className={[styles.txt, ''].join(' ')}>{title || 'NA'}</div>
                              </div>
                            </div>
                            <div className={styles.tbl_data}>
                              {courseState === 'Draft' && (
                                <div className={[styles.txt].join(' ')}>
                                  <div>
                                    {course?.launchDate ? dateIsoToTime(course?.launchDate) : 'NA'}
                                  </div>
                                </div>
                              )}
                              {courseState !== 'Draft' && (
                                <div className={[styles.txt].join(' ')}>
                                  <div>
                                    {course?.launchDate ? dateIsoToTime(course?.launchDate) : 'NA'}
                                  </div>
                                  <div>
                                    {course?.launchDate ? dateIsoToDate(course?.launchDate) : 'NA'}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={styles.tbl_data}>
                              <div className={styles.inner_data}>
                                <div
                                  className={`${styles.status} ${
                                    styles[course?.courseState?.toLowerCase()]
                                  }`}
                                >
                                  <div className={styles.status_txt}>{courseState || 'NA'}</div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.tbl_data}>
                              <div className={styles.inner_data}>
                                {/* Please add here "type_selected" class when course type is added it should be activated */}
                                {clikedData?.some((e) => e?.courseId === courseId) && (
                                  <div
                                    className={[styles.type_wrapper, styles.type_selected].join(
                                      ' ',
                                    )}
                                  >
                                    <div
                                      id={courseId}
                                      className={[
                                        styles.course_type_icon,
                                        course?.courseType == 'Quick Start'
                                          ? [styles.qstart_icon].join(' ')
                                          : course?.courseType == 'Required'
                                          ? [styles.req_icon].join(' ')
                                          : course.courseType == 'General visibility'
                                          ? [styles.gen_visiblity_icon].join(' ')
                                          : course.courseType == 'Recommended'
                                          ? [styles.recom_icon].join(' ')
                                          : [styles.gen_visiblity_icon].join(' '),
                                      ].join(' ')}
                                      onClick={(e) => {
                                        closeOpenPopUpButton({
                                          e,
                                          isCardView: false,
                                          setAaa,
                                          isPopUpClicked,
                                          setPopUpClicked,
                                          setBbb,
                                          isListView: true,
                                        });
                                        mapCoursesToAudience({
                                          course,
                                          setSelectedId,
                                          setRadioButtonData,
                                        });
                                      }}
                                    ></div>
                                    {/* coursepopoup */}

                                    {isPopUpClicked && courseId === bbb ? (
                                      <div className={styles.course_type_popup}>
                                        <div className={styles.custom_overlay}></div>
                                        <div className={styles.popup_wrapper} id={'divPopUp'}>
                                          <div
                                            className={styles.mob_bar}
                                            onClick={(e) => {
                                              closeOpenPopUpButton({
                                                e,
                                                isCardView,
                                                setAaa,
                                                isPopUpClicked,
                                                setPopUpClicked,
                                                setBbb,
                                                isListView,
                                              });
                                            }}
                                          >
                                            <div className={styles.line_bar}></div>
                                          </div>
                                          <div className={styles.title}>Course Type</div>
                                          <div className={styles.course_types}>
                                            {/* Add selected state for Every course type */}
                                            {radioButtonData?.map((item, index) => {
                                              return (
                                                <CourseTypePopUp
                                                  NewRadioButtonsComponent={
                                                    NewRadioButtonsComponent
                                                  }
                                                  selectedId={selectedId}
                                                  index={index}
                                                  handleRadioButtomOnClick={
                                                    handleRadioButtomOnClick
                                                  }
                                                  item={item}
                                                  course={course}
                                                  apiCourseTypeCopy={apiCourseTypeCopy}
                                                  apiCourseType={apiCourseType}
                                                  setSelectedId={setSelectedId}
                                                  setCourseTypeChanged={setCourseTypeChanged}
                                                  key={index}
                                                  setPopUpClicked={setPopUpClicked}
                                                />
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : !isLoading && isSearchFilterEmptyState ? (
          <EmptyScreenComponent />
        ) : null}
        {isLoading && (
          <div className={[styles.loading_shimmer_list_wrapper].join(' ')}>
            <LoadingShimmerList
              count={20}
              className={[styles.loading_shimmer_manage_course].join(' ')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListViewManageCourses;
