import React from 'react';
import styles from '../Content.module.scss';

const NoInstanceMessage = (props) => {
  const {courseDetails} = props;
  if (courseDetails?.length <= 0) {
    return (
      <div className={[styles.empty_instances_wrapper].join(' ')}>
        <div className={styles.txt}>Image not associated with any Instance</div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default NoInstanceMessage;
