import React, { useEffect } from 'react';
import styles from './AdaptFile.module.scss';
// import CheckboxComponent from '';
// import EditImageModal from '../addImagePage/EditImageModal/index';

const AdaptFileModalComponent = (props) => {
  const {unzipState, openPreviewFile, closeModal, localState = ' '} = props;
  useEffect(() => {
    if (unzipState === 'SUCCESS') {
      closeModal();
    }
  },[unzipState])
  return (
    <React.Fragment>
      <div className={[styles.modal_wrapper, ''].join(' ')}>
        <div className={styles.adapt_file_modal}>
          <div className={styles.title_wrapper}>
            <div className={styles.title}>{`Preview Course File`}</div>
          </div>
          <div className={styles.middle_body_wrapper}>
            <div
              // className={[styles.status_wrapper, styles.success_tab, styles.failure_tab].join(' ')}
              className={[
                styles.status_wrapper,
                unzipState === 'SUCCESS' ? styles.success_tab : '',
                unzipState === 'FAILURE' ? styles.failure_tab : '',
              ].join(' ')}
            >
              <div className={styles.txt}>{`File Hosting ${
                unzipState === 'INPROGRESS'
                  ? 'In-Progress'
                  : unzipState === 'SUCCESS'
                  ? 'Completed!'
                  : 'Failed!'
              }`}</div>
              {unzipState === 'INPROGRESS' && <div className={styles.inprogress_icon}></div>}
            </div>
            <div className={styles.description}>
              {unzipState !== 'FAILURE'
                ? `Preview  Course file before publishing the course.`
                : 'Since the File Hosting Failed, Please re-upload the Course file to continue.'}
            </div>
          </div>
          <div className={styles.btn_wrapper}>
            <div
              className={[styles.btn, unzipState !== 'INPROGRESS' ? styles.active : ''].join(' ')}
              onClick={() => {
                if (unzipState === 'FAILURE') {
                  closeModal();
                } else if (unzipState === 'SUCCESS') {
                  openPreviewFile();
                  closeModal();
                }
              }}
            >
              {unzipState !== 'FAILURE' ? 'Preview Course file' : 'Go Back'}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

AdaptFileModalComponent.propTypes = {};

export default AdaptFileModalComponent;
