import React from 'react';
import styles from './DrawerFailScreen.module.scss';
import {dummyFunction} from '../../utils/commonUtil';
const DrawerFailScreen = (props) => {
  const {drawerId, drawerTryAgainCall = dummyFunction} = props;

  return (
    <React.Fragment>
      <div className={styles.api_fail_wrapper}>
        <div className={styles.api_fail_img}></div>
        <div className={styles.sub_text}>Looks like the link is broken</div>
        <div
          className={styles.try_again_btn}
          onClick={() => {
            drawerTryAgainCall(drawerId);
          }}
        >
          Try Again <span className={styles.refresh_img}></span>
        </div>
      </div>
    </React.Fragment>
  );
};

DrawerFailScreen.propTypes = {};

export default DrawerFailScreen;
