import React, {useEffect, useState} from 'react';
import styles from './EditImage.module.scss';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ImageCropper from './ImageCropper';
import NewCropper from './NewImageCropper';
import {
  setCroppedImageData,
  setCroppedImageData169,
  setCroppedImageData11,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setButton_169_status,
  setButton_11_status,
  setThumbnailImage169,
} from '../../../../redux/actions/imageCrop/imageCropAction';
import {setCropDialogue} from '../../../../redux/actions/actionImageLibrary';
import {closeEditImageExtends} from '../../../utils/ContentManagementUtils/EditImageUtils';

const CropImageModalComponent = (props) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const {imageId = false} = query;
  const {
    seteditImageExtends,
    setCroppedImg,
    ratio,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setButton_169_status,
    setButton_11_status,
    setCroppedImageData169,
    setCroppedImageData11,
    local_main_image_data,
    setCropDialogue,
    setThumbnailImage169,
  } = props;

  const [mainImgState, setMainImgState] = useState([]);
  const [croppedImg_11, setCroppedImg_11] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    const ratio = JSON.parse(localStorage.getItem('ratio'));
    // const mainImg = localStorage.getItem("mainImg");
    const croppedImg_11 = localStorage.getItem('croppedImg_11');
    if (ratio && local_main_image_data) {
      setMainImgState(local_main_image_data);
      setCroppedImg_11(croppedImg_11);
    }
  }, []);

  function closeEditImageExtends1() {
    if (ratio === '16:9' || ratio === '1:1') {
      router.push({
        pathname: '/Contentmanagement',
        query: {
          imgId: imageId,
        },
      });
      setCropDialogue(true);
    } else {
      router.push({
        pathname: '/add-new-image',
        query: {
          imgId: imageId,
        },
      });
    }
    seteditImageExtends('hide');
  }

  return (
    <React.Fragment>
      <div className={[styles.modal_wrapper].join(' ')}>
        {/* Edit image page Start */}
        <div className={[styles.edit_image_container].join(' ')}>
          {/* Header Component */}
          <div className={styles.header_main_wrapper}>
            <div className={styles.header_wrapper}>
              <div className={[styles.heading].join(' ')}>Edit image extents</div>
              <div className={styles.btn_wrapper}>
                <button
                  className={styles.btn}
                  onClick={() => {
                    closeEditImageExtends(
                      ratio,
                      setCroppedImage_16_9,
                      setButton_169_status,
                      setCroppedImage_1_1,
                      setButton_11_status,
                      setCroppedImg,
                      seteditImageExtends,
                      router,
                      imageId,
                      setCroppedImageData,
                      setCroppedImageData169,
                      setCroppedImageData11,
                    );
                  }}
                >
                  Cancel
                </button>
                <button
                  className={[styles.btn, isSaveDisabled ? styles.disabled : styles.active].join(
                    ' ',
                  )}
                  onClick={closeEditImageExtends1}
                >
                  Crop
                </button>
              </div>
            </div>
          </div>

          {(ratio === '16:9' || ratio === '169') && (
            <ImageCropper
              imageToCrop={mainImgState}
              onImageCropped={(croppedImage, fileData) => {
                setIsSaveDisabled(false);
                setCroppedImage_16_9(croppedImage);
                setCroppedImageData169({
                  img: croppedImage,
                  ratio: '16:9',
                  imgFile: fileData,
                });
                setThumbnailImage169({
                  img: croppedImage,
                  ratio: '16:9',
                  imgFile: fileData,
                });
              }}
              aspect_ratio={16 / 9}
              sourceImage={local_main_image_data}
            />
          )}

          {(ratio === '1:1' || ratio === '11') && (
            <ImageCropper
              imageToCrop={mainImgState}
              onImageCropped={(croppedImage, fileData) => {
                setIsSaveDisabled(false);
                setCroppedImage_1_1(croppedImage);
                setCroppedImageData11({
                  img: croppedImage,
                  ratio: '1:1',
                  imgFile: fileData,
                });
              }}
              aspect_ratio={1}
              sourceImage={local_main_image_data}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

CropImageModalComponent.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  localState: state,
  ratio: state && state.croppedImage && state.croppedImage.ratio,
  local_main_image_data: state && state.croppedImage && state.croppedImage.local_main_image_data,
});

const mapDispatchToProps = (dispatch) => ({
  setCroppedImageData: (options) => dispatch(setCroppedImageData(options)),
  setCroppedImageData11: (options) => dispatch(setCroppedImageData11(options)),
  setCroppedImageData169: (options) => dispatch(setCroppedImageData169(options)),
  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),
  setButton_169_status: (options) => dispatch(setButton_169_status(options)),
  setButton_11_status: (options) => dispatch(setButton_11_status(options)),
  setCropDialogue: (options) => dispatch(setCropDialogue(options)),
  setThumbnailImage169: (options) => dispatch(setThumbnailImage169(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CropImageModalComponent));
