import React, {useEffect, useState, useRef} from 'react';
import styles from './CourseSequencing.module.scss';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import {ReactComponent as CourseSequencingImg} from '../../../styles/images/Illustration_BuildingYourA.svg';
import LoadingShimmerList from '../../shared/LoadingShimmerList';
import StatusModal from './StatusModal';

const CreateCoursesAddition = ({
  addedCourseList,
  setDeletedCourselist,
  setErrorStateTemplate,
  setAddedCourseList,
  setSubheader,
  subheader,
  refreshList,
  isRefresh,
  setNotificationData,
  data,
}) => {
  // API state
  const [coursesUIData, setCoursesUiData] = useState([]);
  const [width, setWidth] = useState(0);
  const [statusIcon, setStatusIcon] = useState(false);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [isOnline, setOnline] = useState(true);
  const queryAttr = 'data-rbd-drag-handle-draggable-id';
  useEffect(() => {
    if (addedCourseList && addedCourseList.length && addedCourseList.length > 0) {
      let _coursesUIData = [];
      addedCourseList.map((course, index) => {
        _coursesUIData.push({id: `${index}`, course: getCoursesUIData(course)});
      });
      setCoursesUiData(_coursesUIData);
    }
  }, [addedCourseList]);
  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    //setHeight(window.innerHeight);
  };
  const getCoursesUIData = (course) => {
    const {courseId, course_state, state, courseState, description, launch_date, thumbnail, title} =
      course;
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let dateToShowForListView = ' ';
    let dateToShowForCardView = ' ';
    let timeToShow = ' ';
    if (launch_date) {
      const date = new Date(launch_date);
      const hours = date.getHours();
      const mins = date.getMinutes();
      timeToShow = (hours > 12 ? hours - 12 : hours) + ':' + mins + (hours > 12 ? ' pm' : ' am');
      dateToShowForListView =
        months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
      dateToShowForCardView =
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    } else {
      dateToShowForListView = ' ';
      dateToShowForCardView = ' ';
    }

    let className = '';
    switch (course_state || state || courseState) {
      case 'Live':
        className = 'live';
        break;
      case 'Offline':
        className = 'offline';
        break;
      case 'Draft':
        className = 'draft';
        break;
      case 'Archive':
        className = 'archive';
        break;
    }

    return {
      courseId,
      course_state,
      courseState,
      description,
      thumbnail,
      title,
      state,
      timeToShow,
      dateToShowForListView,
      dateToShowForCardView,
      className,
      launch_date,
    };
  };

  useEffect(() => {
    updateWindowDimensions();
    setOnline(navigator.onLine);
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  const onDragEnd = (result) => {
    setPlaceholderProps({});
    if (!result.destination) {
      return;
    }
    const items = reorder(coursesUIData, result.source.index, result.destination.index);
    setCoursesUiData(items);
    reOrderCourselist(items);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    left: '0px',
    top: '72px',

    /* 4 Light Purple */

    //background: isDragging ? '#F1F2FF' : '',
    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',
    pointerEvents: 'auto',
    cursor: isDragging ? 'all-scroll' : 'pointer',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    // color: isDraggingOver ? 'black' : 'white',
    //background: isDraggingOver ? '#F1F2FF' : '',
    cursor: 'all-scroll',
  });
  // const onDragUpdate = (update) => {
  //   if (!update.destination) {
  //     setPlaceholderProps({display: 'none'});
  //     return;
  //   }
  //   const draggableId = update.draggableId;
  //   const destinationIndex = update.destination.index;
  //   const sourceIndex = update.source.index;
  //   let index = 0;
  //   if (destinationIndex > sourceIndex) {
  //     index = destinationIndex - sourceIndex;
  //   } else if (destinationIndex < sourceIndex) {
  //     index = sourceIndex - destinationIndex;
  //   }
  //   const domQuery = `[${queryAttr}='${draggableId}']`;
  //   const draggedDOM = document.querySelector(domQuery);

  //   if (!draggedDOM) {
  //     return;
  //   }
  //   const {clientHeight, clientWidth} = draggedDOM;
  //   const top = draggedDOM.offsetTop;
  //   const left = draggedDOM.offsetLeft;
  //   const clientY =
  //     parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
  //     [...draggedDOM.parentNode.children].slice(0, index).reduce((total, curr) => {
  //       const style = curr.currentStyle || window.getComputedStyle(curr);
  //       const marginBottom = parseFloat(style.marginBottom);
  //       console.log(total, curr.clientHeight, index);
  //       if (destinationIndex > sourceIndex) {
  //         //return total + curr.clientHeight + 15;
  //         return total + curr.clientHeight + 15;
  //       } else if (destinationIndex < sourceIndex) {
  //         return total - curr.clientHeight - 15;
  //       }
  //     }, top);

  //   setPlaceholderProps({
  //     clientHeight,
  //     clientWidth,
  //     clientY,
  //     clientX: parseFloat(left),
  //     display: 'block',
  //   });
  // };

  const onDragUpdate = (event) => {
    if (!event.destination) {
      setPlaceholderProps({display: 'none'});
      return;
    }
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const {clientHeight, clientWidth} = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;
    const top = draggedDOM.offsetTop;
    const left = draggedDOM.offsetLeft;
    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, top);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(left),
    });
  };
  const getDraggedDom = (draggableId) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };
  const onDragStart = (start) => {
    const draggableId = start.draggableId;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);
    const {clientHeight, clientWidth} = draggedDOM;
    const top = draggedDOM.offsetTop;
    const left = draggedDOM.offsetLeft;
    // setPlaceholderProps({
    //   clientHeight,
    //   clientWidth,
    //   clientY: parseFloat(top),
    //   clientX: parseFloat(left),
    //   display: 'block',
    // });
  };

  const handleCoureEdit = (id = 1) => {
    const url = '/coursesManagement';
    // console.log(window.location.href);
    const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');
  };
  const handleCoureDelete = (id = 1) => {
    if ((data && data.state == 'Live') || (data && data.state == 'Offline')) {
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Course Addition disabled',
        description: `You cannot add or remove courses from a Learning Path once its published. You can however change the sequence of the course in the Learning Path by dragging the courses to the desired position.`,
      });
      return;
    } else {
      let _coursesUIData = [];
      let courseList = [];
      _coursesUIData = Array.from(coursesUIData);
      const index = _coursesUIData?.findIndex((ele) => {
        return ele.course.courseId == id;
      });
      if (index !== undefined && index !== -1) {
        _coursesUIData.splice(index, 1);
      }
      setCoursesUiData(_coursesUIData);
      _coursesUIData?.some((data) => {
        courseList.push(data.course);
      });
      setDeletedCourselist(courseList);
    }
  };

  const reOrderCourselist = (list) => {
    let tempArr = [];

    list.forEach((data) => {
      tempArr.push(data.course);
    });
    setAddedCourseList(tempArr);
  };
  const handleClickOutside = (props) => {
    const {e} = props;
    const el = document.getElementById('divParentOfDrawer');
    if (el !== null) {
      const outside = el?.contains(e.target);
      if (outside) {
        if (e.target.className.includes('StatusModal_modal_wrapper')) {
          setStatusIcon(false);
        }
      }
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      handleClickOutside({e});
    });
  }, [statusIcon]);
  return (
    <React.Fragment>
      <div className={[styles.learning_path_main_wrapper, ''].join(' ')}>
        <div className={[styles.course_mgt_wrapper, ''].join(' ')}>
          {/* <div className={[styles.audience_wrapper, styles.is_drawer_open].join(" ")}> */}
          <div className={[styles.audience_wrapper, styles.course_inner_wrapper, ''].join(' ')}>
            {/* <SeverErrorComponent /> */}
            {addedCourseList?.length > 1 ? (
              <div className={[styles.main_wrapper, ''].join(' ')}>
                {/* Course Addition Title & Description Starts */}
                <div className={styles.course_description_wrapper}>
                  <div className={styles.course_title}>Course Sequencing</div>
                  <div className={styles.course_description}>
                    To re-order courses drag using the handles on the left. Please note that the
                    ABOs are free to start any course at any given time.
                    <div className={styles.bold_text}>
                      Only Live Courses in a Learning Path will be published. Remove any other type
                      of course before publishing. To change the status of a particular course,
                      click on the Edit icon to open the ‘Edit Course’ page.
                    </div>
                  </div>
                </div>

                <div
                  className={styles.refresh_list}
                  onClick={() => {
                    refreshList();
                  }}
                >
                  <div className={styles.refresh_wrapper}>
                    <div className={styles.refresh_btn}>Refresh List</div>
                    <div
                      className={isRefresh ? styles.refresh_icon_loading : styles.refresh_icon}
                    ></div>
                  </div>
                </div>
                {/* Course Addition Title & Description Ends */}

                {/* <TopBarComponenent showRoundToggler={true} showGridListSwitch={true} /> */}
                {/* Notification bar */}
                {/* <div className={}></div> */}
                {/* END of notification bar */}
                <div className={styles.inner_wrapper}>
                  {/* Card View Started  */}
                  <div
                    className={[styles.table_main_wrapper, styles.table_reduced_width].join(' ')}
                  >
                    <div className={styles.table_container}>
                      <div className={styles.tbl_header}>
                        <div className={styles.tbl_head}>Thumbnail</div>
                        <div className={styles.tbl_head_wrapper}>
                          <div className={styles.tbl_head}>Title</div>
                          <div className={styles.tbl_head}>
                            Status{' '}
                            <span
                              className={styles.status_icon}
                              onClick={() => {
                                setStatusIcon(!statusIcon);
                              }}
                            />
                            {statusIcon ? (
                              <div id={'divParentOfDrawer'}>
                                <StatusModal />
                              </div>
                            ) : null}
                          </div>
                          <div className={styles.tbl_head}>Actions</div>
                        </div>
                      </div>
                      <div className={styles.tbl_body}>
                        {isRefresh ? (
                          <div className={[styles.tbl_row_wrapper].join(' ')}>
                            <LoadingShimmerList count={6} />
                          </div>
                        ) : (
                          <div className={[styles.tbl_row_wrapper].join(' ')}>
                            <DragDropContext
                              onDragEnd={onDragEnd}
                              onDragUpdate={onDragUpdate}
                              onDragStart={onDragStart}
                            >
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="droppable"
                                    style={getListStyle(snapshot.isDraggingOver)}
                                  >
                                    {coursesUIData?.map((item, index) => {
                                      return (
                                        <Draggable
                                          key={item.id}
                                          draggableId={`${item.id}`}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                              )}
                                              className={`item ${
                                                snapshot.isDragging ? 'dragging' : ''
                                              }`}
                                            >
                                              <div
                                                className={[styles.row_outer_wrapper].join(' ')}
                                                key={index}
                                              >
                                                <div
                                                  className={[styles.tbl_row, styles.selected].join(
                                                    ' ',
                                                  )}
                                                >
                                                  <div
                                                    className={[
                                                      styles.checkbox_wrapper,
                                                      snapshot.isDragging ? styles.active : '',
                                                    ].join(' ')}
                                                  >
                                                    <div className={`${styles.check_box}`}></div>
                                                  </div>

                                                  <div className={styles.tbl_data}>
                                                    <div className={styles.inner_data}>
                                                      {item.course.thumbnail ? (
                                                        <img
                                                          className={styles.img}
                                                          src={item.course.thumbnail}
                                                        />
                                                      ) : (
                                                        <div
                                                          className={[
                                                            // styles.img,
                                                            styles.empty_state_img,
                                                          ].join(' ')}
                                                        ></div>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className={styles.tbl_data_wrapper}>
                                                    <div className={styles.tbl_data}></div>
                                                    <div className={styles.tbl_data}>
                                                      <div className={styles.inner_data}>
                                                        <div className={[styles.txt, ''].join(' ')}>
                                                          {item.course.title || 'NA'}
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className={styles.tbl_data}>
                                                      <div className={styles.inner_data}>
                                                        <div
                                                          className={`${styles.status} ${
                                                            styles[
                                                              item?.course.course_state?.toLowerCase() ||
                                                                item?.course.state?.toLowerCase() ||
                                                                item?.course.courseState?.toLowerCase()
                                                            ]
                                                          }`}
                                                        >
                                                          <div className={styles.status_txt}>
                                                            {item.course.course_state ||
                                                              item.course.state ||
                                                              item.course.courseState ||
                                                              'NA'}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className={styles.tbl_data}>
                                                      <div className={styles.inner_data}>
                                                        <div
                                                          className={styles.edit_icon}
                                                          onClick={() =>
                                                            handleCoureEdit(item.course.courseId)
                                                          }
                                                        />
                                                        <div className={styles.seperator} />
                                                        <div
                                                          className={
                                                            (data && data.state == 'Live') ||
                                                            (data && data.state == 'Offline')
                                                              ? styles.delete_icon_disable
                                                              : styles.delete_icon
                                                          }
                                                          onClick={() =>
                                                            handleCoureDelete(item.course.courseId)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: placeholderProps.clientY,
                                        left: placeholderProps.clientX,
                                        height: placeholderProps.clientHeight,
                                        // display: placeholderProps.display,
                                        display: 'none',
                                        width: placeholderProps.clientWidth,
                                        marginBottom: '15px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          background: 'red',
                                          borderRadius: '12px',
                                          height: 'calc(100% - 15px)',
                                          width: 'inherit',
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>

                            <div className={styles.sequencing_message}>
                              <div className={styles.sequencing_msg}>
                                A Learning Path may contain a maximum of 20 courses.
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.main_wrapper_empty_lp}>
                <div className={styles.emptyLp_wrapper}>
                  <div className={styles.sequencing_empty_img}>
                    <CourseSequencingImg />
                  </div>
                  <div className={styles.sequencing_empty_text_header}>Missing Courses!</div>
                  <div className={styles.sequencing_empty_text_subheader}>
                    {width > 600 ? (
                      <div>
                        <div>
                          {' '}
                          As there are no Courses added to this Learning Path, Course Sequencing is
                          disabled.
                        </div>
                        <div>
                          Add a minimum of 2 or more courses. Go to ‘Course Addition’ tab to add
                          courses to this Learning Path.
                        </div>
                      </div>
                    ) : (
                      <div>
                        As there are no Courses added to this Learning Path, Course Sequencing is
                        disabled. Add a minimum of 2 or more courses. Go to ‘Course Addition’ tab to
                        add courses to this Learning Path.
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.sequencing_empty_text_button_wrapper}
                    onClick={() => setSubheader('Course Addition')}
                  >
                    <div className={styles.sequencing_empty_text_button}>Course Addition</div>
                    <div className={styles.sequencing_empty_text_button_line}></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CreateCoursesAddition.propTypes = {};

export default CreateCoursesAddition;
