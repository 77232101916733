import React from 'react';
import styles from '../ManageCourses.module.scss';

const ListViewColumnTitles = () => {
  return (
    <div className={styles.tbl_header}>
      <div className={styles.tbl_head}>Thumbnail</div>
      <div className={styles.tbl_head_wrapper}>
        <div className={styles.tbl_head}>Course Name</div>
        <div className={styles.tbl_head}>Upload date</div>
        {/* <div className={styles.tbl_head}>
                          <div className={styles.active_user_icon}></div>
                          <span className={styles.user_txt}>Users</span>
                        </div> */}
        <div className={styles.tbl_head}>Status</div>
        <div className={styles.tbl_head}>Type</div>
      </div>
    </div>
  );
};

export default ListViewColumnTitles;
