import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import styles from './NetworkScreen.module.scss';
import {ReactComponent as NoInternetImage} from '../../../styles/images/illustration_network.svg';
const NoInternetConnection = ({errorTemplateState}) => {
  const [width, setWidth] = useState(0);
  const [isOnline, setOnline] = useState(true);
  window.addEventListener('online', () => {
    setOnline(true);
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
  });
  //const [height, setHeight] = useState(0);
  const [variant, setVariant] = useState(null);
  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    //setHeight(window.innerHeight);
  };
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    errorTemplateState?.variant && setVariant(errorTemplateState?.variant);
  }, [errorTemplateState]);
  useEffect(() => {
    setOnline(navigator.onLine);
  }, [navigator.onLine]);

  const networkErrorStyle = () => {
    switch (true) {
      case width < 768:
        return [styles.image_wrapper, styles.empty_img].join(' ');
      case width > 768 && width < 1024:
        return [styles.image_wrapper, styles.empty_img_medium].join(' ');
      case width >= 1024:
        return [styles.image_wrapper, styles.empty_img_large].join(' ');
      default:
        return [styles.image_wrapper, styles.empty_img].join(' ');
    }
  };

  const serverErrorStyle = () => {
    switch (true) {
      case width < 768:
        return [styles.image_wrapper, styles.empty_img_server_error].join(' ');
      case width > 768 && width < 1024:
        return [styles.image_wrapper, styles.empty_img_server_error_medium].join(' ');
      case width >= 1024:
        return [styles.image_wrapper, styles.empty_img_server_error_large].join(' ');
      default:
        return [styles.image_wrapper, styles.empty_img_server_error].join(' ');
    }
  };

  const NetworkScreenErrorComponent = () => {
    return (
      // add here class "courses_screen_height" which should reflect only for course management page
      <div className={[styles.empty_container, ''].join(' ')}>
        <div className={[styles.empty_screen_wrapper].join(' ')}>
          <div className={styles.inner_wrapper_empty}>
            <div className={styles.empty_screen}>
              <NoInternetImage
                className={
                  networkErrorStyle()
                  // width < 768
                  //   ? [styles.image_wrapper, styles.empty_img].join(" ")
                  //   : width > 768 && width < 1024
                  //     ? [styles.image_wrapper, styles.empty_img_medium].join(" ")
                  //     : width >= 1024
                  //       ? [styles.image_wrapper, styles.empty_img_large].join(" ")
                  //       : [styles.image_wrapper, styles.empty_img].join(" ")
                }
              />
              <div className={styles.content_wrapper}>
                <div className={styles.label}>No network available</div>
                <div className={styles.content}>
                  <div>You appear to be offline. Please check your</div>
                  <div>internet connection and try again.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SeverErrorComponent = () => {
    return (
      // add here class "courses_screen_height" which should reflect only for course management page
      <div className={[styles.empty_container, ''].join(' ')}>
        <div className={[styles.empty_screen_wrapper].join(' ')}>
          <div className={styles.inner_wrapper_empty}>
            <div className={styles.empty_screen}>
              <div
                className={
                  serverErrorStyle()
                  // width < 768
                  //   ? [styles.image_wrapper, styles.empty_img_server_error].join(" ")
                  //   : width > 768 && width < 1024
                  //     ? [styles.image_wrapper, styles.empty_img_server_error_medium].join(" ")
                  //     : width >= 1024
                  //       ? [styles.image_wrapper, styles.empty_img_server_error_large].join(" ")
                  //       : [styles.image_wrapper, styles.empty_img_server_error].join(" ")
                }
              ></div>
              <div className={styles.content_wrapper}>
                <div className={styles.label}>We’ll be right back!</div>
                <div className={styles.content}>
                  Academy is currently unavailable due to ongoing maintenance. We apologize for any
                  inconvenience. Please try again later.{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const IncorrectUrlComponent = () => {
    return (
      // add here class "url_error_wrapper" which should reflect only for url error
      <div className={[styles.empty_container, styles.url_error_wrapper, ''].join(' ')}>
        <div className={[styles.empty_screen_wrapper].join(' ')}>
          <div className={styles.inner_wrapper_empty}>
            <div className={styles.empty_screen}>
              <div
                className={
                  width < 768
                    ? [styles.image_wrapper, styles.url_error_img].join(' ')
                    : width > 768 && width < 1024
                    ? [styles.image_wrapper, styles.url_error_img].join(' ')
                    : width >= 1024
                    ? [styles.image_wrapper, styles.empty_img_server_error_large].join(' ')
                    : [styles.image_wrapper, styles.empty_img_server_error].join(' ')
                }
              ></div>
              <div className={styles.content_wrapper}>
                <div className={styles.label}>Sorry, something went wrong</div>
                <div className={styles.content}>
                  <div>Looks like there is an error in the URL.</div>
                  <div>Please try again.</div>
                </div>
              </div>
              <div className={styles.btn_wrapper}>
                <div className={styles.btn}>Back to Dashboard</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    // add here class "courses_screen_height" which should reflect only for course management page
    variant == 'networkError' ? (
      <NetworkScreenErrorComponent />
    ) : variant == 'serverError' && isOnline ? (
      <SeverErrorComponent />
    ) : variant == '404Error' && isOnline ? (
      <IncorrectUrlComponent />
    ) : !isOnline ? (
      <NetworkScreenErrorComponent />
    ) : null
  );
};
const mapStateToProps = (state) => ({
  isUserLoggedIn: state && state.uiState && state.uiState.isUserLoggedIn,
  errorTemplateState: state && state.uiState && state.uiState.errorTemplateState,
});

export default React.memo(connect(mapStateToProps, null)(NoInternetConnection));
