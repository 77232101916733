import React from 'react';
import styles from '../Portal.module.scss';
import ViewAllComponent from '../ViewAllComponent';

const MobileAudienceSection = (props) => {
  const {
    mobileTabSelected,
    TableListComponent,
    audienceGroupList,
    isSearchFilterEmptyState,
    EmptyScreenComponent,
    redirectUserGroup,
    setSelectedNavigationOption,
    router,
    showEmptyTemplateFor,
    isLoading,
  } = props;

  return (
    <React.Fragment>
      {mobileTabSelected === 'AUDIENCE' ? (
        <div className={[styles.card_wrapper, styles.audience_section].join(' ')}>
          <div className={[styles.audience_image_container, 'hide'].join(' ')}>
            <div className={styles.audience_middle_section}>
              <div className={styles.audience_image_area}>
                <div
                  className={[styles.audience_empty_screen_icon, styles.audience_path_icon].join(
                    ' ',
                  )}
                ></div>
                <div className={styles.empty_screen_path_detail}>
                  <div className={styles.error_label}>User Group Management</div>
                  <div className={styles.error_content}>
                    Ability to target courses at specific groups of ABOs coming later in 2022.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={[styles.audience_details_wrapper, styles.user_grp_wrapper, ''].join(' ')}>
            <TableListComponent
              isLoading={isLoading}
              list={audienceGroupList}
              EmptyScreenComponent={EmptyScreenComponent}
              isSearchFilterEmptyState={isSearchFilterEmptyState}
              onClickAudienceGrp={(e, item) => {
                redirectUserGroup({
                  id: item,
                  viewType: 'card',
                  setSelectedNavigationOption,
                  router,
                });
              }}
              drawerOpen={false}
              headerColor={'white'}
              tableHeaderBold={true}
              small={true}
            />
            <ViewAllComponent
              isLoading={isLoading}
              showEmptyTemplateFor={showEmptyTemplateFor}
              list={audienceGroupList}
              SelectedNavigationOptionText={'AUDIENCE-MANAGEMENT'}
              pageName="User Groups"
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

MobileAudienceSection.propTypes = {};

export default MobileAudienceSection;
