import React from 'react';
import styles from '../Content.module.scss';

const ImageReplaceHandle = (props) => {
  const {
    handleOnClickReplaceImageOnAllInstances,
    handleReplaceAllInstance,
    courseDetails,
    isReplaceAllInstance,
    CourseStatusComponent,
  } = props;
  if (courseDetails?.length > 0) {
    return (
      <div className={[styles.img_instances_container, ''].join('')}>
        <div
          className={styles.insturction_text_wrapper}
          onClick={() => handleOnClickReplaceImageOnAllInstances()}
        >
          <div className={styles.info_text}>Replace Image On All Instances </div>
          <div className={styles.replace_icon}></div>
        </div>

        <div className={styles.dropdown_container} onClick={() => handleReplaceAllInstance()}>
          <div className={styles.top_bar}>
            <div className={styles.title}>
              Instances in which this image is used{' '}
              {courseDetails?.length > 0 ? `(${courseDetails?.length})` : ''}
            </div>
            {courseDetails?.length > 0 ? (
              <div
                className={[
                  styles.toggle_arrow,
                  isReplaceAllInstance ? styles.up_arrow : styles.down_arrow,
                ].join(' ')}
              ></div>
            ) : null}
          </div>
          <div className={styles.list_wrapper}>
            <CourseStatusComponent
              isReplaceAllInstance={isReplaceAllInstance}
              courseDetails={courseDetails}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ImageReplaceHandle;
