import React, {useEffect, useState} from 'react';
import styles from './radioNew.module.scss';

const RadioButtonsNewComponent = (props) => {
  const {id, checked, label, onChangeHandler, item} = props;
  const [checkedValue, setCheckedValue] = useState(false);

  useEffect(() => {
    setCheckedValue(checked);
  }, [checked]);
  return (
    <>
      <label className={styles.container}>
        <input
          type="checkbox"
          // checked={selectedFilterItem && selectedFilterItem.id && item && item.id && selectedFilterItem.id === item.id ? true : checked}
          checked={checkedValue}
          key={id}
          name={name}
          onChange={() => {
            onChangeHandler(item);
          }}
          value={label}
        />
        <span className={styles.checkmark}></span>
        {label}
      </label>
    </>
  );
};

RadioButtonsNewComponent.propTypes = {};

export default RadioButtonsNewComponent;
