import styles from './EmptyScreen.module.scss';
import React from 'react';
// import emptySvg from "../../../../../styles/images/userGroupEmpty.svg";
import emptySvg from '../../../../styles/images/userGroupEmpty.svg';
const EmptyScreen = () => {
  return (
    <div className={styles.container}>
      <div className={styles.svg_container}>
        <img src={emptySvg} alt="" />
      </div>
      <div className={styles.description}>
        <div className={styles.title}>No User groups</div>
        <div className={styles.desc}>
          If you restrict the Course to a particular User Group, they shall be displayed here.
        </div>
      </div>
    </div>
  );
};
export default EmptyScreen;
