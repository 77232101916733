import React from 'react';
import styles from '../Portal.module.scss';

const MobileCoursesHeader = (props) => {
  const {
    mobileTabSelected,
    courses,
    search,
    setSearch,
    setLearning,
    setAudience,
    learning,
    audience,
    setNotificationData,
    handleSearch,
    setCourses,
    filterData,
    setFilterDataTemp,
    setFilterData,
    makeRequest,
    setShowEmptyTemplateFor,
    setSearchFilterEmptyStatecourses,
    setCourseListDefaultFilter,
    setErrorStateTemplate,
    setCourseFullList,
    setCourseListCopy,
    setCourseList,
    setShowViewAllListView,
    setShowViewAllCardView,
    filterCourses,
    FilterComponent,
    headingName,
    handleFilterApplyNew,
    filter,
    sortData,
    isOnline,
    setFilterCourses,
    handleFilter,
    dateIsoToTime,
    dateIsoToDate,
    dateIsoToNormalDate,
    setIsLoading,
  } = props;

  return (
    <React.Fragment>
      {mobileTabSelected === 'COURSES' ? (
        <div className={styles.action_wrapper}>
          <div
            className={[styles.search_wrapper, search.length > 0 ? styles.outline : null].join(' ')}
          >
            <input
              type="text"
              placeholder="Search Course"
              className={styles.search_input}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && search?.length < 3 && search?.length > 0) {
                  setNotificationData({
                    show: false,
                    type: 'WARNING',
                    title: 'Insufficient Search Input',
                    description: 'To search for any Course, enter 3 or more characters.',
                  });
                }
              }}
            />
            <div className={styles.icon_wrapper}>
              <div
                className={[styles.search, search.length > 0 ? styles.cross_icon : null].join(' ')}
                onClick={() =>
                  handleSearch({
                    value: 'courses',
                    setSearch,
                    setCourses,
                    courses,
                    setLearning,
                    setAudience,
                    learning,
                    audience,
                  })
                }
              />
            </div>
          </div>
          <div className={styles.filter_main_wrapper}>
            <div className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(' ')}>
              <div
                className={styles.filter}
                onClick={() =>
                  handleFilter({
                    value: 'courses',
                    setFilterCourses,
                  })
                }
              />
            </div>
            {filterCourses ? (
              <FilterComponent
                isPills={true}
                headingName={headingName}
                isLandingPage={true}
                filterData={filterData}
                sortData={sortData}
                handleFilterApply={(params) => {
                  handleFilterApplyNew({
                    params,
                    filterData,
                    setFilterDataTemp,
                    setFilterData,
                    makeRequest,
                    setShowEmptyTemplateFor,
                    setSearchFilterEmptyStatecourses,
                    setCourseListDefaultFilter,
                    setErrorStateTemplate,
                    setCourseFullList,
                    setCourseListCopy,
                    setCourseList,
                    setShowViewAllListView,
                    setShowViewAllCardView,
                    search,
                    filter,
                    dateIsoToTime,
                    dateIsoToDate,
                    dateIsoToNormalDate,
                    isOnline,
                    setIsLoading,
                  });
                }}
                handleFilterCancle={() => setFilterCourses(false)}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

MobileCoursesHeader.propTypes = {};

export default MobileCoursesHeader;
