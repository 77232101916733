import React, {useState} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import FilterComponent from '../../portal/filter';
import CheckboxComponent from '../../shared/checkbox';
import Header from '../../shared/header';
import Pills from '../../shared/pills';
import TagPresetModalComponent from '../tagPresetModal';
import styles from './CreateUserGroup.module.scss';
import {handleCollapsible, handleFilter} from '../../utils/AudienceManagementUtils/FilterUtils';

const LearningPathDropDownComponent = (props) => {
  const {isLearning, setSearch, search, filterCourses, setFilterCourses, handleRowSelected} = props;

  const filterData = [
    {name: 'Recent Activity', id: 5, checked: true},
    {name: 'A - Z', id: 1, checked: false},
    {name: 'Z - A', id: 2, checked: false},
    {name: 'Newest To Oldest', id: 3, checked: false},
    {name: 'Oldest To Newest', id: 4, checked: false},
  ];

  const handleFilterCancle = () => {
    setFilterCourses(!filterCourses);
  };

  return (
    <>
      {isLearning ? (
        <div className={styles.content_wrapper}>
          <div className={styles.top_area_wrapper}>
            <div
              className={[styles.search_main_wrapper, search.length > 3 ? styles.active : ''].join(
                ' ',
              )}
            >
              <div className={styles.search_wrapper}>
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={styles.search_input}
                />
                <div className={styles.icon_wrapper}>
                  <div className={styles.search} />
                </div>
              </div>
              {search.length > 3 ? (
                <div className={styles.dropdown_container}>
                  <div className={styles.list_wrapper}>
                    <div className={styles.list}>Nutrilite customers</div>
                    <div className={styles.list}>Selling Nutrilite products</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                    <div className={styles.list}>Contextual suggestive search</div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.action_wrapper}>
              <div className={[styles.audience_grp].join(' ')}>
                <div className={styles.add} />
                <div className={styles.txt}>Add learning path to this Audience group</div>
              </div>
            </div>
          </div>
          <div className={[styles.tbl_wrapper, styles.tbl_learning_path].join(' ')}>
            <div className={styles.tbl_header}>
              <div className={styles.tbl_head}>
                <div className={styles.filter_main_wrapper}>
                  <div
                    className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(
                      ' ',
                    )}
                  >
                    <div
                      className={styles.filter}
                      onClick={() => handleFilter('courses', setFilterCourses, filterCourses)}
                    />
                  </div>
                  {filterCourses ? (
                    <FilterComponent
                      left="-5px"
                      right="auto"
                      top="42px"
                      isPills={false}
                      headingName="Sort By"
                      filterData={filterData}
                      handleFilterCancle={() => handleFilterCancle()}
                    />
                  ) : null}
                </div>

                <CheckboxComponent checked={false} />

                <div className={styles.txt}>Learning paths</div>
              </div>
              <div className={styles.tbl_head}>
                <div className={styles.txt}>No of courses</div>
              </div>
              <div className={styles.tbl_head}>
                <div className={styles.txt}>Status</div>
              </div>
              <div className={styles.tbl_head}>
                <div className={styles.txt}>Remove</div>
              </div>
            </div>
            <div className={styles.tbl_body}>
              <div className={[styles.tbl_row, styles.selected].join(' ')}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={true} onClick={() => handleRowSelected()} />
                  <div className={styles.txt}>The Science Behind The 6 Step Selling Framework</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_10].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.live].join(' ')}>
                    <div className={styles.status_txt}>Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={[styles.tbl_row, styles.selected].join(' ')}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={true} />
                  <div className={styles.txt}>Selling Matters For New ABOs</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_10].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.draft].join(' ')}>
                    <div className={styles.status_txt}>Draft</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>Goal Setting and Action Planning For Leaders</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_10].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_offline].join(' ')}>
                    <div className={styles.status_txt}>Go Offline</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>How To Use Social Selling Assets</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_10].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>
                    Too Much or Too Little? Providing Effective Strategy
                  </div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_10].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>

              <div className={styles.tbl_row}>
                <div className={styles.tbl_data}>
                  <CheckboxComponent checked={false} />
                  <div className={styles.txt}>Successful Selling Conversations</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={styles.book_icon} />
                  <div className={[styles.txt, styles.ml_10].join(' ')}>20</div>
                </div>
                <div className={styles.tbl_data}>
                  <div className={[styles.status, styles.go_live].join(' ')}>
                    <div className={styles.status_txt}>Go Live</div>
                  </div>
                </div>
                <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                  <div className={styles.remove} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const CreateUserGroupComponent = ({closeNewUserGroup}) => {
  const filterData = [
    {name: 'Recent Activity', id: 5, checked: true},
    {name: 'A - Z', id: 1, checked: false},
    {name: 'Z - A', id: 2, checked: false},
    {name: 'Newest To Oldest', id: 3, checked: false},
    {name: 'Oldest To Newest', id: 4, checked: false},
  ];
  const [search, setSearch] = useState('');
  const [isCourses, setCourses] = useState(false);
  const [isLearning, setLearning] = useState(false);
  const [isTagPreset, setTagPreset] = useState(false);
  const [isDropdown, setDropdown] = useState(false);
  const [filterCourses, setFilterCourses] = useState(false);
  const pillData1 = [
    {id: 1, name: 'Social service'},
    {id: 2, name: 'Taiwan ABOs'},
    {id: 3, name: 'Nutrilite'},
  ];
  const pillData2 = [
    {id: 1, boldName: 'Market', name: ' Taiwan'},
    {id: 2, boldName: 'Registration', name: ' 90 days'},
    {id: 3, boldName: 'Market', name: ' India'},
  ];

  // const handleCollapsible = (value) => {
  //   if (value === 'learning_path') {
  //     setLearning(!isLearning);
  //     setCourses(false);
  //   } else {
  //     setCourses(!isCourses);
  //     setLearning(false);
  //   }
  // };

  // const handleFilter = (value) => {
  //   if (value === 'courses') {
  //     setFilterCourses(!filterCourses);
  //   }
  // };

  const handleFilterCancle = () => {
    setFilterCourses(!filterCourses);
  };

  const handleCloseTagPresetModal = () => {
    setTagPreset(!isTagPreset);
  };

  const handleTagPresetModal = () => {
    setTagPreset(!isTagPreset);
  };

  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const handleRowSelected = () => {
    //error
  };

  return (
    <React.Fragment>
      <div className={[styles.create_user_wrapper, ''].join(' ')}>
        {/* <div className={styles.header_main_wrapper}>
          <div className={styles.header_wrapper}>
            <div className={styles.heading_xs}>Audience Management</div>

            <div className={styles.heading}>Create new audience group</div>
            <div className={[styles.btn_wrapper, styles.btn_xs, styles.ml_auto].join(' ')}>
              <button className={styles.btn} onClick={() => closeNewUserGroup()}>
                Cancel
              </button>
              <button className={[styles.btn, styles.active].join(' ')}>Save changes</button>
            </div>
          </div>
        </div> */}

        {/* Header Component */}
        <Header
          headerName="Create new audience group"
          headerXSName="User Group Management"
          btnName1="Cancel"
          btnName2="Save changes"
          isHeaderXS={true}
          isBtn1={true}
          isBtn2={true}
          handleCloseButton={() => closeNewUserGroup()}
        />
        {/* Header Component */}

        <div className={styles.create_user_main_area}>
          <div className={styles.heading_xs}>Create new audience group</div>

          <div className={styles.inner_wrapper}>
            <form className={[styles.form_wrapper, 'bootstrap-wrapper'].join(' ')}>
              <div className={'row'}>
                <div className={[styles.mb_25, 'col-xl-6 col-lg-6 pull-left'].join(' ')}>
                  <div className={styles.form_field}>
                    <div className={styles.label}>Group Name</div>
                    <div className={styles.input_wrapper}>
                      <input
                        type={'text'}
                        placeholder={'Enter Group Name'}
                        className={styles.input}
                      />
                    </div>
                  </div>
                </div>

                <div className={[styles.mb_25, 'col-xl-12 col-lg-12 pull-left'].join(' ')}>
                  <div className={styles.form_field}>
                    <div className={styles.label}>Group Description</div>
                    <div className={styles.input_wrapper}>
                      <textarea
                        type={'text'}
                        placeholder={'Enter group description'}
                        className={styles.textarea}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={[
                    styles.tag_main_wrapper,
                    styles.clear_fix,
                    'col-xl-12 col-lg-12 pull-left',
                  ].join(' ')}
                >
                  <div className={[styles.row, 'row'].join(' ')}>
                    <div className={[styles.tag_wrapper, 'col-xl-6 col-lg-6 pull-left'].join(' ')}>
                      <div className={styles.tag_container}>
                        <div
                          className={[styles.padding_left_xs, 'col-xl-9 col-lg-9 pull-left'].join(
                            ' ',
                          )}
                        >
                          <div className={styles.form_field}>
                            <div className={styles.label}>Add tags</div>
                            <div className={styles.input_wrapper}>
                              <input
                                type={'text'}
                                placeholder={'Add Keywords and Enter'}
                                className={styles.input}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={[styles.load_preset, styles.direction_col].join(' ')}>
                          <div
                            className={[styles.label, styles.flex_center, styles.mb_12].join(' ')}
                            onClick={() => handleTagPresetModal()}
                          >
                            <div className={[styles.txt, styles.cursor_p].join(' ')}>
                              Load Preset
                            </div>
                            <div className={[styles.arrow_right, styles.ml_auto].join(' ')} />
                          </div>
                          <div className={[styles.btn_wrapper, styles.justify_left].join(' ')}>
                            <button className={[styles.btn, styles.m_0, styles.disabled].join(' ')}>
                              <div className={styles.add_icon} />
                              <div className={[styles.txt, styles.ml_10].join(' ')}>Add</div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={styles.pills_main_wrapper}>
                        <Pills pillData={pillData1} isClose={true} isBold={false} />
                      </div>
                    </div>

                    <div className={[styles.tag_wrapper, 'col-xl-6 col-lg-6 pull-left'].join(' ')}>
                      <div
                        className={[styles.padding_xs, 'col-xl-12 col-lg-12 pull-left'].join(' ')}
                      >
                        <div className={[styles.form_field, styles.new_targeter_wrapper].join(' ')}>
                          <div className={styles.new_targeter}>
                            <div className={[styles.label, styles.w_50].join(' ')}>
                              Add new targeter
                              <div className={styles.users}>
                                <div className={styles.user_icon} />
                                <div className={styles.txt}>0 users</div>
                              </div>
                            </div>
                            <div className={[styles.load_preset, styles.items_center].join(' ')}>
                              <div
                                className={[styles.label, styles.cursor_p, styles.ml_auto].join(
                                  ' ',
                                )}
                              >
                                Load Preset
                              </div>
                              <div className={[styles.arrow_right, styles.ml_10].join(' ')} />
                            </div>
                          </div>
                          <div
                            className={[styles.filter_main_wrapper, 'bootstrap-wrapper'].join(' ')}
                          >
                            <div className={styles.row_wrapper}>
                              <div
                                className={[styles.p_lr, 'col-xl-5 col-lg-5  pull-left'].join(' ')}
                              >
                                <div className={styles.filter_wrapper}>
                                  <div
                                    className={[styles.filter].join(' ')}
                                    onClick={() => handleDropdown()}
                                  >
                                    <div className={styles.txt}>Select a parameter</div>
                                    <div
                                      className={[styles.down_arrow, styles.ml_auto].join(' ')}
                                    />
                                  </div>
                                  {isDropdown ? (
                                    <div className={styles.dropdown_container}>
                                      <div className={styles.list_wrapper}>
                                        <div className={styles.list}>Pin</div>
                                        <div className={styles.list}>Class</div>
                                        <div className={styles.list}>Performance Bonus</div>
                                        <div className={styles.list}>Maybe LOS/LOA</div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div
                                className={[styles.p_lr, 'col-xl-4 col-lg-4 col-8 pull-left'].join(
                                  ' ',
                                )}
                              >
                                <div className={styles.filter_wrapper}>
                                  <div className={[styles.filter].join(' ')}>
                                    <div className={styles.txt}>Select an option</div>
                                    <div
                                      className={[styles.down_arrow, styles.ml_auto].join(' ')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={[styles.p_lr, 'col-xl-3 col-lg-3 col-4 pull-left'].join(
                                  ' ',
                                )}
                              >
                                <div
                                  className={[styles.btn_wrapper, styles.justify_left].join(' ')}
                                >
                                  <button
                                    className={[styles.btn, styles.m_0, styles.disabled].join(' ')}
                                  >
                                    <div className={styles.add_icon} />
                                    <div className={[styles.txt, styles.ml_10].join(' ')}>Add</div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.pills_main_wrapper}>
                        <Pills pillData={pillData2} isClose={true} isBold={true} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={[styles.mb_40, 'col-xl-12 col-lg-12 pull-left'].join(' ')}>
                  <div className={styles.collapsible_wrapper}>
                    <div className={styles.collapsible}>
                      <div className={styles.txt}>Learning Path Management</div>
                      <div className={styles.sub_txt}>(Optional)</div>
                      <div
                        className={[styles.down_arrow, isLearning ? styles.isOpen : ''].join(' ')}
                        onClick={() =>
                          handleCollapsible(
                            'learning_path',
                            setLearning,
                            setCourses,
                            isLearning,
                            isCourses,
                          )
                        }
                      />
                    </div>
                    <LearningPathDropDownComponent
                      isLearning={isLearning}
                      setSearch={setSearch}
                      search={search}
                      filterCourses={filterCourses}
                      setFilterCourses={setFilterCourses}
                      handleRowSelected={handleRowSelected}
                    />
                  </div>
                </div>

                <div className={[styles.mb_40, 'col-xl-12 col-lg-12 pull-left'].join(' ')}>
                  <div className={styles.collapsible_wrapper}>
                    <div className={styles.collapsible}>
                      <div className={styles.txt}>Course Management</div>
                      <div className={styles.sub_txt}>(Optional)</div>
                      <div
                        className={[styles.down_arrow, isCourses ? styles.isOpen : ''].join(' ')}
                        onClick={() =>
                          handleCollapsible(
                            'courses',
                            setLearning,
                            setCourses,
                            isLearning,
                            isCourses,
                          )
                        }
                      />
                    </div>
                    {isCourses ? (
                      <div className={styles.content_wrapper}>
                        <div className={styles.top_area_wrapper}>
                          <div
                            className={[
                              styles.search_main_wrapper,
                              search.length > 3 ? styles.active : '',
                            ].join(' ')}
                          >
                            <div className={styles.search_wrapper}>
                              <input
                                type="text"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                className={styles.search_input}
                              />
                              <div className={styles.icon_wrapper}>
                                <div className={styles.search} />
                              </div>
                            </div>
                            {search.length > 3 ? (
                              <div className={styles.dropdown_container}>
                                <div className={styles.list_wrapper}>
                                  <div className={styles.list}>Nutrilite customers</div>
                                  <div className={styles.list}>Selling Nutrilite products</div>
                                  <div className={styles.list}>Contextual suggestive search</div>
                                  <div className={styles.list}>Contextual suggestive search</div>
                                  <div className={styles.list}>Contextual suggestive search</div>
                                  <div className={styles.list}>Contextual suggestive search</div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className={styles.action_wrapper}>
                            <div className={[styles.audience_grp].join(' ')}>
                              <div className={styles.add} />
                              <div className={styles.txt}>Add course to this Audience group</div>
                            </div>
                          </div>
                        </div>
                        <div className={[styles.tbl_wrapper, styles.tbl_courses].join(' ')}>
                          <div className={styles.tbl_header}>
                            <div className={styles.tbl_head}>
                              <div className={styles.filter_main_wrapper}>
                                <div
                                  className={[
                                    styles.icon_wrapper,
                                    filterCourses ? styles.selected : '',
                                  ].join(' ')}
                                >
                                  <div
                                    className={styles.filter}
                                    onClick={() =>
                                      handleFilter('courses', setFilterCourses, filterCourses)
                                    }
                                  />
                                </div>
                                {filterCourses ? (
                                  <FilterComponent
                                    left="-5px"
                                    right="auto"
                                    top="42px"
                                    isPills={false}
                                    headingName="Sort By"
                                    filterData={filterData}
                                    handleFilterCancle={() => handleFilterCancle()}
                                  />
                                ) : null}
                              </div>

                              <CheckboxComponent checked={false} />

                              <div className={styles.txt}>Course name</div>
                            </div>
                            <div className={styles.tbl_head}>
                              <div className={styles.txt}>Status</div>
                            </div>
                            <div className={styles.tbl_head}>
                              <div className={styles.txt}>Remove</div>
                            </div>
                          </div>
                          <div className={styles.tbl_body}>
                            <div className={[styles.tbl_row, styles.selected].join(' ')}>
                              <div className={styles.tbl_data}>
                                <CheckboxComponent
                                  checked={true}
                                  onClick={() => handleRowSelected()}
                                />
                                <div className={styles.txt}>
                                  The Science Behind The 6 Step Selling Framework
                                </div>
                              </div>
                              <div className={styles.tbl_data}>
                                <div className={[styles.status, styles.live].join(' ')}>
                                  <div className={styles.status_txt}>Live</div>
                                </div>
                              </div>
                              <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                                <div className={styles.remove} />
                              </div>
                            </div>

                            <div className={[styles.tbl_row, styles.selected].join(' ')}>
                              <div className={styles.tbl_data}>
                                <CheckboxComponent checked={true} />
                                <div className={styles.txt}>Selling Matters For New ABOs</div>
                              </div>
                              <div className={styles.tbl_data}>
                                <div className={[styles.status, styles.draft].join(' ')}>
                                  <div className={styles.status_txt}>Draft</div>
                                </div>
                              </div>
                              <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                                <div className={styles.remove} />
                              </div>
                            </div>

                            <div className={styles.tbl_row}>
                              <div className={styles.tbl_data}>
                                <CheckboxComponent checked={false} />
                                <div className={styles.txt}>
                                  Goal Setting and Action Planning For Leaders
                                </div>
                              </div>
                              <div className={styles.tbl_data}>
                                <div className={[styles.status, styles.go_offline].join(' ')}>
                                  <div className={styles.status_txt}>Go Offline</div>
                                </div>
                              </div>
                              <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                                <div className={styles.remove} />
                              </div>
                            </div>

                            <div className={styles.tbl_row}>
                              <div className={styles.tbl_data}>
                                <CheckboxComponent checked={false} />
                                <div className={styles.txt}>How To Use Social Selling Assets</div>
                              </div>
                              <div className={styles.tbl_data}>
                                <div className={[styles.status, styles.go_live].join(' ')}>
                                  <div className={styles.status_txt}>Go Live</div>
                                </div>
                              </div>
                              <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                                <div className={styles.remove} />
                              </div>
                            </div>

                            <div className={styles.tbl_row}>
                              <div className={styles.tbl_data}>
                                <CheckboxComponent checked={false} />
                                <div className={styles.txt}>
                                  Too Much or Too Little? Providing Effective Strategy
                                </div>
                              </div>
                              <div className={styles.tbl_data}>
                                <div className={[styles.status, styles.go_live].join(' ')}>
                                  <div className={styles.status_txt}>Go Live</div>
                                </div>
                              </div>
                              <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                                <div className={styles.remove} />
                              </div>
                            </div>

                            <div className={styles.tbl_row}>
                              <div className={styles.tbl_data}>
                                <CheckboxComponent checked={false} />
                                <div className={styles.txt}>Successful Selling Conversations</div>
                              </div>
                              <div className={styles.tbl_data}>
                                <div className={[styles.status, styles.go_live].join(' ')}>
                                  <div className={styles.status_txt}>Go Live</div>
                                </div>
                              </div>
                              <div className={[styles.tbl_data, styles.justify_center].join(' ')}>
                                <div className={styles.remove} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Tag Preset Modal */}
        {isTagPreset ? (
          <TagPresetModalComponent closeTagPresetModal={handleCloseTagPresetModal} />
        ) : null}
        {/* Tag Preset Modal */}
      </div>
    </React.Fragment>
  );
};

CreateUserGroupComponent.propTypes = {};

export default CreateUserGroupComponent;
