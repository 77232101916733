import React, {useEffect, useState} from 'react';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';

const useLocalMarkets = () => {
  const [localMarketData, setLocalMarketData] = useState([]);
  const [localMarketDataSortedKeys, setLocalMarketDataSortedKeys] = useState([]);

  const getLanguage = async () => {
    let apiData;
    const configData = JSON.parse(localStorage.getItem("configData"));
    apiData = await makeRequest({
      method: 'GET',
      endpoint: `/api/v1/market-languages?locale=${configData?.locale}`,
    });

    if (apiData) {
      const computedList = await apiData.data?.otherLocales.reduce((acc, current) => {
        acc[current.locale] = current.title;
        return acc;
      }, {});
      setLocalMarketData(computedList);
      let count = 1;
      const computedListSorted = await apiData.data?.otherLocales.reduce((acc, current) => {
        acc[current.locale] = count++;
        return acc;
      }, {});
      setLocalMarketDataSortedKeys(computedListSorted);
    }
  };

  useEffect(() => {
    getLanguage();
  }, []);
  return {localMarketData, localMarketDataSortedKeys};
};

export default useLocalMarkets;
