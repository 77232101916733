import React, { useEffect } from 'react';
// import LearningPathComponent from "../components/learningPathManagement";
import CreateNewLearningPath from '../components/learningPathManagement/createNewLearningPath';
import LearningPathModalComponent from '../components/audienceManagement/learningPathModal';
import LearningPathComponent from '../components/learningPathManagement';
import { useDispatch } from 'react-redux';
import { setBlockNavigation } from '../redux/actions/newCourseActions';

function learningPathModal() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBlockNavigation(false));
  }, []);

  return (
    <div className="page_content">
      {/* <SideNavigation pageName="AUDIENCE-MANAGEMENT" />  */}
      {/* <LearningPathComponent/> */}
      {/* <CreateNewLearningPath /> */}
      {/* <LearningPathModalComponent /> */}

      <LearningPathComponent />
    </div>
  );
}
export default learningPathModal;
