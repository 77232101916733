export const SET_NEW_COURSE_DATA = 'SET_NEW_COURSE_DATA';
export const SET_NEW_IMAGE_DATA = 'SET_NEW_IMAGE_DATA';
export const SET_NEW_COURSE_WIZARD = 'SET_NEW_COURSE_WIZARD';
export const SET_ADAPT_FILE_DATA = 'SET_ADAPT_FILE_DATA';
export const SET_THUMBNAIL_ID = 'SET_THUMBNAIL_ID';
export const SET_NEW_COURSE_BLOCK_NAVIGATION = 'SET_NEW_COURSE_BLOCK_NAVIGATION';
export const SET_NEW_THUMBNAIL_DATA = 'SET_NEW_THUMBNAIL_DATA';
/* Export action types above this. */

/* export action creators here */
export const setNewCourseData = (options) => {
  return {
    type: SET_NEW_COURSE_DATA,
    payload: options,
  };
};
export const setNewImageData = (options) => {
  return {
    type: SET_NEW_IMAGE_DATA,
    payload: options,
  };
};
export const setNewCourseWizardState = (options) => {
  return {
    type: SET_NEW_COURSE_WIZARD,
    payload: options,
  };
};
export const setBlockNavigation = (options) => {
  return {
    type: SET_NEW_COURSE_BLOCK_NAVIGATION,
    payload: options,
  };
};
export const setNewAdaptFile = (options) => {
  return {
    type: SET_ADAPT_FILE_DATA,
    payload: options,
  };
};
export const setNewThumbnailId = (options) => {
  return {
    type: SET_THUMBNAIL_ID,
    payload: options,
  };
};
export const setNewThumbnailImageData = (options) => {
  return {
    type: SET_NEW_THUMBNAIL_DATA,
    payload: options,
  };
};
