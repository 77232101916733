import React, {useEffect, useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import CreateNewLearningPath from '../components/learningPathManagement/createNewLearningPath';
import { useDispatch } from 'react-redux';
import { setNewLPWizardState } from '../redux/actions/learningPathActions';
import { setBlockNavigation } from '../redux/actions/newCourseActions';

const CreateLearningPath = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    // This function will be called when the user attempts to leave the page, either by closing the tab/window, refreshing the page, or navigating to a different URL.
    const handleBeforeUnload = (event) => {
      dispatch(setBlockNavigation(false));
      dispatch(setNewLPWizardState({WizardOpen: false}));
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);

  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          {/* <SideNavigationComponent pageName="CONTENT-MANAGEMENT" {...props} /> */}
          <CreateNewLearningPath />
        </main>
      </div>
    </div>
  );
};

CreateLearningPath.propTypes = {};

export default CreateLearningPath;
