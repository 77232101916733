import React from 'react';
import {useEffect} from 'react';
import styles from './loadingShimmerCustom.module.scss';
const LoadingShimmerCustom = ({className, width, height}) => {
  //   return <ul>{getAnimalsContent(count)}</ul>;
  return (
    <React.Fragment>
      <div className={[styles.comment, styles.br, styles.animate, className].join(' ')}></div>
    </React.Fragment>
  );
};

LoadingShimmerCustom.propTypes = {};

export default LoadingShimmerCustom;
