import {makeRequest} from '../APIsUtils/httpsUtils';
import {_apiHeaderConfigs} from '../../utilAPIHeader';
export function cropImageModal_1_1(
  setButton_11_status,
  setCropDialogue,
  seteditImageExtends,
  setRatio,
  router,
  localStorage,
  croppedImageData,
  setLocalMainImage,
  ImageDetail,
  imageId,
) {
  setButton_11_status(2);
  setCropDialogue(false);
  seteditImageExtends('11');
  setRatio('1:1');
  router.push({
    pathname: '/edit-modal',
    query: {imageId},
  });
  localStorage.setItem('ratio', '11');

  if (croppedImageData?.croppedImage?.croppedImage_1_1) {
    // localStorage.setItem("mainImg", croppedImageData.croppedImage.croppedImage_1_1);
    setLocalMainImage(croppedImageData.croppedImage.croppedImage_1_1);
  } else {
    // localStorage.setItem("mainImg", ImageDetail.thumbnailImage);
    setLocalMainImage(ImageDetail.thumbnailImage);
  }
}

export function cropImageModal_16_9(
  setButton_169_status,
  setCropDialogue,
  seteditImageExtends,
  setRatio,
  router,
  localStorage,
  croppedImageData,
  setLocalMainImage,
  ImageDetail,
  imageId,
) {
  setButton_169_status(2);
  setCropDialogue(false);
  seteditImageExtends('169');
  setRatio('16:9');
  router.push({
    pathname: '/edit-modal',
    query: {imageId},
  });
  localStorage.setItem('ratio', '169');

  if (croppedImageData?.croppedImage?.croppedImage_16_9) {
    // localStorage.setItem("mainImg", croppedImageData.croppedImage.croppedImage_16_9);
    setLocalMainImage(croppedImageData.croppedImage.croppedImage_16_9);
  } else {
    // localStorage.setItem("mainImg", ImageDetail.bannerImage);
    setLocalMainImage(ImageDetail.bannerImage);
  }
}

export const dataURLtoFile = (dataurl, filename) => {
  const array = dataurl.split(',');
  const match = array[0].match(/:(.*?);/);
  if (!match || match.length < 1) {
    throw new Error('Invalid dataUrl');
  }
  const mime = match[1];
  const bstr = window.atob(array[1]);
  let n = bstr.length;
  const u8arry = new Uint8Array(n);
  while (n--) {
    u8arry[n] = bstr.codePointAt(n);
  }
  return new File([u8arry], filename, {type: mime});
};

// export const getBase64FromUrl = async (url) => {
//   const data = await makeRequest({
//     method: "GET",
//     url: url
//   })
//   const blob = await data.blob({
//     type: "image/jpeg",
//   });
//   return new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(blob);
//     reader.onloadend = () => {
//       const base64data = reader.result;
//       resolve(base64data);
//     };
//   });
// };

export const getBase64FromUrl = async (url) => {
  let {_accessToken, _idToken} = _apiHeaderConfigs();

  const data = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      // authorization: _accessToken,
      // profile: _idToken,
    },
  });
  const blob = await data?.blob({
    type: 'image/jpeg',
  });
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader?.result;
      resolve(base64data);
    };
  });
};

export const saveChanges = async (props) => {
  const {
    fetchImageList,
    fetchImageDetails,
    setCroppedImageData,
    setCroppedImage_1_1,
    setCroppedImage_16_9,
    setCroppedImageData11,
    setCroppedImageData169,
    selectedImageItem,
    handleImageClick,
    setCropDialogue,
    imageId,
    ImageDetail,
    imageinput,
    descriptionInput,
    croppedImageData169,
    getBase64FromUrl,
    dataURLtoFile,
    croppedImageData11,
    _config,
    makeRequest,
    isImageDetailDrawerUpdated,
    setNotificationData,
    setImageDetailsData,
    setImageDetailDrawerUpdated,
    setImageDetail,
    setImageInput,
    setDescriptionInput,
    setCourseIds,
    setMainImageBackup,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setCourseDetails,
    setLoading,
  } = props;
  // console.log(">>>> >>>>> saveChanges",props)
  let tagString = '';

  if (ImageDetail?.tags) {
    tagString = ImageDetail?.tags?.join();
  }

  const data = new FormData();
  data.append('fileName', imageinput);
  data.append('description', descriptionInput);
  data.append('tags', tagString);
  if (croppedImageData169?.imgFile) {
    data.append('IMAGE_16_9', croppedImageData169?.imgFile, croppedImageData169?.imgFile?.name);
  } else {
    await getBase64FromUrl(ImageDetail.bannerImage).then((imageDataUrl) => {
      const mainFile = dataURLtoFile(imageDataUrl, ImageDetail?.fileName);
      data.append('IMAGE_16_9', mainFile, 'croppedImage.jpeg');
    });
  }
  if (croppedImageData11?.imgFile) {
    data.append('IMAGE_1_1', croppedImageData11?.imgFile, croppedImageData11?.imgFile?.name);
  } else {
    await getBase64FromUrl(ImageDetail.thumbnailImage).then((imageDataUrl) => {
      const mainFile = dataURLtoFile(imageDataUrl, ImageDetail?.fileName);
      data.append('IMAGE_1_1', mainFile, 'croppedImage.jpeg');
    });
  }
  const dataImages = await makeRequest({
    method: 'PATCH',
    endpoint: '/admin/api/v1/images/' + imageId,
    body: data,
  });
  if (dataImages) {
    setNotificationData({
      show: true,
      type: 'SUCCESS',
      title: 'Saved Changes',
      description: `Crop extents of the ${imageinput} is saved.`,
    });
    setCropDialogue(false);
  }

  await fetchImageList();
  await handleImageClick(selectedImageItem);
  // console.log(">>>> >>>>> saveChanges >>>>  imageId",imageId)

  await fetchImageDetails({
    imageId,
    makeRequest,
    isImageDetailDrawerUpdated,
    setNotificationData,
    setImageDetailsData,
    imageinput,
    setImageDetailDrawerUpdated,
    setImageDetail,
    setImageInput,
    setDescriptionInput,
    setCourseIds,
    setMainImageBackup,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setCourseDetails,
  });
  setLoading(false);
  setCroppedImageData11(null);
  setCroppedImageData169(null);
  setCroppedImage_16_9(null);
  setCroppedImage_1_1(null);
  setCroppedImageData(null);
};
