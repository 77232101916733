import React, { useEffect } from 'react';
import AudienceManagementComponent from '../components/audienceManagement';
import { useDispatch } from 'react-redux';
import { setBlockNavigation } from '../redux/actions/newCourseActions';
function audienceManagement() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBlockNavigation(false));
  }, []);

  return (
    <div className="page_content">
      {/* <SideNavigation pageName="AUDIENCE-MANAGEMENT" />  */}
      <AudienceManagementComponent />
    </div>
  );
}
export default audienceManagement;
