import React from 'react';
import styles from '../Content.module.scss';

const SearchCrossIcon = (props) => {
  const {search, setSearch} = props;
  return (
    <React.Fragment>
      {search?.length === 0 && (
        <div className={styles.icon_wrapper}>
          <div className={styles.search} />
        </div>
      )}
      {search?.length > 0 && (
        <div className={styles.icon_wrapper} onClick={() => setSearch('')}>
          <div className={styles.close_icon} />
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchCrossIcon;
