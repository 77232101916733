import React, {useEffect, useState} from 'react';
import styles from './Checkbox.module.scss';

//for {!mandateCheck && (
const isMandateCheckFalse = (courseDetails, checked, onChangeHandler, disableCheck) => {
  return (
    <input
      key={courseDetails && courseDetails.id}
      className={styles.styled_checkbox}
      id={courseDetails && courseDetails.id}
      type="checkbox"
      //value={checkValue}
      // defaultChecked={checkValue}
      // checked={checkValue}
      defaultChecked={courseDetails ? courseDetails.checked : checked ? checked : false} // ? courseDetails.checked : checked ? checked : false}
      onClick={() => {
        if (courseDetails) {
          courseDetails.checked = !courseDetails.checked;
          onChangeHandler &&
            onChangeHandler({
              ...courseDetails,
            });
        }
      }}
      disabled={disableCheck}
    />
  );
};
//for {mandateCheck && (
const isMandateCheckTrue = (courseDetails, checkValue, onChangeHandler, disableCheck) => {
  return (
    <input
      key={courseDetails && courseDetails.id}
      className={styles.styled_checkbox}
      id={courseDetails && courseDetails.id}
      type="checkbox"
      //value={checkValue}
      defaultChecked={checkValue}
      checked={checkValue}
      // defaultChecked={courseDetails ? courseDetails.checked : checked ? checked : false} // ? courseDetails.checked : checked ? checked : false}
      onClick={() => {
        if (courseDetails) {
          courseDetails.checked = !courseDetails.checked;
          onChangeHandler &&
            onChangeHandler({
              ...courseDetails,
            });
        }
      }}
      disabled={disableCheck}
    />
  );
};

const CheckboxComponent = (props) => {
  const {
    courseDetails = {id: 0, checked: props.checkValue},
    checked,
    label,
    onChangeHandler,
    checkFunc,
    disableCheckbox,
    isDisable = false,
    mandateCheck = true,
  } = props;
  const [checkValue, setCheckValue] = useState(false);
  const [disableCheck, setDisableCheck] = useState(false);
  useEffect(() => {
    checked && setCheckValue(checked);
    //setDisableCheck(isDisable);
    courseDetails && courseDetails?.checked && setCheckValue(courseDetails?.checked);
  }, [checked]);
  useEffect(() => {
    setDisableCheck(isDisable);
    isDisable && setCheckValue(false);
  }, [isDisable]);
  return (
    <React.Fragment>
      <div
        className={`${styles.checkbox_wrapper} ${disableCheckbox && styles.disabled}`}
        onClick={() => {
          if (checkFunc && !disableCheck) {
            checkFunc();
            setCheckValue(!checkValue);
          }
        }}
      >
        {!mandateCheck &&
          isMandateCheckFalse(courseDetails, checked, onChangeHandler, disableCheck)}
        {mandateCheck &&
          isMandateCheckTrue(courseDetails, checkValue, onChangeHandler, disableCheck)}
        <label htmlFor={courseDetails && courseDetails.id}>
          {courseDetails && courseDetails.label ? courseDetails.label : label}
        </label>
      </div>
    </React.Fragment>
  );
};

CheckboxComponent.propTypes = {};

export default CheckboxComponent;
