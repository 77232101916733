import React, {useState} from 'react';
import styles from './Filter.module.scss';
import CheckboxComponent from '../../shared/checkbox';

const FilterComponent = ({
  left,
  right,
  top,
  isPills,
  handleFilterCancle,
  filterData,
  headingName,
}) => {
  const [isCollpasible, setCollapsible] = useState(true);

  const handleCollapse = () => {
    setCollapsible(!isCollpasible);
  };

  return (
    <React.Fragment>
      <div className={styles.filter_container} style={{left: left, right: right, top: top}}>
        <div className={styles.filter_content}>
          {isPills ? (
            <div className={styles.pills_wrapper}>
              <div className={[styles.pill, styles.draft].join(' ')}>
                <div className={styles.txt}>Draft</div>
              </div>
              <div className={[styles.pill, styles.goLive].join(' ')}>
                <div className={styles.txt}>Go Live</div>
              </div>
              <div className={[styles.pill, styles.goOffline].join(' ')}>
                <div className={styles.txt}>Go Offline</div>
              </div>
              <div className={[styles.pill, styles.goLive].join(' ')}>
                <div className={styles.txt}>Live</div>
              </div>
            </div>
          ) : null}

          <div className={styles.collapse_main_wrapper}>
            <div className={styles.collapsible_wrapper}>
              <div className={styles.collapse_button} onClick={() => handleCollapse()}>
                <div className={styles.txt}>{headingName}</div>
                <div className={isCollpasible ? styles.collapse_icon : styles.non_collapse} />
              </div>
              {isCollpasible ? (
                <div className={styles.filter_list}>
                  <div className={styles.filter_data}>
                    {filterData &&
                      filterData?.map((item, index) => (
                        <div className={styles.list} key={index}>
                          <CheckboxComponent checked={item.checked} label={item.name} />
                        </div>
                      ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.collapsible_wrapper}>
              <div className={styles.collapse_button}>
                <div className={styles.txt}>Publish Date</div>
                <div className={styles.non_collapse} />
                {/* <div className={styles.collapse_icon} /> */}
              </div>
            </div>
            <div className={styles.collapsible_wrapper}>
              <div className={styles.collapse_button}>
                <div className={styles.txt}>Course Status</div>
                <div className={styles.non_collapse} />

                {/* <div className={styles.collapse_icon} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filter_button_wrapper}>
          <button type="button" className={[styles.btn, styles.dark, styles.m_lr_6].join(' ')}>
            Apply
          </button>
          <button
            type="button"
            className={[styles.btn, styles.m_lr_6].join(' ')}
            onClick={handleFilterCancle}
          >
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

FilterComponent.propTypes = {};

export default FilterComponent;
