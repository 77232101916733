import React from 'react';
import styles from './StatusLabel.module.scss';

const statusConditionReturn = (props) => {
  const {item} = props;

  switch (item?.state || item?.course_state || item?.learningPathState || item?.courseState) {
    case 'Offline':
      return 'Offline';
    case 'Live':
      return 'Live';
    case 'Archive':
      return 'Archive';
    case 'Draft':
      return 'Draft';
  }
};
const StatusLabel = (props) => {
  const {item, large = false} = props;
  // console.log(">>>> >>>> StatusLabel >>>> props",props)
  return large ? (
    <div
      className={[
        styles.instance_status,

        statusConditionReturn({item: item}) == 'Live'
          ? styles.live_sts
          : statusConditionReturn({item: item}) == 'Draft'
          ? styles.draft_sts
          : statusConditionReturn({item: item}) == 'Archive'
          ? styles.archive_sts
          : statusConditionReturn({item: item}) == 'Offline'
          ? styles.offline_sts
          : '',
      ].join(' ')}
    >
      {item?.state || item?.course_state || item?.learningPathState || item?.courseState}
    </div>
  ) : (
    <div
      className={[
        styles.tbl_data,
        statusConditionReturn({item: item}) == 'Live'
          ? styles.live_card
          : statusConditionReturn({item: item}) == 'Offline'
          ? styles.offline_card
          : statusConditionReturn({item: item}) == 'Draft'
          ? styles.draft_card
          : statusConditionReturn({item: item}) == 'Archive'
          ? styles.archive
          : '',
      ].join(' ')}
    >
      <div className={[styles.txt, styles.highlight].join(' ')}>
        {item?.state || item?.course_state || item?.courseState || item?.learningPathState}
      </div>
    </div>
  );
};

export default StatusLabel;
