import React from 'react';
import styles from '../Content.module.scss';
const DrawerImageNameInput = (props) => {
  const {
    handleImageNameChange,
    regexNameChinese,
    handleImageName,
    imageinput,
    isfileError,
    ImageDetail,
    usedFileNameList,
    setImageDetailDrawerUpdated,
    updateImageDetails,
  } = props;
  return (
    <div
      className={[styles.info_container, isfileError ? styles.error_wrapper : '', ' '].join(' ')}
    >
      <div className={styles.label_wrapper}>
        <div className={styles.label_text}>File name</div>
        <div className={styles.tag_text}>Max 30 characterss</div>
      </div>
      <div className={styles.form_field}>
        <div className={styles.input_wrapper}>
          <input
            type={'text'}
            placeholder={'Enter image name.jpg'}
            className={styles.input}
            pattern={regexNameChinese}
            onChange={(e) => {
              e.target.value.length === 0 && handleImageNameChange('');
              handleImageNameChange(
                // e.target?.value?.toString()?.replaceAll(regexNameChinese, '').trimStart(),
                e.target?.value?.toString()?.trimStart(),
              );
            }}
            onBlur={(e) =>
              handleImageName(
                e.target.value,
                ImageDetail,
                usedFileNameList,
                setImageDetailDrawerUpdated,
                updateImageDetails,
              )
            }
            value={
              // imageinput?.toString()?.replaceAll(regexNameChinese, '') // Comment coz don't want - in between image name
              imageinput?.toString()
            }
          />
          {isfileError ? <div className={styles.error_icon_red}></div> : null}
        </div>
      </div>
      {isfileError ? (
        <div className={styles.error_message}>
          {imageinput?.length < 3
            ? 'Need 3 or more characters. Valid characters include A-z, 0-9, ., :, ?, !, -, “ ”, ‘’'
            : 'Image Name already exists in the Library. Enter a different name to save changes.'}
        </div>
      ) : null}
    </div>
  );
};

export default DrawerImageNameInput;
