//import {SET_SAVED_COURSES_DATA} from '../actions/AudienceGroup/coursesActions';
import {
  SET_NOTIFICATION_DATA,
  SET_COURSE_MODE,
  SET_DASHBOARD_NOTIFICATION_SWITCH,
  SET_DASHBOARD_NOTIFICATION_COUNT,
  SET_NOTIFICATION_LIST,
  SET_SELECTED_NAVIGATION_OPTION,
  SET_CREATE_USER_DATA,
  SET_IS_USER_LOGGED_IN,
  ERROR_STATE,
  NOTIFICATION_ERROR_STATE,
  SET_LEARNIG_PATH_WIAZRD,
  SET_NEW_TRANSLATION_ADDED,
  SET_COUNTRY_LIST,
  SET_REMOTE_CONFIG,
  SET_IMAGE_REPLACE_STATE
} from '../actions/uiActions';

const SET_SAVED_COURSES_DATA = 'SET_SAVED_COURSES_DATA';

const initialState = {
  notificationData: null,
  dashboardNotificationSwitch: false,
  dashboardNotificationCount: 0,
  notificationList: [],
  courseMode: '',
  pageName: 'DASHBOARD',
  currentUserId: null,
  errorTemplateState: {
    varient: 'networkError',
    status: false,
  },
  notificationErrorState: false,
  learningPathMode: '',
  addedTranslationListState: [],
  countryList: [],
  remoteConfig: {},
  remoteConfigData: {},
  imageReplaceState:false
};

export const UIStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SAVED_COURSES_DATA: {
      return {...state, tags: action.payload};
    }

    case SET_NOTIFICATION_DATA: {
      return {
        ...state,
        notificationData: action.payload,
      };
    }

    case SET_NEW_TRANSLATION_ADDED: {
      return {
        ...state,
        addedTranslationListState: action.payload,
      };
    }

    case SET_COUNTRY_LIST: {
      return {
        ...state,
        countryList: action.payload,
      };
    }

    case SET_REMOTE_CONFIG: {
      return {
        ...state,
        remoteConfig: action.payload,
      };
    }

    case SET_IMAGE_REPLACE_STATE:{
      return{
        ...state,
        imageReplaceState: action.payload
      }
    }

    case SET_DASHBOARD_NOTIFICATION_SWITCH: {
      return {
        ...state,
        dashboardNotificationSwitch: action.payload,
      };
    }

    case SET_DASHBOARD_NOTIFICATION_COUNT: {
      return {
        ...state,
        dashboardNotificationCount: action.payload,
      };
    }

    case SET_NOTIFICATION_LIST: {
      return {
        ...state,
        notificationList: action.payload,
      };
    }

    case SET_COURSE_MODE: {
      return {
        ...state,
        courseMode: action.payload,
      };
    }

    case SET_LEARNIG_PATH_WIAZRD: {
      return {
        ...state,
        learningPathMode: action.payload,
      };
    }

    case SET_SELECTED_NAVIGATION_OPTION: {
      return {
        ...state,
        pageName: action.payload,
      };
    }

    case SET_CREATE_USER_DATA: {
      return {
        ...state,
        currentUserId: action.payload,
      };
    }

    case SET_IS_USER_LOGGED_IN: {
      return {
        ...state,
        isUserLoggedIn: action.payload,
      };
    }
    case ERROR_STATE: {
      const newValue = action.payload;
      return {...state, errorTemplateState: newValue};
    }
    case NOTIFICATION_ERROR_STATE: {
      return {
        ...state,
        notificationErrorState: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
