import React from 'react';
import styles from './translationConfirmModal.module.scss';
// import CheckboxComponent from '';
// import EditImageModal from '../addImagePage/EditImageModal/index';

const TranslationConfirmModal = (props) => {
  const {
    heading,
    subHeading,
    handleCloseConfirmModal,
    handleConfirmChanges,
    notificationData,
    Background,
    Notification,
  } = props;
  return (
    <React.Fragment>
      <>
        <div className={[styles.modal_wrapper, ''].join(' ')}>
          <Background backgroundFlag="small">
            <div className={styles.delete_modal}>
              <div className={styles.close_wrapper} onClick={() => handleCloseConfirmModal()}>
                <div className={styles.close_icon} />
              </div>
              <div className={styles.header_wrapper}>
                <div className={styles.heading}>{heading ? heading : ''}</div>
              </div>
              <div className={styles.sub_header}>{subHeading ? subHeading : ''}</div>

              <div className={styles.btn_wrapper}>
                <button className={styles.btn} onClick={() => handleCloseConfirmModal()}>
                  Cancel
                </button>
                <button
                  className={[styles.btn, styles.active].join(' ')}
                  onClick={() => handleConfirmChanges()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Background>
        </div>
        {notificationData && notificationData.show && <Notification />}
      </>
    </React.Fragment>
  );
};

TranslationConfirmModal.propTypes = {};

export default TranslationConfirmModal;
