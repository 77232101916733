import React from 'react';
import styles from './Pills.module.scss';

const PillsComponent = ({
  pillData = [],
  isClose,
  isBold,
  onClosePill,
  color = 'blue',
  bgBlue = false,
}) => {
  return (
    <React.Fragment>
      <div className={[styles.pills_wrapper].join(' ')}>
        {pillData?.map((item, index) => (
          <div
            className={[
              color == 'black' ? styles.pill_black : styles.pill,
              bgBlue ? styles.bg_blue : '',
            ].join(' ')}
            key={index}
          >
            <div className={styles.txt}>
              {isBold ? (
                <>
                  <span className={styles.bold}>{`${item.boldName}`}</span>
                  <span>{`${item}`}</span>
                </>
              ) : null}
              {!isBold && item ? item?.title : null}
            </div>
            {isClose ? (
              <div className={styles.rounded_close} onClick={() => onClosePill(item)} />
            ) : null}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

PillsComponent.propTypes = {};

export default PillsComponent;
