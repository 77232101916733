import {INCREMENT_VALUE, DECREMENT_VALUE} from '../actions/example/exampleActionTypes';

const initialState = {
  value: 0,
};

export const exampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_VALUE: {
      const newValue = state.value + action.payload;
      return {...state, value: newValue};
    }

    case DECREMENT_VALUE: {
      const newValue = state.value - action.payload;
      return {...state, value: newValue};
    }

    default: {
      return state;
    }
  }
};
