import React, { useEffect } from 'react';
import styles from './RoundToggle.module.scss';
import {dummyFunction} from '../../utils/commonUtil';
const RoundToggle = (props) => {
  const {
    handleToggleSwitch = dummyFunction,
    clikedData = [],
    //search = ""
    //coursesUIDataCopy=[]
    showAddedCourses,
    setShowAddedCourses
  } = props;
  useEffect(()=>{
    if(clikedData?.length===0) setShowAddedCourses(true)
  },[clikedData])
  //console.log(">>>> >>>> RoundToggle >>>> props",props);
  return (
    <React.Fragment>
      <div
        className={[
          !showAddedCourses || clikedData?.length > 0
            ? `${styles.round_toggle_wapper} ${styles.active}`
            : showAddedCourses
            ? `${styles.round_toggle_wapper} ${styles.disabled}`
            : null,
        ].join(' ')}
        onChange={() => {
          handleToggleSwitch();
        }}
      >
        <label className={styles.switch}>
          <input
            type={clikedData?.length > 0 && 'checkbox'}
            checked={!showAddedCourses}
            onClick={() => handleToggleSwitch()}
          />
          <span className={`${styles.slider} ${styles.round}`}></span>
          <div className={`${styles.whitecheck_icon}`}></div>
          <div className={`${styles.icon} ${styles.blue_course_icon}`}></div>
          <div className={`${styles.icon} ${styles.white_cross_icon}`}></div>
          <div className={`${styles.icon} ${styles.black_course_icon}`}></div>
        </label>
      </div>
    </React.Fragment>
  );
};

RoundToggle.propTypes = {};

export default RoundToggle;
