import { SET_ALL_COURSES_LIST } from "../actions/courseManagementActions";

const initialState = {
    allCoursesList: []
};

export const courseManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_COURSES_LIST: {
            return { ...state, allCoursesList: action.payload };
        }

        default: {
            return state;
        }
    }
};
