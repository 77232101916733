export function closeEditImageExtends(
  ratio,
  setCroppedImage_16_9,
  setButton_169_status,
  setCroppedImage_1_1,
  setButton_11_status,
  setCroppedImg,
  seteditImageExtends,
  router,
  imageId,
  setCroppedImageData,
  setCroppedImageData169,
  setCroppedImageData11,
) {
  if (Number(ratio) === 169) {
    setCroppedImage_16_9(null);
    setButton_169_status(1);
    setCroppedImg(null);
    setCroppedImageData(null);
    setCroppedImageData169(null);
  } else if (Number(ratio) === 11) {
    setCroppedImage_1_1(null);
    setButton_11_status(1);
    setCroppedImg(null);
    setCroppedImageData(null);
    setCroppedImageData11(null);
  }
  if (ratio === '16:9') {
    setCroppedImage_16_9(null);
    setButton_169_status(1);
    setCroppedImg(null);
    setCroppedImageData(null);
    setCroppedImageData169(null);
  }
  if (ratio === '1:1') {
    setCroppedImage_1_1(null);
    setButton_11_status(1);
    setCroppedImg(null);
    setCroppedImageData(null);
    setCroppedImageData11(null);
  }
  setCroppedImg(null);
  setCroppedImageData(null);
  if (ratio === '16:9' || ratio === '1:1') {
    router.push({
      pathname: '/Contentmanagement',
      query: {
        imgId: imageId,
      },
    });
  } else {
    router.push({
      pathname: '/add-new-image',
      query: {
        imgId: imageId,
      },
    });
  }
  seteditImageExtends('hide');
}
