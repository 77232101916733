/* Export action types below this. */
export const SET_CROPPED_IMAGE_DATA = 'SET_CROPPED_IMAGE_DATA';
export const SET_CROPPED_IMAGE_DATA_11 = 'SET_CROPPED_IMAGE_DATA_11';
export const SET_CROPPED_IMAGE_DATA_169 = 'SET_CROPPED_IMAGE_DATA_169';
export const SET_RATIO = 'SET_RATIO';
export const SET_MAIN_IMAGE_BACKUP = 'SET_MAIN_IMAGE_BACKUP';
export const SET_CROPPED_IMAGE_16_9 = 'SET_CROPPED_IMAGE_16_9';
export const SET_CROPPED_IMAGE_1_1 = 'SET_CROPPED_IMAGE_1_1';
export const SET_BACKUP_IMAGE_16_9 = 'SET_BACKUP_IMAGE_16_9';
export const SET_BACKUP_IMAGE_1_1 = 'SET_BACKUP_IMAGE_1_1';
export const SET_REPLACED_IMAGE = 'SET_REPLACED_IMAGE';
export const SET_SELECTED_IMAGE_16_9_BACKUP = 'SET_SELECTED_IMAGE_16_9_BACKUP';
export const SET_SELECTED_IMAGE_1_1_BACKUP = 'SET_SELECTED_IMAGE_1_1_BACKUP';
export const SET_IMAGE_LIST_ALL = 'SET_IMAGE_LIST_ALL';
export const SET_BUTTON_169_STATUS = 'SET_BUTTON_169_STATUS';
export const SET_BUTTON_11_STATUS = 'SET_BUTTON_11_STATUS';
export const SET_REPLACED_IMAGE_UPLOADED = 'SET_REPLACED_IMAGE_UPLOADED';
export const SET_IMAGE_DATA = 'SET_IMAGE_DATA';
export const SET_LOCAL_MAIN_IMAGE = 'SET_LOCAL_MAIN_IMAGE';
export const SET_NEW_IMAGE_ID = 'SET_NEW_IMAGE_ID';
export const SET_DELETE_CHECKBOX = 'SET_DELETE_CHECKBOX';
export const SET_THUMBNAIL_IMAGE_16_9 = 'SET_THUMBNAIL_IMAGE_16_9';

/* Export action types above this. */

/* export action creators here */
export const setCroppedImageData169 = (options) => {
  return {
    type: SET_CROPPED_IMAGE_DATA_169,
    payload: options,
  };
};
export const setCroppedImageData11 = (options) => {
  return {
    type: SET_CROPPED_IMAGE_DATA_11,
    payload: options,
  };
};
export const setCroppedImageData = (options) => {
  return {
    type: SET_CROPPED_IMAGE_DATA,
    payload: options,
  };
};
export const setRatio = (options = '16:9') => {
  return {
    type: SET_RATIO,
    payload: options,
  };
};

export const setMainImageBackup = (options) => {
  return {
    type: SET_MAIN_IMAGE_BACKUP,
    payload: options,
  };
};

export const setSelectedImage_16_9_Backup = (options) => {
  return {
    type: SET_SELECTED_IMAGE_16_9_BACKUP,
    payload: options,
  };
};

export const setSelectedImage_1_1_Backup = (options) => {
  return {
    type: SET_SELECTED_IMAGE_1_1_BACKUP,
    payload: options,
  };
};

export const setReplacedImage = (options) => {
  return {
    type: SET_REPLACED_IMAGE,
    payload: options,
  };
};

export const setBackupImage_16_9 = (options) => {
  return {
    type: SET_BACKUP_IMAGE_16_9,
    payload: options,
  };
};

export const setBackupImage_1_1 = (options) => {
  return {
    type: SET_BACKUP_IMAGE_1_1,
    payload: options,
  };
};

export const setCroppedImage_16_9 = (options) => {
  return {
    type: SET_CROPPED_IMAGE_16_9,
    payload: options,
  };
};

export const setCroppedImage_1_1 = (options) => {
  return {
    type: SET_CROPPED_IMAGE_1_1,
    payload: options,
  };
};

export const setImageListAll = (options) => {
  return {
    type: SET_IMAGE_LIST_ALL,
    payload: options,
  };
};

export const setButton_169_status = (options) => {
  return {
    type: SET_BUTTON_169_STATUS,
    payload: options,
  };
};

export const setButton_11_status = (options) => {
  return {
    type: SET_BUTTON_11_STATUS,
    payload: options,
  };
};

export const setReplacedImageUploaded = (options) => {
  return {
    type: SET_REPLACED_IMAGE_UPLOADED,
    payload: options,
  };
};

export const setImageData = (options) => {
  return {
    type: SET_IMAGE_DATA,
    payload: options,
  };
};

export const setLocalMainImage = (options) => {
  return {
    type: SET_LOCAL_MAIN_IMAGE,
    payload: options,
  };
};

export const setNewImageId = (options) => {
  return {
    type: SET_NEW_IMAGE_ID,
    payload: options,
  };
};

export const setDeletePreviousImage = (options) => {
  return {
    type: SET_DELETE_CHECKBOX,
    payload: options,
  };
};

export const setThumbnailImage169 = (options) => {
  return {
    type: SET_THUMBNAIL_IMAGE_16_9,
    payload: options,
  };
};
