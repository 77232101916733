import React, {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import config from '../../../config';
import styles from './translationDetailsDrawer.module.scss';
import {
  dummyFunction,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  drawerCalculatedHeight,
  pullbox,
} from '../../utils/commonUtil';
import AdaptFileModalComponent from '../../courseManagement/CourseWizard/AdaptFileModal';

export const handleClickOutside = (props) => {
  const {closeDrawerModal, e} = props;
  const el = document.getElementById('divParentOfFilter');
  if (el !== null) {
    const outside = !el?.contains(e.target);
    if (outside) {
      if (e.target.className.includes('Drawer_custom')) {
        closeDrawerModal();
      }
    }
  }
};

const TranslationDetailsDrawerComponent = (props) => {
  const {
    drawerDetails = {},
    drawerOpen = false,
    isEdit = false,
    isAdd = false,
    selectLanguage = {},
    drawerClose,
    isAPIFailed = false,
    drawerTryAgainCall = dummyFunction,
    drawerIdForBrokenLink = '',
    closeDrawerModal,
    saveChanges,
    language,
    translationFor,
    placeholders,
    data,
    isLp,
    courseData,
    learningPathId,
    newCourseID,

    // Course File
    handleTranslationFileUpload,
    adaptFileButtonState,
    uploadingFileButton,
    uploadingFileButtonHindi,
    fileDataTemp,
    fileDataTempHindi,
    isCourseIDFetched,
    setNotificationData,
    setPreviewAdaptFileFlag,
    previewAdaptFileCreateNewCourse,
    adaptFileState,
    openInNewTab,
    fileData,
    setFileData,
    isLocale,
    replaceFile = false,
    setSelectedLanguage = () => {}
  } = props;

  const router = useHistory();
  const {query = {}} = router.location;
  const {currentEnv} = config;
  const [drawerHeight, setDrawerHeight] = useState('');
  const [width, setWidth] = useState(0);
  const [isDropdown, setDropdown] = useState(false);
  const [dropdownVal, setDropdownVal] = useState('');
  const [languageObj, setLanguageObj] = useState({});
  const [btnEnable, setBtnEnable] = useState(false);
  const [lang, setLang] = useState('');
  const [langName, setLangName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [speaker, setSpeaker] = useState('');
  const [adaptFile, setAdaptFile] = useState('');
  const [fieldValidation, setFieldValidation] = useState({
    title: false,
    description: false,
  });

  // Course File
  const [localState, setLocalState] = useState('');
  const [openAdaptFileModal, setOpenAdaptFileModal] = useState(false);
  const [configData, setConfigData] = useState({});
  // const [fileData, setFileData] = useState(null);

  useEffect(() => {
    setLang(selectLanguage?.language || selectLanguage?.locale);
    setLangName(selectLanguage?.languageName);
    setTitle(selectLanguage?.title);
    setDescription(selectLanguage?.description);
    setSpeaker(selectLanguage?.speaker);
    setAdaptFile(selectLanguage?.adaptFileName);

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    setConfigData(configData);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      handleClickOutside({e: e, closeDrawerModal: closeDrawerModal});
    });
    setDrawerHeight(140);
  }, [drawerOpen]);

  useEffect(() => {
    if (drawerHeight >= 600) {
      setLang('');
      setTitle('');
      setDescription('');
      setSpeaker('');
      setAdaptFile('');
      setBtnEnable(false);
      closeDrawerModal();
    }
  }, [drawerHeight]);

  useEffect(() => {
    if (translationFor == 'course wizard') {
      if (isEdit) {
        let prevData = localStorage.getItem('previousLanguage');
        prevData = JSON.parse(prevData);
        if (
          description?.length > 0 &&
          title?.length > 0 &&
          lang?.length > 0 &&
          !uploadingFileButton &&
          (speaker ? speaker?.length <= 30 : true) &&
          (adaptFileState == 'SUCCESS' || selectLanguage?.adaptFileName)
        ) {
          if (
            description !== prevData?.description ||
            title !== prevData?.title ||
            speaker !== prevData?.speaker ||
            selectLanguage?.adaptFileName !== prevData?.adaptFileName
          ) {
            setBtnEnable(true);
          }
        } else {
      
          setBtnEnable(false);
        }
      } else {
        if (
          languageObj &&
          languageObj?.description?.length > 0 &&
          languageObj?.title?.length > 0 &&
          languageObj?.languageName?.length > 0 &&
          // languageObj?.speaker?.length <= 30 &&
          adaptFileState === 'SUCCESS' &&
           (languageObj?.speaker ? languageObj?.speaker?.length <= 30 : true)
        ) {
          setBtnEnable(true);
        } else {
          setBtnEnable(false);
        }
      }
    } else {
      if (isEdit) {
        if (description?.length > 0 && title?.length > 0 && lang?.length > 0 ) { //&& languageObj?.speaker?.length <= 30
          setBtnEnable(true);
        } else {
          setBtnEnable(false);
        }
      } else {
        if (
          languageObj &&
          languageObj?.description?.length > 0 &&
          languageObj &&
          languageObj?.title?.length > 0 &&
          languageObj &&
          languageObj?.languageName?.length > 0 &&
          // languageObj?.speaker?.length <= 30 &&
          !fieldValidation.title &&
          // !fieldValidation.speaker &&
          !fieldValidation.description
        ) {
          setBtnEnable(true);
        } else {
          setBtnEnable(false);
        }
      }
    }
  }, [languageObj, adaptFileState, uploadingFileButton]);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };
  const handleSelected = (val) => {
    setDropdownVal(val?.title);
    setLanguageObj((prev) => ({
      ...prev,
      language: val?.locale,
      languageName: val?.title,
      speaker: val?.speaker,
      adaptFileName: fileDataTemp?.name ? fileDataTemp?.name : '',
      version: fileDataTemp?.version ? fileDataTemp?.version : 0,
    }));
    setDropdown(!isDropdown);
    let language = {
      language: val?.locale,
      languageName: val?.title,
      locale: val?.locale
    };
    setSelectedLanguage(val);
    localStorage.setItem('languageName', JSON.stringify(language));
  };
  const editLanguageObj = () => {
    let tempLanguageObj;
    if (isEdit) {
      setLanguageObj((prev) => ({
        ...prev,
        locale: selectLanguage?.language ? selectLanguage?.language : selectLanguage?.locale,
        title: title,
        speaker: speaker,
        description: description,
        adaptFileName:
          fileDataTemp?.name !== null || fileDataTemp?.name !== undefined
            ? fileDataTemp?.name
            : fileData?.name,
        version: fileDataTemp?.version ? fileDataTemp?.version : 0,
      }));
      tempLanguageObj = {
        ...languageObj,
        locale: selectLanguage?.language ? selectLanguage?.language : selectLanguage?.locale,
        title,
        description,
        speaker,
        adaptFileName:
          fileDataTemp?.name !== null || fileDataTemp?.name !== undefined
            ? fileDataTemp?.name
            : fileData?.name,
        version: fileDataTemp?.version ? fileDataTemp?.version : 0,
      };
    }
    saveChanges(tempLanguageObj ? tempLanguageObj : languageObj, fileDataTemp);
  };

  const handleInputChange = (e) => {
    let name = e && e?.target?.name;
    let value = e && e?.target?.value;
  

    setFieldValidation((prev) => ({
      ...prev,
      [name]:
        name === 'description'
          ? value.length > 500
          : name === 'title'
          ? value.length > 100
          : value.length > 30,
    }));
    if (isEdit) {
      if (name == 'title') {
        setTitle((prev) => value);
      } else if (name == 'description') {
        setDescription((prev) => value);
      } else if (name == 'speaker') {
        if(value.length>30) setBtnEnable(false) 
        setSpeaker((prev) => value);
      }

      setLanguageObj((prev) => ({
        // ...prev,
        id: selectLanguage?.id,
        language: selectLanguage?.locale,
        languageName: selectLanguage?.languageName,
        title: title,
        speaker: speaker,
        description: description,
        adaptFileName: selectLanguage?.adaptFileName,
        version: selectLanguage?.version || 0,
      }));
    } else {
      setLanguageObj((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const selectLanguageBanner = () => {
    setNotificationData({
      show: true,
      type: 'WARNING',
      title: 'Please select a language before uploading/replacing a file',
    });
  }

  const disablehostingBanner = () => {
    setNotificationData({
      show: true,
      type: 'FAILURE',
      title: 'No Action can be taken during File Uploading',
      description:
        'All the buttons are disabled when the file is uploading. Please wait for the file to upload to take any action.',
    });
  };

  const getEnableSaveAndExit = ({primaryTitle = '', primaryDescription = ''}) => {
    let flag = false;

    if (primaryTitle?.length > 0 && primaryDescription?.length > 0) {
      if (
        primaryTitle?.length >= 1 &&
        primaryDescription?.length >= 1 &&
        data?.thumbnailId?.length > 0
      ) {
        flag = true;
      }
    } else if (primaryTitle?.length > 0 || primaryDescription?.length > 0) {
      if (
        primaryTitle?.length >= 1 &&
        primaryDescription?.length >= 1 &&
        data?.thumbnailId?.length > 0
      ) {
        flag = true;
      }
    }
    return flag;
  };

  return (
    <>
      {/* Course File Modal */}
      {openAdaptFileModal && (
        <AdaptFileModalComponent
          unzipState={adaptFileState}
          closeModal={() => {
            setOpenAdaptFileModal(false);
            //fetchSelectedCourse(data?.courseId);
          }}
          localState={JSON.parse(localStorage.getItem('configData')?.locale)}
          openPreviewFile={() => {
            openInNewTab();
            //fetchSelectedCourse(data?.courseId);
            // updateCourseDetails(data?.courseId,{adaptFileName:fileData?.name,courseState:data?.courseState
            //   //  ,version :versionNum
            // })
            // })
            localState == configData?.locale ? setFileData(fileDataTemp) : null;
          }}
        />
      )}
      {/* Course File Modal */}

      <div
        id="divParentOfFilter"
        className={[styles.drawer_container, ''].join(' ')}
        onClick={() => (isDropdown ? setDropdown(false) : null)}
      >
        <div
          className={styles.custom_overlay}
          style={drawerOverlayCalculatedHeight({drawerHeight: drawerHeight})}
        ></div>

        <div
          className={styles.drawer_wrapper}
          style={drawerCalculatedHeight({drawerHeight: drawerHeight})}
        >
          <div
            className={styles.mob_bar}
            draggable
            onTouchMove={(e) => {
              pullbox({e: e, setDrawerHeight: setDrawerHeight});
            }}
            onTouchEnd={() => {
              mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
            }}
          >
            <div className={styles.line_bar} onClick={() => closeDrawerModal}></div>
          </div>

          <div className={[styles.middle_container, ''].join(' ')}>
            <div className={styles.header}>
              <div className={styles.title}>{isEdit ? 'Edit' : 'Add'} Language</div>
              <div className={styles.close_wrapper} onClick={closeDrawerModal}>
                <div className={styles.close_icon} />
              </div>
            </div>

            <div className={styles.form_main_wrapper}>
              <div className={styles.form_wrapper}>
                <div className={styles.field}>
                  <div className={styles.label}>Language*</div>
                  <div className={`${styles.select_list_wrapper} ${isDropdown ? styles.open : ''}`}>
                    <div
                      className={`${styles.select_list} ${
                        dropdownVal && dropdownVal?.length > 0 ? styles.selected : ''
                      }`}
                      onClick={handleDropdown}
                    >
                      {isEdit
                        ? selectLanguage?.languageName
                        : dropdownVal && dropdownVal?.length > 0
                        ? dropdownVal
                        : placeholders?.dropdown || 'Select Dropdown'}
                      <div className={styles.down_arrow} />
                    </div>
                    {isDropdown && !isEdit ? (
                      <div className={styles.dropdown_container}>
                        {Array.isArray(language) &&
                          language.map((item, index) => (
                            <div
                              key={index}
                              className={`${styles.item} ${item.isAdded ? styles.disabled : ''}`}
                              onClick={() => (item.isAdded ? null : handleSelected(item))}
                            >
                              {item.title}
                            </div>
                          ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>

              {/* Course File */}
              {/* Course File */}
              {translationFor == 'course wizard' ? (
                <>
                  <div className={`${styles.form_wrapper} `}>
                    <div className={styles.field}>
                      <div className={styles.inner_label}>
                        <div className={`${styles.label} ${styles.adapt_file}`}>
                          Course File*
                          <div className={[styles.tag_text, styles.padding_zero].join(' ')}>
                            {' '}
                            100MB max | .zip
                          </div>
                        </div>
                      </div>
                      <div className={[styles.sub_text, styles.course_txt].join(' ')}>
                        Upload an Course file from your local device and Preview it by clicking on
                        ‘Preview Course file’.
                      </div>
                    </div>
                  </div>
                  <div
                    className={[styles.info_container, styles.course_adapt_wrapper, ''].join(' ')}
                  >
                    {/* English File upload Starts */}
                    <div className={[styles.inr_wrapper].join(' ')}>
                      <div className={styles.title_wrapper}>
                        <input
                          type={'file'}
                          // accept=".zip,.rar,.7z"
                          accept=".zip"
                          onChange={(event) => {
                            handleTranslationFileUpload({
                              event,
                              courseID: data?.id || data?.courseId || newCourseID,
                              version: data?.version ? Number(data?.version) : 0,
                              selectedLanguage: dropdownVal || selectLanguage?.languageName,
                            });
                          }}
                          hidden={true}
                          multiple={false}
                          id={'uploadFileTranslationEdit'}
                          // disabled={
                          //   isLocale == false && !isEdit
                          // }
                        />
                        <label
                          htmlFor="uploadFileTranslationEdit"
                          style={{
                            display: 'block',
                            width: '100%',
                            marginBottom: '0',
                          }}
                        >
                          <div
                            className={
                              uploadingFileButton
                                ? [styles.btn_wrapper_uaf, styles.inprogress_state].join(' ')
                                : (fileDataTemp?.name ||
                                    getEnableSaveAndExit({
                                      primaryTitle: data?.primaryTitle,
                                      primaryDescription: data?.primaryDescription,
                                    })) &&
                                  !uploadingFileButtonHindi
                                ? [styles.btn_wrapper_uaf, styles.final_state].join(' ')
                                : styles.btn_wrapper_uaf
                            }
                            onClick={() => {
                              if(!selectLanguage?.locale) {
                                selectLanguageBanner();
                                return;
                              }
                              if (uploadingFileButton || uploadingFileButtonHindi) {
                                disablehostingBanner();
                              }
                              setLocalState(configData?.locale);
                            }}
                          >
                            <div
                              className={styles.txt}
                              style={{
                                minHeight: 'fit-content',
                                marginBottom: '0',
                              }}
                            >
                              {uploadingFileButton && replaceFile
                                ? 'File Replacing'
                                : uploadingFileButton && !isEdit
                                ? 'Course File Uploading'
                                : fileDataTemp?.adaptFileName ||
                                  selectLanguage?.adaptFileName ||
                                  fileDataTemp?.name ||
                                  (courseData?.adaptFileName && isEdit)
                                ? 'Replace Course File'
                                : 'Upload Course File'}
                            </div>
                            <div className={styles.upload_icon}></div>
                          </div>
                          {uploadingFileButton && (
                            <div className={styles.note_text}>
                              Note: Please do not navigate to any other page when the file is
                              uploading.
                            </div>
                          )}
                        </label>
                        {(adaptFile ||
                          fileDataTemp?.name ||
                          (courseData?.adaptFileName && isEdit)) &&
                        !uploadingFileButton ? (
                          <div className={[styles.attachement_wrapper, ''].join(' ')}>
                            <div className={[styles.txt, styles.prev_adapt_file_name].join(' ')}>
                              {isEdit
                                ? selectLanguage?.adaptFileName
                                  ? selectLanguage?.adaptFileName
                                  : fileDataTemp?.name // For Edit
                                : fileDataTemp?.name
                                ? fileDataTemp?.name
                                : ''}
                            </div>
                            <div
                              className={[
                                styles.txt,
                                styles.preview_adapt_text,
                                styles.bold,
                                styles.cursor_p,
                              ].join(' ')}
                              onClick={async () => {
                                setPreviewAdaptFileFlag(true);
                                await previewAdaptFileCreateNewCourse(
                                  selectLanguage?.locale
                                    ? selectLanguage?.locale
                                    : selectLanguage?.language,
                                );
                              }}
                            >
                              Preview File
                              <div className={styles.left_arrow_icon} />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* English File upload Starts */}
                  {/* </div> */}
                </>
              ) : (
                ''
              )}
              {/* Course File */}
              {/* Course File */}

              {/* Title */}
              <div
                className={`${styles.form_wrapper} ${
                  fieldValidation?.title ? styles.error_wrapper : ''
                }`}
              >
                <div className={styles.field}>
                  <div className={styles.label_wrapper}>
                    <div className={styles.label}>Title*</div>
                    <div className={styles.tag_text}>Max 100 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    <input
                      type="text"
                      name="title"
                      placeholder={placeholders?.title || 'Enter Title'}
                      onChange={(e) => handleInputChange(e)}
                      value={title}
                    />
                  </div>
                </div>
                {fieldValidation.title && (
                  <div className={styles.error_message}>Maximum character limit reached</div>
                )}
                {/* <div className={styles.error_message}>Maximum character limit reached</div> */}
              </div>
              {/* Title */}

              {/* Speaker */}
              <div
                className={`${styles.form_wrapper} ${
                  fieldValidation?.speaker ? styles.error_wrapper : ''
                }`}
              >
                <div className={styles.field}>
                  <div className={styles.label_wrapper}>
                    <div className={styles.label}>Author/Speaker Name</div>
                    <div className={styles.tag_text}>Max 30 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    <input
                      type="text"
                      name="speaker"
                      placeholder={placeholders?.author || 'Enter Author/Speaker Name'}
                      onChange={(e) => handleInputChange(e)}
                      value={speaker}
                    />
                  </div>
                </div>
                {fieldValidation.speaker && (
                  <div className={styles.error_message}>Maximum character limit reached</div>
                )}
              </div>
              {/* Speaker */}

              {/* Description */}
              <div
                className={`${styles.form_wrapper} ${
                  fieldValidation?.description ? styles.error_wrapper : ''
                }`}
              >
                <div className={styles.field}>
                  <div className={styles.label_wrapper}>
                    <div className={styles.label}>Description*</div>
                    <div className={styles.tag_text}>Max 500 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    <textarea
                      name="description"
                      placeholder={placeholders?.description || 'Enter Description'}
                      onChange={(e) => handleInputChange(e)}
                      value={description}
                    />
                  </div>
                </div>
                {fieldValidation.description && (
                  <div className={styles.error_message}>Maximum character limit reached</div>
                )}
              </div>
              {/* Description */}

              <div className={styles.btn_wrapper}>
                <div
                  className={`${styles.btn} ${btnEnable ? styles.save : styles.disabled}`}
                  onClick={() => {
                    editLanguageObj();
                  }}
                >
                  Save
                  <div className={styles.save_icon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TranslationDetailsDrawerComponent.propTypes = {};

export default TranslationDetailsDrawerComponent;
