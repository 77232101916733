import React from 'react';
import styles from './EmptyScreen.module.scss';

const EmptyScreenComponent = ({small = false}) => {
  return small == true ? (
    <div className={[styles.empty_container, ''].join(' ')}>
      {/* Learning Empty error container */}
      {/* Please add the height class dynamically to below div, "page_height" for page and "block_wise_height" for Dashboard */}
      <div className={[styles.empty_screen_wrapper_small].join(' ')}>
        <div className={styles.inner_wrapper_empty}>
          <div className={styles.empty_screen}>
            <div className={[styles.image_wrapper, styles.empty_img].join(' ')}></div>
            <div className={styles.content_wrapper_small}>
              <div className={styles.label}>No results available</div>
              <div className={styles.content}>
                Try another search term or remove filters to broaden your results
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={[styles.empty_container, ''].join(' ')}>
      {/* Learning Empty error container */}
      {/* Please add the height class dynamically to below div, "page_height" for page and "block_wise_height" for Dashboard */}
      <div className={[styles.empty_screen_wrapper].join(' ')}>
        <div className={styles.inner_wrapper_empty}>
          <div className={styles.empty_screen}>
            <div className={[styles.image_wrapper, styles.empty_img].join(' ')}></div>
            <div className={styles.content_wrapper}>
              <div className={styles.label}>No results available</div>
              <div className={styles.content}>
                Try another search term or remove filters to broaden your results
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EmptyScreenComponent.propTypes = {};

export default EmptyScreenComponent;
