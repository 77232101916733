import React, {useEffect, useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import CategorySkillsTagsManagementComponent from '../components/categorySkillsTagsManagement';
import { useDispatch } from 'react-redux';
import { setBlockNavigation } from '../redux/actions/newCourseActions';

const CategorySkillsTagsManagement = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBlockNavigation(false));
  }, []);

  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          <CategorySkillsTagsManagementComponent />
        </main>
      </div>
    </div>
  );
};

CategorySkillsTagsManagement.propTypes = {};

export default CategorySkillsTagsManagement;
