import {SET_NEW_IMAGE_API_DATA, SET_CROP_DIALOGUE} from '../actions/actionImageLibrary';
const initialState = {imagesData: []};

export const reducerImageLibrary = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_IMAGE_API_DATA: {
      return {...state, imagesData: action.payload};
    }

    case SET_CROP_DIALOGUE: {
      return {...state, cropDialogue: action.payload};
    }

    default: {
      return state;
    }
  }
};
