export const audienceListForEachFun = (listArray) => {
  let tempArr = [];
  listArray?.forEach((ele) => {
    tempArr.push({
      audGrpId: ele?.id || ele?.audGrpId,
      courseType: ele?.courseType,
    });
  });
  return tempArr;
};
export const fullDataCheckFunc = (data, fileData, publishButtonAfterPreview) => {
  if (
    data &&
    data?.primaryTitle?.length > 0 &&
    data?.primaryTitle !== '' &&
    data?.primaryDescription?.length > 0 &&
    data?.primaryDescription !== '' &&
    data?.thumbnail !== '' &&
    data?.thumbnail?.length > 0 &&
    data?.thumbnailId !== '' &&
    data?.thumbnailId !== undefined &&
    data?.category !== '' &&
    data?.category?.length > 0 &&
    data?.duration !== 0 &&
    data?.duration > 0 &&
    ((fileData && fileData?.name.length > 0) || (data?.adaptFileName?.length > 0))

    // &&
    //publishButtonAfterPreview === false
  ) {
    return true;
  } else {
    return false;
  }
};
export const publishEditCourseArrayFun = (
  publishCourse,
  data,
  addUserGroupList,
  deleteUserGroupList,
  fileData,
  locale,
  newCourseIDData = null,
) => {
  const configData = JSON.parse(localStorage.getItem("configData"));
  let publishData = Object.assign({}, data);

  let tempCategories = '';
  let tempSkills = [];
  let tempKeywords = [];
  let addUserList = [];
  let deleteUserList = [];
  if (typeof data?.category === 'string') {
    tempCategories = data?.category;
  }
  if (typeof data?.category === 'object') {
    data?.category?.map((ele) => {
      tempCategories = ele?.id;
    });
  }
  data?.skills?.map((ele) => {
    ele.id ? tempSkills.push(ele?.id) : tempSkills.push(ele);
  });
  data?.keywords?.map((ele) => {
    ele.id ? tempKeywords.push(ele.id) : tempKeywords.push(ele);
  });
  publishData.date = new Date().toISOString();
  if (publishCourse) {
    publishData.courseState = 'Live';
  } else {
    publishData.courseState = data?.courseState
      ? data?.courseState
      : data?.course_state
      ? data?.course_state
      : 'Offline';
  }

  if (publishData?.primaryTitle?.length > 1 && publishData?.chineseTitle?.length > 1) {
    publishData.isMultilingual = Boolean(true);
  } else {
    publishData.isMultilingual = Boolean(false);
  }
  if (publishData?.types?.length > 0) {
    publishData.types = publishData?.types;
  }
  // else {
  //   publishData.types = publishData?.types;
  // }
  if (fileData?.name && locale == configData?.locale) {
    publishData.adaptFileName = fileData?.name;
  } else {
    publishData.adaptFileName = publishData?.adaptFileName ? publishData?.adaptFileName : '';
  }

  delete publishData?.id;
  delete publishData?.courseUrl;
  delete publishData?.thumbnail;
  delete publishData?.updatedAt;
  delete publishData?.updatedBy;
  // delete publishData?.types;
  delete publishData?.course_state;
  delete publishData?.filename;
  delete publishData?.isMultilingual;
  delete publishData?.launchDate;
  delete publishData?.category;
  delete publishData?.skills;
  delete publishData?.keywords;
  delete publishData?.createdBy;

  publishData.version = data?.version;
  publishData.category = tempCategories;
  publishData.skills = tempSkills;
  publishData.keywords = tempKeywords;
  publishData.isDownloadAvailable = true;
  if (publishData?.thumbnailId === null) {
    delete publishData?.thumbnailId;
  }
  addUserGroupList?.forEach((ele) => {
    addUserList.push({
      audGrpId: ele?.id || ele?.audGrpId,
      courseType: ele?.courseType,
    });
  });
  deleteUserGroupList?.forEach((ele) => {
    deleteUserList.push({
      audGrpId: ele?.id || ele?.audGrpId,
      courseType: ele?.courseType,
    });
  });
  publishData.audienceGroups = {
    deleteAudGrpIds: deleteUserList,
    addAudGrpIds: addUserList,
  };
  return publishData;
};
export const EditCourseArrayFun = (
  publishCourse,
  data,
  addUserGroupList,
  deleteUserGroupList,
  fileData,
) => {
  let publishData = Object.assign({}, data);

  let tempCategories = '';
  let tempSkills = [];
  let tempKeywords = [];
  let addUserList = [];
  let deleteUserList = [];
  if (typeof data?.category === 'string') {
    tempCategories = data?.category;
  }
  if (typeof data?.category === 'object') {
    data?.category?.map((ele) => {
      tempCategories = ele?.id;
    });
  }
  data?.skills?.map((ele) => {
    ele.id ? tempSkills.push(ele?.id) : tempSkills.push(ele);
  });
  data?.keywords?.map((ele) => {
    ele.id ? tempKeywords.push(ele.id) : tempKeywords.push(ele);
  });
  publishData.date = new Date().toISOString();
  if (publishCourse) {
    publishData.courseState = 'Live';
  } else {
    publishData.courseState = data?.courseState
      ? data?.courseState
      : data?.course_state
      ? data?.course_state
      : 'Draft';
  }

  if (publishData?.primaryTitle?.length > 1 && publishData?.chineseTitle?.length > 1) {
    publishData.isMultilingual = Boolean(true);
  } else {
    publishData.isMultilingual = Boolean(false);
  }
  if (publishData?.types?.length > 0) {
    publishData.types = publishData?.types;
  }
  // else {
  //   publishData.types = publishData?.types;
  // }
  // if (fileData?.name) {
  //   publishData.adaptFileName = fileData?.name;
  // } else {
  //   publishData.adaptFileName = publishData?.adaptFileName ? publishData?.adaptFileName : '';
  // }
  delete publishData?.id;
  delete publishData?.courseUrl;
  delete publishData?.thumbnail;
  delete publishData?.updatedAt;
  delete publishData?.updatedBy;
  // delete publishData?.types;
  delete publishData?.course_state;
  delete publishData?.filename;
  delete publishData?.isMultilingual;
  delete publishData?.launchDate;
  delete publishData?.category;
  delete publishData?.skills;
  delete publishData?.keywords;
  delete publishData?.createdBy;
  publishData.version = data?.version;
  publishData.category = tempCategories;
  publishData.skills = tempSkills;
  publishData.keywords = tempKeywords;
  if (publishData?.thumbnailId === null) {
    delete publishData?.thumbnailId;
  }
  addUserGroupList?.forEach((ele) => {
    addUserList.push({
      audGrpId: ele?.audGrpId ? ele?.audGrpId : ele?.id,
      courseType: ele?.courseType,
    });
  });
  deleteUserGroupList?.forEach((ele) => {
    deleteUserList.push({
      audGrpId: ele?.audGrpId ? ele?.audGrpId : ele?.id,
      courseType: ele?.courseType,
    });
  });
  publishData.audienceGroups = {
    deleteAudGrpIds: deleteUserList,
    addAudGrpIds: addUserList,
  };
  return publishData;
};
